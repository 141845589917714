import { useState, useEffect, Fragment } from "react"
import axios from "axios"
import { Dialog, Transition } from "@headlessui/react"
import { Switch } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/outline"

const PrivacyConsentPopup = ({
  config,
  user,
  isOpen,
  setToken,
  setUser,
  local,
}) => {
  const [privacyTerms, setPrivacyTerms] = useState(
    user.privacyConsent?.main
      ? user.privacyConsent
      : { main: true, analytics: true }
  )
  const [message, setMessage] = useState("Save my Choices")
  const [popUp, setPopUp] = useState(false)

  useEffect(() => {
    if (!isOpen && !user.privacyConsent?.main) {
      setPopUp(true)
    }
  }, [isOpen, user.privacyConsent])

  const closePopUp = async () => {
    try {
      if (
        JSON.stringify(privacyTerms) === JSON.stringify(user.privacyConsent)
      ) {
        setPopUp(false)
        return
      }
      if (local === "signup" || local === "join") {
        setUser({ ...user, privacyConsent: { ...privacyTerms } })
        setPopUp(false)
        return
      }
      const updateTerms = await axios.put(
        `/api/users/community/profile/update`,
        { _id: user.id, privacyConsent: { ...privacyTerms } },
        config
      )
      if (updateTerms) {
        const checkUser = await axios.get("/api/auth/check", config)
        if (checkUser) {
          localStorage.setItem("authToken", checkUser.data.access_token)
        }
        if (!privacyTerms.analytics) {
          setMessage("The page will now refresh")
          setTimeout(() => {
            window.location.reload()
          }, 2000)
          return
        }
      }
      setUser({ ...user, privacyConsent: { ...privacyTerms } })
      setPopUp(false)
    } catch (e) {
      setPopUp(false)
    }
  }

  return (
    <Transition appear show={popUp} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closePopUp}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-60" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl z-50">
              <Dialog.Title
                as="h3"
                className="text-mono text-lg font-bold leading-6 text-gray-900"
              >
                👁‍🗨 Privacy Terms
              </Dialog.Title>
              <div className="flex flex-col justify-center my-2 overflow-hidden ">
                <p className="mt-1 text-mono text-justify text-xs text-gray-500">
                  Your privacy is important to us.
                </p>
                <p className="mt-1 text-mono text-justify text-xs text-gray-500">
                  In this Community, we use analytical and preferences cookies.
                  These are necessary for the site to work properly and to give
                  us information about its usage.
                </p>
                <div className="flex flex-col justify-center items-center">
                  <Switch.Group
                    as="div"
                    className="w-full flex justify-start items-center space-x-6 mt-2 py-2"
                  >
                    <Switch
                      as="button"
                      checked={privacyTerms.main}
                      onChange={() => {
                        setPrivacyTerms({
                          ...privacyTerms,
                          main: true,
                        })
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                      className={`${
                        privacyTerms.main ? "bg-gray-600" : "bg-gray-200"
                      } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline ml-4 md:ml-0`}
                    >
                      {({ checked }) => (
                        <span
                          className={`${
                            checked ? "translate-x-5" : "translate-x-0"
                          } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                        >
                          <CheckIcon className={checked ? "" : "hidden"} />
                        </span>
                      )}
                    </Switch>
                    <Switch.Label className="mt-2 tracking-wide text-grey-darker text-xs font-bold mb-2">
                      <p className="uppercase">
                        Technical & Preference cookies
                      </p>
                      <p className="text-mono text-justify text-xs text-gray-500">
                        Necessary for the proper functionality of the website
                      </p>
                    </Switch.Label>
                  </Switch.Group>
                  <Switch.Group
                    as="div"
                    className="w-full flex justify-start items-center space-x-6 mt-2 py-2"
                  >
                    <Switch
                      as="button"
                      checked={privacyTerms.analytics}
                      onChange={() => {
                        setPrivacyTerms({
                          ...privacyTerms,
                          analytics: !privacyTerms.analytics,
                        })
                      }}
                      className={`${
                        privacyTerms.analytics ? "bg-flime-600" : "bg-gray-200"
                      } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline ml-4 md:ml-0`}
                    >
                      {({ checked }) => (
                        <span
                          className={`${
                            checked ? "translate-x-5" : "translate-x-0"
                          } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                        >
                          <CheckIcon className={checked ? "" : "hidden"} />
                        </span>
                      )}
                    </Switch>
                    <Switch.Label className="mt-2 tracking-wide text-grey-darker text-xs font-bold mb-2">
                      <p className="uppercase">Analytics cookies</p>
                      <p className="text-mono text-justify text-xs text-gray-500">
                        To improve our services and the website functionalities
                      </p>
                    </Switch.Label>
                  </Switch.Group>
                </div>
                <p className="mt-4 text-mono text-justify text-xs text-gray-500">
                  By using this site, you accept our terms and conditions. You
                  may read our full data protection and privacy terms{" "}
                  <a
                    href="https://www.founderland.org/privacy-policy"
                    alt="privacy terms details"
                    rel="noreferrer"
                    target="_blank"
                    className="text-black hover:text-fblue"
                  >
                    here
                  </a>
                </p>
              </div>

              <div className="mt-4 w-full flex justify-center">
                <button
                  type="button"
                  className="w-52 inline-flex justify-center px-4 py-2 text-sm font-medium text-fblue-900 bg-blue-300 border border-transparent hover:bg-fblue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-fblue-500"
                  onClick={closePopUp}
                >
                  {message}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PrivacyConsentPopup
