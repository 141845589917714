import instructions1 from "../../../assets/images/flag_instructions1.png"
import instructions2 from "../../../assets/images/flag_instructions2.png"
import { ChevronRightIcon } from "@heroicons/react/outline"

const Headshot = () => {
  return (
    <div className="relative flex flex-col md:flex-row justify-center my-2">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
        <p className="text-mono text-justify text-sm text-white p-4">
          Use a full color image:
        </p>
        <img
          src={instructions1}
          className="flex-none w-60 object-cover"
          alt="Portrait Example"
        />
        <p className="text-xs p-2 text-white">
          *Simple Solid background preferred*
        </p>
      </div>
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
        <p className="text-mono text-justify text-sm text-white p-4">
          landscape format (1080 x 566 pixels):
        </p>
        <img
          src={instructions2}
          className="flex-none w-60 object-cover"
          alt="Frame Example"
        />
        <p className="text-xs p-2 text-white">
          *Center yourself within the frame*
        </p>
      </div>
      <div className="hidden md:absolute bottom-1/2 left-1/2">
        <ChevronRightIcon className="w-10" />
      </div>
    </div>
  )
}

export default Headshot
