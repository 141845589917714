import { useContext, useState, useEffect } from "react"
import SymbolPicker from "../Widgets/SymbolPicker"
import Banner from "../../Admin/Widgets/Banner"
import FastTrackContext from "../../../contexts/FastTrack"
import { Popover } from "@headlessui/react"
import { ColorSwatchIcon } from "@heroicons/react/outline"

const symbolColors = ["fblue", "fred", "fpink", "flime"]

const ThirdStep = ({ nextStep, previousStep, isActive }) => {
  const { pageTop, scrollUp, data, setData } = useContext(FastTrackContext)
  const [banner, setBanner] = useState({ show: false })
  const [bannerColor, setBannerColor] = useState(data.bannerColor)
  const [availableColors, setAvailableColors] = useState([])
  const [proceed, setProceed] = useState(false)
  const [touched, setTouched] = useState({
    symbols: [true, true, true],
    bannerColor: true,
  })
  useEffect(() => {
    if (isActive) scrollUp()
  }, [isActive])

  const setSymbols = (symbol, i) => {
    let current = [...data.symbols]
    current[i] = symbol
    setData({
      ...data,
      symbols: current,
    })
  }
  useEffect(() => {
    const symbolsArray = data.symbols.map((item) => item.icon)
    let isDuplicateS = symbolsArray.some(
      (item, i) => symbolsArray.indexOf(item) !== i
    )
    const colorArray = data.symbols.map((item) => item.color)
    let isDuplicateC = colorArray.some(
      (item, i) => colorArray.indexOf(item) !== i
    )
    isDuplicateS || isDuplicateC ? setProceed(false) : setProceed(true)
    const available = symbolColors.filter((color) =>
      data.symbols.some((item) => item.color === color)
    )
    if (!available.some((item) => item === bannerColor)) {
      setBannerColor(available[0])
      setData((prev) => ({
        ...prev,
        bannerColor: available[0],
      }))
    }
    setAvailableColors(available)
  }, [data])

  return (
    <div
      className="h-full flex flex-col justify-around items-center w-full bg-white p-3"
      ref={pageTop}
    >
      <div className="w-full flex flex-col justify-center items-center">
        <h1 className="font-bold text-xl md:text-2xl lg:text-3xl text-center text-grotesk">
          Building Your Flag
        </h1>
      </div>
      <div className="w-full flex flex-col">
        <div className="flex flex-col justify-center items-center w-full text-grotesk font-bold">
          <h4 className="w-full py-4 text-grotesk text-center text-base lg:text-xl">
            We have a collection of symbols to help you create your own flag
            that is true to you and your land.
          </h4>
          <div className="w-full flex flex-col md:flex-row justify-center p-6">
            <div className="w-full md:w-1/3">
              <SymbolPicker
                data={data.symbols[0]}
                setSymbols={setSymbols}
                position={0}
                touched={touched}
                setTouched={setTouched}
              />
            </div>
            <div className="w-full md:w-1/3">
              <SymbolPicker
                data={data.symbols[1]}
                setSymbols={setSymbols}
                position={1}
                touched={touched}
                setTouched={setTouched}
              />
            </div>
            <div className="w-full md:w-1/3">
              <SymbolPicker
                data={data.symbols[2]}
                setSymbols={setSymbols}
                position={2}
                touched={touched}
                setTouched={setTouched}
              />
            </div>
          </div>
          <h4 className="w-full py-4 text-grotesk text-center text-base lg:text-lg">
            Select 3 icons that speak to you and a color for each icon.
          </h4>
          <div className="relative w-full h-20 flex px-10 justify-center">
            <div
              className={`bg-${bannerColor} absolute bottom-0 h-20 flex items-center justify-start w-1/3`}
            >
              <p
                className={`w-full pt-1 text-center text-${
                  bannerColor === "flime" ? "black" : "white"
                } uppercase text-hanson font-bold text-xl tracking-widest`}
              >
                {data.firstName}'s Land
              </p>
              <Popover className=" group">
                <Popover.Button className="absolute -bottom-2 outline-none right-0 bg-flime p-1 h-8 w-8 text-center rounded-full cursor-pointer transition duration-200 group-hover:bg-fblue group-hover:text-white">
                  <ColorSwatchIcon className="h-6 w-6" />
                </Popover.Button>
                <Popover.Panel className="absolute z-20 -bottom-30 right-3 md:right-2 rounded shadow-lg bg-white w-24 h-24 p-2 flex flex-wrap justify-between ">
                  {({ close }) =>
                    availableColors.map((color) => (
                      <span
                        key={color}
                        className={`w-8 h-8 cursor-pointer rounded-full border-2 hover:border-blue-600 bg-${color} ${
                          color === bannerColor
                            ? "border-green-600"
                            : "border-white"
                        }`}
                        onClick={(e) => {
                          setData((prev) => ({
                            ...prev,
                            bannerColor: color,
                          }))
                          close()
                        }}
                        onMouseOver={(e) => setBannerColor((prev) => color)}
                        onMouseOut={(e) =>
                          setBannerColor((prev) => data.bannerColor)
                        }
                      ></span>
                    ))
                  }
                </Popover.Panel>
              </Popover>
            </div>
          </div>
          <h4 className="w-full py-4 text-grotesk text-center text-sm lg:text-base">
            Flags will be used as promotional, shareable social assets
          </h4>
        </div>
        <div className="relative w-full flex justify-between p-4">
          <Banner message={banner} />

          <button
            type="button"
            className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
            onClick={() => previousStep()}
          >
            Back
          </button>
          <button
            type="button"
            className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
            onClick={() => {
              if (proceed) {
                nextStep()
                scrollUp()
              } else {
                setBanner({
                  error: 1,
                  show: true,
                  message:
                    "Please select three different symbols and colors before proceeding",
                })
                setTimeout(() => {
                  setBanner((prev) => ({
                    ...prev,
                    show: false,
                  }))
                }, 3000)
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default ThirdStep
