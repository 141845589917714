import { useContext, useEffect, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import {
  EmojiSadIcon,
  EyeIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import APIKey from './APIKey'
import axios from 'axios' // You'll need axios or another HTTP client to make API requests
import Banner from '../Widgets/Banner'

const APIAccess = () => {
  //Get all API keys
  const [keys, setKeys] = useState([])
  const [keyId, setKeyId] = useState(null)
  const [view, setView] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const { config, reload, setReload } = useContext(AdminContext)

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await axios.get('/api/apikeys/all', config)
        setKeys(response.data)
      } catch (error) {
        console.error('Error fetching API keys:', error)
      }
    }
    fetchApiKeys()
  }, [reload])

  const remove = async (apiKey) => {
    try {
      const response = await axios.delete(`/api/apikeys/${apiKey._id}`, config)
      setReload(reload + 1)
      setBanner({
        show: true,
        success: 1,
        message: 'API key removed successfully',
      })
      setTimeout(() => {
        setBanner({ show: false })
      }, 3000)
    } catch (error) {
      setBanner({
        show: true,
        success: 0,
        message: 'Error deleting API key',
      })
      setTimeout(() => {
        setBanner({ show: false })
      }, 3000)
      console.error('Error deleting API key:', error)
    }
  }

  const lock = async (apiKey) => {
    try {
      const response = await axios.put(
        `/api/apikeys/revoke/${apiKey._id}`,
        { ...apiKey, active: !apiKey.active },
        config
      )
      setBanner({
        show: true,
        success: 1,
        message: 'API key locked successfully',
      })
      setTimeout(() => {
        setBanner({ show: false })
      }, 3000)
      setReload(reload + 1)
    } catch (error) {
      setBanner({
        show: true,
        success: 0,
        message: 'Error locking API key',
      })
      setTimeout(() => {
        setBanner({ show: false })
      }, 3000)
      console.error('Error locking API key:', error)
    }
  }

  return (
    <>
      <div className='mr-auto'>
        <button
          className='flex px-8 py-2 space-x-2 shadow-lg m-2 bg-flime transition duration-200 hover:bg-fblue hover:text-white'
          onClick={() => {
            setKeyId(null)
            setView(true)
          }}
        >
          <PlusIcon className='h-5 w-5' />
          <p className='text-mono text-sm'>Add new Access Key</p>
        </button>
      </div>
      <APIKey view={view} setView={setView} viewKey={keyId} />
      <Banner message={banner} />
      <div className='relative h-full flex-none flex flex-col w-full overflow-hidden px-2 pb-12 pt-2'>
        <table className='min-w-max w-full table-auto'>
          <thead>
            <tr className=' bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
              <th className='py-2 text-left text-sm'>
                <span className='flex relative items-center justify-center space-x-4'>
                  User
                </span>
              </th>
              <th className='py-2 text-left text-sm'>
                <span className='flex relative items-center justify-center space-x-4'>
                  Permissions
                </span>
              </th>
              <th className='py-2 text-left text-sm'>
                <span className='flex relative items-center justify-center space-x-4'>
                  Status
                </span>
              </th>
              <th className='py-2 text-left text-sm'>
                <span className='flex relative items-center justify-center space-x-4'>
                  Calls Made
                </span>
              </th>
              <th className='py-2 text-left text-sm'>
                <span className='flex relative items-center justify-center space-x-4'>
                  {' '}
                </span>
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 text-sm font-light'>
            {keys.length ? (
              keys.map((apiKey) => (
                <tr
                  className='border-b border-gray-200 hover:bg-gray-100'
                  key={apiKey._id}
                >
                  <td className='py-3 px-5 max-w-sm text-center text-sm'>
                    {apiKey.user}
                  </td>
                  <td className='py-3 px-5 max-w-sm text-center text-sm'>
                    {apiKey.permissions}
                  </td>
                  <td className='py-3 px-5 max-w-sm text-center text-sm'>
                    <div className='flex justify-center items-center px-2'>
                      <p
                        className={`${
                          apiKey.active
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        } text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full`}
                      >
                        {apiKey.active ? 'Active' : 'Inactive'}
                      </p>
                    </div>
                  </td>
                  <td className='py-3 px-5 max-w-sm text-center text-sm'>
                    <div className='flex justify-center items-center px-2'>
                      <p
                        className={` bg-gray-100 text-gray-800 text-xs font-medium px-4 py-2 rounded-full`}
                      >
                        {apiKey.apiCalls}
                      </p>
                    </div>
                  </td>
                  <td className='flex justify-center gap-2 items-center py-3 px-5'>
                    <button
                      onClick={() => {
                        remove(apiKey)
                      }}
                      className='group p-4'
                    >
                      <TrashIcon className='h-5 w-5 group-hover:text-fred' />
                    </button>
                    <button
                      onClick={() => {
                        lock(apiKey)
                      }}
                      className='group p-4'
                    >
                      {apiKey.active ? (
                        <LockClosedIcon className='h-5 w-5 group-hover:text-fred' />
                      ) : (
                        <LockOpenIcon className='h-5 w-5 group-hover:text-fblue' />
                      )}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className='border-b border-gray-200 hover:bg-gray-100'>
                <td colSpan={4} className='py-3 px-5 text-left'>
                  <div className='flex items-center'>
                    <span className='font-medium flex space-x-4 items-center'>
                      <EmojiSadIcon className='h-6 w-6' />
                      <p>Nothing to display</p>
                    </span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default APIAccess
