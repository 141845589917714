import { useEffect, useState, useContext } from "react"
import axios from "axios"
import AdminContext from "../../../contexts/Admin"
import Loading from "../Widgets/Loading"
import { EmojiSadIcon } from "@heroicons/react/outline"
import UpdateInfo from "./UpdateInfo"

const updateUrl = "/api/auth/updates"

const UpdateList = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const { reload, config } = useContext(AdminContext)
  useEffect(() => {
    const getData = async () => {
      try {
        const updatesList = await axios.get(updateUrl, config)
        if (updatesList.data.data) setData([...updatesList.data.data])
        setLoading(false)
      } catch (e) {}
    }
    getData()
  }, [reload])

  return loading ? (
    <Loading />
  ) : (
    <div className="flex flex-col pr-4 pt-2 w-full justify-start overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue">
      {data.length ? (
        data.map((update) => <UpdateInfo key={update._id} update={update} />)
      ) : (
        <div className="font-medium flex space-x-4 items-center my-2 ml-2">
          <EmojiSadIcon className="h-6 w-6" />
          <p>Nothing to display</p>
        </div>
      )}
    </div>
  )
}

export default UpdateList
