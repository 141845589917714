import Question from "./Question"
import { useState } from "react"
import Banner from "../../Admin/Widgets/Banner"

const FormPage = ({ nextStep, previousStep, questions, data }) => {
  const [answers, setAnswers] = useState(questions)
  const [banner, setBanner] = useState({ show: false })

  const handleSubmit = (e) => {
    e.preventDefault()
    //Check if agree to save data
    if (questions[0]?.category === "Tell Us More") {
      const [checkAgreement] = data.responses.filter((question) =>
        question.question
          .toUpperCase()
          .includes(
            "FOUNDERLAND IS COMMITTED TO PROTECTING AND RESPECTING YOUR PRIVACY"
          )
      )
      if (!checkAgreement?.answer_value) {
        setBanner({
          show: true,
          success: 0,
          message:
            "You will need to agree to Founderland privacy terms to proceed",
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
        return
      }
      if (checkAgreement?.answer_value.toLowerCase().includes("i agree")) {
        nextStep()
      } else {
        setBanner({
          show: true,
          success: 0,
          message:
            "You will need to agree to Founderland privacy terms to proceed",
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    } else {
      nextStep()
    }
  }

  return (
    <div className="w-full flex ">
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-wrap h-5/6 justify-around md:px-5 text-grotesk font-bold"
      >
        {questions.map((question, i) => (
          <Question
            key={i}
            question={question}
            i={i}
            setAnswers={setAnswers}
            answers={answers}
          />
        ))}
        <div className="w-full flex justify-between p-4">
          <Banner message={banner} />
          <button
            type="button"
            className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
            onClick={() => {
              previousStep()
            }}
          >
            Prev
          </button>
          <button
            type="submit"
            className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
          >
            Next
          </button>
        </div>
      </form>
    </div>
  )
}

export default FormPage
