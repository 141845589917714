const styles = {
  small: {
    icon: null,
    style:
      "flex justify-start items-center space-x-2 px-8 py-2 bg-fblue font-bold text-lg md:text-xl text-mono uppercase text-white transition duration-100 hover:bg-fblue-400",
  },

  generic: {
    icon: null,
    style:
      "flex justify-start items-center space-x-2 px-12 py-4 bg-fblue font-bold text-2xl text-mono uppercase text-white transition duration-100 hover:bg-fblue-400",
  },
  next: {
    icon: null,
    style:
      "flex justify-start items-center space-x-2 px-12 py-4 bg-fblue font-bold text-2xl text-mono uppercase text-white transition duration-100 hover:bg-fblue-400",
  },
  back: {
    icon: null,
    style:
      "flex justify-start items-center space-x-2 px-0 py-4 bg-transparent font-bold text-2xl text-mono uppercase text-gray-500 transition duration-100 hover:text-black",
  },
  submit: {
    icon: null,
    style:
      "flex justify-start items-center space-x-2 px-12 py-4 bg-fblue font-bold text-2xl text-mono uppercase text-white transition duration-100 hover:bg-fblue-400",
  },
}

const Button = ({ type, styled, label, action, loading, classNames }) => {
  let actions = {}
  if (action) actions.onClick = action

  return (
    <div className="max-w-max mt-2 lg:mt-4">
      <button
        type={type}
        className={`${styles[styled].style} ${classNames && classNames}`}
        {...actions}
      >
        {loading ? (
          <div className="flex justify-center w-7">
            <div
              style={{ borderTopColor: "transparent" }}
              className="w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin"
            ></div>
          </div>
        ) : (
          <>
            {styles[styled].icon && styles[styled].icon}
            <p>{label}</p>
          </>
        )}
      </button>
    </div>
  )
}

export default Button
