import { useState, useEffect, useContext } from "react"
import AdminContext from "../../../contexts/Admin"
import ListWidget from "../Widgets/ListWidget"
import Loading from "../Widgets/Loading"
import axios from "axios"
import moment from "moment"

const FirmsList = () => {
  const [data, setData] = useState({
    header: [
      {
        title: "Name",
        key: "companyName",
        style: "text-left text-sm",
        showFilter: true,
      },
      {
        title: "Location",
        key: "location",
        style: "text-center text-sm",
        showFilter: true,
      },
      {
        title: "Added on",
        key: "createdOn",
        style: "text-center text-sm",
        showFilter: false,
      },
      {
        title: "Associates",
        key: "membersCount",
        style: "text-center text-sm",
        showFilter: false,
      },
      { title: "", key: "-", style: "text-center text-sm", showFilter: false },
    ],
    data: [],
  })
  const [loading, setLoading] = useState(true)
  const { config, reload } = useContext(AdminContext)
  const firmsAPI = "/api/users/community/firms/"
  const [filter, setFilter] = useState([
    { key: "companyName", search: "", show: false, type: "text" },
    { key: "location", search: "", show: false, type: "text" },
  ])
  //FETCH DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(firmsAPI, config)
        result.data.forEach((element) => {
          if (element.created) {
            element.createdOn = moment(element.created).format("DD/M/YYYY")
          }
          element.location = element.city.length ? element.city : ""
          element.location += element.country.length
            ? ", " + element.country
            : ""
          element.membersCount = element.members.length
        })
        let filteredData = [...result.data].sort(
          (a, b) => new Date(a.created) - new Date(b.created)
        )
        filter.forEach(
          (term) =>
            (filteredData = [
              ...filteredData.filter((item) =>
                item[term.key].toLowerCase().includes(term.search.toLowerCase())
              ),
            ])
        )
        setData({ ...data, data: [...filteredData] })
        setLoading(false)
      } catch (e) {}
    }
    fetchData()
  }, [reload, filter])

  return loading ? (
    <Loading />
  ) : (
    <>
      <ListWidget
        title=""
        data={data}
        showing={10}
        styles={{}}
        filter={filter}
        setFilter={setFilter}
        link={"/admin/members/firm/"}
      />
    </>
  )
}

export default FirmsList
