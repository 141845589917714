import { useState, useContext, useEffect } from "react"
import Banner from "../../Admin/Widgets/Banner"
import FastTrackContext from "../../../contexts/FastTrack"
import BusinessAreaSelect from "../Profile/BusinessAreaSelect"

const SecondStep = ({ previousStep, nextStep, isActive }) => {
  const [banner, setBanner] = useState({ show: false })
  const { pageTop, scrollUp, data, setData, checkField } =
    useContext(FastTrackContext)
  const formatValue = (value) => {
    const newValue = value.trimStart()
    return newValue.replace(value[0], value[0]?.toUpperCase())
  }
  const isLink = (link) => {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/.test(
      link
    )
  }
  useEffect(() => {
    if (isActive) scrollUp()
  }, [isActive])

  return (
    <div
      className="flex flex-col justify-around items-center h-full w-full bg-white p-3"
      ref={pageTop}
    >
      <div className="w-full flex flex-col justify-center items-center">
        <h1 className="font-bold text-grotesk text-xl md:text-2xl lg:text-3xl">
          You and your business
        </h1>
      </div>
      <div className="w-full flex ">
        <form
          onSubmit={(e) => {
            e.preventDefault(e)
            if (!isLink(data.companyLink)) {
              setBanner({
                success: 0,
                show: true,
                message: "Please provide a valid company link",
              })
              setTimeout(() => {
                setBanner((prev) => ({ ...prev, show: false }))
              }, 3000)
              return
            }
            if (
              data.businessArea !== "Select the business area" &&
              data.businessArea !== "Other"
            ) {
              nextStep()
            } else {
              setBanner({
                success: 0,
                show: true,
                message: "Please fill out all the required fields",
              })
              setTimeout(() => {
                setBanner((prev) => ({ ...prev, show: false }))
              }, 3000)
            }
          }}
          className="flex flex-wrap justify-center items-center md:px-5 text-grotesk font-bold"
        >
          <div className="w-full md:w-1/2 p-4 py-6 ">
            <label className="flex items-center space-x-1 uppercase text-gray-400 text-md font-bold mb-2">
              <p>Title</p>
              <p className="text-xxs"> (your role, ex. CEO, CMO, MD) </p>
              <p>*</p>
            </label>
            <input
              type="text"
              className={`w-full ${checkField(
                data.title
              )} text-2xl appearance-none border p-3 outline-none`}
              required={true}
              placeholder="Title"
              value={data.title}
              onChange={(e) =>
                setData({
                  ...data,
                  title: formatValue(e.target.value),
                })
              }
            />
          </div>
          <div className="w-full md:w-1/2 p-4 py-6 ">
            <label className="block uppercase text-gray-400 text-md font-bold mb-2">
              Vertical/Industry *
            </label>
            <BusinessAreaSelect
              profile={data}
              setProfile={setData}
              required={banner.show}
              bgColor={`bg-white`}
              checkField={checkField}
            />
          </div>
          <div className="w-full md:w-1/2 p-4 py-6 ">
            <label className="block uppercase text-gray-400 text-md font-bold mb-2">
              Company name *
            </label>
            <input
              type="text"
              className={`w-full ${checkField(
                data.companyName
              )} text-2xl appearance-none border p-3 outline-none`}
              required={data.role === "founder" ? true : false}
              placeholder="Company Name"
              value={data.companyName}
              onChange={(e) =>
                setData({
                  ...data,
                  companyName: formatValue(e.target.value),
                })
              }
            />
          </div>
          <div className="w-full md:w-1/2 p-4 py-6 ">
            <label className="flex items-center space-x-1 uppercase text-gray-400 text-md font-bold mb-2">
              <p>Company Website</p>
              <p className="lowercase">
                {data.role === "founder" ? "*" : "(optional)"}
              </p>
            </label>
            <input
              type="text"
              className={`w-full ${
                !data.companyLink
                  ? "border-l-4 border-black"
                  : !isLink(data.companyLink)
                  ? "border-l-4 border-fred"
                  : "border-l-4 border-valid"
              } text-2xl appearance-none border p-3 outline-none`}
              required={data.role === "founder" ? true : false}
              placeholder="Company Link"
              value={data.companyLink}
              onChange={(e) =>
                setData({
                  ...data,
                  companyLink: e.target.value,
                })
              }
            />
          </div>
          <div className="w-full p-4 py-6  ">
            <label className="flex items-center space-x-1 uppercase text-gray-400 text-md font-bold mb-2">
              <p>Company Bio</p>
              <p className="lowercase">
                {data.role === "founder" ? "*" : "(optional)"}
              </p>
            </label>
            <textarea
              type="text"
              className={`w-full ${checkField(
                data.companyBio
              )} text-2xl appearance-none border p-3 outline-none`}
              required={data.role === "founder" ? true : false}
              value={data.companyBio}
              placeholder="About you (max 3 sentences)"
              onChange={(e) =>
                setData({
                  ...data,
                  companyBio: formatValue(e.target.value),
                })
              }
            />
          </div>

          <div className="w-full flex justify-between p-4">
            <Banner message={banner} />

            <button
              type="button"
              className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
              onClick={() => previousStep()}
            >
              Back
            </button>
            <button
              type="submit"
              className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SecondStep
