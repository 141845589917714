import { useState, useContext, useEffect } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import DropzoneCloudinary from "../Widgets/DropzoneCloudinary";
import Banner from "../../Admin/Widgets/Banner";
import Instructions from "../Widgets/Instructions";
import FastTrackContext from "../../../contexts/FastTrack";
import Headshot from "../Widgets/Headshot";

const FourthStep = ({ nextStep, previousStep, isActive }) => {
    const { pageTop, scrollUp, data, setData, config } =
        useContext(FastTrackContext);
    const [display, setDisplay] = useState(false);
    // const [previewSource, setPreviewSource] = useState(null)
    const [uploadStatus, setUploadStatus] = useState({
        success: false,
        message: ""
    });
    const [banner, setBanner] = useState({ show: false });

    useEffect(() => {
        if (uploadStatus.success) {
            setBanner({
                success: 1,
                show: true,
                message: `Photo uploaded`
            });
            setTimeout(() => {
                setBanner((prev) => ({ ...prev, show: false }));
            }, 2000);
        } else if (uploadStatus.message !== "") {
            setBanner({
                success: 0,
                show: true,
                message: uploadStatus.message
            });
            setTimeout(() => {
                setBanner((prev) => ({ ...prev, show: false }));
            }, 2000);
        }
    }, [uploadStatus]);

    useEffect(() => {
        if (isActive && !data.photo?.public_id) {
            scrollUp();
            setTimeout(() => {
                setDisplay(true);
            }, 1000);
        }
    }, [isActive]);

    return (
        <div
            className="relative h-full flex flex-col justify-around items-center w-full bg-white p-3"
            ref={pageTop}
        >
            <Instructions
                display={display}
                setDisplay={setDisplay}
                message={<Headshot />}
            />
            <div className=" w-full flex flex-col justify-center items-center">
                <h1 className="font-bold text-xl md:text-2xl lg:text-3xl text-center text-grotesk">
                    Headshot picture
                </h1>
                <button
                    onClick={() => {
                        setDisplay(true);
                    }}
                    className="m-2 absolute top-0 right-0 rounded-full p-0 w-12 h-12 bg-fblue text-white hover:text-black hover:bg-flime active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none flex justify-center items-center"
                >
                    <InformationCircleIcon className="w-10 h-10" />
                </button>
            </div>
            <div className="w-full flex flex-col flex-grow">
                <div className="flex flex-col justify-center items-center w-full text-grotesk font-bold h-full">
                    <h4 className="w-full py-4 text-grotesk text-center text-sm lg:text-xl">
                        {data.photo?.public_id
                            ? "Picture uploaded, if you are happy with the result you may proceed"
                            : "Select a photo for your profile. A preview of your own personal flag will be displayed after uploading a picture."}
                    </h4>
                    <DropzoneCloudinary
                        data={data}
                        setData={setData}
                        type="profilePicture"
                        setUploadStatus={setUploadStatus}
                        uploadStatus={uploadStatus}
                        config={config}
                    />
                    <h2 className="text-xs text-grotesk px-4 text-center">
                        💡 Use the help guide for more information on best
                        practices
                    </h2>
                </div>
                <div className="w-full flex justify-between p-4">
                    <Banner message={banner} />
                    <button
                        type="button"
                        className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
                        onClick={() => previousStep()}
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
                        onClick={() => {
                            if (data.photo?.url) {
                                nextStep();
                                scrollUp();
                            } else {
                                setBanner({
                                    error: 1,
                                    show: true,
                                    message:
                                        "Please select a picture for your profile"
                                });
                                setTimeout(() => {
                                    setBanner((prev) => ({
                                        ...prev,
                                        show: false
                                    }));
                                }, 3000);
                            }
                        }}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FourthStep;
