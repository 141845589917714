import { useContext } from 'react'
import { useHistory, useParams } from 'react-router'
import { UserAddIcon, KeyIcon } from '@heroicons/react/outline'
import AdminContext from '../../../contexts/Admin'
import Tabs from '../Widgets/Tabs'
import MembersList from './MembersList'
import AddMember from './AddMember'
import MemberProfile from './MemberProfile'
import NewsLetterList from './NewsLetterList'
import TokensList from './TokensList'
import FirmsList from './FirmsList'
import Token from './Token'
import AddFirm from './AddFirm'
import FirmProfile from './FirmProfile'
import { useEffect } from 'react'

const Members = () => {
  const history = useHistory()
  const { id, category } = useParams()
  const {
    reload,
    selectedTab,
    user,
    setCCModal,
    setSatisticsFilter,
    statisticsFilter,
  } = useContext(AdminContext)

  const tabs = [
    {
      index: 0,
      name: 'Founder',
      role: 'founder',
      restricted: '',
    },
    {
      index: 1,
      name: 'Investor',
      role: 'investor',
      restricted: '',
    },
    {
      index: 2,
      name: 'Ally',
      role: 'ally',
      restricted: '',
    },

    {
      index: 3,
      name: 'Newsletter',
      role: 'Newsletter',
      restricted: '',
    },
    {
      index: 4,
      name: 'Fast Track Tokens',
      role: 'fasttrack',
      restricted: '',
    },
    {
      index: 5,
      name: 'Investment Firms',
      role: 'firm',
      restricted: '',
    },
  ]

  useEffect(() => {
    selectedTab === 0
      ? setSatisticsFilter({
          ...statisticsFilter,
          filters: [
            'Notified',
            'Not Notified',
            'Signed Up',
            'Flag Setup',
            'Notified this week',
            'Unconfirmed >1 month',
          ],
        })
      : setSatisticsFilter({
          search: '',
          filters: [],
        })
  }, [selectedTab])

  return (
    <>
      <Tabs tabs={tabs} id={id} />
      <section className='relative h-full w-full flex md:px-4  items-center flex-col bg-white outline-none overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue'>
        {!id ? (
          <>
            <div className='flex space-x-2 mr-auto'>
              {selectedTab !== 3 &&
                selectedTab !== 4 &&
                user.role.includes('admin') && (
                  <button
                    className='flex px-8 py-2 w-fit space-x-2 shadow-md m-2 bg-flime transition duration-200 hover:bg-fblue hover:text-white'
                    onClick={() => history.push('/admin/members/id/new')}
                  >
                    <UserAddIcon className='h-5 w-5' />
                    <p className='text-mono text-sm capitalize'>
                      Add New {tabs[selectedTab].role}
                    </p>
                  </button>
                )}
              {tabs[selectedTab].role === 'fasttrack' &&
                user.role.includes('admin') && (
                  <button
                    className='flex px-8 py-2 w-52 space-x-2 m-2 shadow-md bg-flime transition duration-200 hover:bg-fblue hover:text-white'
                    onClick={() => setCCModal(true)}
                  >
                    <KeyIcon className='h-5 w-5' />
                    <p className='text-mono text-sm'>Add New Token</p>
                  </button>
                )}
            </div>
            {selectedTab === 3 ? (
              <NewsLetterList reload={reload} role={tabs[selectedTab].role} />
            ) : selectedTab === 4 ? (
              <TokensList />
            ) : selectedTab === 5 ? (
              <FirmsList />
            ) : (
              <MembersList reload={reload} role={tabs[selectedTab].role} />
            )}
          </>
        ) : id === 'new' ? (
          <div className='relative self-center flex flex-col w-full xl:w-5/6 2xl:w-4/6 pb-6 shadow-lg border-0'>
            {tabs[selectedTab].role === 'firm' ? (
              <AddFirm role={tabs[selectedTab].role} />
            ) : (
              <AddMember role={tabs[selectedTab].role} />
            )}
          </div>
        ) : category === 'token' ? (
          <Token />
        ) : category === 'firm' ? (
          <FirmProfile />
        ) : (
          <MemberProfile />
        )}
      </section>
    </>
  )
}

export default Members
