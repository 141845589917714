import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import Banner from '../Widgets/Banner'
import Loading from '../Widgets/Loading'

import moment from 'moment'
import { HashtagIcon, ChevronRightIcon } from '@heroicons/react/outline'
import AvailabilityCard from './AvailabilityCard'
import SessionsList from './SessionsList'

const availableInvestorUrl = '/api/availability/id/'

const defaultProfile = {
  photo: null,
  locked: false,
  _id: null,
  firstName: null,
  lastName: null,
  title: null,
  companyName: null,
  companyBio: null,
  city: null,
  country: null,
  geoLocation: [],
  email: null,
  created: null,
  confirmed: null,
  role: null,
  following: [],
  followers: [],
  applicationId: null,
  notified: null,
  about: null,
  businessArea: null,
  hashedPassword: null,
  lastUpdate: null,
  symbols: [
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
  ],
  bannerColor: 'fblue',
  consent: false,
  linkedinBio: null,
  pronouns: null,
  origins: null,
  asks: null,
}

const AvailableInvestor = () => {
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState(defaultProfile)
  const [emojiFlags, setEmojiFlags] = useState(null)
  const [banner, setBanner] = useState({ show: false })
  const { id } = useParams()
  const { config, countryList, reload } = useContext(AdminContext)

  const getEmojiFlag = (countryName) => {
    const [currentCountry] = countryList.filter(
      (country) => country.name === countryName
    )
    if (currentCountry) {
      const codePoints = currentCountry?.iso2
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt())
      return String.fromCodePoint(...codePoints)
    }
    return null
  }

  //GET PROFILE
  useEffect(() => {
    setLoading(true)
    const getProfile = async () => {
      try {
        const response = await axios.get(availableInvestorUrl + id, config)
        if (response.data) {
          let profileData = {
            ...profile,
            ...response.data.memberId,
            availability: { ...response.data },
            available: response.data.available,
          }
          setProfile(profileData)
          setEmojiFlags(getEmojiFlag(profileData.country))
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
        setBanner({
          error: 1,
          show: true,
          message: 'Error getting profile data!',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    }
    getProfile()
    return () => {
      setProfile(defaultProfile)
    }
  }, [id, reload])
  return (
    <section className='h-full w-full xl:w-5/6 mx-auto'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='pt-8 w-full flex items-center justify-center z-20'>
            <Banner message={banner} />
          </div>
          <div className='w-full flex-none md:flex no-wrap md:-mx-2'>
            <div
              className={`w-full md:w-1/3 md:mx-2 shadow bg-white p-3 border-t-8 border-fred`}
            >
              <div className='image overflow-hidden p-0'>
                <div className='mx-auto relative p-0 w-64 h-64 md:h-52 md:w-52 xl:w-64 xl:h-64'>
                  <img
                    src={profile.photo ? profile.photo.url : 'null'}
                    alt='profile'
                    className='w-full h-full object-cover'
                  />
                </div>
              </div>

              <h1 className='text-gray-900 uppercase font-bold text-xl mt-3'>
                {profile.firstName} {profile.lastName}
              </h1>
              {profile.pronouns && (
                <p className='text-gray-600 text-xs font-semibold'>
                  {profile.pronouns}
                </p>
              )}
              <p className='text-gray-600 text-lg font-semibold mt-3'>
                {profile.title}
              </p>
              <p className='text-gray-600 text-base mt-1'>
                {profile.companyName}
              </p>
              <div className='text-xs'>
                {profile.companyLink ? (
                  <a
                    href={profile.companyLink}
                    className='text-gray-600 hover:text-sky-600'
                    rel='noreferrer'
                    target='_blank'
                  >
                    {profile.companyLink}
                  </a>
                ) : (
                  ''
                )}
              </div>
              <p className='text-gray-700 text-base mt-3'>
                {profile.businessArea !== 'Select the business area' &&
                  profile.businessArea !== 'Other' &&
                  profile.businessArea}
              </p>
              {profile.country && (
                <p className='text-gray-700 text-base mt-3'>
                  {profile.city}, {profile.country}{' '}
                  {getEmojiFlag(profile.country)}
                </p>
              )}
              <div className='bg-gray-100 bg-opacity-60 text-gray-700 py-1 px-3 mt-3 divide-y '>
                <div className='flex items-center py-3'>
                  <p className='uppercase text-sm text-grotesk'>Status</p>
                  {profile.locked ? (
                    <div className='ml-auto bg-red-500 py-1 px-2 text-white text-sm shadow'>
                      Locked
                    </div>
                  ) : profile.confirmed ? (
                    <div className='ml-auto bg-green-500 py-1 px-2 text-white text-sm shadow'>
                      Active
                    </div>
                  ) : profile.notified ? (
                    <div className='ml-auto bg-yellow-500 py-1 px-2 text-white text-sm shadow'>
                      Unconfirmed
                    </div>
                  ) : (
                    <div className='ml-auto bg-yellow-500 py-1 px-2 text-white text-sm shadow'>
                      Not Notified
                    </div>
                  )}
                </div>
                <div className='flex items-center py-3'>
                  <p className='uppercase text-sm text-grotesk'>Availability</p>
                  {!profile.available ? (
                    <div className='ml-auto bg-red-500 py-1 px-2 text-white text-sm shadow'>
                      Unavailable
                    </div>
                  ) : (
                    <div className='ml-auto bg-green-500 py-1 px-2 text-white text-sm shadow'>
                      Available
                    </div>
                  )}
                </div>
                <div className='flex items-center py-3'>
                  <p className='uppercase text-sm text-grotesk'>Rating</p>
                  {profile.rating ? (
                    <div className='ml-auto bg-red-500 py-1 px-2 text-white text-sm shadow'>
                      Rated
                    </div>
                  ) : (
                    <div className='ml-auto bg-yellow-500 py-1 px-2 text-white text-sm shadow'>
                      No rating yet
                    </div>
                  )}
                </div>
                <div className='flex flex-col justify-center py-3'>
                  <p className='uppercase text-sm text-grotesk '>
                    Full Profile
                  </p>
                  <div className='ml-auto'>
                    {profile._id ? (
                      <Link
                        to={`/admin/members/id/${profile._id}`}
                        className='flex space-x-2 items-center justify-center font-bold hover:text-sky-600 text-sm'
                      >
                        Go to Profile <ChevronRightIcon className='h-4' />
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                {profile.availability.created && (
                  <div className='flex flex-col justify-center py-3'>
                    <p className='uppercase text-sm text-grotesk'>
                      Availability set on
                    </p>
                    <p className='ml-auto font-bold text-sm'>
                      {moment(profile.availability.created).format('DD/M/YYYY')}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className='my-4'></div>
            <div
              className={`w-full md:w-2/3 md:mx-1 shadow bg-white p-3 border-t-8 border-fred`}
            >
              <div className='bg-white p-3 text-base text-gray-700'>
                <div className='flex items-center space-x-2 font-semibold uppercase text-sm my-2'>
                  <HashtagIcon className={`h-5 w-5 text-fred`} />
                  <span className='tracking-wider text-grotesk'>About</span>
                </div>
                <div className='grid md:grid-cols-1 gap-2'>
                  <div className='grid grid-cols-1'>
                    <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                      My Bio
                    </div>
                    <div className='p-2 text-base'>{profile.bio}</div>
                  </div>
                </div>
                <div className='flex items-center my-2'>
                  <AvailabilityCard availability={profile.availability} />
                </div>
                <div className='grid md:grid-cols-1 gap-2'>
                  <div className='grid grid-cols-1'>
                    <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                      Sessions
                    </div>
                    <SessionsList memberId={profile._id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default AvailableInvestor
