import { useContext, useEffect, useState } from 'react'
import Banner from '../Widgets/Banner'
import Loading from '../Widgets/Loading'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import moment from 'moment'
import NewFounderCard from './components/NewFounderCard'
import { MailIcon, RefreshIcon } from '@heroicons/react/outline'

const NewFounders = () => {
  const [banner, setBanner] = useState({ show: false })
  const [newFounders, setNewFounders] = useState([])
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const [role, setRole] = useState('ACCEPTED_FOUNDERS')
  const membersAPI = '/api/users/community/newmembers/'
  const { config, reload } = useContext(AdminContext)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(membersAPI + role, config)
        const dataSet = result.data.data
        setNewFounders([...dataSet])
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
        setBanner({
          show: true,
          type: 'error',
          message: 'Error loading data',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 5000)
      }
    }
    setLoading(true)
    fetchData()
  }, [reload, role])

  const handleEmail = async () => {
    const emailInput = prompt(
      'Enter the email address to send the email to: \n (Leave blank to send to admin@founderland.org)'
    )
    setSending(true)
    try {
      const result = await axios.post(
        '/api/users/community/newmembers/' + role,
        {
          email: emailInput,
          role: role.split('_')[1].toLowerCase(),
        },
        config
      )
      setBanner({
        show: true,
        success: true,
        message: 'Email sent',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
      setSending(false)
    } catch (e) {
      setBanner({
        show: true,
        type: 'error',
        message: 'Error sending email',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
      setSending(false)
    }
  }

  return (
    <div className='w-full'>
      <div className=' w-full px-3 mt-4'>
        <div className='w-ful flex justify-center'>
          <Banner message={banner} />
        </div>
        <div className='w-full flex items-center justify-start gap-2'>
          <select
            className='w-48 h-10 shadow-md border border-gray-300 px-2 py-1 m-2 text-sm text-gray-700 bg-white hover:bg-gray-50'
            onChange={(e) => setRole(e.target.value)}
          >
            <option value='ACCEPTED_FOUNDERS'>Founders</option>
            <option value='ACCEPTED_INVESTORS'>Investors</option>
            <option value='ACCEPTED_ALLIES'>Allies</option>
          </select>
          <button
            disabled={loading || sending || newFounders.length === 0}
            className={`flex items-center gap-2 px-8 py-2 space-x-2 shadow-lg m-2 transition duration-200 ${
              loading || sending || newFounders.length === 0
                ? 'cursor-not-allowed'
                : 'bg-flime hover:bg-fblue hover:text-white'
            }`}
            onClick={() => {
              handleEmail()
            }}
          >
            {sending ? (
              <>
                <RefreshIcon className='h-5 w-5 animate-spin' /> Sending email
              </>
            ) : (
              <>
                <MailIcon className='h-5 w-5' /> Send by email
              </>
            )}
          </button>
        </div>
        {loading ? (
          <Loading />
        ) : newFounders.length > 0 ? (
          <>
            <h1 className='text-grotesk text-lg pb-2'>
              New {role.split('_')[1].toLowerCase()} accepted this past week
            </h1>
            <div className='flex flex-wrap gap-4'>
              {newFounders.map((founder) => (
                <NewFounderCard key={founder._id} data={founder} />
              ))}
            </div>
          </>
        ) : (
          <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
            <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
              No new {role.split('_')[1].toLowerCase()} approved in the last
              week
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

export default NewFounders
