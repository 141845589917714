const Banner = () => {
  return (
    <div className=" bg-white ">
      <svg
        width="376"
        height="94"
        viewBox="0 0 376 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="sm:hidden h-26"
      >
        <g clipPath="url(#clip0_680_731)">
          <path d="M47 0H0V47H47V0Z" fill="#D7FB03" />
        </g>
        <g clipPath="url(#clip1_680_731)">
          <path d="M47 47H0V94H47V47Z" fill="#F6331C" />
          <path
            d="M8.52148 70.5002C11.9981 71.6549 15.1593 73.6007 17.7564 76.1845C20.3534 78.7682 22.3154 81.9194 23.4879 85.3901C24.6603 81.9196 26.6221 78.7684 29.2189 76.1847C31.8157 73.601 34.9767 71.6551 38.4531 70.5002C34.9767 69.3453 31.8157 67.3994 29.2189 64.8157C26.6221 62.232 24.6603 59.0809 23.4879 55.6104C22.3152 59.0809 20.3532 62.232 17.7562 64.8158C15.1592 67.3995 11.998 69.3453 8.52148 70.5002Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip2_680_731)">
          <path d="M94 0H47V47H94V0Z" fill="#EE93B5" />
          <path
            d="M71.0443 10.278C69.2367 10.2732 67.446 10.6268 65.776 11.3185C64.1059 12.0102 62.5897 13.0263 61.3148 14.3078C55.9507 19.6728 55.9507 28.4022 61.3148 33.7667C63.8953 36.3469 67.3951 37.7965 71.0443 37.7965C74.6935 37.7965 78.1932 36.3469 80.7737 33.7667C86.1378 28.4022 86.1378 19.6728 80.7737 14.3078C79.4989 13.0263 77.9826 12.0103 76.3125 11.3186C74.6425 10.6269 72.8519 10.2732 71.0443 10.278ZM71.0443 42.7737C66.0396 42.7737 61.334 40.8245 57.7955 37.2856C50.49 29.9801 50.49 18.0939 57.7955 10.7893C61.334 7.25041 66.0392 5.30078 71.0443 5.30078C76.0493 5.30078 80.7541 7.25041 84.2931 10.7893C91.5981 18.0944 91.5981 29.981 84.2931 37.2856C80.7541 40.8245 76.0489 42.7737 71.0443 42.7737Z"
            fill="white"
          />
          <path
            d="M71.044 17.8493C69.8197 17.8488 68.6228 18.2114 67.6047 18.8913C66.5866 19.5712 65.793 20.5378 65.3244 21.6688C64.8558 22.7998 64.7331 24.0444 64.972 25.2451C65.2109 26.4459 65.8006 27.5488 66.6664 28.4143C69.08 30.8278 73.008 30.8282 75.4219 28.4143C76.2882 27.5489 76.8783 26.4459 77.1174 25.245C77.3565 24.0441 77.2339 22.7993 76.7652 21.6681C76.2964 20.5369 75.5026 19.5702 74.4841 18.8904C73.4657 18.2106 72.2684 17.8483 71.044 17.8493ZM71.044 34.4633C68.9811 34.4641 66.9643 33.853 65.2488 32.7073C63.5333 31.5616 62.1962 29.9329 61.4065 28.0271C60.6169 26.1213 60.4102 24.0242 60.8127 22.0009C61.2152 19.9777 62.2087 18.1193 63.6676 16.6608C67.7353 12.5935 74.3527 12.5935 78.4204 16.6608C79.8799 18.119 80.874 19.9775 81.2768 22.0009C81.6796 24.0244 81.4731 26.1219 80.6834 28.0279C79.8936 29.9339 78.5561 31.5628 76.8402 32.7084C75.1243 33.854 73.1071 34.4647 71.044 34.4633Z"
            fill="white"
          />
          <path
            d="M71.0452 23.054C70.8178 23.0543 70.5975 23.1335 70.4219 23.278C70.2463 23.4225 70.1263 23.6234 70.0822 23.8464C70.0381 24.0695 70.0727 24.301 70.1801 24.5014C70.2875 24.7018 70.4611 24.8588 70.6712 24.9457C70.8814 25.0325 71.1152 25.0438 71.3327 24.9776C71.5503 24.9114 71.7381 24.7719 71.8644 24.5827C71.9906 24.3935 72.0473 24.1665 72.0249 23.9402C72.0025 23.7139 71.9023 23.5024 71.7415 23.3416C71.6501 23.2502 71.5415 23.1777 71.422 23.1283C71.3025 23.0789 71.1744 23.0537 71.0452 23.054ZM71.0452 27.6952C70.1989 27.6946 69.379 27.4006 68.7252 26.8633C68.0714 26.326 67.6241 25.5786 67.4595 24.7485C67.2949 23.9184 67.4232 23.0569 67.8226 22.3107C68.222 21.5646 68.8677 20.9801 69.6497 20.6566C70.4317 20.3332 71.3017 20.2909 72.1114 20.5369C72.9212 20.783 73.6205 21.3022 74.0904 22.006C74.5602 22.7099 74.7715 23.5549 74.6882 24.3971C74.6049 25.2392 74.2323 26.0265 73.6336 26.6247C73.2942 26.9648 72.8909 27.2345 72.4469 27.4182C72.0029 27.6019 71.527 27.6961 71.0465 27.6952"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip3_680_731)">
          <path d="M94 47H47V94H94V47Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip4_680_731)">
          <path d="M141 0H94V47H141V0Z" fill="#F6331C" />
        </g>
        <g clipPath="url(#clip5_680_731)">
          <path d="M141 47H94V94H141V47Z" fill="#D7FB03" />
          <path
            d="M118.42 56.6592L130.406 63.5795V77.4197L118.42 84.34L106.435 77.4197V63.5795L118.42 56.6592Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip6_680_731)">
          <path d="M282 0H235V47H282V0Z" fill="#EE93B5" />
          <path
            d="M262.175 24.2075C291.529 24.2075 258.506 57.2319 258.506 27.8765C258.506 57.2319 225.482 24.2075 254.836 24.2075C225.482 24.2075 258.506 -8.81567 258.506 20.5384C258.506 -8.81567 291.529 24.2075 262.175 24.2075Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip7_680_731)">
          <path d="M282 47H235V94H282V47Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip8_680_731)">
          <path d="M329 0H282V47H329V0Z" fill="#F6331C" />
        </g>
        <g clipPath="url(#clip9_680_731)">
          <path d="M329 47H282V94H329V47Z" fill="#D7FB03" />
          <path
            d="M293.545 64.5703C292.364 64.5703 291.21 64.9205 290.228 65.5766C289.246 66.2326 288.481 67.1651 288.029 68.2561C287.577 69.3472 287.459 70.5477 287.689 71.7059C287.919 72.8641 288.488 73.928 289.323 74.763C290.158 75.598 291.222 76.1667 292.38 76.3971C293.538 76.6275 294.739 76.5092 295.83 76.0573C296.921 75.6054 297.853 74.8401 298.509 73.8582C299.166 72.8763 299.516 71.722 299.516 70.5411C299.516 68.9576 298.886 67.4389 297.767 66.3192C296.647 65.1995 295.128 64.5704 293.545 64.5703Z"
            fill="black"
          />
          <path
            d="M305.54 52.5703C304.359 52.5703 303.205 52.9205 302.223 53.5766C301.241 54.2326 300.476 55.1651 300.024 56.2561C299.572 57.3472 299.454 58.5477 299.684 59.7059C299.914 60.8641 300.483 61.928 301.318 62.763C302.153 63.598 303.217 64.1667 304.375 64.3971C305.533 64.6275 306.734 64.5092 307.825 64.0573C308.916 63.6054 309.848 62.8401 310.505 61.8582C311.161 60.8763 311.511 59.722 311.511 58.5411C311.511 56.9575 310.882 55.4388 309.762 54.3191C308.642 53.1994 307.124 52.5703 305.54 52.5703Z"
            fill="black"
          />
          <path
            d="M305.46 76.4854C304.279 76.4854 303.125 76.8355 302.143 77.4916C301.161 78.1477 300.396 79.0802 299.944 80.1712C299.492 81.2622 299.374 82.4627 299.604 83.6209C299.834 84.7791 300.403 85.843 301.238 86.678C302.073 87.5131 303.137 88.0817 304.295 88.3121C305.453 88.5425 306.654 88.4242 307.745 87.9723C308.836 87.5204 309.768 86.7551 310.424 85.7733C311.081 84.7914 311.431 83.637 311.431 82.4561C311.431 80.8726 310.802 79.354 309.682 78.2343C308.562 77.1146 307.044 76.4855 305.46 76.4854Z"
            fill="black"
          />
          <path
            d="M317.456 64.4883C316.275 64.4883 315.121 64.8385 314.139 65.4945C313.157 66.1506 312.392 67.0831 311.94 68.1741C311.488 69.2651 311.37 70.4656 311.6 71.6239C311.83 72.7821 312.399 73.846 313.234 74.681C314.069 75.516 315.133 76.0847 316.291 76.315C317.449 76.5454 318.65 76.4272 319.741 75.9753C320.832 75.5234 321.765 74.7581 322.421 73.7762C323.077 72.7943 323.427 71.6399 323.427 70.459C323.427 68.8755 322.798 67.3568 321.678 66.2371C320.558 65.1173 319.04 64.4883 317.456 64.4883Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip10_680_731)">
          <path d="M376 0.0322266H329V47.0322H376V0.0322266Z" fill="#0063E2" />
          <path
            d="M352.506 22.4644C361.067 22.4644 368.009 15.5223 368.009 6.96094H337.003C337.003 15.5223 343.945 22.4644 352.506 22.4644Z"
            fill="white"
          />
          <path
            d="M352.506 24.6123C343.945 24.6123 337.003 31.5544 337.003 40.1158H368.009C368.009 31.5509 361.067 24.6123 352.506 24.6123Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip11_680_731)">
          <path d="M376 47H329V94H376V47Z" fill="#EE93B5" />
        </g>
        <rect x="141" width="94" height="94" fill="black" />
        <rect
          width="68.5165"
          height="64.6382"
          transform="translate(154.084 14.3613)"
          fill="black"
        />
        <path
          d="M186.425 27.3467V32.5369H173.703V34.1926H183.664V39.1767H173.703V44.3887H168.003V27.3467H186.425Z"
          fill="white"
        />
        <path
          d="M168.003 65.8936V48.8516H179.63C184.564 48.8516 189.29 50.0883 189.29 57.3726C189.29 63.9581 184.068 65.8936 179.686 65.8936H168.003ZM183.394 57.3896C183.394 55.2569 182.331 53.5284 179.303 53.5284H173.64V61.2533H179.308C182.048 61.2533 183.394 59.5247 183.394 57.3896Z"
          fill="white"
        />
        <path
          d="M208.53 60.2533V65.8936H190.518V48.8516H196.164V60.2533H208.53Z"
          fill="white"
        />
        <path
          d="M190.928 31.6072H201.32L191.524 41.3479L194.382 44.3887L204.306 34.6187V44.3887H208.531V27.3467H190.928V31.6072Z"
          fill="white"
        />
        <defs>
          <clipPath id="clip0_680_731">
            <rect width="47" height="47" fill="white" />
          </clipPath>
          <clipPath id="clip1_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(0 47)"
            />
          </clipPath>
          <clipPath id="clip2_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(47)"
            />
          </clipPath>
          <clipPath id="clip3_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(47 47)"
            />
          </clipPath>
          <clipPath id="clip4_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(94)"
            />
          </clipPath>
          <clipPath id="clip5_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(94 47)"
            />
          </clipPath>
          <clipPath id="clip6_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(235)"
            />
          </clipPath>
          <clipPath id="clip7_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(235 47)"
            />
          </clipPath>
          <clipPath id="clip8_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(282)"
            />
          </clipPath>
          <clipPath id="clip9_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(282 47)"
            />
          </clipPath>
          <clipPath id="clip10_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(329)"
            />
          </clipPath>
          <clipPath id="clip11_680_731">
            <rect
              width="47"
              height="47"
              fill="white"
              transform="translate(329 47)"
            />
          </clipPath>
        </defs>
      </svg>
      <svg
        width="1368"
        height="72"
        viewBox="0 0 1368 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden sm:block lg:hidden h-18"
      >
        <g clipPath="url(#clip0_677_3438)">
          <path d="M72 0H0V72H72V0Z" fill="#D7FB03" />
        </g>
        <g clipPath="url(#clip1_677_3438)">
          <path d="M1224 0H1152V72H1224V0Z" fill="#EE93B5" />
        </g>
        <g clipPath="url(#clip2_677_3438)">
          <path d="M576 0H504V72H576V0Z" fill="#EE93B5" />
          <path
            d="M545.631 37.0835C590.599 37.0835 540.011 87.6741 540.011 42.7041C540.011 87.6741 489.42 37.0835 534.389 37.0835C489.42 37.0835 540.011 -13.5052 540.011 31.4628C540.011 -13.5052 590.599 37.0835 545.631 37.0835Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip3_677_3438)">
          <path d="M360 0H288V72H360V0Z" fill="#EE93B5" />
        </g>
        <g clipPath="url(#clip4_677_3438)">
          <path d="M936 0H864V72H936V0Z" fill="#D7FB03" />
        </g>
        <g clipPath="url(#clip5_677_3438)">
          <path
            d="M1151.64 -0.363281H1079.64V71.6367H1151.64V-0.363281Z"
            fill="#D7FB03"
          />
          <path
            d="M1120.1 58.0899L1114.15 52.1459L1131.68 36.0332L1114.15 19.9206L1120.1 13.9766L1142.15 36.0332L1120.1 58.0899Z"
            fill="black"
          />
          <path
            d="M1095.57 58.0899L1089.62 52.1459L1107.15 36.0332L1089.62 19.9206L1095.57 13.9766L1117.63 36.0332L1095.57 58.0899Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip6_677_3438)">
          <path d="M432 0H360V72H432V0Z" fill="#D7FB03" />
          <path
            d="M397.409 14.7979L415.771 25.3992V46.6012L397.409 57.2025L379.048 46.6012V25.3992L397.409 14.7979Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip7_677_3438)">
          <path d="M864 0.0498047H792V72.0498H864V0.0498047Z" fill="#F6331C" />
          <path
            d="M828.06 34.875L820.182 7.16504H835.939L828.06 34.875Z"
            fill="white"
          />
          <path
            d="M827.224 35.1523L802.058 21.129L813.2 9.98633L827.224 35.1523Z"
            fill="white"
          />
          <path
            d="M826.826 35.9392L799.116 43.8185V28.0605L826.826 35.9392Z"
            fill="white"
          />
          <path
            d="M827.103 36.7764L813.08 61.9424L801.938 50.7997L827.103 36.7764Z"
            fill="white"
          />
          <path
            d="M827.89 37.1738L835.77 64.8838H820.012L827.89 37.1738Z"
            fill="white"
          />
          <path
            d="M828.728 36.8965L853.894 50.9198L842.751 62.0632L828.728 36.8965Z"
            fill="white"
          />
          <path
            d="M829.125 36.1091L856.835 28.2305V43.9878L829.125 36.1091Z"
            fill="white"
          />
          <path
            d="M828.848 35.2724L842.871 10.1064L854.013 21.2491L828.848 35.2724Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip8_677_3438)">
          <path d="M1296 0H1224V72H1296V0Z" fill="#F6331C" />
          <path
            d="M1237.05 35.9995C1242.38 37.7684 1247.22 40.7492 1251.2 44.7073C1255.18 48.6653 1258.19 53.4927 1259.98 58.8095C1261.78 53.4929 1264.78 48.6657 1268.76 44.7076C1272.74 40.7496 1277.58 37.7686 1282.91 35.9995C1277.58 34.2303 1272.74 31.2493 1268.76 27.2913C1264.78 23.3332 1261.78 18.506 1259.98 13.1895C1258.19 18.5061 1255.18 23.3333 1251.2 27.2914C1247.22 31.2494 1242.38 34.2303 1237.05 35.9995Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip9_677_3438)">
          <path d="M648 0H576V72H648V0Z" fill="#F6331C" />
        </g>
        <g clipPath="url(#clip10_677_3438)">
          <path d="M216 0H144V72H216V0Z" fill="#F6331C" />
        </g>
        <g clipPath="url(#clip11_677_3438)">
          <path d="M792 0H720V72H792V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip12_677_3438)">
          <path d="M504 0H432V72H504V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip13_677_3438)">
          <path d="M288 0H216V72H288V0Z" fill="#0063E2" />
          <path
            d="M272.926 52.6346C269.624 52.6346 267.121 50.3479 265.486 45.8392C264.563 43.2939 263.865 40.0846 263.189 36.9806C262.983 36.0346 262.735 34.8886 262.462 33.7272C262.189 34.8886 261.939 36.0346 261.734 36.9806C261.058 40.0846 260.361 43.2939 259.438 45.8392C257.8 50.3479 255.299 52.6346 252 52.6346C248.701 52.6346 246.196 50.3479 244.56 45.8392C243.639 43.2939 242.94 40.0846 242.264 36.9806C242.059 36.0352 241.811 34.8899 241.537 33.7286C241.265 34.8899 241.015 36.0352 240.81 36.9806C240.134 40.0846 239.437 43.2939 238.514 45.8392C236.879 50.3479 234.377 52.6346 231.074 52.6346C227.771 52.6346 225.267 50.3479 223.632 45.8379C222.709 43.2926 222.012 40.0832 221.333 36.9792C220.779 34.4299 219.907 30.4206 219.113 28.5846H216V19.3652H220.61C223.912 19.3652 226.415 21.6519 228.049 26.1619C228.971 28.7059 229.669 31.9152 230.345 35.0192C230.551 35.9646 230.799 37.1099 231.072 38.2706C231.345 37.1099 231.593 35.9652 231.799 35.0206C232.475 31.9166 233.173 28.7072 234.095 26.1619C235.733 21.6519 238.233 19.3652 241.533 19.3652C244.833 19.3652 247.338 21.6519 248.973 26.1619C249.895 28.7072 250.594 31.9166 251.269 35.0206C251.475 35.9652 251.723 37.1099 251.996 38.2706C252.269 37.1099 252.517 35.9646 252.723 35.0206C253.399 31.9166 254.097 28.7059 255.019 26.1619C256.655 21.6519 259.157 19.3652 262.459 19.3652C265.76 19.3652 268.264 21.6519 269.899 26.1619C270.823 28.7059 271.52 31.9166 272.196 35.0206C272.401 35.9652 272.651 37.1132 272.924 38.2746C273.196 37.1132 273.447 35.9652 273.652 35.0206C274.327 31.9166 275.025 28.7059 275.948 26.1619C277.583 21.6519 280.087 19.3652 283.389 19.3652H288V28.5846H284.884C284.09 30.4206 283.217 34.4312 282.663 36.9806C281.987 40.0846 281.289 43.2939 280.366 45.8392C278.733 50.3479 276.227 52.6346 272.926 52.6346Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip14_677_3438)">
          <path d="M1080 0H1008V72H1080V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip15_677_3438)">
          <path
            d="M1008 0.0488281H936V72.0488H1008V0.0488281Z"
            fill="#EE93B5"
          />
          <path
            d="M959.078 61.9379L946.152 49.0119L959.078 36.0859L972.003 49.0119L959.078 61.9379Z"
            fill="white"
          />
          <path
            d="M959.078 35.9624L946.152 23.037L959.078 10.1104L972.003 23.037L959.078 35.9624Z"
            fill="white"
          />
          <path
            d="M984.874 61.9379L971.948 49.0119L984.874 36.0859L997.799 49.0119L984.874 61.9379Z"
            fill="white"
          />
          <path
            d="M984.874 35.9624L971.948 23.037L984.874 10.1104L997.799 23.037L984.874 35.9624Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip16_677_3438)">
          <path d="M144 0H72V72H144V0Z" fill="black" />
          <path d="M144 0H72V72H144V0Z" fill="#EE93B5" />
          <path
            d="M99.8428 49.7992C96.179 49.7992 92.6651 48.3437 90.0744 45.753C87.4836 43.1622 86.0282 39.6484 86.0282 35.9845C86.0282 32.3207 87.4836 28.8068 90.0744 26.2161C92.6651 23.6253 96.179 22.1699 99.8428 22.1699C100.906 22.1711 101.965 22.2943 103 22.5372C104.463 21.1006 106.152 19.9142 108 19.0252C101 15.6659 92.3335 16.8825 86.5375 22.6792C83.4645 25.76 81.5536 29.8101 81.1296 34.1408C80.7057 38.4715 81.7949 42.8153 84.2121 46.4336C86.6292 50.0518 90.2251 52.7211 94.3881 53.9875C98.5511 55.2539 103.024 55.0392 107.047 53.3799C107.37 53.2465 107.687 53.1041 108 52.9525C106.155 52.0591 104.467 50.8716 103.003 49.4365C101.967 49.6791 100.907 49.8008 99.8428 49.7992Z"
            fill="white"
          />
          <path
            d="M113.655 35.9846C113.657 32.8696 112.606 29.8455 110.671 27.404C108.737 24.9625 106.033 23.2472 103 22.5373C102.954 22.5846 102.902 22.6326 102.854 22.6793C99.3271 26.2087 97.3457 30.9944 97.3457 35.9843C97.3457 40.9741 99.3271 45.7598 102.854 49.2893C102.906 49.3419 102.954 49.3886 103.006 49.4359C106.038 48.724 108.74 47.0075 110.674 44.5655C112.607 42.1234 113.658 39.0993 113.655 35.9846ZM129.467 22.6793C123.667 16.8826 115 15.6639 108 19.0246C109.91 19.944 111.65 21.1796 113.148 22.6793C120.486 30.0166 120.486 41.9513 113.148 49.2893C111.65 50.7908 109.91 52.0293 108 52.9526C112.006 54.8768 116.563 55.322 120.865 54.2094C125.167 53.0968 128.937 50.4981 131.507 46.8733C134.077 43.2486 135.282 38.8314 134.909 34.4035C134.536 29.9756 132.608 25.8225 129.467 22.6793Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip17_677_3438)">
          <path d="M1368 0H1296V72H1368V0Z" fill="#D7FB03" />
        </g>
        <rect x="648" width="72" height="72" fill="black" />
        <g clipPath="url(#clip18_677_3438)">
          <rect
            x="661.623"
            y="14.5977"
            width="44.2388"
            height="41.7347"
            fill="black"
          />
          <path
            d="M681.502 14.5977V20.2068H667.774V21.9962H678.524V27.3826H667.774V33.0153H661.623V14.5977H681.502Z"
            fill="white"
          />
          <path
            d="M661.623 56.3313V37.9922H674.31C679.695 37.9922 684.851 39.3231 684.851 47.1617C684.851 54.2485 679.153 56.3313 674.371 56.3313H661.623ZM678.418 47.1801C678.418 44.8851 677.258 43.0249 673.954 43.0249H667.774V51.3378H673.959C676.948 51.3378 678.418 49.4777 678.418 47.1801Z"
            fill="white"
          />
          <path
            d="M705.861 50.2411V56.3323H686.257V37.9277H692.402V50.2411H705.861Z"
            fill="white"
          />
          <path
            d="M686.587 19.1824H697.966L687.24 29.6645L690.369 32.9367L701.235 22.4232V32.9367H705.861V14.5977H686.587V19.1824Z"
            fill="white"
          />
        </g>
        <rect
          x="656.194"
          y="9.16868"
          width="55.0968"
          height="52.5927"
          stroke="black"
          strokeWidth="10.858"
        />
        <defs>
          <clipPath id="clip0_677_3438">
            <rect width="72" height="72" fill="white" />
          </clipPath>
          <clipPath id="clip1_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1152)"
            />
          </clipPath>
          <clipPath id="clip2_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(504)"
            />
          </clipPath>
          <clipPath id="clip3_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(288)"
            />
          </clipPath>
          <clipPath id="clip4_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(864)"
            />
          </clipPath>
          <clipPath id="clip5_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1080)"
            />
          </clipPath>
          <clipPath id="clip6_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(360)"
            />
          </clipPath>
          <clipPath id="clip7_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(792)"
            />
          </clipPath>
          <clipPath id="clip8_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1224)"
            />
          </clipPath>
          <clipPath id="clip9_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(576)"
            />
          </clipPath>
          <clipPath id="clip10_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(144)"
            />
          </clipPath>
          <clipPath id="clip11_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(720)"
            />
          </clipPath>
          <clipPath id="clip12_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(432)"
            />
          </clipPath>
          <clipPath id="clip13_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(216)"
            />
          </clipPath>
          <clipPath id="clip14_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1008)"
            />
          </clipPath>
          <clipPath id="clip15_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(936)"
            />
          </clipPath>
          <clipPath id="clip16_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(72)"
            />
          </clipPath>
          <clipPath id="clip17_677_3438">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1296)"
            />
          </clipPath>
          <clipPath id="clip18_677_3438">
            <rect
              x="661.623"
              y="14.5977"
              width="44.2388"
              height="41.7347"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="1454"
        height="72"
        viewBox="0 0 1454 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden lg:block 2xl:hidden h-18"
      >
        <g clipPath="url(#clip0_712_606)">
          <path d="M72 0H0V72H72V0Z" fill="#D7FB03" />
        </g>
        <g clipPath="url(#clip1_712_606)">
          <path d="M144 0H72V72H144V0Z" fill="black" />
          <path d="M144 0H72V72H144V0Z" fill="#EE93B5" />
          <path
            d="M99.8428 49.7992C96.179 49.7992 92.6651 48.3437 90.0744 45.753C87.4836 43.1622 86.0282 39.6484 86.0282 35.9845C86.0282 32.3207 87.4836 28.8068 90.0744 26.2161C92.6651 23.6253 96.179 22.1699 99.8428 22.1699C100.906 22.1711 101.965 22.2943 103 22.5372C104.463 21.1006 106.152 19.9142 108 19.0252C101 15.6659 92.3335 16.8825 86.5375 22.6792C83.4645 25.76 81.5536 29.8101 81.1296 34.1408C80.7057 38.4715 81.7949 42.8153 84.2121 46.4336C86.6292 50.0518 90.2251 52.7211 94.3881 53.9875C98.5511 55.2539 103.024 55.0392 107.047 53.3799C107.37 53.2465 107.687 53.1041 108 52.9525C106.155 52.0591 104.467 50.8716 103.003 49.4365C101.967 49.6791 100.907 49.8008 99.8428 49.7992Z"
            fill="white"
          />
          <path
            d="M113.655 35.9846C113.657 32.8696 112.606 29.8455 110.671 27.404C108.737 24.9625 106.033 23.2472 103 22.5373C102.954 22.5846 102.902 22.6326 102.854 22.6793C99.3271 26.2087 97.3457 30.9944 97.3457 35.9843C97.3457 40.9741 99.3271 45.7598 102.854 49.2893C102.906 49.3419 102.954 49.3886 103.006 49.4359C106.038 48.724 108.74 47.0075 110.674 44.5655C112.607 42.1234 113.658 39.0993 113.655 35.9846ZM129.467 22.6793C123.667 16.8826 115 15.6639 108 19.0246C109.91 19.944 111.65 21.1796 113.148 22.6793C120.486 30.0166 120.486 41.9513 113.148 49.2893C111.65 50.7908 109.91 52.0293 108 52.9526C112.006 54.8768 116.563 55.322 120.865 54.2094C125.167 53.0968 128.937 50.4981 131.507 46.8733C134.077 43.2486 135.282 38.8314 134.909 34.4035C134.536 29.9756 132.608 25.8225 129.467 22.6793Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip2_712_606)">
          <path d="M216 0H144V72H216V0Z" fill="#F6331C" />
        </g>
        <g clipPath="url(#clip3_712_606)">
          <path d="M288 0H216V72H288V0Z" fill="#0063E2" />
          <path
            d="M272.926 52.6346C269.624 52.6346 267.121 50.3479 265.486 45.8392C264.563 43.2939 263.865 40.0846 263.189 36.9806C262.983 36.0346 262.735 34.8886 262.462 33.7272C262.189 34.8886 261.939 36.0346 261.734 36.9806C261.058 40.0846 260.361 43.2939 259.438 45.8392C257.8 50.3479 255.299 52.6346 252 52.6346C248.701 52.6346 246.196 50.3479 244.56 45.8392C243.639 43.2939 242.94 40.0846 242.264 36.9806C242.059 36.0352 241.811 34.8899 241.537 33.7286C241.265 34.8899 241.015 36.0352 240.81 36.9806C240.134 40.0846 239.437 43.2939 238.514 45.8392C236.879 50.3479 234.377 52.6346 231.074 52.6346C227.771 52.6346 225.267 50.3479 223.632 45.8379C222.709 43.2926 222.012 40.0832 221.333 36.9792C220.779 34.4299 219.907 30.4206 219.113 28.5846H216V19.3652H220.61C223.912 19.3652 226.415 21.6519 228.049 26.1619C228.971 28.7059 229.669 31.9152 230.345 35.0192C230.551 35.9646 230.799 37.1099 231.072 38.2706C231.345 37.1099 231.593 35.9652 231.799 35.0206C232.475 31.9166 233.173 28.7072 234.095 26.1619C235.733 21.6519 238.233 19.3652 241.533 19.3652C244.833 19.3652 247.338 21.6519 248.973 26.1619C249.895 28.7072 250.594 31.9166 251.269 35.0206C251.475 35.9652 251.723 37.1099 251.996 38.2706C252.269 37.1099 252.517 35.9646 252.723 35.0206C253.399 31.9166 254.097 28.7059 255.019 26.1619C256.655 21.6519 259.157 19.3652 262.459 19.3652C265.76 19.3652 268.264 21.6519 269.899 26.1619C270.823 28.7059 271.52 31.9166 272.196 35.0206C272.401 35.9652 272.651 37.1132 272.924 38.2746C273.196 37.1132 273.447 35.9652 273.652 35.0206C274.327 31.9166 275.025 28.7059 275.948 26.1619C277.583 21.6519 280.087 19.3652 283.389 19.3652H288V28.5846H284.884C284.09 30.4206 283.217 34.4312 282.663 36.9806C281.987 40.0846 281.289 43.2939 280.366 45.8392C278.733 50.3479 276.227 52.6346 272.926 52.6346Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip4_712_606)">
          <path d="M360 0H288V72H360V0Z" fill="#EE93B5" />
        </g>
        <g clipPath="url(#clip5_712_606)">
          <path d="M432 0H360V72H432V0Z" fill="#D7FB03" />
          <path
            d="M397.409 14.7979L415.771 25.3992V46.6012L397.409 57.2025L379.048 46.6012V25.3992L397.409 14.7979Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip6_712_606)">
          <path d="M504 0H432V72H504V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip7_712_606)">
          <path d="M576 0H504V72H576V0Z" fill="#EE93B5" />
          <path
            d="M545.631 37.0835C590.599 37.0835 540.011 87.6741 540.011 42.7041C540.011 87.6741 489.42 37.0835 534.389 37.0835C489.42 37.0835 540.011 -13.5052 540.011 31.4628C540.011 -13.5052 590.599 37.0835 545.631 37.0835Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip8_712_606)">
          <path d="M648 0H576V72H648V0Z" fill="#F6331C" />
        </g>
        <rect
          width="157.847"
          height="72"
          transform="translate(648)"
          fill="black"
        />
        <rect
          x="658.571"
          y="14.571"
          width="136.705"
          height="42.858"
          fill="black"
        />
        <path
          d="M664 20.2021H679.132V24.5014H668.685V25.8675H676.864V30.006H668.683V34.3273H664V20.2021Z"
          fill="white"
        />
        <path
          d="M688.777 20.0008C689.906 19.9915 691.033 20.062 692.152 20.2118C693.082 20.3391 693.992 20.5786 694.864 20.9249C695.628 21.2161 696.323 21.6643 696.903 22.2408C697.46 22.8257 697.881 23.5251 698.139 24.29C698.452 25.2251 698.602 26.207 698.583 27.193C698.601 28.1584 698.469 29.1208 698.191 30.0457C697.959 30.7961 697.576 31.4909 697.064 32.0869C696.531 32.6749 695.871 33.135 695.135 33.4329C694.27 33.7934 693.362 34.0398 692.433 34.1662C691.268 34.3236 690.093 34.3974 688.918 34.3871C685.595 34.3871 683.18 33.7844 681.673 32.5791C680.166 31.3737 679.413 29.4946 679.413 26.9419C679.413 24.5846 680.183 22.8402 681.723 21.7084C683.264 20.5767 685.615 20.0075 688.777 20.0008ZM688.958 30.6886C690.376 30.6886 691.474 30.4006 692.253 29.8247C693.031 29.2488 693.419 28.3716 693.418 27.193C693.418 26.0559 693.009 25.1853 692.192 24.5813C691.375 23.9773 690.277 23.6793 688.897 23.6873C688.253 23.6771 687.61 23.7548 686.987 23.9183C686.489 24.0467 686.026 24.2834 685.631 24.6114C685.283 24.9117 685.011 25.2904 684.837 25.7164C684.657 26.1839 684.568 26.6818 684.576 27.1829C684.558 27.6623 684.647 28.1398 684.839 28.5798C685.03 29.0197 685.318 29.4109 685.681 29.7243C686.419 30.3685 687.51 30.6899 688.954 30.6886H688.958Z"
          fill="white"
        />
        <path
          d="M708.608 34.3268C706.506 34.3268 704.752 34.1192 703.344 33.704C701.936 33.2888 700.965 32.5722 700.431 31.5544C700.004 30.6597 699.739 29.6967 699.648 28.7096C699.488 27.0102 699.421 25.3034 699.447 23.5968L699.467 20.2217H704.409V26.5299C704.406 26.8891 704.426 27.248 704.469 27.6047C704.522 27.9629 704.627 28.3114 704.78 28.6393C704.936 29.0001 705.173 29.3199 705.474 29.5735C705.844 29.861 706.267 30.0726 706.719 30.1963C707.336 30.3761 707.976 30.4615 708.618 30.4494C709.257 30.4621 709.895 30.3767 710.508 30.1963C710.947 30.0757 711.357 29.8672 711.713 29.5835C711.999 29.3257 712.222 29.0063 712.366 28.6494C712.512 28.3202 712.611 27.9718 712.658 27.6147C712.693 27.2542 712.71 26.8921 712.708 26.5299V20.2217H717.65V23.5968C717.687 25.3031 717.627 27.0102 717.469 28.7096C717.378 29.6967 717.113 30.6597 716.686 31.5544C716.4 32.0944 715.99 32.5591 715.49 32.9104C714.925 33.3011 714.297 33.5901 713.632 33.7642C712.882 33.9706 712.116 34.1151 711.342 34.1962C710.433 34.2881 709.521 34.3316 708.608 34.3268Z"
          fill="white"
        />
        <path
          d="M736.719 34.3268H730.338L723.427 25.8267V34.3268H718.734V20.2217H725.266L732.056 28.5791V20.2217H736.719V34.3268Z"
          fill="white"
        />
        <path
          d="M747.71 34.3271H738.005V20.2642H747.648C748.461 20.2579 749.273 20.3083 750.079 20.4149C750.818 20.5236 751.54 20.7261 752.228 21.0176C752.908 21.2894 753.524 21.6999 754.037 22.223C754.569 22.811 754.97 23.5063 755.212 24.2621C755.532 25.2407 755.685 26.2662 755.664 27.2956C755.678 28.2549 755.515 29.2086 755.182 30.1082C754.906 30.8597 754.475 31.5443 753.916 32.1172C753.375 32.646 752.75 33.0806 752.066 33.403C751.387 33.734 750.668 33.9738 749.926 34.1161C749.196 34.255 748.454 34.3256 747.71 34.3271ZM747.389 30.4899C748.045 30.509 748.695 30.3596 749.277 30.056C749.766 29.803 750.165 29.407 750.422 28.9209C750.671 28.4221 750.795 27.8707 750.784 27.3137C750.784 26.3494 750.516 25.5759 749.98 24.9933C749.445 24.4107 748.584 24.1194 747.399 24.1194H742.698V30.4899H747.389Z"
          fill="white"
        />
        <path
          d="M756.492 20.2217H772.064V24.1332H761.173V25.3787H771.381V29.1355H761.173V30.4012H772.064V34.3207H756.492V20.2217Z"
          fill="white"
        />
        <path
          d="M773.107 20.2217H783.476C783.743 20.2217 783.998 20.2217 784.239 20.2418C784.58 20.2664 784.918 20.31 785.254 20.3724C785.658 20.4365 786.055 20.5374 786.441 20.6737C786.841 20.8236 787.221 21.0191 787.576 21.2563C787.964 21.5048 788.307 21.8173 788.591 22.1804C788.893 22.5908 789.124 23.0493 789.274 23.5365C789.463 24.1409 789.554 24.7716 789.545 25.4049C789.545 27.6281 788.68 29.2159 786.951 30.1682L789.846 34.3268L784.44 34.3067L782.029 30.9918H777.79V34.3268H773.107V20.2217ZM783.034 26.8714C784.199 26.8714 784.782 26.4294 784.782 25.5455C784.782 24.7419 784.172 24.3401 782.953 24.3401H777.79V26.8714H783.034Z"
          fill="white"
        />
        <path
          d="M668.683 47.3177H678.909V52.0006H664V37.8936H668.683V47.3177Z"
          fill="white"
        />
        <path
          d="M693.277 37.9336L699.607 51.9965H694.177L693.112 49.6239H686.141L685.076 51.9965H679.692L686.001 37.9336H693.277ZM687.811 45.5677H691.449L689.621 41.8712L687.811 45.5677Z"
          fill="white"
        />
        <path
          d="M718.486 52.0006H712.098L705.193 43.5006V52.0006H700.512V37.8936H707.035L713.827 46.2529V37.8936H718.486V52.0006Z"
          fill="white"
        />
        <path
          d="M729.504 52.0009H719.799V37.938H729.442C730.255 37.9318 731.068 37.9821 731.875 38.0887C732.614 38.1974 733.336 38.4 734.024 38.6914C734.704 38.9636 735.32 39.374 735.833 39.8968C736.365 40.4856 736.766 41.1815 737.008 41.9379C737.328 42.9165 737.481 43.942 737.46 44.9715C737.475 45.9308 737.311 46.8845 736.978 47.784C736.703 48.536 736.272 49.2209 735.712 49.793C735.172 50.322 734.547 50.7566 733.864 51.0788C733.185 51.4111 732.465 51.6516 731.722 51.794C730.991 51.9306 730.248 51.9999 729.504 52.0009ZM729.183 48.1617C729.839 48.1815 730.489 48.0328 731.071 47.7298C731.56 47.4763 731.96 47.0805 732.218 46.5947C732.466 46.0959 732.591 45.5446 732.58 44.9875C732.58 44.0232 732.307 43.2498 731.776 42.6672C731.246 42.0846 730.37 41.7933 729.193 41.7933H724.492V48.1617H729.183Z"
          fill="white"
        />
        <path
          d="M738.419 41.4493H747.066L738.913 49.4873L741.294 51.9965L749.551 43.9344V51.9965H753.066V37.9336H738.419V41.4493Z"
          fill="white"
        />
        <rect
          x="658.571"
          y="14.571"
          width="136.705"
          height="42.858"
          stroke="black"
          strokeWidth="10.858"
        />
        <g clipPath="url(#clip9_712_606)">
          <path d="M877.847 0H805.847V72H877.847V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip10_712_606)">
          <path
            d="M949.847 0.0498047H877.847V72.0498H949.847V0.0498047Z"
            fill="#F6331C"
          />
          <path
            d="M913.907 34.875L906.028 7.16504H921.786L913.907 34.875Z"
            fill="white"
          />
          <path
            d="M913.07 35.1523L887.904 21.129L899.047 9.98633L913.07 35.1523Z"
            fill="white"
          />
          <path
            d="M912.673 35.9392L884.963 43.8185V28.0605L912.673 35.9392Z"
            fill="white"
          />
          <path
            d="M912.95 36.7764L898.927 61.9424L887.784 50.7997L912.95 36.7764Z"
            fill="white"
          />
          <path
            d="M913.737 37.1738L921.616 64.8838H905.858L913.737 37.1738Z"
            fill="white"
          />
          <path
            d="M914.574 36.8965L939.74 50.9198L928.598 62.0632L914.574 36.8965Z"
            fill="white"
          />
          <path
            d="M914.972 36.1091L942.682 28.2305V43.9878L914.972 36.1091Z"
            fill="white"
          />
          <path
            d="M914.694 35.2724L928.718 10.1064L939.86 21.2491L914.694 35.2724Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip11_712_606)">
          <path d="M1021.85 0H949.847V72H1021.85V0Z" fill="#D7FB03" />
        </g>
        <g clipPath="url(#clip12_712_606)">
          <path
            d="M1093.85 0.0488281H1021.85V72.0488H1093.85V0.0488281Z"
            fill="#EE93B5"
          />
          <path
            d="M1044.92 61.9379L1032 49.0119L1044.92 36.0859L1057.85 49.0119L1044.92 61.9379Z"
            fill="white"
          />
          <path
            d="M1044.92 35.9624L1032 23.037L1044.92 10.1104L1057.85 23.037L1044.92 35.9624Z"
            fill="white"
          />
          <path
            d="M1070.72 61.9379L1057.79 49.0119L1070.72 36.0859L1083.65 49.0119L1070.72 61.9379Z"
            fill="white"
          />
          <path
            d="M1070.72 35.9624L1057.79 23.037L1070.72 10.1104L1083.65 23.037L1070.72 35.9624Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip13_712_606)">
          <path d="M1165.85 0H1093.85V72H1165.85V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip14_712_606)">
          <path
            d="M1237.48 -0.363281H1165.48V71.6367H1237.48V-0.363281Z"
            fill="#D7FB03"
          />
          <path
            d="M1205.94 58.0899L1200 52.1459L1217.52 36.0332L1200 19.9206L1205.94 13.9766L1228 36.0332L1205.94 58.0899Z"
            fill="black"
          />
          <path
            d="M1181.42 58.0899L1175.47 52.1459L1192.99 36.0332L1175.47 19.9206L1181.42 13.9766L1203.47 36.0332L1181.42 58.0899Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip15_712_606)">
          <path d="M1309.85 0H1237.85V72H1309.85V0Z" fill="#EE93B5" />
        </g>
        <g clipPath="url(#clip16_712_606)">
          <path d="M1381.85 0H1309.85V72H1381.85V0Z" fill="#F6331C" />
          <path
            d="M1322.9 35.9995C1328.23 37.7684 1333.07 40.7492 1337.05 44.7073C1341.03 48.6653 1344.03 53.4927 1345.83 58.8095C1347.62 53.4929 1350.63 48.6657 1354.61 44.7076C1358.59 40.7496 1363.43 37.7686 1368.75 35.9995C1363.43 34.2303 1358.59 31.2493 1354.61 27.2913C1350.63 23.3332 1347.62 18.506 1345.83 13.1895C1344.03 18.5061 1341.03 23.3333 1337.05 27.2914C1333.07 31.2494 1328.23 34.2303 1322.9 35.9995Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip17_712_606)">
          <path d="M1453.85 0H1381.85V72H1453.85V0Z" fill="#D7FB03" />
        </g>
        <defs>
          <clipPath id="clip0_712_606">
            <rect width="72" height="72" fill="white" />
          </clipPath>
          <clipPath id="clip1_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(72)"
            />
          </clipPath>
          <clipPath id="clip2_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(144)"
            />
          </clipPath>
          <clipPath id="clip3_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(216)"
            />
          </clipPath>
          <clipPath id="clip4_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(288)"
            />
          </clipPath>
          <clipPath id="clip5_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(360)"
            />
          </clipPath>
          <clipPath id="clip6_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(432)"
            />
          </clipPath>
          <clipPath id="clip7_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(504)"
            />
          </clipPath>
          <clipPath id="clip8_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(576)"
            />
          </clipPath>
          <clipPath id="clip9_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(805.847)"
            />
          </clipPath>
          <clipPath id="clip10_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(877.847)"
            />
          </clipPath>
          <clipPath id="clip11_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(949.847)"
            />
          </clipPath>
          <clipPath id="clip12_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1021.85)"
            />
          </clipPath>
          <clipPath id="clip13_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1093.85)"
            />
          </clipPath>
          <clipPath id="clip14_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1165.85)"
            />
          </clipPath>
          <clipPath id="clip15_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1237.85)"
            />
          </clipPath>
          <clipPath id="clip16_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1309.85)"
            />
          </clipPath>
          <clipPath id="clip17_712_606">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1381.85)"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="1812"
        height="72"
        viewBox="0 0 1812 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden 2xl:block h-18"
      >
        <g clipPath="url(#clip0_712_605)">
          <path d="M72 0H0V72H72V0Z" fill="#D7FB03" />
        </g>
        <g clipPath="url(#clip1_712_605)">
          <path d="M144 0H72V72H144V0Z" fill="black" />
          <path d="M144 0H72V72H144V0Z" fill="#EE93B5" />
          <path
            d="M99.8428 49.7992C96.179 49.7992 92.6651 48.3437 90.0744 45.753C87.4836 43.1622 86.0282 39.6484 86.0282 35.9845C86.0282 32.3207 87.4836 28.8068 90.0744 26.2161C92.6651 23.6253 96.179 22.1699 99.8428 22.1699C100.906 22.1711 101.965 22.2943 103 22.5372C104.463 21.1006 106.152 19.9142 108 19.0252C101 15.6659 92.3335 16.8825 86.5375 22.6792C83.4645 25.76 81.5536 29.8101 81.1296 34.1408C80.7057 38.4715 81.7949 42.8153 84.2121 46.4336C86.6292 50.0518 90.2251 52.7211 94.3881 53.9875C98.5511 55.2539 103.024 55.0392 107.047 53.3799C107.37 53.2465 107.687 53.1041 108 52.9525C106.155 52.0591 104.467 50.8716 103.003 49.4365C101.967 49.6791 100.907 49.8008 99.8428 49.7992Z"
            fill="white"
          />
          <path
            d="M113.655 35.9846C113.657 32.8696 112.606 29.8455 110.671 27.404C108.737 24.9625 106.033 23.2472 103 22.5373C102.954 22.5846 102.902 22.6326 102.854 22.6793C99.3271 26.2087 97.3457 30.9944 97.3457 35.9843C97.3457 40.9741 99.3271 45.7598 102.854 49.2893C102.906 49.3419 102.954 49.3886 103.006 49.4359C106.038 48.724 108.74 47.0075 110.674 44.5655C112.607 42.1234 113.658 39.0993 113.655 35.9846ZM129.467 22.6793C123.667 16.8826 115 15.6639 108 19.0246C109.91 19.944 111.65 21.1796 113.148 22.6793C120.486 30.0166 120.486 41.9513 113.148 49.2893C111.65 50.7908 109.91 52.0293 108 52.9526C112.006 54.8768 116.563 55.322 120.865 54.2094C125.167 53.0968 128.937 50.4981 131.507 46.8733C134.077 43.2486 135.282 38.8314 134.909 34.4035C134.536 29.9756 132.608 25.8225 129.467 22.6793Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip2_712_605)">
          <path d="M216 0H144V72H216V0Z" fill="#F6331C" />
        </g>
        <g clipPath="url(#clip3_712_605)">
          <path d="M288 0H216V72H288V0Z" fill="#0063E2" />
          <path
            d="M272.926 52.6346C269.624 52.6346 267.121 50.3479 265.486 45.8392C264.563 43.2939 263.865 40.0846 263.189 36.9806C262.983 36.0346 262.735 34.8886 262.462 33.7272C262.189 34.8886 261.939 36.0346 261.734 36.9806C261.058 40.0846 260.361 43.2939 259.438 45.8392C257.8 50.3479 255.299 52.6346 252 52.6346C248.701 52.6346 246.196 50.3479 244.56 45.8392C243.639 43.2939 242.94 40.0846 242.264 36.9806C242.059 36.0352 241.811 34.8899 241.537 33.7286C241.265 34.8899 241.015 36.0352 240.81 36.9806C240.134 40.0846 239.437 43.2939 238.514 45.8392C236.879 50.3479 234.377 52.6346 231.074 52.6346C227.771 52.6346 225.267 50.3479 223.632 45.8379C222.709 43.2926 222.012 40.0832 221.333 36.9792C220.779 34.4299 219.907 30.4206 219.113 28.5846H216V19.3652H220.61C223.912 19.3652 226.415 21.6519 228.049 26.1619C228.971 28.7059 229.669 31.9152 230.345 35.0192C230.551 35.9646 230.799 37.1099 231.072 38.2706C231.345 37.1099 231.593 35.9652 231.799 35.0206C232.475 31.9166 233.173 28.7072 234.095 26.1619C235.733 21.6519 238.233 19.3652 241.533 19.3652C244.833 19.3652 247.338 21.6519 248.973 26.1619C249.895 28.7072 250.594 31.9166 251.269 35.0206C251.475 35.9652 251.723 37.1099 251.996 38.2706C252.269 37.1099 252.517 35.9646 252.723 35.0206C253.399 31.9166 254.097 28.7059 255.019 26.1619C256.655 21.6519 259.157 19.3652 262.459 19.3652C265.76 19.3652 268.264 21.6519 269.899 26.1619C270.823 28.7059 271.52 31.9166 272.196 35.0206C272.401 35.9652 272.651 37.1132 272.924 38.2746C273.196 37.1132 273.447 35.9652 273.652 35.0206C274.327 31.9166 275.025 28.7059 275.948 26.1619C277.583 21.6519 280.087 19.3652 283.389 19.3652H288V28.5846H284.884C284.09 30.4206 283.217 34.4312 282.663 36.9806C281.987 40.0846 281.289 43.2939 280.366 45.8392C278.733 50.3479 276.227 52.6346 272.926 52.6346Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip4_712_605)">
          <path d="M360 0H288V72H360V0Z" fill="#EE93B5" />
        </g>
        <g clipPath="url(#clip5_712_605)">
          <path d="M432 0H360V72H432V0Z" fill="#D7FB03" />
          <path
            d="M397.409 14.7979L415.771 25.3992V46.6012L397.409 57.2025L379.048 46.6012V25.3992L397.409 14.7979Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip6_712_605)">
          <path d="M504 0H432V72H504V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip7_712_605)">
          <path d="M576 0H504V72H576V0Z" fill="#EE93B5" />
          <path
            d="M545.631 37.0835C590.599 37.0835 540.011 87.6741 540.011 42.7041C540.011 87.6741 489.42 37.0835 534.389 37.0835C489.42 37.0835 540.011 -13.5052 540.011 31.4628C540.011 -13.5052 590.599 37.0835 545.631 37.0835Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip8_712_605)">
          <path d="M648 0H576V72H648V0Z" fill="#F6331C" />
        </g>
        <rect
          width="515.581"
          height="72"
          transform="translate(648)"
          fill="black"
        />
        <rect
          x="658.571"
          y="14.571"
          width="494.439"
          height="42.858"
          fill="black"
        />
        <path
          d="M664 20.4482H697.645V30.0131H674.414V33.051H692.596V42.254H674.414V51.8635H664.004L664 20.4482Z"
          fill="white"
        />
        <path
          d="M719.089 20.0018C721.599 19.981 724.107 20.1377 726.594 20.4709C728.663 20.7562 730.689 21.2902 732.63 22.0613C734.329 22.7099 735.873 23.7066 737.164 24.9875C738.398 26.2905 739.335 27.845 739.912 29.5444C740.607 31.624 740.939 33.8076 740.895 35.9999C740.937 38.1469 740.643 40.2874 740.023 42.3437C739.509 44.0108 738.658 45.5543 737.522 46.8781C736.334 48.1853 734.869 49.2097 733.233 49.8758C731.307 50.6759 729.286 51.224 727.22 51.5065C724.629 51.8567 722.016 52.0209 719.402 51.9979C712.012 51.9979 706.643 50.6576 703.292 47.9771C699.941 45.2967 698.268 41.1106 698.271 35.4191C698.271 30.1743 699.985 26.295 703.413 23.7813C706.841 21.2676 712.066 20.0078 719.089 20.0018ZM719.491 43.7733C722.651 43.7733 725.093 43.1329 726.818 41.8522C728.542 40.5716 729.407 38.6208 729.413 35.9999C729.413 33.4683 728.503 31.5324 726.684 30.1921C724.864 28.8519 722.422 28.1892 719.357 28.2041C717.925 28.1811 716.498 28.354 715.113 28.7179C714.005 29.0004 712.975 29.527 712.097 30.2592C711.312 30.9219 710.699 31.7649 710.31 32.7163C709.907 33.7553 709.709 34.863 709.73 35.9775C709.687 37.0437 709.886 38.1057 710.312 39.0843C710.737 40.0629 711.378 40.9327 712.187 41.6289C713.846 43.0614 716.282 43.7762 719.495 43.7733H719.491Z"
          fill="white"
        />
        <path
          d="M763.196 51.8628C758.518 51.8628 754.614 51.4011 751.487 50.4778C748.36 49.5546 746.198 47.9597 745 45.6932C744.055 43.7038 743.466 41.5644 743.258 39.3717C742.904 35.5926 742.755 31.7972 742.811 28.002L742.856 20.4922H753.85V34.5245C753.845 35.3232 753.89 36.1215 753.985 36.9146C754.1 37.7115 754.334 38.4869 754.677 39.2154C755.023 40.0177 755.551 40.7287 756.218 41.2927C757.041 41.9329 757.982 42.4036 758.988 42.6776C760.359 43.0765 761.782 43.2648 763.21 43.2361C764.63 43.2656 766.046 43.0773 767.409 42.6776C768.386 42.4106 769.298 41.9469 770.09 41.3151C770.726 40.7429 771.223 40.0322 771.542 39.2377C771.869 38.5064 772.087 37.7313 772.189 36.9369C772.27 36.1354 772.308 35.3301 772.301 34.5245V20.4922H783.296V28.002C783.373 31.7964 783.239 35.5922 782.894 39.3717C782.686 41.5644 782.096 43.7038 781.151 45.6932C780.516 46.8953 779.605 47.9301 778.493 48.7132C777.235 49.5821 775.835 50.2247 774.356 50.6119C772.687 51.0686 770.984 51.3898 769.263 51.5724C767.248 51.7764 765.222 51.8733 763.196 51.8628Z"
          fill="white"
        />
        <path
          d="M825.705 51.8628H811.499L796.126 32.9609V51.8628H785.717V20.4922H800.236L815.341 39.0813V20.4922H825.705V51.8628Z"
          fill="white"
        />
        <path
          d="M850.151 51.8633H828.568V20.591H850.012C851.82 20.5772 853.626 20.6891 855.418 20.926C857.062 21.1678 858.668 21.6182 860.198 22.2663C861.709 22.8718 863.079 23.7845 864.219 24.9468C865.403 26.2545 866.295 27.8006 866.832 29.4812C867.55 31.6549 867.895 33.934 867.855 36.2227C867.889 38.3559 867.525 40.4768 866.783 42.4771C866.173 44.1493 865.213 45.6722 863.969 46.9446C862.767 48.1209 861.378 49.0887 859.859 49.8082C858.349 50.5431 856.749 51.0763 855.101 51.3942C853.469 51.704 851.812 51.861 850.151 51.8633ZM849.436 43.3259C850.895 43.3692 852.34 43.0386 853.635 42.3654C854.721 41.8028 855.61 40.9222 856.182 39.8413C856.734 38.732 857.009 37.506 856.986 36.2673C856.986 34.1229 856.39 32.403 855.199 31.1074C854.008 29.8118 852.094 29.1641 849.458 29.1641H838.978V43.3259H849.436Z"
          fill="white"
        />
        <path
          d="M869.674 20.4922H904.306V29.2082H880.088V31.9781H902.787V40.3322H880.088V43.1467H904.306V51.8628H869.674V20.4922Z"
          fill="white"
        />
        <path
          d="M906.628 20.4922H929.685C930.279 20.4922 930.846 20.4922 931.382 20.5369C931.918 20.5815 932.669 20.6664 933.616 20.8272C934.513 20.9735 935.395 21.1992 936.252 21.5018C937.14 21.8344 937.987 22.2676 938.776 22.7929C939.639 23.3473 940.401 24.0436 941.032 24.8524C941.707 25.7634 942.221 26.7833 942.551 27.868C942.972 29.212 943.175 30.6145 943.154 32.0227C943.154 36.9667 941.233 40.496 937.391 42.6106L943.824 51.8628L931.807 51.8181L926.446 44.4423H917.037V51.8628H906.628V20.4922ZM928.702 35.284C931.293 35.284 932.588 34.3011 932.588 32.3354C932.588 30.5485 931.233 29.655 928.523 29.655H917.037V35.284H928.702Z"
          fill="white"
        />
        <path
          d="M994.627 41.4491H1017.37V51.8628H984.218V20.4922H994.627V41.4491Z"
          fill="white"
        />
        <path
          d="M1049.32 20.583L1063.4 51.8553H1051.33L1048.96 46.5792H1033.46L1031.09 51.8553H1019.12L1033.15 20.583H1049.32ZM1037.17 37.5639H1045.26L1041.19 29.3437L1037.17 37.5639Z"
          fill="white"
        />
        <path
          d="M1105.4 51.8628H1091.19L1075.82 32.9609V51.8628H1065.41V20.4922H1079.93L1095.03 39.0813V20.4922H1105.4V51.8628Z"
          fill="white"
        />
        <path
          d="M1129.89 51.8633H1108.3V20.591H1129.75C1131.55 20.5771 1133.36 20.689 1135.15 20.9261C1136.8 21.1679 1138.4 21.6182 1139.93 22.2663C1141.44 22.8718 1142.81 23.7846 1143.95 24.9468C1145.14 26.2534 1146.03 27.7998 1146.57 29.4813C1147.28 31.6566 1147.62 33.9376 1147.58 36.2272C1147.61 38.3609 1147.25 40.4821 1146.5 42.4816C1145.89 44.1538 1144.93 45.6767 1143.69 46.9491C1142.48 48.1254 1141.09 49.0932 1139.58 49.8127C1138.07 50.5489 1136.47 51.0822 1134.82 51.3987C1133.19 51.7061 1131.54 51.8616 1129.89 51.8633ZM1129.17 43.326C1130.63 43.3692 1132.07 43.0386 1133.37 42.3655C1134.46 41.8028 1135.34 40.9222 1135.92 39.8413C1136.47 38.7321 1136.74 37.506 1136.72 36.2673C1136.72 34.123 1136.12 32.403 1134.93 31.1074C1133.74 29.8119 1131.83 29.1641 1129.19 29.1641H1118.73V43.326H1129.17Z"
          fill="white"
        />
        <path
          d="M946.696 28.4011H965.924L947.8 46.2754L953.089 51.8553L971.455 33.9318V51.8553H979.269V20.583H946.696V28.4011Z"
          fill="white"
        />
        <rect
          x="658.571"
          y="14.571"
          width="494.439"
          height="42.858"
          stroke="black"
          strokeWidth="10.858"
        />
        <g clipPath="url(#clip9_712_605)">
          <path d="M1235.58 0H1163.58V72H1235.58V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip10_712_605)">
          <path
            d="M1307.58 0.0498047H1235.58V72.0498H1307.58V0.0498047Z"
            fill="#F6331C"
          />
          <path
            d="M1271.64 34.875L1263.76 7.16504H1279.52L1271.64 34.875Z"
            fill="white"
          />
          <path
            d="M1270.8 35.1523L1245.64 21.129L1256.78 9.98633L1270.8 35.1523Z"
            fill="white"
          />
          <path
            d="M1270.41 35.9392L1242.7 43.8185V28.0605L1270.41 35.9392Z"
            fill="white"
          />
          <path
            d="M1270.68 36.7764L1256.66 61.9424L1245.52 50.7997L1270.68 36.7764Z"
            fill="white"
          />
          <path
            d="M1271.47 37.1738L1279.35 64.8838H1263.59L1271.47 37.1738Z"
            fill="white"
          />
          <path
            d="M1272.31 36.8965L1297.47 50.9198L1286.33 62.0632L1272.31 36.8965Z"
            fill="white"
          />
          <path
            d="M1272.71 36.1091L1300.42 28.2305V43.9878L1272.71 36.1091Z"
            fill="white"
          />
          <path
            d="M1272.43 35.2724L1286.45 10.1064L1297.59 21.2491L1272.43 35.2724Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip11_712_605)">
          <path d="M1379.58 0H1307.58V72H1379.58V0Z" fill="#D7FB03" />
        </g>
        <g clipPath="url(#clip12_712_605)">
          <path
            d="M1451.58 0.0488281H1379.58V72.0488H1451.58V0.0488281Z"
            fill="#EE93B5"
          />
          <path
            d="M1402.66 61.9379L1389.73 49.0119L1402.66 36.0859L1415.58 49.0119L1402.66 61.9379Z"
            fill="white"
          />
          <path
            d="M1402.66 35.9624L1389.73 23.037L1402.66 10.1104L1415.58 23.037L1402.66 35.9624Z"
            fill="white"
          />
          <path
            d="M1428.45 61.9379L1415.53 49.0119L1428.45 36.0859L1441.38 49.0119L1428.45 61.9379Z"
            fill="white"
          />
          <path
            d="M1428.45 35.9624L1415.53 23.037L1428.45 10.1104L1441.38 23.037L1428.45 35.9624Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip13_712_605)">
          <path d="M1523.58 0H1451.58V72H1523.58V0Z" fill="#0063E2" />
        </g>
        <g clipPath="url(#clip14_712_605)">
          <path
            d="M1595.22 -0.363281H1523.22V71.6367H1595.22V-0.363281Z"
            fill="#D7FB03"
          />
          <path
            d="M1563.68 58.0899L1557.73 52.1459L1575.26 36.0332L1557.73 19.9206L1563.68 13.9766L1585.73 36.0332L1563.68 58.0899Z"
            fill="black"
          />
          <path
            d="M1539.15 58.0899L1533.21 52.1459L1550.73 36.0332L1533.21 19.9206L1539.15 13.9766L1561.21 36.0332L1539.15 58.0899Z"
            fill="black"
          />
        </g>
        <g clipPath="url(#clip15_712_605)">
          <path d="M1667.58 0H1595.58V72H1667.58V0Z" fill="#EE93B5" />
        </g>
        <g clipPath="url(#clip16_712_605)">
          <path d="M1739.58 0H1667.58V72H1739.58V0Z" fill="#F6331C" />
          <path
            d="M1680.64 35.9995C1685.96 37.7684 1690.8 40.7492 1694.78 44.7073C1698.76 48.6653 1701.77 53.4927 1703.56 58.8095C1705.36 53.4929 1708.36 48.6657 1712.34 44.7076C1716.32 40.7496 1721.16 37.7686 1726.49 35.9995C1721.16 34.2303 1716.32 31.2493 1712.34 27.2913C1708.36 23.3332 1705.36 18.506 1703.56 13.1895C1701.77 18.5061 1698.76 23.3333 1694.78 27.2914C1690.8 31.2494 1685.96 34.2303 1680.64 35.9995Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip17_712_605)">
          <path d="M1811.58 0H1739.58V72H1811.58V0Z" fill="#D7FB03" />
        </g>
        <defs>
          <clipPath id="clip0_712_605">
            <rect width="72" height="72" fill="white" />
          </clipPath>
          <clipPath id="clip1_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(72)"
            />
          </clipPath>
          <clipPath id="clip2_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(144)"
            />
          </clipPath>
          <clipPath id="clip3_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(216)"
            />
          </clipPath>
          <clipPath id="clip4_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(288)"
            />
          </clipPath>
          <clipPath id="clip5_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(360)"
            />
          </clipPath>
          <clipPath id="clip6_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(432)"
            />
          </clipPath>
          <clipPath id="clip7_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(504)"
            />
          </clipPath>
          <clipPath id="clip8_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(576)"
            />
          </clipPath>
          <clipPath id="clip9_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1163.58)"
            />
          </clipPath>
          <clipPath id="clip10_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1235.58)"
            />
          </clipPath>
          <clipPath id="clip11_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1307.58)"
            />
          </clipPath>
          <clipPath id="clip12_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1379.58)"
            />
          </clipPath>
          <clipPath id="clip13_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1451.58)"
            />
          </clipPath>
          <clipPath id="clip14_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1523.58)"
            />
          </clipPath>
          <clipPath id="clip15_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1595.58)"
            />
          </clipPath>
          <clipPath id="clip16_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1667.58)"
            />
          </clipPath>
          <clipPath id="clip17_712_605">
            <rect
              width="72"
              height="72"
              fill="white"
              transform="translate(1739.58)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Banner
