import { EyeIcon, PencilAltIcon } from '@heroicons/react/outline'
import { Link, useParams } from 'react-router-dom'
import { useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import moment from 'moment'

const RowsWidget = ({ headers, item, styles, link }) => {
  const { view } = useParams()
  const { avatarInitials, isIsoDate } = useContext(AdminContext)
  const displayReviews = (reviews) => {
    const extra = reviews.length - 3
    let reviewsDisplay = []
    if (reviews.length) {
      if (reviews.length <= 3) {
        reviewsDisplay = [
          ...reviews.map((review, i) => (
            <div
              className={`cursor-default flex relative w-8 h-8 justify-center items-center m-1 mr-2 -ml-3 rounded-full text-sm text-mono border-r-2 border-white ${
                review.user.avatar
              } ${styles[String(review.user.role)]}`}
              key={review.user.firstName + i}
            >
              {avatarInitials(review.user.firstName, review.user.lastName)}
            </div>
          )),
        ]
      } else {
        if (extra > 0) {
          reviewsDisplay.push(
            <div
              key={extra + item._id}
              className='cursor-default flex relative w-8 h-8 bg-gray-500 justify-center items-center m-1 mr-2 -ml-3 rounded-full border-r-2 border-white text-sm font-bold text-white'
            >
              + {extra}
            </div>
          )
        }
        for (let i = 0; i < 3; i++) {
          reviewsDisplay.push(
            <div
              className={`cursor-default flex relative w-8 h-8 justify-center items-center m-1 mr-2 -ml-3 rounded-full text-lg text-mono border-r-2 border-white ${
                reviews[i].user.avatar
              } ${styles[String(reviews[i].user.role)]}`}
              key={reviews[i].user.firstName + i}
            >
              {avatarInitials(
                reviews[i].user.firstName,
                reviews[i].user.lastName
              )}
            </div>
          )
        }
      }
    } else {
      reviewsDisplay.push(
        <p key={'noreview' + item._id} className='text-sm'>
          Not reviewed yet
        </p>
      )
    }
    return reviewsDisplay
  }
  return (
    <>
      <tr className='border-b border-gray-200 hover:bg-gray-100'>
        {headers.map((header) => {
          if (header.key !== '-') {
            if (Array.isArray(item[header.key])) {
              return (
                <td
                  key={header.key + item._id}
                  className={`py-3 px-5 ${header.style}`}
                >
                  <div className={`flex items-center px-2 ${header.style}`}>
                    <div className='flex flex-row-reverse items-center'>
                      {displayReviews(item[header.key])}
                    </div>
                  </div>
                </td>
              )
            } else if (typeof item[header.key] === 'boolean') {
              return header.key === 'isLocked' ? (
                <td
                  key={header.key + item._id}
                  className={`py-3 px-5 ${header.style}`}
                >
                  <div className='flex items-center px-2'>
                    {item[header.key] ? (
                      <p className='bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300'>
                        Access Locked
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </td>
              ) : header.key === 'flagSetup' ? (
                <td
                  key={header.key + item._id}
                  className={`py-3 px-5 ${header.style}`}
                >
                  <div className='flex items-center px-2'>
                    {item[header.key] ? (
                      <p
                        className={`${
                          item.role === 'founder'
                            ? 'bg-fred text-white'
                            : item.role === 'investor'
                            ? 'bg-fblue text-white'
                            : 'bg-flime text-black'
                        } text-xs w-28 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300`}
                      >
                        {item.role === 'founder'
                          ? 'Flag available'
                          : 'Signed up'}
                      </p>
                    ) : (
                      <p className='bg-gray-100 text-black text-xs w-28 font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300'>
                        {item.role === 'founder' ? 'No Flag' : 'Not Signed up'}
                      </p>
                    )}
                  </div>
                </td>
              ) : (
                <td
                  key={header.key + item._id}
                  className={`relative  py-3 px-5  max-w-sm ${header.style}`}
                >
                  {item[header.key] ? (
                    <p className='bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300'>
                      Available
                    </p>
                  ) : (
                    <p className='bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300'>
                      Unavailable
                    </p>
                  )}
                </td>
              )
            } else if (isIsoDate(item[header.key])) {
              return (
                <td
                  key={header.key}
                  className={`relative  py-3 px-5  max-w-sm ${header.style}`}
                >
                  <p
                    className={
                      styles[
                        String(item[header.key])
                          .replace(/[^a-zA-Z0-9]/g, '')
                          .toLowerCase()
                      ]
                        ? styles[
                            String(item[header.key])
                              .replace(/[^a-zA-Z0-9]/g, '')
                              .toLowerCase()
                          ]
                        : header.key === 'totalScore'
                        ? `bg-${
                            item[header.key] > 75 && item[header.key] <= 150
                              ? styles.med.bg + 'text-' + styles.med.text
                              : item[header.key] > 150
                              ? styles.high.bg + 'text-' + styles.high.text
                              : styles.low.bg + 'text-' + styles.low.text
                          } font-bold mx-auto px-2 py-1 text-sm shadow-xl rounded-full outline-none focus:outline-none max-w-min`
                        : 'text-sm'
                    }
                  >
                    {moment(item[header.key]).format('DD/M/YYYY')}
                  </p>
                </td>
              )
            } else {
              return (
                <td
                  key={header.key + item._id}
                  className={`relative  py-3 px-5  max-w-sm ${header.style}`}
                >
                  <p
                    className={
                      styles[
                        String(item[header.key])
                          .replace(/[^a-zA-Z0-9]/g, '')
                          .toLowerCase()
                      ]
                        ? styles[
                            String(item[header.key])
                              .replace(/[^a-zA-Z0-9]/g, '')
                              .toLowerCase()
                          ]
                        : header.key === 'totalScore'
                        ? `bg-${
                            item[header.key] > 75 && item[header.key] <= 150
                              ? styles.med.bg + 'text-' + styles.med.text
                              : item[header.key] > 150
                              ? styles.high.bg + 'text-' + styles.high.text
                              : styles.low.bg + 'text-' + styles.low.text
                          } font-bold mx-auto px-2 py-1 text-sm shadow-xl rounded-full outline-none focus:outline-none max-w-min`
                        : 'text-sm'
                    }
                  >
                    {item[header.key]}
                  </p>
                </td>
              )
            }
          } else {
            return (
              <td key={header.key + item._id} className='max-w p-3 text-center'>
                <div className='flex justify-center items-center'>
                  <Link
                    to={
                      link ? link + item._id : `/admin/${view}/id/${item._id}`
                    }
                    className='w-6 flex item-center justify-center transform transition duration-100 hover:text-fblue hover:scale-125'
                  >
                    {view === 'forms' ? (
                      <PencilAltIcon className='h-5 w-5' />
                    ) : (
                      <EyeIcon className='h-5 w-5' />
                    )}
                  </Link>
                </div>
              </td>
            )
          }
        })}
      </tr>
    </>
  )
}

export default RowsWidget
