import { Fragment, useContext, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Banner from '../Widgets/Banner'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import moment from 'moment'
import { DuplicateIcon } from '@heroicons/react/outline'

const APIKey = ({ view, setView }) => {
  const { config, reload, setReload, user } = useContext(AdminContext)
  const [currentKey, setCurrentKey] = useState({
    user: '',
    prefix: '',
    key: '',
    active: true,
    permissions: 'read',
    issuedBy: user.firstName + ' ' + user.lastName,
  })
  const [saving, setSaving] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const [copied, setCopied] = useState(false)

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }
  const handleCopyClick = async (textToCopy) => {
    copyTextToClipboard(textToCopy)
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 1500)
      })
      .catch((err) => {})
  }
  const save = async () => {
    setSaving(true)
    try {
      //Generate new key
      const response = await axios.post('/api/apikeys/new', currentKey, config)
      setCurrentKey((prev) => ({
        ...prev,
        key: response.data.apiKey,
        prefix: response.data.newAPIKey.prefix,
      }))
      handleCopyClick(
        response.data.newAPIKey.prefix + '.' + response.data.apiKey
      )
      setSaving(false)
      setBanner({
        success: 1,
        show: true,
        message: 'Key created and copied to clipboard!',
      })
      setReload(reload + 1)
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    } catch (e) {
      console.log(e)
      setSaving(false)
      setBanner({
        error: 1,
        show: true,
        message: 'Something went wrong while saving',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  return (
    <Transition appear show={view} as={Fragment}>
      <Dialog
        as='div'
        onClose={() => setView(false)}
        className='fixed z-50 inset-1 overflow-auto h-screen'
      >
        <div className='flex justify-center items-center h-screen'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0 scale-80'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-80'
          >
            <div className='bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full lg:w-1/2 shadow-lg z-50'>
              <div className='w-full flex justify-center items-center'>
                <Banner message={banner} />
              </div>
              <div className='md:flex w-full px-3'>
                <div className='flex justify-center items-center w-full px-3'>
                  <div className='w-full mb-2 px-2'>
                    <label className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'>
                      Add new Access Key
                    </label>
                    <div className='w-full flex flex-col gap-4 px-2'>
                      <div className=' w-full flex flex-col items-start justify-between'>
                        <label className=' font-bold mb-2'>
                          Name of User/Organization
                        </label>
                        {currentKey.key ? (
                          <span className='text-mono'>{currentKey.user}</span>
                        ) : (
                          <input
                            type='text'
                            className={`w-full p-2`}
                            value={currentKey.user}
                            onChange={(e) => {
                              setCurrentKey((prev) => ({
                                ...prev,
                                user: e.target.value,
                              }))
                            }}
                          />
                        )}
                      </div>
                      <div className=' w-full flex flex-col items-start justify-between'>
                        <label className='font-bold mb-2'>Permissions</label>
                        {currentKey.key ? (
                          <span className='text-mono'>
                            {currentKey.permissions}
                          </span>
                        ) : (
                          <select
                            className='w-full p-2'
                            value={currentKey.permissions}
                            onChange={(e) => {
                              setCurrentKey((prev) => ({
                                ...prev,
                                permissions: e.target.value,
                              }))
                            }}
                          >
                            <option value='read'>Read</option>
                            <option value='write'>Read/Write</option>
                          </select>
                        )}
                      </div>
                      {currentKey.key && (
                        <div className=' w-full flex flex-col items-start justify-between mb-2'>
                          <label className='font-bold mb-2'>
                            Generated Key
                          </label>
                          <span className='flex gap-2 text-mono'>
                            {currentKey.prefix + '.' + currentKey.key}
                            <button
                              className='flex space-x-1 text-sm justify-center items-center hover:text-fblue'
                              onClick={() => {
                                handleCopyClick(
                                  currentKey.prefix + '.' + currentKey.key
                                )
                              }}
                            >
                              <DuplicateIcon
                                className={`h-5 w-5 ${
                                  copied
                                    ? 'text-green-800 animate-ping'
                                    : 'text-fblue '
                                }`}
                              />
                            </button>
                          </span>

                          <p className='text-xs mt-2'>
                            ⚠️ You can now copy and share this unique key with
                            the user. This key is unique and not retrievable
                            from the database once this popup is closed
                          </p>
                        </div>
                      )}
                      {currentKey.issuedBy && (
                        <div className=' w-full flex flex-col items-start justify-between'>
                          <label className='font-bold mb-2'>Issued by</label>

                          <span className='text-mono'>
                            {currentKey.issuedBy} on{' '}
                            {moment(currentKey.created).format('DD/MM/YYYY')}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
                <button
                  className='px-10 py-2 w-40 shadow-lg bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4'
                  onClick={() => {
                    setView(false)
                  }}
                >
                  {currentKey.key ? 'Close' : 'Cancel'}
                </button>
                {!currentKey.key && (
                  <button
                    className={`px-8 py-2 w-40 shadow-lg transition duration-200 mb-4 ${
                      currentKey.user.length > 0
                        ? 'bg-flime hover:bg-fblue hover:text-white '
                        : 'opacity-50 cursor-not-allowed'
                    }`}
                    onClick={() => save()}
                  >
                    {saving ? (
                      <div className='flex justify-center'>
                        <div
                          style={{ borderTopColor: 'transparent' }}
                          className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                        ></div>
                      </div>
                    ) : (
                      'Save'
                    )}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default APIKey
