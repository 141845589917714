import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/outline"
import { useField, useFormikContext } from "formik"
import { Fragment } from "react"

const ListInput = ({ label, options, name, notes, footNotes, required }) => {
  const [field, meta] = useField({ label, name })
  const {
    setFieldValue,
    touched: { [name]: touched },
    errors: { [name]: error },
  } = useFormikContext()

  const changeValue = (value) => {
    setFieldValue(name, value, true)
  }

  return (
    <div className="w-full mt-4 lg:mt-8">
      <label className="block text-black text-grotesk text-base font-bold lg:text-xl">
        {label} {required ? "*" : "(optional)"}
      </label>
      {notes && (
        <p className="mt-2 text-base text-grotesk lg:text-xl">{notes}</p>
      )}
      <Listbox name={name} onChange={changeValue}>
        <div className={"mt-2 relative w-full"}>
          <Listbox.Button
            className={`${
              !touched
                ? "border-l-5 border-black"
                : error
                ? "border-l-5 border-fred"
                : meta.value !== "" && meta.value
                ? "border-l-5 border-valid"
                : "border-l-5 border-black"
            } relative w-full pl-6 pr-8 py-4 text-base lg:text-xl text-grotesk text-left border-2 outline-none appearance-none bg-white cursor-default h-16 truncate`}
            {...field}
          >
            {meta.value}
            <span className="absolute inset-y-0 right-0 flex items-center justify-end pr-2 pointer-events-none w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3">
              <ChevronDownIcon
                className="w-5 h-5 text-gray-600 "
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full z-40 mt-1 overflow-auto text-base lg:text-xl text-grotesk text-left bg-white shadow-lg max-h-44 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((choice, index) => (
                <Listbox.Option
                  key={index + choice.value}
                  className={({ active }) =>
                    `relative ${
                      active ? "font-bold bg-gray-200" : "font-normal w-full"
                    }
                    pl-12 pr-8 py-4 select-none`
                  }
                  value={choice.value}
                >
                  {({ selected, active }) => (
                    <>
                      <div
                        className={`${
                          selected ? "font-bold" : "font-normal"
                        } flex justify-between truncate`}
                      >
                        {choice.name}
                      </div>
                      {selected ? (
                        <span className="text-black absolute inset-y-0 left-0 flex items-center ml-3">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {touched && error ? (
        <p
          className={`mt-2 text-base text-grotesk lg:text-xl ${
            touched && error ? "text-fred " : "text-fgray"
          }`}
        >
          {meta.error}
        </p>
      ) : null}
    </div>
  )
}

export default ListInput
