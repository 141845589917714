import SelectAnswer from './SelectAnswer'
import AnswersContext from '../../contexts/Answers'

import { useContext, useEffect, useState } from 'react'

const Question = ({
  _id,
  question,
  type,
  category,
  rank,
  answers,
  mandatory,
  selectedAnswer,
  selectValidation,
  questionPreview,
}) => {
  const [answerData, setAnswerData] = useState({
    id: _id,
    question: question,
    rank: rank,
    category: category,
    value: [],
    answer_id: '',
    score: '',
  })
  const [checkBoxValues, setCheckBoxValues] = useState([])

  const { submit, answerHandler } = useContext(AnswersContext)

  useEffect(() => {
    if (submit) {
      answerHandler(answerData)
    }
  }, [submit])

  const selectedDisplay = (answer, id, score) => {
    selectedAnswer(answer)
    setAnswerData({
      id: _id,
      question: question,
      rank: rank,
      category: category,
      value: answer,
      answer_id: id,
      score: score,
    })
  }

  const handleCheck = (item) => {
    let scoreSum = Number(answerData.score)
    //search for the selected checkbox value index
    const selectedIndex = checkBoxValues.findIndex((el) => el === item.answer)
    //selectedIndex > 0 means the option was already there (so it's being deselected)
    if (selectedIndex === -1) {
      //add the answer to the array + sum its points
      checkBoxValues.push(item.answer)
      scoreSum += Number(item.points)
    } else {
      //removes it from the array and subtract  points
      checkBoxValues.splice(selectedIndex, 1)
      scoreSum -= Number(item.points)
    }
    if (scoreSum < 0) scoreSum = 0
    setCheckBoxValues([...checkBoxValues])
    //updates the answer values
    setAnswerData({
      id: _id,
      question: question,
      rank: rank,
      category: category,
      value: [...checkBoxValues],
      answer_id: item[0]?._id,
      score: scoreSum,
    })
  }

  return (
    <div className='w-full mt-4 lg:mt-8'>
      <label className='block text-black text-grotesk text-base font-bold lg:text-xl'>
        {question}
      </label>
      {type === 'open' || question === 'Email' ? (
        <input
          required={questionPreview ? false : mandatory}
          type={question === 'Email' ? 'email' : 'text'}
          className={`w-full mt-2 border-l-5 text-base text-grotesk border-2 px-6 py-4 outline-none appearance-none lg:text-xl placeholder:text-fgray ${
            answerData.value.length && question !== 'Email'
              ? 'border-valid'
              : !answerData.value.length && question !== 'Email'
              ? 'border-black'
              : answerData.value.length &&
                /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
                  answerData.value
                )
              ? 'border-valid'
              : answerData.value.length
              ? 'border-fred'
              : 'border-black'
          }`}
          name={question}
          placeholder=''
          onChange={(e) =>
            setAnswerData({
              id: _id,
              question: question,
              rank: rank,
              category: category,
              value: e.target.value ? e.target.value : '',
              answer_id: answers[0]?._id,
              score: '',
            })
          }
        />
      ) : type === 'multiple' ? (
        <div className=' flex flex-col'>
          {answers.map((item, index) => (
            <div key={item.answer}>
              <input
                type='checkbox'
                id='scales'
                name='scales'
                required={
                  questionPreview
                    ? false
                    : mandatory && !checkBoxValues.length
                    ? true
                    : false
                }
                onChange={() => handleCheck(item)}
              />

              <label className='text-base text-grotesk lg:text-xl ml-4'>
                {item.answer}
              </label>
            </div>
          ))}
        </div>
      ) : (
        <SelectAnswer
          answers={answers}
          selectedAnswer={selectedDisplay}
          selectValidation={selectValidation}
        />
      )}
    </div>
  )
}
export default Question
