import { useEffect, useState } from "react"
import { ChevronDownIcon, ColorSwatchIcon } from "@heroicons/react/outline"
import { Popover } from "@headlessui/react"
import icons from "../../../assets/icons/Icons"

const symbolColor = ["fblue", "fred", "fpink", "flime"]

const SymbolPicker = ({
  data,
  position,
  setSymbols,
  size,
  touched,
  setTouched,
}) => {
  const [category, setCategory] = useState({
    icon: "affirmative",
    color: "black",
  })

  useEffect(() => {
    setCategory(data)
  }, [data])

  return (
    <div className="w-full flex justify-center">
      <div className="px-3 mb-2 md:mb-0">
        <label
          className="block uppercase text-hansen text-black text-xs font-extrabold mb-2"
          htmlFor="categoryIcon"
        >
          {category.icon.replace("_", " ")}
        </label>
        <div
          className={`relative w-${size} h-${size} md:w-${size + 12} md:h-${
            size + 12
          } rounded-2xl p-2 mb-3 border-2 border-gray-100 transition duration-200 group-hover:border-fblue`}
        >
          <div
            className={`rounded-full w-36 h-36 md:w-48 md:h-48 flex justify-center items-center`}
          >
            <p className="text-6xl md:text-8xl text-mono">
              {icons[category.icon](
                `w-36 h-36 md:w-48 md:h-48 text-${
                  touched.symbols[position] ? category.color : "black"
                } fill-current`,
                `${
                  category.color === "flime" ? "text-black" : "text-white"
                } fill-current`,
                0
              )}
            </p>
          </div>
          <Popover className=" group">
            <Popover.Button className="absolute -bottom-2 outline-none right-0 bg-flime p-1 h-8 w-8 text-center rounded-full cursor-pointer transition duration-200 group-hover:bg-fblue group-hover:text-white">
              <ColorSwatchIcon className="h-6 w-6" />
            </Popover.Button>
            <Popover.Panel className="absolute z-20 -bottom-30 right-3 md:right-2 rounded shadow-lg bg-white w-24 h-24 p-2 flex flex-wrap justify-between ">
              {({ close }) =>
                symbolColor.map((color) => (
                  <span
                    key={color}
                    className={`w-8 h-8 cursor-pointer rounded-full border-2 hover:border-blue-600 bg-${color} ${
                      color === category.color
                        ? "border-green-600"
                        : "border-white"
                    }`}
                    onClick={(e) => {
                      setSymbols(category, position)
                      close()
                    }}
                    onMouseOver={(e) => {
                      setCategory((prev) => ({
                        ...prev,
                        color: color,
                      }))
                      let symbolsTouched = [...touched.symbols]
                      symbolsTouched[position] = true
                      setTouched({ ...touched, symbols: symbolsTouched })
                    }}
                    onMouseOut={(e) =>
                      setCategory((prev) => ({
                        ...prev,
                        color: data.color,
                      }))
                    }
                  ></span>
                ))
              }
            </Popover.Panel>
          </Popover>
          <Popover className=" group">
            <Popover.Button className="absolute -bottom-2 outline-none right-10 bg-flime p-1 h-8 w-8 text-center rounded-full cursor-pointer transition duration-200 group-hover:bg-fblue group-hover:text-white">
              <ChevronDownIcon className="h-6 w-6" />
            </Popover.Button>
            <Popover.Panel className="absolute z-20 -bottom-30 right-8 md:right-10 rounded shadow-xl bg-white w-40 min-h-min p-2 flex flex-wrap justify-center ">
              {({ close }) =>
                Object.keys(icons).map((icon) => (
                  <span
                    key={icon}
                    className={`w-7 h-7 border-2 hover:border-blue-600 ${
                      icon === category.icon
                        ? "border-green-600"
                        : "border-white"
                    }`}
                    onClick={(e) => {
                      setSymbols(category, position)
                      close()
                    }}
                    onMouseOver={(e) => {
                      setCategory((prev) => ({
                        ...prev,
                        icon: icon,
                      }))
                      let symbolsTouched = [...touched.symbols]
                      symbolsTouched[position] = true
                      setTouched({ ...touched, symbols: symbolsTouched })
                    }}
                    onMouseOut={(e) =>
                      setCategory((prev) => ({
                        ...prev,
                        icon: data.icon,
                      }))
                    }
                  >
                    {icons[icon](
                      `cursor-pointer w-6 h-6 text-black fill-current`,
                      "text-white fill-current",
                      0
                    )}
                  </span>
                ))
              }
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    </div>
  )
}

export default SymbolPicker

SymbolPicker.defaultProps = {
  size: 40,
}
