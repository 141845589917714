import moment from 'moment'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'

const Notifications = ({ notifications }) => {
  return (
    <div className='w-full overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue'>
      {notifications.length ? (
        <h1 className='flex w-full justify-between bg-blue-100 px-4 py-2 text-center text-lg font-bold text-mono'>
          Sent to
        </h1>
      ) : (
        'Not sent to any group yet'
      )}
      <div className='mx-auto w-full'>
        {
          //Sort by date and map through notifications
          notifications
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((notification) => (
              <Disclosure as='div' key={notification._id}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className='flex w-full justify-between items-center gap-2 bg-blue-100 px-4 py-2 text-left text-sm font-medium text-fblue-900 hover:bg-fblue-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                      <span className='text-sm flex-grow'>
                        {notification.list}
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-fblue-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className='px-4 py-2 text-sm text-gray-500'>
                      <h1 className='font-bold text-mono mt-2'>
                        Sent on {moment(notification.date).format('DD-MM-YYYY')}
                      </h1>
                      {notification.recipients.length ? (
                        <p className='mb-2 text-mono'>
                          To {notification.recipients.length} profiles
                        </p>
                      ) : (
                        ''
                      )}
                      {notification.recipients.length ? (
                        <div className='flex flex-col gap-1 overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue'>
                          {notification.recipients.map((recipient) => (
                            <div
                              key={recipient._id}
                              className='flex justify-between items-center text-xs'
                            >
                              <span className='truncate'>{recipient.name}</span>
                              <span className='px-1'>{recipient.email}</span>
                              <span>{recipient.sent ? '✅' : '❌'}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className='italic my-2'>
                          List of recipients not saved
                        </p>
                      )}
                      {notification.recipients.length ? (
                        <p className='my-2 text-mono'>
                          {
                            notification.recipients.filter(
                              (recipient) => recipient.sent
                            ).length
                          }{' '}
                          of {notification.recipients.length} emails sent
                        </p>
                      ) : (
                        ''
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))
        }
      </div>
    </div>
  )
}

export default Notifications
