import instructions1 from "../../../assets/images/photo_instructions1.png"
import instructions2 from "../../../assets/images/photo_instructions2.png"
import instructions3 from "../../../assets/images/photo_instructions3.png"

const Headshot = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center my-2">
      <div className="w-full md:w-1/3 flex flex-col justify-center items-center">
        <p className="text-mono text-justify text-sm text-white p-4">
          Use a full color image:
        </p>
        <img
          src={instructions1}
          className="flex-none w-40 object-cover"
          alt="Portrait Example"
        />
        <p className="text-xs p-2 text-white">
          *Simple Solid background preferred*
        </p>
      </div>
      <div className="w-full md:w-1/3 flex flex-col justify-center items-center">
        <p className="text-mono text-justify text-sm text-white p-4">
          3/4 4:5 ratio, vertical:
        </p>
        <img
          src={instructions2}
          className="flex-none w-40 object-cover"
          alt="Frame Example"
        />
        <p className="text-xs p-2 text-white">
          *Center yourself within the frame*
        </p>
      </div>
      <div className="w-full md:w-1/3 flex flex-col justify-center items-center">
        <p className="text-mono text-justify text-sm text-white p-4">
          Frontal or 3/4 view:
        </p>
        <img
          src={instructions3}
          className="flex-none w-40 object-cover"
          alt="Portraits Examples"
        />
        <p className="text-xs p-2 text-white">*Don't crop your face*</p>
      </div>
    </div>
  )
}

export default Headshot
