import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import ApplicantsDispatcher from './components/ApplicantsDispatcher'
import Form from './components/Form'
import Thankyou from './components/Forms/Thankyou'
import Admin from './components/Admin/Admin'
import Community from './components/Community/Community'
import Verify from './components/Verify'
import NewsLetter from './components/Forms/NewsLetter'
import FastTrack from './components/Community/FastTrack/FastTrack'
import NSignUp from './components/Community/NSignUp/NSignUp'
import PageNotFound from './components/PageNotFound'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/community' />
        </Route>
        <Route exact path='/verify/:token' component={Verify} />
        <Route path='/admin' component={Admin} />
        <Route path='/community' component={Community} />
        <Redirect from='/foundersignup/:token' to='/signup/:token' />
        <Route exact path='/signup/:token' component={NSignUp} />
        <Route exact path='/fasttrack/:token' component={FastTrack} />
        <Route
          exact
          path='/join-our-community'
          component={ApplicantsDispatcher}
        />
        <Route exact path='/newsletter' component={NewsLetter} />
        <Route exact path='/thankyou' component={Thankyou} />
        <Route exact path='/form/:memberType' component={Form} />
        <Route path='*'>
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
