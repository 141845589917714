import Menu from "./Menu"
import Content from "./Content"
import Header from "./Header"
import Popup from "./Widgets/Popup"
import { Route, useRouteMatch } from "react-router"
import { useState, useEffect, useContext } from "react"
import AdminContext from "../../contexts/Admin"
import axios from "axios"

const Main = () => {
  const [popUp, setPopUp] = useState(false)
  const [versionUpdate, setVersionUpdate] = useState({
    title: "",
    content: "",
    message: "Update",
  })

  const closePopUp = () => {
    setPopUp(false)
    window.location.reload(true)
  }
  const { config, user } = useContext(AdminContext)
  useEffect(() => {
    //Check version and reload on new
    const checkVersion = async () => {
      const { data } = await axios.get("/api/auth/version/admin", config)
      if (data.version !== user.version && data.update) {
        setVersionUpdate({
          title: "⚙️ Update available - version " + data.update[0].version,
          content: data.update[0].details,
          message: "Update",
        })
        setPopUp(true)
      }
    }
    checkVersion()
  }, [])
  const { path } = useRouteMatch()
  return (
    <Route exact path={`${path}/:view/:category?/:id?/`}>
      <div className="flex h-screen">
        <Menu />
        <div className="w-full flex flex-col overflow-hidden">
          <Header />
          <Content />
        </div>
      </div>
      <Popup
        popUp={popUp}
        closePopUp={closePopUp}
        title={versionUpdate.title}
        content={versionUpdate.content}
        message={versionUpdate.message}
      />
    </Route>
  )
}

export default Main
