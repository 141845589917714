import { useContext } from 'react'
import { useParams } from 'react-router'
import AdminContext from '../../../contexts/Admin'
import Tabs from '../Widgets/Tabs'
import FlagCreation from './FlagCreation'
import DataDownload from './DataDownload'
import NewFounders from './NewFounders'

const Tools = () => {
  const { id } = useParams()
  const { selectedTab } = useContext(AdminContext)
  const tabs = [
    {
      index: 0,
      name: 'Data Download',
      restricted: '',
    },
    {
      index: 1,
      name: 'Flag Creation',
      restricted: '',
    },
    {
      index: 2,
      name: 'New Members',
      restricted: '',
    },
  ]

  return (
    <>
      <Tabs tabs={tabs} id={id} />
      <section className='relative h-full w-full flex md:px-4  items-center flex-col bg-white outline-none overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue'>
        {selectedTab === 0 ? (
          <DataDownload />
        ) : selectedTab === 1 ? (
          <FlagCreation />
        ) : selectedTab === 2 ? (
          <NewFounders />
        ) : (
          'Nothing to show'
        )}
      </section>
    </>
  )
}

export default Tools
