import { useContext, useEffect, useState } from 'react'
import Banner from '../Widgets/Banner'
import Loading from '../Widgets/Loading'
import { CloudDownloadIcon } from '@heroicons/react/outline'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import Filter from './components/Filter'
import moment from 'moment'
import { saveAs } from 'file-saver'

const tables = [
  {
    name: 'Members',
    url: '/api/users/community/members/download/',
    fields: [
      { name: 'Profile Data', key: 'profile' },
      { name: 'Names & Emails', key: 'basic' },
      { name: 'Profile Data - inc. Application Answers', key: 'all' },
    ],
    filters: [
      {
        name: 'Role',
        key: 'role',
        values: ['all', 'founder', 'investor', 'ally'],
        type: 'list',
      },
      { name: 'City', key: 'city', values: '', type: 'text' },
      { name: 'Country', key: 'country', values: '', type: 'text' },
      {
        name: 'Unreachable',
        key: 'flagged',
        values: ['include', 'exclude', 'only'],
        type: 'boolean',
      },
    ],
    headers: [
      [
        'firstName',
        'lastName',
        'pronouns',
        'email',
        'title',
        'companyName',
        'companyBio',
        'companyLink',
        'city',
        'country',
        'businessArea',
        'industry',
        'role',
        'bio',
        'origins',
        'asks',
        'socialmedia.linkedin',
        'socialmedia.instagram',
        'socialmedia.twitter',
        'photo.url',
        'flag.url',
        'created',
        'confirmed',
        'notified',
        'lastUpdate',
        'locked',
        'flagged',
      ],
      [
        'firstName',
        'lastName',
        'pronouns',
        'email',
        'role',
        'created',
        'confirmed',
        'notified',
        'lastUpdate',
        'locked',
        'flagged',
      ],
      [
        'firstName',
        'lastName',
        'pronouns',
        'email',
        'title',
        'companyName',
        'companyBio',
        'companyLink',
        'city',
        'country',
        'businessArea',
        'industry',
        'role',
        'bio',
        'origins',
        'asks',
        'socialmedia.linkedin',
        'socialmedia.instagram',
        'socialmedia.twitter',
        'photo.url',
        'flag.url',
        'created',
        'confirmed',
        'notified',
        'lastUpdate',
        'locked',
        'flagged',
        'answers',
      ],
    ],
  },
  {
    name: 'Applicants',
    url: '/api/applicants/download/',
    fields: [
      { name: 'Inc. Answers', key: 'all' },
      { name: 'Basic', key: 'basic' },
    ],
    filters: [
      {
        name: 'Role',
        key: 'role',
        values: ['all', 'founder', 'investor', 'ally'],
        type: 'list',
      },
      {
        name: 'Status',
        key: 'status',
        values: ['all', 'new', 'pending', 'approved', 'rejected', 'fasttrack'],
        type: 'list',
      },
    ],
    headers: [
      [
        'firstName',
        'lastName',
        'email',
        'role',
        'submissionDate',
        'totalScore',
        'status',
        'evaluatedBy',
        'evaluatedOn',
        'comments',
        'memberId.notified',
        'memberId.confirmed',
        'memberId.locked',
        'memberId.flagged',
        'answers',
      ],
      [
        'firstName',
        'lastName',
        'email',
        'role',
        'submissionDate',
        'totalScore',
        'status',
        'evaluatedBy',
        'evaluatedOn',
        'comments',
        'memberId.notified',
        'memberId.confirmed',
        'memberId.locked',
        'memberId.flagged',
      ],
    ],
  },
  {
    name: 'Newsletter',
    url: '/api/applicants/responses/newsletter/',
    fields: [
      { name: 'All', key: 'all' },
      { name: 'Name & email', key: 'basic' },
    ],
    filters: [],
    headers: [
      ['firstName', 'lastName', 'email', 'interests', 'subscriptionDate'],
      ['firstName', 'lastName', 'email'],
    ],
  },
]

const DataDownload = () => {
  const { config } = useContext(AdminContext)
  const [banner, setBanner] = useState({ show: false })
  const [selectedTable, setSelectedTable] = useState(0)
  const [selectedFields, setSelectedFields] = useState(0)
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [downloadData, setDownloadData] = useState([])
  const [loadingDownload, setLoadingDownload] = useState(false)

  //GET THE FULL SET OF DATA
  const fetchData = async () => {
    try {
      setLoading(true)
      const result = await axios.get(
        tables[selectedTable].url +
          tables[selectedTable].fields[selectedFields].key,
        config
      )

      if (result.data?.data?.length) {
        setData(result.data.data)
        setDownloadData(result.data.data)
      } else if (result.data.length) {
        setData(result.data)
        setDownloadData(result.data)
      } else {
        setBanner({
          show: true,
          type: 'error',
          message: 'No data available to download',
        })
        setTimeout(() => {
          setBanner({ show: false })
        }, 3000)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setBanner({
        show: true,
        type: 'error',
        message: 'Error fetching data',
      })
      setTimeout(() => {
        setBanner({ show: false })
      }, 3000)
    }
  }

  //APPLY FILTERS
  useEffect(() => {
    if (data.length) {
      let newData = [...data]
      if (Object.keys(filters).length) {
        Object.keys(filters).forEach((key) => {
          if (filters[key].length) {
            newData = newData.filter((el) => {
              if (key === 'role' && filters[key] !== 'all') {
                return el[key] === filters[key]
              } else if (key === 'location') {
                return el
                  .includes('(City, Country)')
                  .toLowerCase()
                  .includes(filters[key].toLowerCase())
              } else if (key === 'city') {
                return el.city
                  .toLowerCase()
                  .includes(filters[key].toLowerCase())
              } else if (key === 'country') {
                return el.country
                  .toLowerCase()
                  .includes(filters[key].toLowerCase())
              } else if (key === 'flagged') {
                if (filters[key] === 'exclude') {
                  return !el.flagged
                } else if (filters[key] === 'only') {
                  return el.flagged
                }
                return el
              } else if (key === 'status') {
                if (filters[key] === 'all') {
                  return el
                } else {
                  return el.status === filters[key]
                }
              } else {
                return el
              }
            })
          }
        })
      }
      setDownloadData(newData)
    }
  }, [filters])

  //RESET DATA WHEN CHANGING TABLE OR FIELDS
  useEffect(() => {
    setSelectedFields(0)
    setData([])
    setDownloadData([])
    setFilters({})
  }, [selectedTable])
  useEffect(() => {
    setData([])
    setDownloadData([])
    setFilters({})
  }, [selectedFields])

  //FORCE FILTER UPDATE WHEN DATA IS FETCHED AND TABLE IS MEMBERS OR APPLICANTS AND ANSWERS ARE REQUESTED
  useEffect(() => {
    //If the table is Members, force to apply the filters for role
    if (
      tables[selectedTable].name !== 'Newsletter' &&
      tables[selectedTable].fields[selectedFields].key === 'all'
    ) {
      setFilters({ ...filters, role: 'founder' })
    } else {
      setFilters({})
    }
  }, [data])

  //DOWNLOAD Members CSV
  const downloadMemberCSV = async (apiResponse, filename) => {
    try {
      if (apiResponse.length === 0) {
        setBanner({
          show: true,
          type: 'error',
          message: 'No data to download',
        })
        return
      }

      // Use Set to ensure unique headers
      const answersHeadersSet = new Set()
      apiResponse.forEach((el) => {
        if (el.applicationId) {
          el['answers'] = {}
          el.applicationId.answerData.forEach((answer) => {
            el.answers[answer.question] = answer.answer_value
            answersHeadersSet.add(answer.question)
          })
        }
      })

      // Convert Set to array
      const answersHeaders = Array.from(answersHeadersSet)
      const headers = [...tables[selectedTable].headers[selectedFields]]
      const headersCombined = [
        ...headers.filter((header) => header !== 'answers'),
        ...answersHeaders.map((el) => {
          const safeHeader = el.replace(/"/g, '""')
          return safeHeader.includes(',') ? `"${safeHeader}"` : safeHeader
        }),
      ]

      const csvData =
        headersCombined.join(',') +
        '\n' +
        apiResponse
          .map((row) => {
            return headersCombined
              .map((header) => {
                if (answersHeaders.includes(header)) {
                  return row.answers && row.answers[header]
                    ? `"${row.answers[header]
                        .replace(/"/g, '""')
                        .replace(/(\r\n|\n|\r)/gm, ' ')}"`
                    : ' '
                }
                const value = deepValue(row, header)
                return typeof value === 'string' &&
                  isNaN(new Date(value).getTime())
                  ? `"${value
                      .replace(/"/g, '""')
                      .replace(/(\r\n|\n|\r)/gm, ' ')}"`
                  : typeof value === 'string' &&
                    !isNaN(new Date(value).getTime())
                  ? // convert to ISO 8601 date format
                    moment(value).format('DD/MM/YYYY')
                  : value
                  ? value
                  : ' '
              })
              .join(',')
          })
          .join('\n')

      // Add current date and time to filename
      const currentDate = new Date()
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '')
        .slice(0, 10)
      if (filters.role) filename += `_${filters.role}`
      if (filters.location) filename += `_${filters.location}`
      if (filters.city) filename += `_${filters.city}`
      if (filters.country) filename += `_${filters.country}`
      if (filters.flagged) filename += `_flagged-${filters.flagged}`

      filename += `_${currentDate}`

      // Create a blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      // Use FileSaver.js to save the file
      saveAs(blob, filename + '.csv')

      setLoadingDownload(false)
      return true
    } catch (e) {
      console.error(e) // Debugging
      setBanner({
        show: true,
        type: 'error',
        message: 'Error downloading data',
      })
    }
  }

  //DOWNLOAD Newsletter CSV
  const downloadNewsletterCSV = async (apiResponse) => {
    try {
      if (apiResponse.length === 0) {
        setBanner({
          show: true,
          type: 'error',
          message: 'No data to download',
        })
        return
      }
      const headers = [...tables[selectedTable].headers[selectedFields]]
      const csvData =
        'data:text/csv;charset=utf-8,' +
        headers.join(',') +
        '\n' +
        apiResponse
          .map((row) => {
            return headers
              .map((header) => {
                if (header === 'subscriptionDate') {
                  return moment(row.subscriptionDate).format('DD/MM/YYYY')
                }
                const value = deepValue(row, header)
                return typeof value === 'string' && value.includes(',')
                  ? `"${value
                      .replace(/"/g, '""')
                      .replace(/(\r\n|\n|\r)/gm, ' ')}"`
                  : value
                  ? value
                  : ' '
              })
              .join(',')
          })
          .join('\n')

      // Add current date and time to filename
      const currentDate = new Date()
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '')
        .slice(0, 10)
      let filename = tables[selectedTable].name
      filename += `_${currentDate}`
      // Create a blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      // Use FileSaver.js to save the file
      saveAs(blob, filename + '.csv')

      setLoadingDownload(false)
      return true
    } catch (e) {
      setBanner({
        show: true,
        type: 'error',
        message: 'Error downloading data',
      })
    }
  }

  //DOWNLOAD Applicants CSV
  const downloadApplicantsCSV = async (apiResponse) => {
    try {
      if (apiResponse.length === 0) {
        setBanner({
          show: true,
          type: 'error',
          message: 'No data to download',
        })
        return
      }

      const answersHeadersSet = new Set()
      apiResponse.forEach((el) => {
        if (el.answerData) {
          el['answers'] = {}
          el.answerData.forEach((answer) => {
            el.answers = {
              ...el.answers,
              [answer.question]: answer.answer_value,
            }
            answersHeadersSet.add(answer.question)
          })
        }
      })

      const answersHeaders = Array.from(answersHeadersSet)
      const headers = [...tables[selectedTable].headers[selectedFields]]
      const headersCombined = [
        ...headers,
        ...answersHeaders.map((el) => {
          const safeHeader = el.replace(/"/g, '""')
          return safeHeader.includes(',') ? `"${safeHeader}"` : safeHeader
        }),
      ]

      const csvData =
        headersCombined.join(',') +
        '\n' +
        apiResponse
          .map((row) => {
            return headersCombined
              .map((header) => {
                if (header === 'answers') {
                  return answersHeaders
                    .map((answer) => {
                      return row.answers && row.answers[answer]
                        ? `"${row.answers[answer]
                            .replace(/"/g, '""')
                            .replace(/(\r\n|\n|\r)/gm, ' ')}"`
                        : ' '
                    })
                    .join(',')
                }
                if (header === 'comments') {
                  return row.comments
                    .map((comment) => {
                      return comment &&
                        comment.user &&
                        comment.user.firstName &&
                        comment.text
                        ? `"${comment.user.firstName}: ${comment.text
                            .replace(/"/g, '""')
                            .replace(/(\r\n|\n|\r)/gm, ' ')}"`
                        : ' '
                    })
                    .join(' _ ')
                }
                const value = deepValue(row, header)
                return typeof value === 'string' &&
                  isNaN(new Date(value).getTime())
                  ? `"${value
                      .replace(/"/g, '""')
                      .replace(/(\r\n|\n|\r)/gm, ' ')}"`
                  : typeof value === 'string' &&
                    !isNaN(new Date(value).getTime())
                  ? //convert to ISO 8601 date format
                    moment(value).format('DD/MM/YYYY')
                  : value
                  ? value
                  : ' '
              })
              .join(',')
          })
          .join('\n')

      // Add current date and time to filename
      const currentDate = new Date()
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '')
        .slice(0, 10)
      let filename = tables[selectedTable].name
      filename += `_${currentDate}`

      // Create a blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      // Use FileSaver.js to save the file
      saveAs(blob, filename + '.csv')

      setLoadingDownload(false)
      return true
    } catch (e) {
      console.log(e)
      setBanner({
        show: true,
        type: 'error',
        message: 'Error downloading data',
      })
      setTimeout(() => {
        setBanner({ show: false })
      }, 3000)
    }
  }

  const downloadDataHandler = async () => {
    setLoadingDownload(true)
    setTimeout(async () => {
      switch (tables[selectedTable].name) {
        case 'Members':
          await downloadMemberCSV(downloadData, tables[selectedTable].name)
          break
        case 'Applicants':
          await downloadApplicantsCSV(downloadData, tables[selectedTable].name)
          break
        case 'Newsletter':
          await downloadNewsletterCSV(downloadData, tables[selectedTable].name)
          break
        default:
          setBanner({
            show: true,
            type: 'error',
            message: 'Error downloading data',
          })
      }
    }, 2000)
  }
  // Function to access nested values
  function deepValue(obj, path) {
    const keys = path.split('.')
    let value = obj
    for (const key of keys) {
      if (value && value.hasOwnProperty(key)) {
        value = value[key]
      } else {
        return ' '
      }
    }
    return value
  }

  return (
    <div className='w-full'>
      <div className='w-full px-3 mt-8'>
        <div className='w-full flex justify-center items-center'>
          <Banner message={banner} />
        </div>
      </div>
      <div className=' w-full px-3 mt-8'>
        <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
          <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
            Select the data you want to download
          </label>
          <div className='w-full flex justify-center items-center mb-4'>
            <select
              className='w-full border border-gray-400 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              value={selectedTable}
              onChange={(e) => {
                setSelectedTable(e.target.value)
                setSelectedFields(0)
              }}
            >
              {tables.map((table, index) => (
                <option key={index} value={index}>
                  {table.name}
                </option>
              ))}
            </select>
          </div>
          <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
            Select the type of data you want to download
          </label>
          <div className='w-full flex justify-center items-center'>
            <select
              className='w-full border border-gray-400 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              value={selectedFields}
              onChange={(e) => setSelectedFields(e.target.value)}
            >
              {tables[selectedTable].fields.map((field, index) => (
                <option key={index} value={index}>
                  {field.name}
                </option>
              ))}
            </select>
          </div>

          <div className='w-full flex justify-center items-center mt-4'>
            <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
              <div className='w-full flex justify-center items-center mt-8'>
                <div
                  className='cursor-pointer flex items-center justify-center gap-4 w-full md:w-1/2 bg-fblue hover:bg-fblue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                  onClick={() => fetchData()}
                >
                  {loading ? 'loading' : 'Fetch data set'}
                </div>
              </div>

              {loading ? (
                <Loading />
              ) : (
                <div className='w-full flex flex-col justify-center items-center mt-8'>
                  {data.length ? (
                    <>
                      {tables[selectedTable].filters?.length > 0 && (
                        <div className='w-full flex flex-col justify-center'>
                          <p className='text-center text-gray-500 text-sm mb-2'>
                            {data.length} entries found for{' '}
                            {tables[selectedTable].name}
                          </p>
                          <label className='block text-center uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
                            Apply the filters you want or download the full
                            dataset below
                          </label>
                          <div className='w-full grid sm:grid-cols-2 sm:grid-row-2 xl:grid-rows-1 xl:grid-cols-4 gap-2 my-4'>
                            {tables[selectedTable].filters.map(
                              (filter, index) => (
                                <Filter
                                  key={index}
                                  filter={filter}
                                  filters={filters}
                                  setFilter={setFilters}
                                  data={data}
                                />
                              )
                            )}
                          </div>
                        </div>
                      )}
                      <div className='w-full flex justify-center items-center my-4'>
                        <p className='text-center text-gray-500 text-sm mb-2'>
                          {downloadData.length} entries ready to download
                        </p>
                      </div>
                      <div
                        className='cursor-pointer flex items-center justify-center gap-4 w-full md:w-1/2 bg-fblue hover:bg-fblue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                        type='button'
                        onClick={() => downloadDataHandler()}
                      >
                        <CloudDownloadIcon
                          className={`h-5 w-5 ${
                            loadingDownload ? 'animate-pulse text-flime' : ''
                          }`}
                        />
                        Download Data
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataDownload
