import { Popover, Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import SymbolPicker from "../Widgets/SymbolPicker"
import Button from "./Components/Button"
import { ColorSwatchIcon } from "@heroicons/react/outline"
import { useContext } from "react"
import SignUpContext from "../../../contexts/SignUp"
import { useFormikContext } from "formik"
import Banner from "./Components/Banner"
import { at, flatten } from "lodash"

const symbolColors = ["fblue", "fred", "fpink", "flime"]
const currentStep = 2

const Symbols = () => {
  const { handleBack, handleNext, activeStep, previousStep } =
    useContext(SignUpContext)
  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched: symbolsTouched,
  } = useFormikContext()
  const [isActive, setIsActive] = useState(activeStep === currentStep)
  const [bannerColor, setBannerColor] = useState(values.bannerColor)
  const [availableColors, setAvailableColors] = useState(["fblue"])
  const [proceed, setProceed] = useState(false)
  const [touched, setTouched] = useState({
    symbols:
      previousStep > currentStep ||
      !values.symbols.some((item) => item.color === "black")
        ? [true, true, true]
        : [false, false, false],
    bannerColor:
      previousStep > currentStep || bannerColor !== "black" ? true : false,
  })
  const [error, setError] = useState("")
  const allTouched =
    flatten(at(touched, "symbols", "bannerColor")).every((el) => el === true) &&
    !values.symbols.some((item) => item.color === "black")
  const setSymbols = (symbol, i) => {
    let current = [...values.symbols]
    current[i] = symbol
    setFieldValue("symbols", current)
    setFieldTouched("symbols")
  }

  useEffect(() => {
    const symbolsArray = values.symbols.map((item) => item.icon)
    let isDuplicateS = symbolsArray.some(
      (item, i) => symbolsArray.indexOf(item) !== i
    )
    const colorArray = values.symbols.map((item) => item.color)
    let isDuplicateC = colorArray.some(
      (item, i) => colorArray.indexOf(item) !== i
    )
    if (isDuplicateS || isDuplicateC || !allTouched) {
      setProceed(false)
    } else {
      setProceed(true)
    }
    const available = symbolColors.filter((color) =>
      values.symbols.some((item) => item.color === color)
    )
    setAvailableColors(available.length > 0 ? available : ["fblue"])
    if (values.bannerColor !== "black") {
      if (!available.some((item) => item === bannerColor)) {
        setBannerColor(available[0] ? available[0] : "fblue")
        setFieldValue("bannerColor", available[0] ? available[0] : "fblue")
      }
    }
  }, [values])

  useEffect(() => {}, [symbolsTouched])

  const next = () => {
    if (proceed) {
      setIsActive(false)
      setTimeout(() => {
        handleNext()
      }, 200)
      return
    }
    setError(
      "Please select 3 different symbols and colors and a color for your banner."
    )
  }
  const back = () => {
    setIsActive(false)
    setTimeout(() => {
      handleBack()
    }, 200)
  }

  return (
    <div className="max-h-screen w-full flex flex-col items-center overflow-y-scroll">
      <Banner />
      <Transition
        appear={true}
        show={isActive}
        enter="transform transition-all ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transform transition-all ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="h-full form flex flex-col justify-start items-center  px-6 lg:px-12">
          <h2 className="w-full text-center lg:text-left">
            Building Your Flag
          </h2>
          <section>
            <p className="lead text-center">
              We have a collection of symbols to help you create your own flag
              that is true to you and your land.
            </p>
            <div className="w-full flex flex-col md:flex-row justify-center mt-12 lg:mt-16">
              {values.symbols.map((symbol, i) => (
                <div className="w-full md:w-1/3" key={"symbol" + i}>
                  <SymbolPicker
                    data={symbol}
                    setSymbols={setSymbols}
                    position={i}
                    touched={touched}
                    setTouched={setTouched}
                  />
                </div>
              ))}
            </div>
            <p className="lead text-center">
              Select 3 icons that speak to you and a color for each.
            </p>
            {error && !proceed && (
              <p className="paragraph text-center text-fred">{error}</p>
            )}
            <div className="relative w-full h-20 flex mt-4 lg:mt-8 justify-center bg-gr">
              <div
                className={`bg-${bannerColor} absolute bottom-0 h-20 flex items-center justify-start w-full`}
              >
                <p
                  className={`w-full pt-1 text-center text-${
                    bannerColor === "flime" ? "black" : "white"
                  } uppercase text-hanson font-bold text-xl tracking-widest`}
                >
                  {values.firstName}'s Land
                </p>
                <Popover className=" group">
                  <Popover.Button className="absolute -bottom-2 outline-none right-0 bg-flime p-1 h-8 w-8 text-center rounded-full cursor-pointer transition duration-200 group-hover:bg-fblue group-hover:text-white">
                    <ColorSwatchIcon className="h-6 w-6" />
                  </Popover.Button>
                  <Popover.Panel className="absolute z-20 -bottom-30 right-3 md:right-2 rounded shadow-lg bg-white w-24 h-24 p-2 flex flex-wrap justify-between ">
                    {({ close }) =>
                      availableColors.map((color) => (
                        <span
                          key={color}
                          className={`w-8 h-8 cursor-pointer rounded-full border-2 hover:border-blue-600 bg-${color} ${
                            color === bannerColor
                              ? "border-green-600"
                              : "border-white"
                          }`}
                          onClick={(e) => {
                            setFieldValue("bannerColor", color)
                            setFieldTouched("bannerColor")
                            close()
                          }}
                          onMouseOver={(e) => {
                            setBannerColor((prev) => color)
                            setTouched({ ...touched, bannerColor: true })
                          }}
                          onMouseOut={(e) =>
                            setBannerColor((prev) => values.bannerColor)
                          }
                        ></span>
                      ))
                    }
                  </Popover.Panel>
                </Popover>
              </div>
            </div>
            <p className="paragraph text-center">
              Flags will be used to help promote you across social channels and
              beyond.
            </p>
          </section>
          <div className="w-full flex justify-between mt-12 lg:mt-16">
            <Button
              label={"< Back"}
              styled={"back"}
              type={"button"}
              action={back}
            />
            <Button
              label={"Next"}
              styled={"next"}
              type={"button"}
              action={next}
            />
          </div>
          <div className="h-24 w-full"></div>
        </div>
      </Transition>
    </div>
  )
}

export default Symbols
