import { useField, useFormikContext } from "formik"
import { at } from "lodash"

const TextInput = ({
  label,
  placeholder,
  name,
  notes,
  footNotes,
  type,
  required,
  dataList,
}) => {
  const [field, meta] = useField({ name, label })
  const [touched, error] = at(meta, "touched", "error")
  const { validateField, handleChange } = useFormikContext()

  const update = (e) => {
    handleChange(e)
    if (touched) {
      validateField(name)
    }
  }

  return (
    <div className="w-full mt-4 lg:mt-8">
      <label className="block text-black text-grotesk text-base font-bold lg:text-xl">
        {label} {required ? "*" : "(optional)"}
      </label>
      {notes && (
        <p className="mt-2 text-base text-grotesk lg:text-xl">{notes}</p>
      )}
      <input
        {...field}
        type={type}
        list={name}
        placeholder={placeholder}
        className={`w-full mt-2 ${
          !touched
            ? "border-l-5 border-black"
            : error
            ? "border-l-5 border-fred"
            : meta.value !== "" && meta.value !== null
            ? "border-l-5 border-valid"
            : "border-l-5 border-black"
        } text-base text-grotesk border-2 px-6 py-4 outline-none appearance-none lg:text-xl placeholder:text-fgray`}
        autoComplete={type === "password" ? "new-password" : "none"}
        value={meta.value}
        onChange={(e) => {
          update(e)
        }}
      />
      {dataList && (
        <datalist id={name} className="relative">
          {dataList.length > 0 &&
            dataList
              .filter((item) => item.name.startsWith(meta.value))
              .splice(0, 10)
              .map((item, i) => (
                <option
                  className="absolute py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-40 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  key={i}
                >
                  {item.name}
                </option>
              ))}
        </datalist>
      )}
      {footNotes && (
        <p className={`mt-2 text-base text-grotesk lg:text-xl text-fgray`}>
          {footNotes}
        </p>
      )}
      {touched && error && (
        <p
          className={`mt-2 text-base text-grotesk lg:text-xl ${
            touched && error ? "text-fred " : "text-fgray"
          }`}
        >
          {meta.error}
        </p>
      )}
    </div>
  )
}

export default TextInput
