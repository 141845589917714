import { useContext, useEffect, useState } from 'react'
import { DatabaseIcon, UserAddIcon } from '@heroicons/react/outline'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import ListWidget from '../Widgets/ListWidget'
import Loading from '../Widgets/Loading'
import Profile from './Profile'
import { useHistory, useParams } from 'react-router'
import Tabs from '../Widgets/Tabs'
import UpdateList from './UpdateList'
import APIAccess from './APIAccess'

const usersAPI = '/api/users/all'

const styles = {
  supervisor:
    'flex justify-center items-center w-min m-1 font-medium py-1 px-2 rounded-full text-green-700 bg-green-100 border border-green-300 bg-opacity-30',
  administrator:
    'flex justify-center items-center w-min m-1 font-medium py-1 px-2 rounded-full text-fblue-700 bg-fblue-100 border border-fblue-300 bg-opacity-30',
  reviewer:
    'flex justify-center items-center w-min m-1 font-medium py-1 px-2 rounded-full text-fpink-700 bg-fpink-100 border border-fpink-300 bg-opacity-30',
}

const Settings = () => {
  const history = useHistory()
  const { id } = useParams()
  const [data, setData] = useState({
    header: [
      {
        title: 'Name',
        key: 'name',
        style: 'text-xs md:text-sm',
        showFilter: true,
      },
      {
        title: '',
        key: 'isLocked',
        style: 'hidden md:table-cell text-center text-sm',
        showFilter: false,
      },
      {
        title: 'Email',
        key: 'email',
        style: 'text-xs md:text-sm',
        showFilter: true,
      },
      {
        title: 'Role',
        key: 'role',
        style: 'hidden text-xs md:text-sm sm:block',
        showFilter: false,
      },
      {
        title: 'Actions',
        key: '-',
        style: 'text-xs md:text-sm',
        showFilter: false,
      },
    ],
    data: [],
  })
  const [filter, setFilter] = useState([
    { key: 'name', search: '', show: false, type: 'text' },
    { key: 'email', search: '', show: false, type: 'text' },
    { key: 'role', search: '', show: false, type: 'text' },
  ])
  const [loading, setLoading] = useState(false)
  const { config, rolesLabel, selectedTab, setSelectedTab, user, reload } =
    useContext(AdminContext)
  const tabs = [
    {
      index: 0,
      name: 'Profile',
      restricted: '',
    },
    {
      index: 1,
      name: 'Users',
      restricted: 'sadmin',
    },
    {
      index: 2,
      name: 'API Access',
      restricted: 'sadmin',
    },
    {
      index: 3,
      name: 'Updates',
      restricted: '',
    },
  ]

  //Get all registered Users and set the result as data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(usersAPI, config)
        let filteredData = response.data.data
          .filter((item) => item._id !== user.id)
          .map((item) => ({
            ...item,
            name: item.firstName + ' ' + item.lastName,
            role: rolesLabel[item.role],
          })) //sort by isLocked and then by name
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => a.isLocked - b.isLocked)

        filter.forEach(
          (term) =>
            (filteredData = [
              ...filteredData.filter((item) =>
                item[term.key].toLowerCase().includes(term.search.toLowerCase())
              ),
            ])
        )
        setData({ ...data, data: [...filteredData] })
        setLoading(false)
      } catch (e) {}
    }
    fetchData()
  }, [selectedTab, reload, filter])

  const exportDb = () => {
    setLoading(true)
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        id={id}
      />
      <section className='relative h-full w-full flex md:px-4  items-center flex-col bg-white outline-none overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue'>
        {loading ? (
          <Loading />
        ) : id || selectedTab === 0 ? (
          <Profile />
        ) : selectedTab === 1 ? (
          <>
            <div className='mr-auto'>
              <button
                className='flex px-8 py-2 space-x-2 shadow-lg m-2 bg-flime transition duration-200 hover:bg-fblue hover:text-white'
                onClick={() => history.push('settings/id/new')}
              >
                <UserAddIcon className='h-5 w-5' />
                <p className='text-mono text-sm'>Add user</p>
              </button>
            </div>
            <ListWidget
              title=''
              data={data}
              showing={8}
              styles={styles}
              cellAlignment={'justify-center'}
              filter={filter}
              setFilter={setFilter}
            />
          </>
        ) : selectedTab === 2 ? (
          <APIAccess />
        ) : (
          <UpdateList />
        )}
      </section>
    </>
  )
}

export default Settings
