import Question from './Question'
import smallLogo from '../../assets/images/smallLogo.svg'
import { useHistory, useParams } from 'react-router-dom'
import { useState, useContext } from 'react'
import AnswersContext from '../../contexts/Answers'
import gifLogo from '../../assets/images/logo-animation.gif'

const FormPage = (props) => {
  const { questions, isFirst, isLast } = props
  const { submitHandler, nextHandler, prev, prevHandler } =
    useContext(AnswersContext)
  const [selected, setSelected] = useState([])
  const [gify, setGify] = useState(false)
  const [selectValidation, setSelectValidation] = useState(true)

  const history = useHistory()
  const { memberType } = useParams()

  const submit = async () => {
    submitHandler(true)
    setGify(true)
    const timer = setTimeout(() => {
      let path = `/thankyou`
      history.push(path)
      setGify(false)
      submitHandler(false)
    }, 4000)

    return () => clearTimeout(timer)
  }

  const previous = () => {
    submitHandler(false)
    props.previousStep()
    prevHandler(true)
  }

  const nextClicked = () => {
    nextHandler(true)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const questionType = questions.filter(
      (item) => item.type === 'choice' || item.type === 'list'
    )
    if (
      questionType.length <= selected.length ||
      prev ||
      props.questionPreview
    ) {
      props.nextStep()
      setSelected([])
      prevHandler(false)
      setSelectValidation(true)
    } else {
      setSelectValidation(false)
    }
    if (isLast && questionType.length <= selected.length) submit()
  }

  const selectedAnswer = (answer) => {
    selected.push(answer)
    setSelected([...selected])
  }

  return (
    <>
      {gify && (
        <div classname='relative flex flex-col justify-center items-centers w-screen h-screen'>
          <img src={gifLogo} alt='founderland logo' className='max-h-80' />

          <h2 className='text-center'>Sending your application!</h2>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div
          className={
            !gify
              ? 'max-h-screen w-screen md:w-full flex justify-center flex-col overflow-hidden'
              : 'hidden'
          }
        >
          <div className=' flex flex-col justify-between p-10 h-screen overflow-y-auto'>
            <div className='flex  justify-between md:justify-end mb-7'>
              <div className='md:hidden mt-3'>
                <img src={smallLogo} alt='logo' className='w-20 pr-5' />
              </div>
            </div>
            <div
              className={`w-full flex-grow ${
                questions.length >= 4
                  ? 'lg:grid lg:grid-cols-2 lg:gap-x-16'
                  : 'flex-col'
              }`}
            >
              {questions.map((question, i) => (
                <Question
                  key={i}
                  {...question}
                  selectedAnswer={selectedAnswer}
                  selectValidation={selectValidation}
                  questionPreview={props.questionPreview}
                />
              ))}
            </div>
            <div
              className={`w-full flex ${
                isFirst ? 'justify-end' : 'justify-between'
              } mt-12 lg:mt-16`}
            >
              {!isFirst && (
                <button
                  type='button'
                  className='flex justify-start items-center space-x-2 px-0 py-4 bg-transparent font-bold text-2xl text-mono uppercase text-gray-500 transition duration-100 hover:text-black'
                  onClick={previous}
                >
                  {'< Back'}
                </button>
              )}
              <button
                type='submit'
                disabled={isLast && props.questionPreview}
                className={
                  'flex justify-start items-center space-x-2 px-12 py-4 bg-fblue font-bold text-2xl text-mono uppercase text-white transition duration-100 hover:bg-fblue-40' +
                  (isLast && props.questionPreview && ' opacity-30')
                }
                onClick={nextClicked}
              >
                {isLast ? 'Submit' : 'next'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default FormPage
