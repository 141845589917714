import { useState, useCallback, useRef } from "react"
import { useDropzone } from "react-dropzone"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import axios from "axios"
import icons from "../../../../assets/icons/Icons"
import { ReactComponent as SmallLogo } from "../../../../assets/small.svg"
import Button from "./Button"

const CloudinaryDropzone = ({
  data,
  setData,
  type,
  setUploadStatus,
  uploadStatus,
  noPreview,
  config,
}) => {
  const [previewSource, setPreviewSource] = useState(null)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [cropData, setCropData] = useState("#")
  const [cropping, setCropping] = useState(false)
  const [cropper, setCropper] = useState()
  const finalPreview = useRef(null)
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" })
  }

  const getCropData = async () => {
    setCropping(true)
    setTimeout(() => {
      if (typeof cropper !== "undefined") {
        uploadImage(cropper.getCroppedCanvas().toDataURL())
        setCropping(false)
        setPreviewSource(cropper.getCroppedCanvas().toDataURL())
        scrollToRef(finalPreview)
      }
    }, 200)
  }

  // CLOUDINARY
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onerror = () => console.log("file reading has failed")
    reader.onloadend = () => {
      const binaryStr = reader.result
      if (!noPreview) {
        setImage(binaryStr)
      }
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const uploadImage = async (base64EncodedImage) => {
    setLoading(true)
    try {
      const result = await axios.post(
        "/api/profile-picture/upload",
        {
          data: base64EncodedImage,
          public_id: `${type}`, //-${Date.now()}
          folder: `profiles/${data.firstName}-${data.lastName}-${
            data._id || data.id
          }`,
        },
        config
      )
      if (result.data?.url) {
        setUploadStatus({
          success: true,
          message: result.data.message,
        })
        type.includes("profile")
          ? setData("photo", {
              public_id: result.data.public_id,
              url: result.data.url,
            })
          : setData("flag", {
              public_id: result.data.public_id,
              url: result.data.url,
            })
        setLoading(false)
        setTimeout(() => {
          setUploadStatus({
            success: false,
            message: "",
          })
          // setPreviewSource(null)
        }, 4000)
      }
    } catch (e) {
      setLoading(false)
      setUploadStatus({
        success: false,
        message: e.response?.data.message || "Sorry something went wrong",
      })
      setTimeout(() => {
        setUploadStatus({
          success: false,
          message: "",
        })
      }, 4000)
    }
  }

  return (
    <>
      {image && (
        <div className="relative flex flex-col w-full justify-center items-center">
          <p className="w-full my-2 text-mono font-normal text-center text-base">
            ℹ️&nbsp;&nbsp;Scroll to zoom, adjust the area to crop.
            <br /> Click the 'Use' button to upload!
          </p>
          <Cropper
            style={{ height: "320px", width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            aspectRatio={type.includes("profile") ? 1 : 8 / 4}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance)
            }}
            guides={true}
          />
          <Button
            label={"Use"}
            styled={"small"}
            classNames={"absolute bottom-4 right-0 min-h-12 min-w-"}
            type={"button"}
            action={getCropData}
            loading={cropping}
          />
        </div>
      )}

      <div
        {...getRootProps()}
        className="w-full h-full flex flex-col justify-center items-center border-dashed border-4 border-black-600 py-2 my-2 rounded-xl"
      >
        <input {...getInputProps()} />
        <div className="flex flex-col md:flex-row">
          {loading ? (
            <div className="flex justify-center">
              <span
                style={{ borderTopColor: "transparent" }}
                className="w-16 h-16 border-8 border-black  border-dotted rounded-full animate-spin"
              ></span>
            </div>
          ) : (previewSource || data.photo?.public_id) &&
            type.includes("profile") ? (
            <>
              {data.role === "founder" && (
                <div className="relative m-2 w-full md:w-1/2 flex-none">
                  <p>Headshot Flag Preview</p>
                  <div className=" relative p-0 h-48 w-48">
                    <img
                      src={previewSource ? previewSource : data.photo.url}
                      alt="chosen"
                      className="w-full h-full object-cover"
                    />
                    {data.role === "founder" &&
                      data.symbols.map((symbol, i) =>
                        icons[symbol.icon](
                          `m-0 absolute bottom-0 inset-2/3 inset-x-${
                            i === 0 ? 0 : i === 1 ? "1/3" : "2/3"
                          } w-1/3 h-1/3 text-${symbol.color} fill-current`,
                          `${
                            symbol.color === "flime"
                              ? "text-black"
                              : "text-white"
                          } fill-current`,
                          i
                        )
                      )}
                  </div>
                </div>
              )}
              <div
                className={`m-2 w-full ${
                  data.role === "founder" && "md:w-1/2"
                } flex-none`}
              >
                <p>Profile Picture Preview</p>
                <img
                  src={previewSource ? previewSource : data.photo.url}
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                  alt="chosen"
                  className="flex-none p-4 object-cover rounded-full"
                />
              </div>
            </>
          ) : (previewSource || data.flag?.public_id) &&
            type.includes("flag") ? (
            <div className="w-full flex flex-col justify-center items-center">
              <p>Flag Preview</p>
              <div className="relative p-0 w-full md:w-4/6">
                <img
                  src={previewSource ? previewSource : data.flag.url}
                  alt="chosen"
                  className="w-full h-full object-cover"
                />

                {data.symbols.map((symbol, i) =>
                  icons[symbol.icon](
                    `absolute right-0 inset-y-${
                      i === 0 ? 0 : i === 1 ? "1/4" : "2/4"
                    } h-1/4 text-${symbol.color} fill-current`,
                    `${
                      symbol.color === "flime" ? "text-black" : "text-white"
                    } fill-current`,
                    i
                  )
                )}
                <SmallLogo className="absolute right-0 h-1/4 z-20 text-white bg-black fill-current inset-y-3/4" />
                <div
                  className={`bg-${data.bannerColor} absolute bottom-0 h-1/4 w-full flex items-center justify-start`}
                >
                  <p
                    className={`w-5/6 pt-1 text-center text-${
                      data.bannerColor === "flime" ? "black" : "white"
                    } uppercase text-hanson font-bold text-xs md:text-base tracking-widest`}
                  >
                    {data.firstName}'s Land
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <p
          className={`uppercase ${
            !previewSource ? "min-h-16" : "flex-grow"
          } flex items-center text-gray-600 text-md font-bold my-2 text-center`}
          ref={finalPreview}
        >
          Drag and drop your photo here, or click to select it.
        </p>
      </div>
    </>
  )
}

export default CloudinaryDropzone
