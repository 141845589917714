import {
  LinkIcon,
  PhotographIcon,
  VideoCameraIcon,
  CollectionIcon,
} from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'

const types = {
  article: <CollectionIcon className='w-4 h-4 text-black' />,
  link: <LinkIcon className='w-4 h-4 text-black' />,
  video: <VideoCameraIcon className='w-4 h-4 text-black' />,
  picture: <PhotographIcon className='w-4 h-4 text-black' />,
}

const ResourceCard = ({ resource }) => {
  const history = useHistory()
  return (
    <div className='flex justify-center group py-4 px-2'>
      <div
        onClick={() => history.push(`/admin/resources/id/${resource._id}`)}
        className='relative w-80 xl:w-96 overflow-hidden flex items-center justify-center group cursor-pointer flex-col bg-white shadow-md hover:shadow-lg'
      >
        <div className='w-full flex '>
          <img
            className='w-full h-40 object-cover'
            src={resource.articleCover?.url}
            alt='resource cover'
          />
        </div>
        <div className='w-full h-24 flex flex-col justify-center px-3 py-3'>
          <p className='px-2 mb-2 text-black text-base text-grotesk font-bold overflow-ellipsis text-left truncate'>
            {resource.articleTitle}
          </p>
          <p className='px-2 mb-2 text-black text-xs md:text-sm text-mono overflow-ellipsis text-justify truncate'>
            {resource.articleDescription}
          </p>
        </div>
        <span className='absolute top-2 left-2 inline-block bg-flime rounded-full px-3 py-1 text-xs font-semibold text-black'>
          {types[resource.articleType]}
        </span>
      </div>
    </div>
  )
}

export default ResourceCard
