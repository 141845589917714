import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import Banner from '../Widgets/Banner'
import Tags from '../Widgets/Tags'
import SunEditorBox from '../Widgets/SunEditorBox'

const addArticleUrl = '/api/newsfeed/add'
const updateArticleUrl = '/api/newsfeed/update'
const uploadUrl = '/api/profile-picture/assets'

const AddArticle = ({ article, edit, setEdit }) => {
  const history = useHistory()
  const [data, setData] = useState({
    member: '61814cbf5f7dd7305e7615f5',
    articleTitle: '',
    articleContent: '',
    tags: [],
  })
  const [saving, setSaving] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const [required, setRequired] = useState(false)
  //EDIT
  useEffect(() => {
    if (edit) {
      setData((prev) => ({
        ...prev,
        ...article,
      }))
    }
  }, [edit])
  const onEditorChange = (value) => {
    setData((prev) => ({
      ...prev,
      articleContent: value,
    }))
  }
  const { config, reload, setReload } = useContext(AdminContext)

  //SAVE ARTICLE
  const save = async () => {
    setSaving(true)
    if (data.articleTitle && data.articleContent) {
      try {
        let newArticle = null
        if (edit) {
          newArticle = await axios.put(updateArticleUrl, data, config)
        } else {
          newArticle = await axios.post(addArticleUrl, data, config)
        }
        if (newArticle.data.success && !edit) {
          setSaving(false)
          setBanner({
            success: 1,
            show: true,
            message: 'Article saved! Redirecting...',
          })
          setTimeout(() => {
            setBanner((prev) => ({ ...prev, show: false }))
            setReload(reload + 1)
            history.goBack()
          }, 2000)
        } else {
          setEdit(false)
          setReload(reload + 1)
        }
      } catch (e) {
        if (e?.message === 'invalid_URL') {
          setSaving(false)
          setBanner({
            error: 1,
            show: true,
            message: 'Invalid URL provided',
          })
          setTimeout(() => {
            setBanner((prev) => ({ ...prev, show: false }))
          }, 5000)
        } else if (e?.message === 'missing_field') {
          setSaving(false)
          setRequired(true)
          setBanner({
            error: 1,
            show: true,
            message: 'Please fill in all required fields!',
          })
          setTimeout(() => {
            setBanner((prev) => ({ ...prev, show: false }))
            setRequired(false)
          }, 5000)
        } else {
          setSaving(false)
          setBanner({
            error: 1,
            show: true,
            message: 'Error saving to the database',
          })
          setTimeout(() => {
            setBanner((prev) => ({ ...prev, show: false }))
          }, 5000)
        }
      }
    } else {
      setSaving(false)
      setRequired(true)
      setBanner({
        error: 1,
        show: true,
        message: 'Please fill in all required fields!',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
        setRequired(false)
      }, 5000)
    }
  }

  const pushTag = (value) => {
    const convertedValue =
      '#' +
      value
        .replace(/[^\w,;\s]+/g, '')
        .replace(/\s+/g, '-')
        .replace(/[,;]{2,}/g, '')
        .toLowerCase()
    if (!data.tags.includes(convertedValue)) {
      setData((prev) => ({
        ...prev,
        tags: [...data.tags, convertedValue],
      }))
    }
  }
  const popTag = (value) => {
    const newTags = [...data.tags].filter((tag) => tag !== value)

    setData((prev) => ({ ...prev, tags: newTags }))
  }

  return (
    <div className='bg-white px-4 md:px-8 pt-6 flex flex-col w-full '>
      <div className='w-full flex items-center justify-center z-20'>
        <Banner message={banner} />
      </div>
      <div className='w-full uppercase font-bold tracking-wider text-xl flex items-center justify-center mb-4'>
        {edit ? 'Edit' : 'Add new'} Article
      </div>

      <div className='md:flex w-full px-3'>
        <div className='w-full  mb-2 px-2'>
          <label
            className={`block uppercase tracking-wide text-xs font-bold mb-2 ${
              required ? 'text-red-600 animate-pulse' : ''
            }`}
          >
            Title
          </label>
          <input
            className={`${
              data.articleTitle === ''
                ? ''
                : data.articleTitle.length <= 1
                ? 'border-l-4 border-fred'
                : 'border-l-4 border-flime'
            } appearance-none outline-none block w-full border py-3 px-4 mb-3 ${
              required ? 'bg-red-200 animate-pulse' : 'bg-grey-lighter '
            }`}
            type='text'
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                articleTitle: e.target.value,
              }))
            }
            value={data.articleTitle}
            autoComplete='off'
          />
        </div>
      </div>

      <div className='md:flex w-full px-3'>
        <div className='w-full mb-2 px-2'>
          <label
            className={`block uppercase tracking-wide text-xs font-bold mb-2 ${
              required ? 'text-red-600 animate-pulse' : ''
            }`}
          >
            Content
          </label>
          <SunEditorBox
            onEditorChange={onEditorChange}
            articleContent={data.articleContent}
            config={config}
            uploadUrl={uploadUrl}
            height={'400px'}
          />
        </div>
      </div>
      <div className='md:flex w-full px-3'>
        <div className='w-full mb-2 px-2'>
          <label className='block uppercase tracking-wide text-xs font-bold mb-2'>
            Tags
          </label>
          <div className=''>
            <Tags tags={data.tags} pushTag={pushTag} popTag={popTag} />
          </div>
        </div>
      </div>

      <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
        <button
          className='px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4'
          onClick={() => {
            edit ? setEdit(false) : history.goBack()
          }}
        >
          Cancel
        </button>
        <button
          className='px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4'
          onClick={save}
        >
          {saving ? (
            <div className='flex justify-center'>
              <div
                style={{ borderTopColor: 'transparent' }}
                className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
              ></div>
            </div>
          ) : (
            'Save'
          )}
        </button>
      </div>
    </div>
  )
}

export default AddArticle
