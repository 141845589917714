const SessionDetails = () => {
  return (
    <div className='w-full h-full flex flex-col justify-center'>
      <h1>Session details</h1>
      <div>Request</div>
      <div>Status</div>
      <div>Review</div>
    </div>
  )
}

export default SessionDetails
