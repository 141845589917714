import React from 'react'

export default function ActiveDot() {
  return (
    <span className='flex h-4 w-4 relative mr-4'>
      <span className='absolute inline-flex h-full w-full rounded-full bg-white opacity-75'></span>
      <span className='relative inline-flex rounded-full h-4 w-4 bg-white'></span>
    </span>
  )
}
