import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import Banner from '../Widgets/Banner'
import Loading from '../Widgets/Loading'
import EventPreview from '../../Community/Profile/EventPreview'
import ComponentModal from '../Widgets/ComponentModal'
import SymbolsModal from '../Widgets/SymbolsModal'
import ConfirmModal from '../Widgets/ConfirmModal'
import ConfirmRemove from './ConfirmRemove'
import Notify from './Notify'
import NotifyToken from './NotifyToken'
import icons from '../../../assets/icons/Icons'
import { ReactComponent as SmallLogo } from '../../../assets/small.svg'
import SymbolPicker from '../../Community/Widgets/SymbolPicker'
import * as htmlToImage from 'html-to-image'
import { Popover } from '@headlessui/react'
import { ReactComponent as InstagramLogo } from '../../../assets/images/instagram.svg'
import { ReactComponent as LinkedinLogo } from '../../../assets/images/linkedin.svg'
import { ReactComponent as TwitterLogo } from '../../../assets/images/twitter.svg'

import moment from 'moment'
import {
  LockOpenIcon,
  LockClosedIcon,
  TrashIcon,
  HashtagIcon,
  CalendarIcon,
  ChevronRightIcon,
  MailIcon,
  DownloadIcon,
  ExternalLinkIcon,
  ClipboardCopyIcon,
  PencilAltIcon,
  ColorSwatchIcon,
  InboxInIcon,
  ExclamationCircleIcon,
  CogIcon,
} from '@heroicons/react/outline'
import EmailUpdate from './EmailUpdate'

const symbolColors = ['fblue', 'fred', 'fpink', 'flime']

const memberUrl = '/api/users/community/profile/'
const lockUrl = '/api/users/community/lock'
const flagUrl = '/api/users/community/flag'
const eventsUrl = '/api/events/future'
const updateProfile = '/api/users/community/profile/update'

const defaultProfile = {
  photo: null,
  locked: false,
  _id: null,
  firstName: null,
  lastName: null,
  title: null,
  companyName: null,
  companyBio: null,
  city: null,
  country: null,
  geoLocation: [],
  email: null,
  created: null,
  confirmed: null,
  role: null,
  following: [],
  followers: [],
  applicationId: null,
  notified: null,
  about: null,
  businessArea: null,
  hashedPassword: null,
  lastUpdate: null,
  symbols: [
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
  ],
  bannerColor: 'fblue',
  consent: false,
  linkedinBio: null,
  pronouns: null,
  origins: null,
  asks: null,
}
const styles = {
  founder: 'fred',
  investor: 'fblue',
  ally: 'flime',
}

const MemberProfile = () => {
  const [loading, setLoading] = useState(true)
  const [copied, setCopied] = useState(false)
  const [locking, setLocking] = useState(false)
  const [flagging, setFlagging] = useState(false)
  const [currentSymbols, setCurrentSymbols] = useState([
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
  ])
  const [currentBanner, setCurrentBanner] = useState('fblue')
  const [applicationAnswers, setApplicationAnswers] = useState({
    pronouns: null,
    origins: null,
    asks: null,
  })
  const [profile, setProfile] = useState(defaultProfile)
  const [linkedInBio, setLinkedInBio] = useState(null)
  const [emojiFlags, setEmojiFlags] = useState(null)
  const [events, setEvents] = useState({
    own: [],
    interested: [],
    going: [],
  })
  const [availability, setAvailability] = useState({
    available: false,
    id: null,
  })
  const [banner, setBanner] = useState({ show: false })
  const [removing, setRemoving] = useState(false)
  const [emailChange, setEmailChange] = useState(false)
  const [touched, setTouched] = useState({
    symbols: [true, true, true],
    bannerColor: true,
  })
  const { id } = useParams()
  const {
    config,
    user,
    setCModal,
    setCCModal,
    setSModal,
    countryList,
    reload,
  } = useContext(AdminContext)

  const getEmojiFlag = (countryName) => {
    const [currentCountry] = countryList.filter(
      (country) => country.name === countryName
    )
    if (currentCountry) {
      const codePoints = currentCountry?.iso2
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt())
      return String.fromCodePoint(...codePoints)
    }
    return null
  }

  //GET PROFILE
  useEffect(() => {
    setLoading(true)
    const getProfile = async () => {
      try {
        const response = await axios.get(memberUrl + id, config)
        if (response) {
          let profileData = { ...profile, ...response.data.data }
          if (profileData.symbols.length === 0)
            profileData.symbols = [...defaultProfile.symbols]
          setProfile((prev) => ({ ...prev, ...profileData }))
          setEmojiFlags(getEmojiFlag(profileData.country))
          setCurrentSymbols([...profileData.symbols])
          setCurrentBanner(profileData.bannerColor)
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
        setBanner({
          error: 1,
          show: true,
          message: 'Error getting profile data!',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    }
    getProfile()
    return () => {
      setProfile(defaultProfile)
    }
  }, [id, reload])

  //CONFIGURE BIO
  useEffect(() => {
    const generateBio = () => {
      const pronoun = profile.pronouns
        ? profile.pronouns.split('/')[0]
        : applicationAnswers.pronouns
        ? applicationAnswers.pronouns.split('/')[0]
        : 'She'
      const bio = `👋 Please meet ${profile.firstName} ${
        profile.lastName
      }, founder of ${profile.companyName}. ${pronoun}${
        pronoun !== 'They' ? "'s" : ' are'
      } ${
        profile.origins
          ? profile.origins
          : applicationAnswers.origins
          ? applicationAnswers.origins
          : '[...]'
      }, living in ${profile.city}, ${profile.country}. ${
        emojiFlags ? emojiFlags : ''
      }\r\n\r\n${profile.companyBio}\r\n\r\n🙌 ${pronoun} stand${
        pronoun !== 'They' ? 's' : ''
      } for and has chosen the symbols of ${
        profile.symbols[0].icon.charAt(0).toUpperCase() +
        profile.symbols[0].icon.replace('_', ' ').slice(1)
      } (${profile.symbols[0].color.slice(1)}), ${
        profile.symbols[1].icon.charAt(0).toUpperCase() +
        profile.symbols[1].icon.replace('_', ' ').slice(1)
      } (${profile.symbols[1].color.slice(1)}) and ${
        profile.symbols[2].icon.charAt(0).toUpperCase() +
        profile.symbols[2].icon.replace('_', ' ').slice(1)
      } (${profile.symbols[2].color.slice(1)}) for ${
        pronoun === 'She' ? 'Her' : pronoun === 'He' ? 'his' : 'their'
      } Founderland flag.\r\n\r\n❓ ${
        pronoun === 'She' ? 'Her' : pronoun === 'He' ? 'his' : 'their'
      } ask: ${
        profile.asks
          ? profile.asks
          : applicationAnswers.asks
          ? applicationAnswers.asks
          : '[...]'
      }\r\n\r\n 💙 Welcome to Founderland ${profile.firstName} ${
        profile.lastName
      }`
      return bio
    }
    const bio = generateBio()
    if (!profile.linkedinBio) {
      setLinkedInBio(bio)
    } else {
      setLinkedInBio(profile.linkedinBio)
    }
  }, [profile, emojiFlags, applicationAnswers])

  //GET EVENTS
  useEffect(() => {
    axios
      .get(eventsUrl, config)
      .then((res) => {
        let searchId = id
        if (res.data.data) {
          let own = res.data.data.filter((item) => item.member._id === searchId)
          let going = res.data.data.filter(
            (item) =>
              item.going.filter((going) => going._id === searchId).length
          )
          let interested = res.data.data.filter(
            (item) =>
              item.interested.filter(
                (interested) => interested._id === searchId
              ).length
          )
          setEvents((prev) => ({ ...prev, own, going, interested }))
        }
      })
      .catch((e) => {
        setLoading(false)
        setBanner({
          error: 1,
          show: true,
          message: 'Error getting events data!',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      })
  }, [id])

  //GET APPLICATION
  useEffect(() => {
    const getApplication = async () => {
      try {
        const applicationData = await axios.get(
          '/api/applicants/response/' + profile.applicationId,
          config
        )
        //Grab origins - 'roots/heritage'
        const [originsAnswer] = applicationData.data.answerData.filter(
          (answer) => answer.question.includes('roots/heritage')
        )
        //Grab ask
        const [askAnswer] = applicationData.data.answerData.filter((answer) =>
          answer.question.includes('ask')
        )
        //Grab Pronouns
        const [pronounsAnswer] = applicationData.data.answerData.filter(
          (answer) => answer.question.includes('Pronouns')
        )
        setApplicationAnswers({
          origins: originsAnswer ? originsAnswer.answer_value : null,
          pronouns: pronounsAnswer ? pronounsAnswer.answer_value : null,
          asks: askAnswer ? askAnswer.answer_value : null,
        })
      } catch (err) {}
    }
    if (profile.applicationId) getApplication()
  }, [profile])

  //GET AVILABILITY
  useEffect(() => {
    const getAvailability = async () => {
      try {
        const { data } = await axios.get(
          `/api/availability/member/${profile._id}`,
          config
        )
        if (data) {
          setAvailability({ available: data.available, id: data._id })
        }
      } catch (err) {}
    }
    if (profile.role === 'ally') getAvailability()
  }, [profile])

  //COPY BIO
  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }
  const handleCopyClick = async (textToCopy) => {
    copyTextToClipboard(textToCopy)
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 1500)
      })
      .catch((err) => {})
    //SET linkedinBio to profile
    const updateBio = await axios.put(
      updateProfile,
      { _id: id, linkedinBio: linkedInBio },
      config
    )
    if (updateBio) {
      setBanner({
        success: 1,
        show: true,
        message: 'Copied and saved!',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  //SET IDENTITY
  const setSymbols = (symbol, i) => {
    let current = [...currentSymbols]
    current[i] = symbol
    setCurrentSymbols([...current])
  }

  //DOWNLOAD MATERIAL
  const download = () => {
    exportAsPicture(profile.firstName + '-' + profile.lastName)
  }
  const exportAsPicture = async (filename) => {
    const data = document.getElementsByClassName('htmlToCanvas')
    for (let i = 0; i < data.length; ++i) {
      const canvas = await htmlToImage.toJpeg(data[i], { quality: 0.95 })
      saveAs(canvas, filename)
    }
  }
  const saveAs = async (blob, fileName) => {
    const elem = window.document.createElement('a')
    elem.href = blob
    elem.download = fileName
    elem.style = 'display:none;'
    ;(document.body || document.documentElement).appendChild(elem)
    if (typeof elem.click === 'function') {
      elem.click()
    } else {
      elem.target = '_blank'
      elem.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      )
    }
    URL.revokeObjectURL(elem.href)
    elem.remove()
  }

  //LOCK ACCOUNT
  const lock = async () => {
    setLocking(true)
    try {
      const isLocked = await axios.put(
        lockUrl,
        { _id: id, locked: !profile.locked },
        config
      )
      if (isLocked) {
        setProfile((prev) => ({ ...prev, locked: !prev.locked }))
        setLocking(false)
        setBanner({
          success: 1,
          show: true,
          message: isLocked.data.data.locked
            ? 'Account locked'
            : 'Account unlocked',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 2000)
      } else {
        setLocking(false)
        setBanner({
          error: 1,
          show: true,
          message: profile.locked
            ? 'Error unlocking member!'
            : 'Error locking member!',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    } catch (e) {
      setLocking(false)
      setBanner({
        error: 1,
        show: true,
        message: profile.locked
          ? 'Error unlocking member!'
          : 'Error locking member!',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }
  //Flag ACCOUNT
  const flag = async () => {
    setFlagging(true)
    try {
      const isFlagged = await axios.put(
        flagUrl,
        { _id: id, flagged: !profile.flagged },
        config
      )
      if (isFlagged) {
        setProfile((prev) => ({ ...prev, flagged: !prev.flagged }))
        setFlagging(false)
        setBanner({
          success: 1,
          show: true,
          message: isFlagged.data.data.flagged
            ? 'Account flagged as unreacheable'
            : 'Account unlocked',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 2000)
      } else {
        setFlagging(false)
        setBanner({
          error: 1,
          show: true,
          message: profile.flagged
            ? 'Error unlocking member!'
            : 'Error locking member!',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    } catch (e) {
      setFlagging(false)
      setBanner({
        error: 1,
        show: true,
        message: profile.flagged
          ? 'Error unlocking member!'
          : 'Error locking member!',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }
  //REMOVE ACCOUNT
  const remove = async () => {
    setRemoving(true)
    setCCModal(true)
  }

  //Update email function
  const updateEmail = async () => {
    const { data } = await axios.put(
      '/api/users/community/profile/update',
      { _id: id, email: profile.email },
      config
    )
    if (data) {
      setBanner({
        success: 1,
        show: true,
        message: 'Email updated!',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    } else {
      setBanner({
        error: 1,
        show: true,
        message: 'Error updating email!',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }
  return (
    <section className='h-full w-full xl:w-5/6 mx-auto'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ComponentModal>
            <Notify member={profile} />
          </ComponentModal>
          <SymbolsModal
            currentSymbols={currentSymbols}
            currentBanner={currentBanner}
            id={id}
          >
            <div className='w-full flex flex-col sm:flex-row justify-center p-2'>
              {currentSymbols.map((symbol, i) => (
                <div className='w-full md:w-1/3' key={i}>
                  <SymbolPicker
                    data={symbol}
                    setSymbols={setSymbols}
                    position={i}
                    touched={touched}
                    setTouched={setTouched}
                  />
                </div>
              ))}
            </div>
            <div className='relative w-full h-20 flex px-10 justify-center'>
              <div
                className={`bg-${currentBanner} absolute bottom-0 h-20 flex items-center justify-start w-full md:w-2/3 lg:w-2/4 xl:w-2/6`}
              >
                <p
                  className={`w-full pt-1 text-center text-${
                    currentBanner === 'flime' ? 'black' : 'white'
                  } uppercase text-hanson font-bold text-base md:text-xl tracking-widest`}
                >
                  {profile.firstName}'s Land
                </p>
                <Popover className=' group'>
                  <Popover.Button className='absolute -bottom-2 outline-none right-0 bg-flime p-1 h-8 w-8 text-center rounded-full cursor-pointer transition duration-200 group-hover:bg-fblue group-hover:text-white'>
                    <ColorSwatchIcon className='h-6 w-6' />
                  </Popover.Button>
                  <Popover.Panel className='absolute z-20 -bottom-30 right-3 md:right-2 rounded shadow-lg bg-white w-24 h-24 p-2 flex flex-wrap justify-between '>
                    {({ close }) =>
                      symbolColors
                        .filter((color) =>
                          currentSymbols.some((item) => item.color === color)
                        )
                        .map((color) => (
                          <span
                            key={color}
                            className={`w-8 h-8 cursor-pointer rounded-full border-2 hover:border-blue-600 bg-${color} ${
                              color === profile.bannerColor
                                ? 'border-green-600'
                                : 'border-white'
                            }`}
                            onClick={(e) => {
                              setCurrentBanner((prev) => color)
                              close()
                            }}
                            onMouseOver={(e) =>
                              setCurrentBanner((prev) => color)
                            }
                            onMouseOut={(e) =>
                              setCurrentBanner((prev) => profile.bannerColor)
                            }
                          ></span>
                        ))
                    }
                  </Popover.Panel>
                </Popover>
              </div>
            </div>
          </SymbolsModal>
          <ConfirmModal>
            {removing ? (
              <ConfirmRemove data={profile} setRemoving={setRemoving} />
            ) : (
              <NotifyToken data={profile} setRemoving={setRemoving} />
            )}
          </ConfirmModal>
          <EmailUpdate
            member={profile}
            view={emailChange}
            setView={setEmailChange}
          />
          <div className='pt-8 w-full flex items-center justify-center z-20'>
            <Banner message={banner} />
          </div>
          <div className='w-full flex-none md:flex no-wrap md:-mx-2'>
            <div
              className={`w-full md:w-1/3 md:mx-2 shadow bg-white p-3 border-t-8 ${
                profile.role === 'founder'
                  ? 'border-fred'
                  : profile.role === 'investor'
                  ? 'border-fblue'
                  : 'border-flime'
              }`}
            >
              <div className='image overflow-hidden p-0'>
                <div className='mx-auto relative p-0 w-64 h-64 md:h-52 md:w-52 xl:w-64 xl:h-64'>
                  <img
                    src={profile.photo ? profile.photo.url : 'null'}
                    alt='profile'
                    className='w-full h-full object-cover'
                  />
                  {profile.role === 'investor' &&
                    profile.firmId &&
                    profile.firmId?.logo?.public_id && (
                      <img
                        src={profile.firmId.logo.url}
                        alt='logo'
                        className='border-fblue border absolute m-1 bottom-0 right-0 w-16 h-16 object-cover shadow-lg'
                      />
                    )}
                  {profile.role === 'founder' &&
                    profile.symbols.map((symbol, i) =>
                      icons[symbol.icon](
                        `m-0 absolute bottom-0 inset-2/3 inset-x-${
                          i === 0 ? 0 : i === 1 ? '1/3' : '2/3'
                        } w-1/3 h-1/3 text-${symbol.color} fill-current`,
                        `${
                          symbol.color === 'flime' ? 'text-black' : 'text-white'
                        } fill-current`,
                        i
                      )
                    )}
                </div>
              </div>

              <h1 className='text-gray-900 uppercase font-bold text-xl mt-3'>
                {profile.firstName} {profile.lastName}
              </h1>
              {profile.pronouns ? (
                <p className='text-gray-600 text-xs font-semibold'>
                  {profile.pronouns}
                </p>
              ) : applicationAnswers.pronouns ? (
                <p className='text-gray-600 text-xs font-semibold'>
                  {applicationAnswers.pronouns}
                </p>
              ) : (
                ''
              )}
              <p className='text-gray-600 text-lg font-semibold mt-3'>
                {profile.title}
              </p>
              <p className='text-gray-600 text-base mt-1'>
                {profile.companyName}
              </p>
              <div className='text-xs'>
                {profile.companyLink ? (
                  <a
                    href={profile.companyLink}
                    className='text-gray-600 hover:text-sky-600'
                    rel='noreferrer'
                    target='_blank'
                  >
                    {profile.companyLink}
                  </a>
                ) : (
                  ''
                )}
              </div>
              <p className='text-gray-700 text-base mt-3'>
                {profile.businessArea !== 'Select the business area' &&
                  profile.businessArea !== 'Other' &&
                  profile.businessArea}
              </p>
              {profile.country && (
                <p className='text-gray-700 text-base mt-3'>
                  {profile.city}, {profile.country}{' '}
                  {getEmojiFlag(profile.country)}
                </p>
              )}
              <div className='bg-gray-100 bg-opacity-60 text-gray-700 py-1 px-3 mt-3 divide-y '>
                <div className='flex items-center py-3'>
                  <p className='uppercase text-sm text-grotesk'>Status</p>
                  {profile.locked ? (
                    <div className='ml-auto bg-red-500 py-1 px-2 text-white text-sm shadow'>
                      Locked
                    </div>
                  ) : profile.flagged ? (
                    <div className='ml-auto bg-red-500 py-1 px-2 text-white text-sm shadow'>
                      Unreachable
                    </div>
                  ) : profile.confirmed ? (
                    <div className='ml-auto bg-green-500 py-1 px-2 text-white text-sm shadow'>
                      Active
                    </div>
                  ) : profile.notified ? (
                    <div className='ml-auto bg-yellow-500 py-1 px-2 text-white text-sm shadow'>
                      Unconfirmed
                    </div>
                  ) : (
                    <div className='ml-auto bg-yellow-500 py-1 px-2 text-white text-sm shadow'>
                      Not Notified
                    </div>
                  )}
                </div>
                {profile.role !== 'founder' && (
                  <div className='flex items-center py-3'>
                    <p className='uppercase text-sm text-grotesk'>
                      Availability
                    </p>
                    {availability.available ? (
                      <div className='ml-auto'>
                        <Link
                          to={`/admin/allyprogram/id/${availability.id}`}
                          className='flex space-x-2 items-center justify-center font-bold hover:text-sky-600 text-sm'
                        >
                          Available <ChevronRightIcon className='h-4' />
                        </Link>
                      </div>
                    ) : availability.id ? (
                      <div className='ml-auto'>
                        <Link
                          to={`/admin/allyprogram/id/${availability.id}`}
                          className='flex space-x-2 items-center justify-center font-bold hover:text-sky-600 text-sm'
                        >
                          Unavailable <ChevronRightIcon className='h-4' />
                        </Link>
                      </div>
                    ) : (
                      <div className='ml-auto bg-yellow-500 py-1 px-2 text-white text-sm shadow'>
                        Not Set Yet
                      </div>
                    )}
                  </div>
                )}
                <div className='flex items-center py-3'>
                  <p className='uppercase text-sm text-grotesk'>Email</p>
                  <div
                    className={` flex w-full items-center justify-around px-6`}
                  >
                    <a
                      target='_blank'
                      disabled
                      href={`mailto:${profile.email}`}
                      rel='noreferrer'
                    >
                      {profile.email}
                    </a>
                  </div>
                </div>
                <div className='flex items-center py-3'>
                  <p className='uppercase text-sm text-grotesk'>Socials</p>
                  <div
                    className={` flex w-full items-center justify-between px-6`}
                  >
                    <a
                      target='_blank'
                      disabled
                      href={profile.socialmedia?.instagram || null}
                      rel='noreferrer'
                    >
                      <InstagramLogo
                        className={`w-8 h-8 ${
                          !profile.socialmedia?.instagram && ' opacity-30 '
                        }`}
                      />
                    </a>
                    <a
                      target='_blank'
                      href={profile.socialmedia?.linkedin || null}
                      rel='noreferrer'
                    >
                      <LinkedinLogo
                        className={`w-8 h-8 ${
                          !profile.socialmedia?.linkedin && ' opacity-30'
                        }`}
                      />
                    </a>
                    <a
                      target='_blank'
                      href={profile.socialmedia?.twitter || null}
                      rel='noreferrer'
                    >
                      <TwitterLogo
                        className={`w-8 h-8 ${
                          !profile.socialmedia?.twitter && ' opacity-30'
                        }`}
                      />
                    </a>
                  </div>
                </div>
                <div className='flex flex-col items-start justify-center py-3'>
                  <p className='uppercase mb-2 text-sm text-grotesk'>
                    Member since
                  </p>
                  <div className='w-full grid grid-cols-2 gap-x-2'>
                    <div className='ml-auto w-full text-center'>
                      <p className='text-xs text-grotesk'>Approved on</p>
                      <p className='font-bold text-xs md:text-sm'>
                        {moment(profile.created).format('DD/M/YYYY')}
                      </p>
                    </div>
                    {profile.confirmed && (
                      <div className='ml-auto w-full text-center'>
                        <p className='text-xs text-grotesk'>Confirmed on</p>
                        <p className='font-bold text-xs md:text-sm'>
                          {moment(profile.confirmed).format('DD/M/YYYY')}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex flex-col justify-center py-3'>
                  <p className='uppercase text-sm text-grotesk '>Application</p>
                  <div className='ml-auto'>
                    {profile.applicationId ? (
                      <Link
                        to={`/admin/applicants/id/${profile.applicationId}`}
                        className='flex space-x-2 items-center justify-center font-bold hover:text-sky-600 text-sm'
                      >
                        Go to Application <ChevronRightIcon className='h-4' />
                      </Link>
                    ) : profile.confirmed && profile.role === 'founder' ? (
                      <button
                        onClick={() => {
                          setRemoving(false)
                          setCCModal(true)
                        }}
                        className='flex items-center justify-center font-bold hover:text-sky-600 text-sm'
                      >
                        Send Fast Track Email
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {profile.role === 'founder' && (
                  <div className='flex flex-col justify-center py-3'>
                    <p className='uppercase text-sm text-grotesk '>Consent</p>
                    <div className='ml-auto text-sm flex space-x-2'>
                      {profile.consent ? <p>✅</p> : <p>❌</p>}
                      <p>Consent to share media</p>
                    </div>
                  </div>
                )}
                {profile.role === 'investor' && (
                  <div className='flex flex-col justify-center py-3'>
                    <p className='uppercase text-sm text-grotesk '>
                      Investment Firm
                    </p>
                    <div className='w-full text-right'>
                      {profile.firmId ? (
                        <Link
                          to={`/admin/members/firm/${profile.firmId._id}`}
                          className='flex space-x-4 items-center justify-end font-bold hover:text-sky-600 text-sm'
                        >
                          <p>{profile.firmId.companyName}</p>
                          <ChevronRightIcon className='h-4' />
                        </Link>
                      ) : (
                        <p>❌</p>
                      )}
                    </div>
                  </div>
                )}
                {profile.notified && (
                  <div className='flex flex-col justify-center py-3'>
                    <p className='uppercase text-sm text-grotesk'>
                      Notified to Join
                    </p>
                    <p className='ml-auto font-bold text-sm'>
                      {moment(profile.notified).format('DD/M/YYYY')}
                    </p>
                  </div>
                )}
              </div>
              <button
                className='flex items-center justify-center space-x-2 px-8 py-2 w-full bg-gray-700 transition duration-200 hover:bg-fred text-white mb-4 '
                onClick={() => flag()}
              >
                {flagging ? (
                  <div className='flex justify-center'>
                    <div
                      style={{
                        borderTopColor: 'transparent',
                      }}
                      className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                    ></div>
                  </div>
                ) : profile.flagged ? (
                  <>
                    <InboxInIcon className='w-6 h-6' />
                    <p className='text-sm xl:text-base'>Mark as Reachable</p>
                  </>
                ) : (
                  <>
                    <ExclamationCircleIcon className='w-6 h-6' />
                    <p className='text-sm xl:text-base'>Flag as Unreachable</p>
                  </>
                )}
              </button>
              {user.role.search('admin') >= 0 && (
                <>
                  <button
                    className='flex items-center justify-center space-x-2 px-8 py-2 w-full bg-gray-700 transition duration-200 hover:bg-fred text-white mb-4 '
                    onClick={() => lock()}
                  >
                    {locking ? (
                      <div className='flex justify-center'>
                        <div
                          style={{
                            borderTopColor: 'transparent',
                          }}
                          className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                        ></div>
                      </div>
                    ) : profile.locked ? (
                      <>
                        <LockOpenIcon className='w-6 h-6' />
                        <p className='text-sm xl:text-base'>Unlock Access</p>
                      </>
                    ) : (
                      <>
                        <LockClosedIcon className='w-6 h-6' />
                        <p className='text-sm xl:text-base'>Lock Access</p>
                      </>
                    )}
                  </button>
                  {profile.role === 'founder' && (
                    <>
                      <button
                        className='flex items-center justify-center space-x-2 px-8 py-2 w-full bg-gray-700 transition duration-200 hover:bg-flime hover:text-black text-white mb-4 '
                        onClick={() => download()}
                      >
                        <DownloadIcon className='w-6 h-6' />
                        <p className='text-sm xl:text-base'>Download Media</p>
                      </button>
                      <button
                        className='flex items-center justify-center space-x-2 px-8 py-2 w-full bg-gray-700 transition duration-200 hover:bg-flime hover:text-black text-white mb-4 '
                        onClick={() => setSModal(true)}
                      >
                        <PencilAltIcon className='w-6 h-6' />
                        <p className='text-sm xl:text-base'>Edit Symbols</p>
                      </button>
                    </>
                  )}
                  <button
                    className='flex items-center justify-center space-x-2 px-8 py-2 w-full bg-gray-700 transition duration-200 hover:bg-fblue text-white mb-4 '
                    onClick={() => setEmailChange(true)}
                  >
                    <CogIcon className='w-6 h-6' />
                    <p className='text-sm xl:text-base'>Change Email</p>
                  </button>
                </>
              )}
            </div>
            <div className='my-4'></div>
            <div
              className={`w-full md:w-2/3 md:mx-1 shadow bg-white p-3 border-t-8 border-${
                styles[profile.role]
              }`}
            >
              {profile.role === 'founder' && (
                <div className='flex bg-white p-3 text-base text-gray-700 justify-center'>
                  {profile?.flag?.public_id && (
                    <div className='relative p-0 w-full pb-1/2'>
                      <img
                        src={profile.flag?.url ? profile.flag.url : 'null'}
                        alt='flag'
                        className='w-full h-full object-cover'
                      />
                      <div
                        className={`bg-${profile.bannerColor} absolute bottom-0 h-1/4 w-full flex items-center justify-start`}
                      >
                        <p
                          className={`w-full mr-16 md:mr-10 xl:mr-16 pt-1 text-center text-${
                            profile.bannerColor === 'flime' ? 'black' : 'white'
                          }  uppercase text-hanson font-bold text-xl sm:text-3xl md:text-2xl xl:text-3xl`}
                        >
                          {profile.firstName}'s Land
                        </p>
                      </div>
                      {profile.symbols.map((symbol, i) =>
                        icons[symbol.icon](
                          `absolute right-0 inset-y-${
                            i === 0 ? 0 : i === 1 ? '1/4' : '2/4'
                          } h-1/4 text-${symbol.color} fill-current`,
                          `${
                            symbol.color === 'flime'
                              ? 'text-black'
                              : 'text-white'
                          } fill-current`,
                          i
                        )
                      )}
                      <SmallLogo className='absolute right-0 h-1/4 z-20 text-white bg-black fill-current inset-y-3/4' />
                    </div>
                  )}
                </div>
              )}
              <div className='bg-white p-3 text-base text-gray-700'>
                <div className='flex items-center space-x-2 font-semibold uppercase text-sm my-2'>
                  <HashtagIcon
                    className={`h-5 w-5 text-${styles[profile.role]}`}
                  />
                  <span className='tracking-wider text-grotesk'>About</span>
                </div>
                <div className='grid md:grid-cols-1 gap-2'>
                  <div className='grid grid-cols-1'>
                    <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                      My Bio
                    </div>
                    <div className='p-2 text-base'>{profile.bio}</div>
                  </div>
                  <div className='grid grid-cols-1'>
                    <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                      {profile.role === 'ally' ? 'My Expertise' : 'Company Bio'}
                    </div>
                    <div className='p-2 text-base'>{profile.companyBio}</div>
                  </div>
                </div>
              </div>
              {profile.role === 'founder' && (
                <>
                  <div className='bg-white p-3 text-base text-gray-700'>
                    <div className='flex items-center space-x-2 font-semibold uppercase text-sm my-2'>
                      <CalendarIcon
                        className={`h-5 w-5 text-${styles[profile.role]}`}
                      />
                      <span className='tracking-wider text-grotesk'>
                        Events
                      </span>
                    </div>
                    <div className='grid md:grid-cols-2 gap-2'>
                      <div className='grid grid-cols-1'>
                        <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                          Host
                        </div>
                        <div className='w-full max-h-80 flex flex-col justify-start px-2 mt-1 overflow-y-auto overflow-x-hidden'>
                          {events.own.length ? (
                            events.own.map((event) => (
                              <EventPreview key={event._id} event={event} />
                            ))
                          ) : (
                            <p className='text-xs text-mono'>
                              Not hosting any future event
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='grid grid-cols-1'>
                        <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                          Interested/Going
                        </div>
                        <div className='w-full max-h-80 flex flex-col justify-start px-2 mt-1 overflow-y-auto overflow-x-hidden'>
                          {events.going.length || events.interested.length ? (
                            <>
                              {events.going.map((event) => (
                                <EventPreview key={event._id} event={event} />
                              ))}
                              {events.interested.map((event) => (
                                <EventPreview key={event._id} event={event} />
                              ))}
                            </>
                          ) : (
                            <p className='text-xs text-mono'>
                              Not participating in any future event
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-white p-3 text-base text-gray-700'>
                    <div className='flex items-center space-x-2 font-semibold uppercase text-sm my-2'>
                      <ExternalLinkIcon
                        className={`h-5 w-5 text-${styles[profile.role]}`}
                      />
                      <span className='tracking-wider text-grotesk'>
                        LinkedIn Bio
                      </span>
                    </div>
                    <div className='grid md:grid-cols-1 gap-2'>
                      <div className='grid grid-cols-1'>
                        <div className='p-2 uppercase text-xs font-bold'>
                          <button
                            className='flex space-x-1 text-sm justify-center items-center hover:text-fblue'
                            onClick={() => {
                              handleCopyClick(linkedInBio)
                            }}
                          >
                            <ClipboardCopyIcon
                              className={`h-3 w-3 ${
                                copied
                                  ? 'text-green-800 animate-ping'
                                  : 'text-fblue '
                              }`}
                            />
                            <p className='text-xs'>Copy to clipboard</p>{' '}
                          </button>
                        </div>
                        <div className='p-2 text-base'>
                          <textarea
                            className='flex flex-col space-y-2 w-full border-0 text-sm'
                            value={
                              profile.linkedinBio
                                ? profile.linkedinBio
                                : linkedInBio
                            }
                            rows={15}
                            onChange={(e) => {
                              profile.linkedinBio
                                ? setProfile({
                                    ...profile,
                                    linkedinBio: e.target.value,
                                  })
                                : setLinkedInBio(e.target.value)
                            }}
                          ></textarea>
                        </div>
                        <div className='grid grid-cols-1'>
                          <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                            Origins
                          </div>
                          <div className='p-2 text-base'>
                            {profile.origins
                              ? profile.origins
                              : applicationAnswers.origins
                              ? applicationAnswers.origins
                              : 'No answer'}
                          </div>
                        </div>
                        <div className='grid grid-cols-1'>
                          <div className='p-2 uppercase text-xs font-bold text-gray-400'>
                            I ask
                          </div>
                          <div className='p-2 text-base'>
                            {profile.asks
                              ? profile.asks
                              : applicationAnswers.asks
                              ? applicationAnswers.asks
                              : 'No answer'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='my-4 flex flex-col sm:flex-row items-center justify-around '>
            <button
              className='flex items-center justify-center space-x-2 px-8 py-2 w-5/6 shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fblue text-white mb-4 '
              onClick={() => setCModal(true)}
            >
              <MailIcon className='w-6 h-6' />
              <p className='text-sm md:text-base'>Notify by Email</p>
            </button>

            {user.role.includes('sadmin') &&
              id !== '61814cbf5f7dd7305e7615f5' && (
                <button
                  className='flex items-center justify-center space-x-2 px-8 py-2 w-5/6 shadow-lg sm:w-1/3 bg-fred-700 transition duration-200 hover:bg-fred-100 text-white mb-4 '
                  onClick={() => remove()}
                >
                  <TrashIcon className='w-6 h-6' />
                  <p className='text-sm md:text-base'>Remove Member</p>
                </button>
              )}
          </div>
          <div className='w-full h-0 overflow-hidden'>
            {/* Flag Original */}
            <div
              className='htmlToCanvas relative'
              style={{ width: 1200, height: 600 }}
            >
              {profile.flag?.public_id && (
                <img
                  src={profile.flag.url}
                  alt='flag'
                  className='w-full h-full object-cover'
                />
              )}
              <div
                className={`bg-${profile.bannerColor} absolute bottom-0 h-1/4 w-full flex items-center justify-start`}
                style={{ width: 1050, height: 150 }}
              >
                <p
                  className={`w-full pt-4 text-center text-${
                    profile.bannerColor === 'flime' ? 'black' : 'white'
                  } uppercase text-hanson font-bold ${
                    profile.firstName?.length >= 10 ? 'text-6xl' : 'text-7xl'
                  }`}
                >
                  {profile.firstName}'s Land
                </p>
              </div>
              {profile.symbols.map((symbol, i) =>
                icons[symbol.icon](
                  `absolute right-0 inset-y-${
                    i === 0 ? 0 : i === 1 ? '1/4' : '2/4'
                  } h-1/4 text-${symbol.color} fill-current`,
                  `${
                    symbol.color === 'flime' ? 'text-black' : 'text-white'
                  } fill-current`,
                  i
                )
              )}
              <SmallLogo className='absolute right-0 h-1/4 z-20 text-white bg-black fill-current inset-y-3/4' />
            </div>
            {/* Profile */}
            <div
              className='htmlToCanvas relative flex justify-center items-center '
              style={{ width: 1288, height: 1278 }}
            >
              {profile.photo?.public_id && (
                <img
                  src={profile.photo.url}
                  alt='profile'
                  className='w-full h-full object-cover'
                />
              )}

              {profile.symbols.map((symbol, i) => (
                <div
                  key={i}
                  className={`absolute bottom-0 left-${
                    i === 0 ? 0 : i === 1 ? '1/3' : '2/3'
                  }`}
                  style={{ width: 430, height: 430 }}
                >
                  <div className='relative'>
                    {icons[symbol.icon](
                      `absolute text-${symbol.color} fill-current`,
                      `${
                        symbol.color === 'flime' ? 'text-black' : 'text-white'
                      } fill-current`,
                      i
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* symbols */}
            {profile.symbols.map((symbol, i) => (
              <div
                key={i}
                className={`htmlToCanvas  relative flex justify-center items-center bg-${symbol.color}`}
                style={{ width: 1288, height: 1278 }}
              >
                <div
                  className='w-full absolute flex justify-center items-center left-0 bottom-0'
                  style={{
                    height: 246,
                  }}
                >
                  <p
                    className={`w-full text-7xl font-bold text-center text-hanson ${
                      symbol.color === 'flime' ? 'text-black' : 'text-white'
                    }`}
                  >
                    {symbol.icon.charAt(0).toUpperCase() +
                      symbol.icon.replace('_', ' ').slice(1)}
                  </p>
                </div>
                <div className='absolute ' style={{ width: 700, height: 700 }}>
                  {icons[symbol.icon](
                    `absolute m-auto inset-0 w-full h-full text-${symbol.color} fill-current`,
                    `${
                      symbol.color === 'flime' ? 'text-black' : 'text-white'
                    } fill-current`,
                    i
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </section>
  )
}

export default MemberProfile
