import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { MailIcon, PencilIcon } from '@heroicons/react/solid'

const ArticleCard = ({ article }) => {
  const history = useHistory()
  return (
    <div className='flex justify-center group py-4 px-2'>
      <div
        onClick={() => history.push(`/admin/newsfeed/id/${article._id}`)}
        className='relative w-80 xl:w-96 h-48 overflow-hidden flex items-center justify-center group cursor-pointer flex-col bg-white shadow-md hover:shadow-lg'
      >
        {
          <div className='flex gap-2 p-2 w-full overflow-x-scroll bg-flime bg-opacity-20'>
            {article.tags.map((tag, i) => (
              <span
                key={tag + i}
                className='text-xs px-2 py-1 bg-black text-gray-200 inline-flex items-center justify-center'
              >
                {tag}
              </span>
            ))}
          </div>
        }
        <div className='flex-grow w-full flex bg-flime bg-opacity-20'>
          <span className='font-bold text-xl px-4 py-2'>
            {article.articleTitle}
          </span>
        </div>
        <div className='w-full flex flex-col'>
          <div className='w-full flex justify-between items-center'>
            <div className='w-full flex justify-start items-center gap-2 p-2 '>
              <PencilIcon className='w-4 h-4 text-gray-700' />
              <span className='bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700'>
                {moment(article.articleLastUpdateDate).format('DD/MM/YYYY')}
              </span>
            </div>
            <div className='w-full flex justify-end items-center gap-2 p-2 '>
              <MailIcon className='w-4 h-4 text-gray-700' />
              <span className='bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700'>
                {article.articleNotifications.length
                  ? moment(
                      article.articleNotifications[
                        article.articleNotifications.length - 1
                      ].date
                    ).format('DD/MM/YYYY')
                  : 'Not sent yet'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
