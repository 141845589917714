import { useState, useEffect, useContext } from "react"
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/outline"
import CloudinaryDropZone from "./Components/CloudinaryDropZone"
import Instructions from "../Widgets/Instructions"
import Flag from "../Widgets/Flag"
import Button from "./Components/Button"
import SignUpContext from "../../../contexts/SignUp"
import { useFormikContext } from "formik"
import Banner from "./Components/Banner"
import { Transition } from "@headlessui/react"

const FlagCreation = () => {
  const { handleBack, handleNext, activeStep, config } =
    useContext(SignUpContext)
  const { values, setFieldValue } = useFormikContext()
  const currentStep = values.role === 'founder' ? 4 : 0
  const [isActive, setIsActive] = useState(activeStep === currentStep)
  const [error, setError] = useState("")

  const [uploadStatus, setUploadStatus] = useState({
    success: false,
    message: "",
  })
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        if (!values.flag?.public_id) setDisplay(true)
      }, 500)
    }
  }, [isActive])

  const next = () => {
    if (values.flag?.public_id) {
      setIsActive(false)
      setTimeout(() => {
        handleNext()
      }, 200)
    } else {
      setError("Please upload a flag picture to proceed")
    }
  }
  const back = () => {
    setIsActive(false)
    setTimeout(() => {
      handleBack()
    }, 200)
  }

  return (
    <div className="max-h-screen w-full flex flex-col items-center overflow-y-scroll">
      <Banner />
      <Instructions
        display={display}
        setDisplay={setDisplay}
        message={<Flag />}
      />
      <Transition
        appear={true}
        show={isActive}
        enter="transform transition-all ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transform transition-all ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="h-full form flex flex-col justify-start items-center  px-6 lg:px-12">
          <h2 className="w-full text-center lg:text-left">Flag picture</h2>
          <div className="w-full flex flex-col flex-grow">
            <div className="flex flex-col justify-center items-center w-full text-grotesk font-bold h-full">
              <p className="lead w-full text-center mb-4 lg:mb-8">
                Create your personal flag for your profile.
              </p>
              <CloudinaryDropZone
                data={values}
                setData={setFieldValue}
                type="flagPicture"
                setUploadStatus={setUploadStatus}
                uploadStatus={uploadStatus}
                config={config}
              />
              <p className="flex paragraph items-center text-center">
                <button
                  type="button"
                  onClick={() => {
                    setDisplay(true)
                  }}
                  className="m-2 rounded-full p-0 w-10 h-10 bg-fblue text-white hover:bg-fblue-400 active:shadow-lg shadow transition ease-in duration-200 focus:outline-none flex justify-center items-center"
                >
                  <InformationCircleIcon className="w-10 h-10" />
                </button>
                Check the help guide for more information on best practices
              </p>
            </div>
            {error && !values.flag?.public_id ? (
              <p className="paragraph text-center text-fred">{error}</p>
            ) : values.flag?.public_id ? (
              <div className="flex flex-col flex-grow justify-center items-center paragraph text-center">
                <p className="flex items-center">
                  <CheckIcon className="h-6 w-6 text-valid" /> Flag created!
                </p>
                <p>If you are happy with the result you may proceed.</p>
              </div>
            ) : null}
            <div className="w-full flex justify-between mt-12 lg:mt-16">
              <Button
                label={"< Back"}
                styled={"back"}
                type={"button"}
                action={back}
              />
              <Button
                label={"Next"}
                styled={"next"}
                type={"button"}
                action={next}
              />
            </div>
            <div className="h-24 w-full"></div>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default FlagCreation
