import logoMdWhite from '../../assets/images/logo_md_white.png'
import arrow from '../../assets/images/arrow.svg'
import symbolVerticalBig from '../../assets/images/symbols_vertical_big.svg'
import logoLgWhite from '../../assets/images/logo_large_white.png'
import founderlandGif from '../../assets/images/founderlandThankyou.gif'
const Thankyou = () => {
  return (
    <div className='v2'>
      <div className=' h-screen flex flex-col justify-end '>
        <div className='flex w-screen justify-end h-4/5 md:h-full relative'>
          <div className='flex flex-col max-w-full justify-evenly'>
            <div className='flex w-full justify-end md:justify-start px-8 lg:px-12'>
              <img
                className='xl:w-64 2xl:w-72 md:w-56 w-20'
                src={arrow}
                alt='Logo'
              />
            </div>
            <h1 className='text-right md:text-left text-6xl px-8 lg:px-12'>
              THANK YOU
            </h1>
            <h2 className='text-left  px-8 lg:px-12'>
              Thank you for your interest in our growing community! We will be
              in touch within a few weeks.
            </h2>
            <div className='flex justify-start pl-24 py-10 mt-4 pr-6 md:mt-10'>
              <a
                href='https://www.founderland.org'
                className='flex justify-start items-center space-x-2 px-12 py-4 bg-fblue font-bold text-2xl text-mono uppercase text-white transition duration-100 hover:bg-fblue-40 '
              >
                RETURN TO HOMEPAGE
              </a>
            </div>
          </div>

          <div className='hidden bg-black items-center justify-center md:flex sm:w-4/12 md:w-2/12 xl:w-1/12'>
            <img
              className='md:h-3/4  md:object-fit '
              src={logoLgWhite}
              alt='Logo'
            />
          </div>

          <div className='self-center md:self-end w-36 lg:w-1/12 relative'>
            <img
              className='h-full md:w-full md:object-fit'
              src={symbolVerticalBig}
              alt='Logo'
            />
          </div>
        </div>

        <div className='z-40 h-1/6 bg-black flex justify-end items-center p-6 md:hidden'>
          <div>
            <img src={logoMdWhite} alt='Logo' />
          </div>
        </div>

        {/* Gif */}
      </div>

      {/* For desktop  */}
    </div>
  )
}

export default Thankyou
