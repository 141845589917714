import { useParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import {
  ClipboardCopyIcon,
  MailOpenIcon,
  TrashIcon,
} from "@heroicons/react/outline"
import Loading from "../Widgets/Loading"
import moment from "moment"
import axios from "axios"
import AdminContext from "../../../contexts/Admin"
import ConfirmModal from "../Widgets/ConfirmModal"
import Confirm from "./Confirm"
import NotifyToken from "./NotifyToken"

const tokenUrl = "/api/users/community/token/"
const styles = {
  sadmin: "border border-green-600",
  admin: "border-fblue",
  user: "border-fpink",
}
const Token = ({ category, categories }) => {
  const { id } = useParams()
  const { config, reload, setCCModal, user, avatarInitials } =
    useContext(AdminContext)
  const [data, setData] = useState({})
  const [copied, setCopied] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  //GET DATA FROM DB WITH resourceId FROM URL
  useEffect(() => {
    axios
      .get(tokenUrl + id, config)
      .then((res) => {
        if (res.data) {
          setData(res.data)
          setLoading(false)
        } else {
          setError("No token found with this ID")
        }
      })
      .catch((err) => {})
  }, [id, reload])

  //COPY TOKEN
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }
  const handleCopy = (textToCopy) => {
    copyTextToClipboard(textToCopy)
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 1500)
      })
      .catch((err) => {})
  }

  return (
    <section className=" flex flex-col w-full xl:w-5/6 2xl:w-4/6 pb-6 shadow-lg border-0">
      <ConfirmModal>
        {removing ? (
          <Confirm data={data} setRemoving={setRemoving} />
        ) : (
          <NotifyToken data={data} setRemoving={setRemoving} />
        )}
      </ConfirmModal>
      {loading ? (
        <Loading />
      ) : error ? (
        error
      ) : (
        <>
          <div className="relative mb-4 md:mb-0 w-full mx-auto ">
            <div className=" w-full p-4 z-10 bg-black">
              <h2 className="text-4xl font-semibold text-gray-100 leading-tight truncate">
                {data.token}
              </h2>
              <div className="flex w-full justify-end items-end">
                <p className="text-white text-xs px-4">Issued by:</p>
                <div className="flex mt-3 ">
                  <div
                    className={`cursor-default flex relative w-10 h-10 justify-center items-center rounded-full text-base text-mono  ${
                      data.issuedBy.avatar
                    } ${styles[String(data.issuedBy.role)]}`}
                  >
                    {avatarInitials(
                      data.issuedBy.firstName,
                      data.issuedBy.lastName
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-3xl px-10 py-2 flex flex-col">
            <div className="=w-full flex space-x-6 p-4">
              <div>
                <p className="text-sm">Issued On:</p>
                <p className="font-semibold text-base">
                  {moment(data.issuedOn).format("D MMMM YYYY")}
                </p>
              </div>
              <div>
                <p className="text-sm">
                  {new Date(data.expiredOn) < new Date()
                    ? "Expired "
                    : "Expires "}
                  On
                </p>
                <p className="font-semibold text-base">
                  {moment(data.expiredOn).format("D MMMM YYYY")}
                </p>
              </div>
            </div>

            <div className="flex  py-2 w-full">
              {data.usedOn ? (
                "Token used ✅"
              ) : new Date(data.expiredOn) > new Date() ? (
                <p className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-green-700 bg-green-100 border border-green-300 bg-opacity-30">
                  Token valid for use
                </p>
              ) : (
                <p className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-red-700 bg-red-100 border border-red-300 bg-opacity-30">
                  Token Expired
                </p>
              )}
            </div>
          </div>
          <div className="max-w-3xl mt-2 px-10 py-2 text-justify text-base text-mono">
            <p className="text-sm">Note:</p>
            <p>{data.notes}</p>
          </div>
          {user.role.includes("admin") && (
            <div className="px-4 pt-6 flex flex-col sm:flex-row-reverse items-center justify-around ">
              <button
                className="flex justify-center items-center px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4"
                onClick={() => {
                  setRemoving(true)
                  setCCModal(true)
                }}
              >
                <TrashIcon className="h-5 w-5" />
                Delete
              </button>
              {!data.usedOn && new Date(data.expiredOn) > new Date() && (
                <>
                  <button
                    className="flex justify-center items-center px-10 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4"
                    onClick={() => {
                      handleCopy(
                        window.location.host + "/fasttrack/" + data.token
                      )
                    }}
                  >
                    {copied ? (
                      <div className="flex justify-center animate-pulse">
                        Copied to clipboard
                      </div>
                    ) : (
                      <>
                        <ClipboardCopyIcon className="h-5 w-5" />
                        Copy to clipboard
                      </>
                    )}
                  </button>
                  <button
                    className="flex justify-center items-center px-10 py-2 w-full shadow-lg sm:w-1/4 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4"
                    onClick={() => {
                      setRemoving(false)
                      setCCModal(true)
                    }}
                  >
                    <MailOpenIcon className="h-5 w-5" />
                    Send by Email
                  </button>
                </>
              )}
            </div>
          )}
        </>
      )}
    </section>
  )
}

export default Token
