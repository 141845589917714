import axios from 'axios'
import { useState, useContext, useEffect } from 'react'
import AdminContext from '../../../contexts/Admin'
import { useParams } from 'react-router'
import Banner from '../Widgets/Banner'

const notifyMember = '/api/users/community/notify/'
const sendEmail = '/api/users/community/sendtoken/'

const NotifyToken = ({ data }) => {
  const [saving, setSaving] = useState(false)
  const { id, category } = useParams()
  const [notification, setNotification] = useState({
    to: '',
    email: '',
    connect: true,
    template: id === 'token' ? 'fasttracknew' : 'fasttrack',
    subject: 'Complete your Founderland Profile!',
    body: '',
    signOff: '',
  })
  const [validEmail, setValidEmail] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const { setCCModal, config } = useContext(AdminContext)
  const isEmailValid = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(
    notification.email
  )
  const checkEmail = () => {
    if (notification.email) {
      return isEmailValid && validEmail
        ? 'border-l-4 border-flime'
        : 'border-l-4 border-fred'
    } else {
      return 'border-l-4 border-gray'
    }
  }

  const save = async () => {
    setSaving(true)
    try {
      if (!notification.to) await Promise.reject('Provide a name')

      if (!validEmail && category === 'token')
        await Promise.reject('Invalid Email or already in use by member')

      const notifyUrl = category === 'token' ? sendEmail : notifyMember
      const notifying = await axios.post(
        notifyUrl + data._id,
        { _id: id, ...notification },
        config
      )
      if (notifying.data.success) {
        setSaving(false)
        setBanner({
          success: 1,
          show: true,
          message: category === 'token' ? 'Email sent! ' : 'Member Notified! ',
        })
        setTimeout(() => {
          setCCModal(false)
        }, 2000)
      } else {
        throw new Error('Sorry, something went wrong while saving')
      }
    } catch (e) {
      setSaving(false)
      setBanner({
        error: 1,
        show: true,
        message: e,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  useEffect(() => {
    //check if email exists
    const checkExistingEamil = async () => {
      if (isEmailValid) {
        const response = await axios.get(
          '/api/users/community/checkprofile/' + notification.email
        )
        response.data.data.isEmailValid
          ? setValidEmail(true)
          : setValidEmail(false)
      }
    }
    checkExistingEamil()
  }, [notification.email])

  return (
    <div className='bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg'>
      <div className='w-full flex justify-center items-center'>
        <Banner message={banner} />
      </div>
      <div className='md:flex w-full px-3'>
        {category === 'id' ? (
          <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
            <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
              Are you sure you want to notify this member with a Fast Track
              token?
            </label>
            <p className='block tracking-wide text-grey-darker text-sm  mb-2'>
              You will be notified by Email once they complete the Fast Track
              process
            </p>
          </div>
        ) : (
          <>
            <div className='flex justify-center items-center w-full px-3'>
              <div className='w-full mb-2 px-2'>
                <label className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'>
                  Send Email To
                </label>
                <div className='w-full flex space-x-4 px-2'>
                  <div className=' w-full flex flex-col items-start justify-between mb-2'>
                    <label className='lg:mx-3 font-bold mb-2'>Name</label>
                    <input
                      type='text'
                      className={`w-full p-2`}
                      value={notification.to}
                      onChange={(e) => {
                        setNotification({
                          ...notification,
                          to: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className=' w-full flex flex-col items-start justify-between mb-2'>
                    <label className='lg:mx-3 font-bold mb-2'>Email</label>
                    <input
                      type='text'
                      className={` ${checkEmail()} w-full p-2`}
                      value={notification.email}
                      onChange={(e) => {
                        setNotification({
                          ...notification,
                          email: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className='flex justify-center items-center w-full px-3'>
                  <div className='w-full mb-2 px-2'>
                    <label className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'>
                      Email preview
                    </label>
                    <div className='w-full max-h-96 overflow-y-auto'>
                      <div className='w-full font-bold py-2'>
                        Subject: Complete your Founderland Profile!
                      </div>
                      <p style={{ fontSize: '10px' }}>
                        <span
                          style={{
                            fontSize: '10px',
                            color: '#666666',
                          }}
                        >
                          Dear {notification.to},
                        </span>
                      </p>
                      <p style={{ fontSize: '10px' }}>
                        <span
                          style={{
                            fontSize: '10px',
                            color: '#666666',
                          }}
                        >
                          We would love to invite you to be a part of the
                          Founderland community. Which is why we have
                          fast-tracked your application.
                        </span>
                      </p>
                      <p style={{ fontSize: '10px' }}>
                        <span
                          style={{
                            fontSize: '10px',
                            color: '#666666',
                          }}
                        >
                          In order for you to be an active member of Founderland
                          we will need you to set up your profile.
                        </span>
                      </p>
                      <p style={{ fontSize: '10px' }}>
                        <span
                          style={{
                            fontSize: '10px',
                            color: '#666666',
                          }}
                        >
                          Follow the link below to get started.
                        </span>
                      </p>
                      <p style={{ fontSize: '10px' }}>
                        <span
                          style={{
                            fontSize: '10px',
                            color: '#666666',
                          }}
                        >
                          {' '}
                          We're excited to welcome you to the fastest growing
                          community of women of colour founders in Europe!
                        </span>
                      </p>
                      <div align='left'>
                        <p
                          style={{
                            fontSize: '10px',
                            lineHeight: '100%',
                          }}
                        >
                          &nbsp;
                        </p>
                        <p
                          style={{
                            boxSizing: 'border-box',
                            display: 'inline-block',
                            textDecoration: 'none',
                            textAlign: 'center',
                            color: '#000000',
                            backgroundColor: '#d7fb03',
                            borderRadius: '1px',
                            width: 'auto',
                            maxWidth: '100%',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            wordWrap: 'break-word',
                          }}
                        >
                          <span
                            style={{
                              display: 'block',
                              padding: '15px 40px',
                              lineHeight: '120%',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '10px',
                              }}
                            >
                              Complete My Profile
                            </span>
                          </span>
                        </p>
                        <p
                          style={{
                            fontSize: '10px',
                            lineHeight: '100%',
                          }}
                        >
                          &nbsp;
                        </p>
                      </div>
                      <div
                        style={{
                          color: '#000000',
                          lineHeight: '100%',
                          textAlign: 'left',
                          wordWrap: 'break-word',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '10px',
                            lineHeight: '100%',
                          }}
                        >
                          <span
                            style={{
                              color: '#888888',
                              fontSize: '10px',
                              lineHeight: '19.6px',
                            }}
                          >
                            <em>
                              <span
                                style={{
                                  fontSize: '8px',
                                }}
                              >
                                This link is valid for 7 days, if you have any
                                trouble in the steps to confirm your
                                registration, don't hesitate to contact us:
                                <a href='mailto:admin@founderland.org?subject=Sign up confirmation'>
                                  admin@founderland.org
                                </a>
                              </span>
                            </em>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
        <button
          className='px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4'
          onClick={() => {
            setCCModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className='px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4'
          onClick={save}
        >
          {saving ? (
            <div className='flex justify-center'>
              <div
                style={{ borderTopColor: 'transparent' }}
                className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
              ></div>
            </div>
          ) : (
            'Confirm'
          )}
        </button>
      </div>
    </div>
  )
}

export default NotifyToken
