import { Transition } from "@headlessui/react"
import { useEffect } from "react"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import SignUpContext from "../../../contexts/SignUp"
import Banner from "./Components/Banner"


const Success = ({ created }) => {
  const { activeStep } = useContext(SignUpContext)
  const currentStep = 6
  const isActive = activeStep === currentStep
  let history = useHistory()

  useEffect(() => {
    if (created)
      setTimeout(() => {
        history.push("/community")
      }, 1500)
  }, [created])

  return (
    <div className="h-screen w-full flex flex-col items-center overflow-y-scroll">
      <Banner />
      <div className="form flex flex-grow flex-col justify-center items-center px-6 lg:px-12 scal">
        <Transition
          appear={true}
          show={isActive}
          enter="transform transition-all ease-in-out duration-500"
          enterFrom="opacity-50 scale-50"
          enterTo="scale-100 opacity-100"
          leave="transform transition-all ease-in-out duration-200"
          leaveFrom="scale-100"
          leaveTo="scale-75"
        >
          <h1 className="text-center">You're in!</h1>
        </Transition>
      </div>
    </div>
  )
}

export default Success
