import { PlusCircleIcon, XIcon } from "@heroicons/react/outline"
import axios from "axios"
import { Fragment, useState, useContext, useEffect } from "react"
import AdminContext from "../../../contexts/Admin"
import { Combobox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"

import Banner from "../Widgets/Banner"
import AssociateCard from "./AssociateCard"

const getInvestors = "/api/users/community/search/investor/"

const AddAssociates = ({ members, firm, updateMembers }) => {
  const [searchResult, setSearchResult] = useState([])
  const [selected, setSelected] = useState()
  const [search, setSearch] = useState("")
  const [banner, setBanner] = useState({ show: false })

  const { setCCModal, config } = useContext(AdminContext)

  useEffect(() => {
    const getInvestorsList = async () => {
      try {
        const { data } = await axios.get(
          getInvestors + (search ? search : "all"),
          config
        )
        setSearchResult([...data])
      } catch (err) {
        setBanner({
          error: 1,
          show: true,
          message: "Something went wrong while searching",
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    }
    getInvestorsList()
  }, [search])

  const setSelectedInvestor = (value) => {
    if (value.firmId) {
      setBanner({
        error: 1,
        show: true,
        message: "Investor already part of " + value.firmId.companyName,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    } else {
      setSelected(value)
    }
  }

  useEffect(() => {
    const existing = members.filter(
      (member) => member.member._id === selected?._id
    )
    setTimeout(() => {
      if (!existing.length) setSelected({})
    }, 5000)
  }, [members])

  return (
    <div className="relative bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg">
      <div className="w-full flex justify-center items-center">
        <Banner message={banner} />
      </div>
      <div className="md:flex w-full px-3">
        <div className="w-full mb-2 px-2 flex flex-col justify-center items-center">
          <label className="block uppercase tracking-wide text-grey-darker text-md font-bold mb-2">
            Add a new associate to this firm
          </label>
          <div className="w-72">
            <Combobox value={search} onChange={setSelectedInvestor}>
              <div className="relative mt-1">
                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                  <Combobox.Input
                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                    displayValue={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <SelectorIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setSearch("")}
                >
                  <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                    {searchResult.length === 0 && search !== "" ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      searchResult.map((investor) => {
                        let alreadySelected = false
                        const existing = members.filter(
                          (member) => member.member._id === investor._id
                        )
                        if (existing.length) alreadySelected = true
                        return (
                          <Combobox.Option
                            key={investor._id}
                            className={({ active }) =>
                              `relative cur ${
                                alreadySelected
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              } select-none py-2 pl-10 pr-4 ${
                                active ? "bg-fblue text-white" : "text-gray-900"
                              }`
                            }
                            value={investor}
                            onClick={(e) => {
                              alreadySelected && e.preventDefaul()
                            }}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={` flex flex-col truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  <p>
                                    {investor.firstName} {investor.lastName}
                                  </p>
                                  <p className="text-xs">{investor.email}</p>
                                </span>
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 `}
                                >
                                  {!alreadySelected && !investor.firmId ? (
                                    <PlusCircleIcon
                                      className={`h-5 w-5 ${
                                        active ? "text-white" : "text-black"
                                      }`}
                                      aria-hidden="true"
                                    />
                                  ) : investor.firmId?._id !== firm._id ? (
                                    <XIcon
                                      className={`h-5 w-5 ${
                                        active ? "text-white" : "text-black"
                                      }`}
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <CheckIcon
                                      className={`h-5 w-5 ${
                                        active ? "text-white" : "text-black"
                                      }`}
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        )
                      })
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
          <div className="w-full flex justify-center items-center py-4">
            <div className="p-2">
              {selected?._id ? (
                <AssociateCard
                  associate={selected}
                  firm={firm}
                  setBanner={setBanner}
                  updateMembers={updateMembers}
                  selected={selected}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-2 px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around ">
        <XIcon
          className="w-6 h-6 hover:text-fred"
          onClick={() => {
            setCCModal(false)
          }}
        />
      </div>
    </div>
  )
}

export default AddAssociates
