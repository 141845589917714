import { useState, useContext, Fragment, useEffect } from "react"
import axios from "axios"
import AdminContext from "../../../contexts/Admin"
import {
  CheckIcon,
  DotsHorizontalIcon,
  XCircleIcon,
} from "@heroicons/react/outline"
import { Menu, Transition } from "@headlessui/react"

const addAssociateUrl = "/api/users/community/firms/associates/update"
const updateAssociateUrl = "/api/users/community/firms/associates/main"

const AssociateCard = ({
  firm,
  associate,
  setBanner,
  updateMembers,
  selected,
  main,
}) => {
  const [updating, setUpdating] = useState(false)
  const [updateComplete, setUpdateComplete] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const { config, reload, setReload } = useContext(AdminContext)

  const addAssociate = async (selected) => {
    setUpdating(true)
    try {
      const updateData = { firm: firm, investor: selected }
      const { data } = await axios.put(addAssociateUrl, updateData, config)
      if (data.success) {
        setUpdateComplete(true)
        setUpdateSuccess(true)
        updateMembers(data.firm.members)
        setUpdating(false)
        setTimeout(() => {
          setUpdateComplete(false)
          setUpdateSuccess(false)
        }, 5000)
      } else {
        setUpdateComplete(true)
        setUpdateSuccess(false)
        setUpdating(false)
        throw new Error("Unable to add investor")
      }
    } catch (err) {
      setBanner({
        error: 1,
        show: true,
        message: err?.message ? err.message : "Sorry, something went wrong",
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  const setMainAssociate = async (selected) => {
    setUpdating(true)
    try {
      const updateData = { firm: firm, investor: selected }
      const { data } = await axios.put(updateAssociateUrl, updateData, config)
      if (data.success) {
        setUpdateComplete(true)
        setUpdateSuccess(true)
        updateMembers(data.firm.members)
        setUpdating(false)
        setTimeout(() => {
          setUpdateComplete(false)
          setUpdateSuccess(false)
        }, 5000)
      } else {
        setUpdateComplete(true)
        setUpdateSuccess(false)
        setUpdating(false)
        throw new Error("Unable to set as main")
      }
    } catch (err) {
      setBanner({
        error: 1,
        show: true,
        message: err?.message ? err.message : "Sorry, something went wrong",
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  useEffect(() => {
    if (selected) {
      addAssociate(selected)
    }
  }, [])

  return (
    <div
      className={`overflow-visible w-full px-2 py-3 sm:py-4 shadow border-b ${
        main ? "border-fred" : "border-fblue-100"
      }`}
    >
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          {associate.photo?.url && (
            <img
              className="w-8 h-8 rounded-full"
              src={associate.photo.url}
              alt="profile"
            />
          )}
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex space-x-2">
            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
              {associate.firstName} {associate.lastName}
            </p>
            {main ? (
              <span className="text-sm bg-fred font-thin text-mono text-white rounded-full px-2">
                main
              </span>
            ) : null}
          </div>

          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
            {associate.email}
          </p>
        </div>
        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
          {updating ? (
            <div className="flex justify-center">
              <div
                style={{ borderTopColor: "transparent" }}
                className="w-4 h-4 border-4 border-black border-dotted rounded-full animate-spin"
              ></div>
            </div>
          ) : updateSuccess ? (
            <CheckIcon className="h-4 w-4 text-green-600 animate-pulse" />
          ) : updateComplete && !updateSuccess ? (
            <XCircleIcon className="h-4 w-4 text-red-600 animate-pulse" />
          ) : (
            <Menu as="div" className="relative ">
              <Menu.Button className="group flex items-center space-x-3 relative outline-none">
                <DotsHorizontalIcon className="z-40 h-4 w-4 hover:text-fblue" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-50 outline-none w-40 bg-white shadow right-0 -top-0">
                  <Menu.Item
                    as="button"
                    className=" z-50 items-center w-full px-2 py-1 text-sm text-gray-800 hover:bg-flime"
                    onClick={() => setMainAssociate(associate)}
                  >
                    Set as main
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    className="w-full z-50 px-2 py-1 text-sm text-gray-800 hover:bg-fred hover:text-white"
                    onClick={() => addAssociate(associate)}
                  >
                    Remove
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>
    </div>
  )
}

export default AssociateCard
