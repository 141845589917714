import { useState, useEffect, useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import ListWidget from '../Widgets/ListWidget'
import Loading from '../Widgets/Loading'
import axios from 'axios'
import moment from 'moment'

const styles = {
  new: 'bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs',
  pending: 'bg-yellow-200 text-yellow-600 py-1 px-3 rounded-full text-xs',
  reviewed: 'bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs',
  founder: 'bg-fred bg-opacity-50 text-red-900 py-1 px-3 rounded-full text-xs',
  investor:
    'bg-fblue bg-opacity-50 text-blue-900 py-1 px-3 rounded-full text-xs',
  ally: 'bg-flime bg-opacity-50 py-1 px-3 rounded-full text-xs',
  sadmin: 'bg-fred bg-opacity-50 py-1 px-3 rounded-full text-xs',
  admin: 'bg-fblue bg-opacity-50 py-1 px-3 rounded-full text-xs',
  user: 'bg-fpink bg-opacity-50 py-1 px-3 rounded-full text-xs',
}

const MembersList = ({ role }) => {
  const [data, setData] = useState({
    header: [
      {
        title: 'Name',
        key: 'name',
        style: 'text-left text-sm',
        showFilter: true,
      },
      {
        title: 'Email',
        key: 'email',
        style: 'text-center text-sm',
        showFilter: true,
      },
      {
        title: 'Location',
        key: 'location',
        style: 'text-center text-sm',
        showFilter: true,
      },
      {
        title: '',
        key: 'flagSetup',
        style: 'text-center text-sm',
        showFilter: false,
      },
      {
        title: 'Added on',
        key: 'createdOn',
        style: 'text-center text-sm',
        showFilter: false,
      },
      { title: '', key: '-', style: 'text-center text-sm', showFilter: false },
    ],
    data: [],
  })
  const [loading, setLoading] = useState(true)
  const { config, reload, statisticsFilter, setSatisticsFilter } =
    useContext(AdminContext)
  const membersAPI = '/api/users/community/map/'
  const [filter, setFilter] = useState([
    { key: 'name', search: '', show: false, type: 'text' },
    { key: 'email', search: '', show: false, type: 'text' },
    { key: 'location', search: '', show: false, type: 'text' },
  ])

  //FETCH DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const lastWeek = new Date()
        lastWeek.setDate(lastWeek.getDate() - 7)
        const lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)

        const result = await axios.get(membersAPI + role, config)
        const dataSet = result.data.data

        let filteredDataSet
        if (statisticsFilter.search === 'Notified')
          filteredDataSet = dataSet.filter(
            (member) => member.notified && !member.confirmed
          )
        if (statisticsFilter.search === 'Flag Setup')
          filteredDataSet = dataSet.filter((member) => member.flag?.public_id)
        if (statisticsFilter.search === 'Not Notified')
          filteredDataSet = dataSet.filter((member) => member.notified === null)
        if (statisticsFilter.search === 'Signed Up')
          filteredDataSet = dataSet.filter(
            (member) => member.confirmed && !member.flag?.public_id
          )
        if (statisticsFilter.search === 'Notified this week')
          filteredDataSet = dataSet.filter(
            (member) =>
              member.notified &&
              new Date(member.notified).valueOf() >= lastWeek.valueOf()
          )
        if (statisticsFilter.search === 'Unconfirmed >1 month')
          filteredDataSet = dataSet.filter(
            (member) =>
              !member.confirmed &&
              member.notified &&
              new Date(member.notified).valueOf() <= lastMonth.valueOf()
          )
        if (statisticsFilter.search === '') filteredDataSet = [...dataSet]
        filteredDataSet.forEach((element) => {
          if (element.created) {
            element.createdOn = moment(element.created).format('DD/M/YYYY')
          }
          if (element.notified) {
            element.notifiedOn = moment(element.notified).format('DD/M/YYYY')
          }
          if (element.confirmed) {
            element.confirmedOn = moment(element.confirmed).format('DD/M/YYYY')
          }
          element.name = element.firstName + ' ' + element.lastName
          element.location = element.city.length ? element.city : ''
          element.location += element.country.length
            ? ', ' + element.country
            : ''
        })
        let filteredData = [...filteredDataSet].sort((a, b) =>
          a.firstName.localeCompare(b.firstName)
        )
        //add boolean property of flag setup if flag exists
        filteredData.forEach((element) => {
          element.flagSetup =
            element.role === 'founder'
              ? element.flag?.public_id
                ? true
                : false
              : element.photo?.public_id
              ? true
              : false
        })

        filter.forEach(
          (term) =>
            (filteredData = [
              ...filteredData.filter((item) =>
                item[term.key].toLowerCase().includes(term.search.toLowerCase())
              ),
            ])
        )
        setData({ ...data, data: [...filteredData] })
        setLoading(false)
      } catch (e) {}
    }
    fetchData()
  }, [reload, role, filter, statisticsFilter])

  useEffect(() => {
    setLoading(true)
  }, [role])

  return loading ? (
    <Loading />
  ) : (
    <ListWidget
      title=''
      data={data}
      styles={styles}
      showing={10}
      filter={filter}
      setFilter={setFilter}
      extraFilter={statisticsFilter}
      setExtraFilter={setSatisticsFilter}
    />
  )
}

export default MembersList
