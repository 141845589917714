import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import plugins from 'suneditor/src/plugins'

const colors = [
  '#000000',
  '#0063e2',
  '#f6331c',
  '#ee93b5',
  '#d7fb03',
  '#ffffff',
  '#ffffff',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#000000',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
  '#222222',
]

const template = `
<link href="https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Raleway&family=Roboto&family=Rubik&family=Space+Grotesk&family=Space+Mono&display=swap" rel="stylesheet">
  <style type="text/css" scoped>
  @font-face {
    font-family: 'Hanson';
    src: url('Hanson-Bold.ttf') format('truetype');
}
  body{margin:0; padding:0;}
  .main {width:auto; max-width:1080px; margin:auto;}
  .flex{display:flex;}
  .flex-col{flex-direction:column;}
  .w-full{width:100%;}
  .items-center{align-items:center;}
  .justify-center{justify-content:center;}
  .fpink {background-color: #FDA2C5;}
  .fblue {background-color: #0063e2;}
  .padContent {padding: 2rem 3rem;}
  .p-4{padding:1rem;}
  .py-3{padding-top:.75rem;padding-bottom:.75rem;}
  .space-x-2{--space-x-reverse:0;margin-right:calc(.5rem * var(--space-x-reverse));margin-left:calc(.5rem * calc(1 - var(--space-x-reverse)));}
  .text-white{color:#fff;}
  .text-center{text-align:center;}
  .text-grotesk {
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  .text-mono {
    font-family: "Space Mono", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .text-hanson {
    font-family: "Hanson";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .border-t-white{border-color: #fff;border-top-width:1px;}
  .mx-auto{margin-left:auto;margin-right:auto;}
  .py-4{padding-top:1rem;padding-bottom:1rem;}
  .w-5\/6{width:83.333333%;}
  .clearLink{ text-decoration: none; }   
  </style>
<div class="flex flex-col" style="place-items: center; padding: 0rem 10rem;">

<div class="w-full fblue" style="display:flex;justify-content:center;">
          <img src="https://pbs.twimg.com/profile_banners/1396067735612891141/1625879788/1500x500" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 600px;" width="600"/>
</div>
<div class="flex w-full">
    <div class="w-full padContent">
      {{contents}}
    </div>
</div>
<div class="w-full" style="justify-content: center; align-content: center; display: flex;">
  <div class="w-full fblue p-4 flex-col justify-center items-center" style="display: flex;">
    <div class="w-full py-3" style="display: flex; justify-content: center;">
      <a
      href="https://www.linkedin.com/company/founderland"
      title="LinkedIn"
      target="_blank"
      rel="noreferrer"
      style="margin: 1rem 1rem;"
      >
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="32" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important;enable-background:new 0 0 291.319 291.319;" viewbox="0 0 291.319 291.319" xml:space="preserve">
      <g>
      <path style="fill:#FFFFFF;" d="M145.659,0c80.45,0,145.66,65.219,145.66,145.66s-65.21,145.659-145.66,145.659S0,226.1,0,145.66
      S65.21,0,145.659,0z"/>
      <path style="fill:#0063e2;" d="M82.079,200.136h27.275v-90.91H82.079V200.136z M188.338,106.077
      c-13.237,0-25.081,4.834-33.483,15.504v-12.654H127.48v91.21h27.375v-49.324c0-10.424,9.55-20.593,21.512-20.593
      s14.912,10.169,14.912,20.338v49.57h27.275v-51.6C218.553,112.686,201.584,106.077,188.338,106.077z M95.589,100.141
      c7.538,0,13.656-6.118,13.656-13.656S103.127,72.83,95.589,72.83s-13.656,6.118-13.656,13.656S88.051,100.141,95.589,100.141z"/>
      </g>
      </svg>     
      </a>
      <a
      href="https://www.instagram.com/founderland_/"
      title="Instagram"
      target="_blank"
      rel="noreferrer"
      style="margin: 1rem 1rem;"
      >
      <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="32" style="color: #fff;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important" viewbox="0 0 260 260" enable-background="new 0 0 260 260" xml:space="preserve" color="white">
      <path stroke="currentcolor" fill="currentcolor" d="M164.58,64H95.42C78.1,64,64,78.1,64,95.42v69.16C64,181.9,78.1,196,95.42,196h69.16c17.32,0,31.42-14.1,31.42-31.42V95.42
      C196,78.1,181.9,64,164.58,64z M130,171.1c-22.66,0-41.1-18.44-41.1-41.1s18.44-41.1,41.1-41.1s41.1,18.44,41.1,41.1
      S152.66,171.1,130,171.1z M172.22,97.3c-5.3,0-9.6-4.3-9.6-9.61c0-5.3,4.3-9.6,9.6-9.6c5.31,0,9.61,4.3,9.61,9.6
      C181.83,93,177.53,97.3,172.22,97.3z M130,102.9c-14.94,0-27.1,12.16-27.1,27.1s12.16,27.1,27.1,27.1s27.1-12.16,27.1-27.1
      S144.94,102.9,130,102.9z M130,2C59.31,2,2,59.31,2,130s57.31,128,128,128s128-57.31,128-128S200.69,2,130,2z M210,164.58
      c0,25.04-20.38,45.42-45.42,45.42H95.42C70.38,210,50,189.62,50,164.58V95.42C50,70.38,70.38,50,95.42,50h69.16
      C189.62,50,210,70.38,210,95.42V164.58z"/>
      </svg>                </a>
      <a
      href="https://twitter.com/founderland_/"
      title="Twitter"
      target="_blank"
      rel="noreferrer"
      style="margin: 1rem 1rem;"
      >
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="32" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important;enable-background:new 0 0 112.197 112.197;" viewbox="0 0 112.197 112.197" xml:space="preserve">
      <g>
      <circle style="fill:#FFFFFF;" cx="56.099" cy="56.098" r="56.098"/>
      <g>
      <path style="fill:#0063e2;" d="M90.461,40.316c-2.404,1.066-4.99,1.787-7.702,2.109c2.769-1.659,4.894-4.284,5.897-7.417
      c-2.591,1.537-5.462,2.652-8.515,3.253c-2.446-2.605-5.931-4.233-9.79-4.233c-7.404,0-13.409,6.005-13.409,13.409
      c0,1.051,0.119,2.074,0.349,3.056c-11.144-0.559-21.025-5.897-27.639-14.012c-1.154,1.98-1.816,4.285-1.816,6.742
      c0,4.651,2.369,8.757,5.965,11.161c-2.197-0.069-4.266-0.672-6.073-1.679c-0.001,0.057-0.001,0.114-0.001,0.17
      c0,6.497,4.624,11.916,10.757,13.147c-1.124,0.308-2.311,0.471-3.532,0.471c-0.866,0-1.705-0.083-2.523-0.239
      c1.706,5.326,6.657,9.203,12.526,9.312c-4.59,3.597-10.371,5.74-16.655,5.74c-1.08,0-2.15-0.063-3.197-0.188
      c5.931,3.806,12.981,6.025,20.553,6.025c24.664,0,38.152-20.432,38.152-38.153c0-0.581-0.013-1.16-0.039-1.734
      C86.391,45.366,88.664,43.005,90.461,40.316L90.461,40.316z"/>
      </g>
      </g>
      </svg>  
      </a>
    </div>
    <div class="w-full text-white text-grotesk text-center" style="padding: 0rem 0rem 1rem 0rem;>
      <a
      style="color:#FFFFFF;"
      class="clearLink"
      rel="noreferrer"
      href="https://www.founderland.org"
      target="_blank"
      >
      www.founderland.org
      </a>
      &copy; 2022 Founderland
    </div>
    <div class="w-5/6 border-t-white">&nbsp;</div>
  </div>
</div>
</div>`

const SunEditorBox = ({
  articleContent,
  onEditorChange,
  config,
  uploadUrl,
  disable,
  hideToolbar,
  height,
}) => {
  return (
    <div className='w-full '>
      <SunEditor
        onChange={onEditorChange}
        width='100%'
        disable={disable}
        showPathLabel={false}
        hideToolbar={hideToolbar}
        plugins={plugins}
        height={height}
        className='w-full border-0'
        setContents={articleContent}
        setOptions={{
          value: articleContent,
          previewTemplate: template,
          mode: 'classic',
          font: [
            'Space Mono',
            'Space Grotesk',
            'Hanson',
            'Roboto',
            'Arial',
            'tahoma',
          ],
          colorList: [[...colors], [...colors]],
          linkTargetNewWindow: true,
          imageUploadHeader: config,
          imageUploadUrl: uploadUrl,
          placeholder: 'Start writing something here...',
          buttonList: [
            [
              'undo',
              'redo',
              'font',
              'fontSize',
              'formatBlock',
              'paragraphStyle',
              'blockquote',
              'bold',
              'underline',
              'italic',
              'strike',
              'fontColor',
              'hiliteColor',
              'textStyle',
              'removeFormat',
              'outdent',
              'indent',
              'align',
              'horizontalRule',
              'list',
              'lineHeight',
              'table',
              'link',
              'image',
              'fullScreen',
              'showBlocks',
              'codeView',
              'preview',
            ],
          ],
          resizingBar: false,
          charCounter: true,
          charCounterType: 'char',
          charCounterLabel: 'Characters',
          charCounterMax: 20000,
        }}
      />
    </div>
  )
}

export default SunEditorBox
