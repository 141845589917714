import axios from "axios"
import { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router"

import AdminContext from "../../../contexts/Admin"

import Banner from "../Widgets/Banner"
import DropzoneProfilePicture from "../Widgets/DropZoneProfilePicture"

const updateUrl = "/api/users/community/firms/update"

const EditPicture = ({ data, setEditingLogo, setProfile }) => {
  const history = useHistory()

  const [saving, setSaving] = useState(false)
  const [photo, setPhoto] = useState({})
  const { setCCModal, config, reload, setReload } = useContext(AdminContext)
  const [uploadStatus, setUploadStatus] = useState({
    success: false,
    message: "",
  })
  const [banner, setBanner] = useState({ show: false })

  useEffect(() => {
    if (uploadStatus.success) {
      setBanner({
        success: 1,
        show: true,
        message: `Photo uploaded`,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 2000)
    } else if (uploadStatus.message !== "") {
      setBanner({
        success: 0,
        show: true,
        message: uploadStatus.message,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 2000)
    }
  }, [uploadStatus])

  const save = async () => {
    setSaving(true)
    try {
      const updateData = { _id: data._id, logo: { ...photo.photo } }
      const update = await axios.put(updateUrl, updateData, config)
      if (update.data.success) {
        setSaving(false)
        setBanner({
          success: 1,
          show: true,
          message: "Picture updated! Redirecting..",
        })
        setTimeout(() => {
          setCCModal(false)
          setEditingLogo(false)
          setProfile({ ...data, ...update.data.firm })
        }, 2000)
      } else {
        throw new Error("Sorry, something went wrong while saving")
      }
    } catch (e) {
      setSaving(false)
      setBanner({
        error: 1,
        show: true,
        message: e.message,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }
  return (
    <div className="bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg">
      <div className="w-full flex justify-center items-center">
        <Banner message={banner} />
      </div>
      <div className=" w-full px-3">
        <div className="w-full mb-2 px-2 flex flex-col justify-center items-center">
          <label className="block uppercase tracking-wide text-grey-darker text-md font-bold mb-2">
            Upload Logo
          </label>
        </div>
        <DropzoneProfilePicture
          classes={`appearance-none outline-none outline-none block w-full border-2 border-gray-300 border-black border-dotted  py-3 px-4 mb-3`}
          data={photo}
          setData={setPhoto}
          type="profilePicture"
          folder="events"
          config={config}
          setUploadStatus={setUploadStatus}
          field={"logo"}
        />
      </div>
      <div className="px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around ">
        <button
          className="px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4"
          onClick={() => {
            setCCModal(false)
            setEditingLogo(false)
          }}
        >
          Cancel
        </button>
        <button
          className="px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4"
          onClick={save}
        >
          {saving ? (
            <div className="flex justify-center">
              <div
                style={{ borderTopColor: "transparent" }}
                className="w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin"
              ></div>
            </div>
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  )
}

export default EditPicture
