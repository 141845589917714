import axios from "axios"
import { useState, useContext } from "react"
import AdminContext from "../../../contexts/Admin"

import Banner from "../Widgets/Banner"

const addTokenUrl = "/api/users/community/tokens/add"

const AddToken = () => {
  const [saving, setSaving] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const [note, setNote] = useState("")
  const { setCCModal, config, reload, setReload } = useContext(AdminContext)

  //COPY TOKEN
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }
  const handleCopy = (textToCopy) => {
    copyTextToClipboard(textToCopy)
  }

  const save = async () => {
    setSaving(true)
    try {
      const created = await axios.post(addTokenUrl, { note: note }, config)
      if (created.data.success) {
        setSaving(false)
        handleCopy(window.location.host + "/fasttrack/" + created.data.token)
        setBanner({
          success: 1,
          show: true,
          message: "Token created! Link copied to Clipboard!",
        })
        setTimeout(() => {
          setReload(reload + 1)
          setCCModal(false)
        }, 3000)
      } else {
        throw new Error("Sorry, something went wrong while saving")
      }
    } catch (e) {
      setSaving(false)
      setBanner({
        error: 1,
        show: true,
        message: e.message,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }
  return (
    <div className="bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg">
      <div className="w-full flex justify-center items-center over">
        <Banner message={banner} />
      </div>
      <div className="md:flex w-full px-3">
        <div className="w-full mb-2 px-2 flex flex-col justify-center items-center">
          <p className="block uppercase tracking-wide text-grey-darker text-md font-bold mb-2 text-center">
            A new sign up token for Founder admission will be issued!
          </p>
          <p>🗓 &nbsp;&nbsp;The link will be valid for 7 days!</p>
          <p className="block w-full text-left uppercase tracking-wide text-grey-darker text-md font-bold mb-2 mt-2">
            add a note:
          </p>
          <textarea
            type="text"
            className="border px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white  text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            rows="3"
            placeholder="Write a note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className="px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around ">
        <button
          className="px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4"
          onClick={() => {
            setCCModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className="px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4"
          onClick={save}
        >
          {saving ? (
            <div className="flex justify-center">
              <div
                style={{ borderTopColor: "transparent" }}
                className="w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin"
              ></div>
            </div>
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  )
}

export default AddToken
