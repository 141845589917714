import { ReactComponent as LogoLines } from '../../../assets/line.svg'
import { ReactComponent as SmallLogo } from '../../../assets/small.svg'

const Maintenance = () => {
  return (
    <div className='flex h-screen justify-center items-center w-full '>
      <div className='flex bg-white shadow-lg max-w-2xl md:w-5/6'>
        <div className={`relative hidden md:block md:w-1/2 bg-fred`}>
          <SmallLogo className='absolute bottom-0 h-20 w-20 text-white fill-current' />
        </div>
        <div className='w-full p-8 md:w-1/2'>
          <div className='flex justify-center'>
            <LogoLines className='w-full' />
          </div>
          <div className='mt-4 flex items-center justify-between'>
            <span className='border-b w-1/5 lg:w-1/4'></span>
            <p className='text-grotesk text-center text-blue-500 uppercase'>
              Community
            </p>
            <span className='border-b w-1/5 lg:w-1/4'></span>
          </div>
          <div className='flex flex-col'>
            <h1 className='text-lg text-fblue text-center my-4'>
              Platform under Maintenance
            </h1>
            <p className='text-center text-sm mb-4'>
              We are currently updating our service and adding some cool new
              features. We will be back shortly. Thank you for your patience!
            </p>
            <p className=' my-4 text-sm font-bold'>Founderland Team!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Maintenance
