import axios from "axios"
import { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router"
import AdminContext from "../../../contexts/Admin"
import Banner from "../Widgets/Banner"
import SelectOption from "../Widgets/SelectOption"

const addFirmUrl = "/api/users/community/firms/add"
const updateFirmUrl = "/api/users/community/firms/update"

const AddFirm = ({ firm, edit, setEdit }) => {
  const history = useHistory()

  const [data, setData] = useState({
    _id: null,
    companyName: "",
    companyBio: "",
    companyThesis: "",
    companyLink: "",
    city: "",
    country: "",
    geoLocation: [],
    investingIn: [],
    stagesIn: [],
    socialmedia: {
      linkedin: "",
      instagram: "",
      twitter: "",
    },
    connect: false,
    template: "investor",
    subject: "Welcome to Founderland!",
    body: "",
    signOff: "",
  })
  const [saving, setSaving] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const [countryList, setCountryList] = useState([])
  const [cityList, setCityList] = useState([])
  const [selectedCountry, setSelectedCounty] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [investingIns, setInvestingIns] = useState([
    "SaaS/Enterprise Software",
    "Mobility",
    "Sustainability/Impact investment",
    "HealthTech",
    "DTC",
    "E-comm/Marketplaces",
    "IoT",
    "FoodTech",
    "Gaming/Entertainment",
    "Engineering/DeepTech/AI",
    "FinTech",
    "EdTech",
  ])
  const [stagesIns, setStagesIns] = useState([
    "Pre-Seed",
    "Seed",
    "Series A",
    "Series B and Beyond",
  ])
  const { config, reload, setReload, isLink, pageTop, scrollUp } =
    useContext(AdminContext)

  //EDIT
  useEffect(() => {
    if (edit) {
      scrollUp()
      setData((prev) => ({
        ...prev,
        ...firm,
      }))
    }
  }, [edit])

  const configuration = {
    headers: {
      "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRIES,
      "Content-Type": "application/json",
    },
  }

  const save = async () => {
    setSaving(true)
    scrollUp()
    try {
      if (!data.companyName.length)
        await Promise.reject("Company name is mandatory")
      if (!isLink(data.companyLink)) {
        await Promise.reject("A valid company link is required")
      }
      if (!isLink(data.socialmedia.linkedin)) {
        await Promise.reject("A valid Linkedin link is required")
      }
      if (!data.investingIn.length)
        await Promise.reject("Select at least one sector")
      if (!data.stagesIn.length)
        await Promise.reject("Select at least one stage")
      let response
      if (edit) {
        response = await axios.put(updateFirmUrl, data, config)
      } else {
        response = await axios.post(addFirmUrl, data, config)
      }
      if (response.data.success) {
        setSaving(false)
        setBanner({
          success: 1,
          show: true,
          message: "Firm saved! Redirecting..",
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
          setEdit(false)
          history.push("/admin/members/firm/" + response.data.firm._id)
        }, 2000)
      }
    } catch (err) {
      if (err?.response?.status) {
        setSaving(false)
        setBanner({
          error: 1,
          show: true,
          message: err.response.data.message,
        })
      } else {
        setSaving(false)
        setBanner({
          error: 1,
          show: true,
          message: err,
        })
      }
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }
  const setChoices = (field, value) => {
    if (field === "stagesIn" && !stagesIns.some((stage) => stage === value)) {
      setStagesIns([...stagesIns, value])
    } else if (
      field === "investingIn" &&
      !investingIns.some((sector) => sector === value)
    ) {
      setInvestingIns([...investingIns, value])
    }
    let currentChoices = [...data[field]]
    if (currentChoices.some((choice) => choice === value)) {
      currentChoices = currentChoices.filter((choice) => choice !== value)
    } else {
      currentChoices.push(value)
    }
    setData((prev) => ({ ...prev, [field]: [...currentChoices] }))
  }

  // Country and city
  // get all the cities
  useEffect(() => {
    axios
      .get(`https://api.countrystatecity.in/v1/countries`, configuration)
      .then((res) => {
        setCountryList(res.data)
      })
      .catch((e) => {})
  }, [])
  useEffect(() => {
    // check if the country entered is in our list
    setSelectedCounty(
      countryList.find((country) => country.name === data.country)
    )

    // get list of cities in the selected country
    if (selectedCountry) {
      axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${selectedCountry?.iso2}/cities`,
          configuration
        )
        .then((res) => {
          setCityList(res.data)
        })
        .catch((e) => {})
    }
  }, [data.country, selectedCountry, countryList])
  useEffect(() => {
    // check if the city entered is in our list
    setSelectedCity(cityList.find((city) => city.name === data.city))

    // get city coordinates
    if (selectedCity && data.city) {
      axios
        .get(
          `https://api.openweathermap.org/geo/1.0/direct?q=${data.city},${selectedCountry?.iso2}&limit=1&appid=${process.env.REACT_APP_OPEN_WEATHER}`
        )
        .then(({ data: [location] }) => {
          setData({
            ...data,
            geoLocation: { lat: location?.lat, lng: location?.lon },
          })
        })
        .catch((e) => {})
    }
  }, [data.city, selectedCity, cityList])
  const formatValue = (value) => {
    const newValue = value.trimStart()
    return newValue.replace(value[0], value[0]?.toUpperCase())
  }
  const checkCountry = () => {
    if (data.country) {
      return selectedCountry
        ? "border-l-4 border-flime"
        : "border-l-4 border-fred"
    } else {
      return "border-l-4 border-gray"
    }
  }
  const checkCity = () => {
    if (data.city) {
      return selectedCity ? "border-l-4 border-flime" : "border-l-4 border-fred"
    } else {
      return "border-l-4 border-gray"
    }
  }
  return (
    <div
      className="bg-white pt-6 pb-4 flex flex-col items-center justify-center w-full mx-auto overflow-auto"
      ref={pageTop}
    >
      <div className="w-full flex items-center justify-center z-20">
        <Banner message={banner} />
      </div>
      <div className="w-full uppercase font-bold tracking-wider text-xl flex items-center justify-center mb-4">
        {edit ? "Editing" : "Add new"} Firm
      </div>
      <div className="md:flex w-full px-3">
        <div className="w-full md:w-1/2 mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            Company Name
          </label>
          <input
            className={`${
              data.companyName === ""
                ? ""
                : data.companyName.length <= 1
                ? "border-l-4 border-fred"
                : "border-l-4 border-flime"
            } appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3`}
            type="text"
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                companyName: e.target.value,
              }))
            }
            value={data.companyName}
            autoComplete="off"
          />
        </div>
        <div className="w-full md:w-1/2 mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            Website
          </label>
          <input
            className={`${
              data.companyLink === ""
                ? ""
                : !isLink(data.companyLink)
                ? "border-l-4 border-fred"
                : "border-l-4 border-flime"
            }  appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3`}
            type="text"
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                companyLink: e.target.value,
              }))
            }
            value={data.companyLink}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="w-full px-3">
        <div className="w-full mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            Company Bio
          </label>
          <textarea
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3"
            type="text"
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                companyBio: e.target.value,
              }))
            }}
            value={data.companyBio}
          ></textarea>
        </div>
      </div>
      <div className="w-full px-3">
        <div className="w-full mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            Investment Thesis
          </label>
          <textarea
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3"
            type="text"
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                companyThesis: e.target.value,
              }))
            }}
            value={data.companyThesis}
          ></textarea>
        </div>
      </div>
      <div className="md:flex w-full px-3">
        <div className="w-full md:w-1/2 mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            Country
          </label>
          <input
            className={
              checkCountry() +
              " appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3"
            }
            type="text"
            list="countries"
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                country: formatValue(e.target.value),
              }))
            }}
            value={data.country}
          />
          <datalist id="countries">
            {countryList.length > 0 &&
              countryList
                .filter((country) => country.name.startsWith(data.country))
                .splice(0, 10)
                .map((country, i) => <option key={i}>{country.name}</option>)}
          </datalist>
        </div>

        {/* City */}
        <div className="w-full md:w-1/2 mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            City
          </label>
          <input
            className={
              checkCity() +
              " appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-3"
            }
            type="text"
            list="cities"
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                city: formatValue(e.target.value),
              }))
            }}
            value={data.city}
          />
          <datalist id="cities">
            {cityList.length > 0 &&
              cityList
                .filter((city) => city.name.startsWith(data.city))
                .splice(0, 10)
                .map((city, i) => <option key={i}>{city.name}</option>)}
          </datalist>
        </div>
      </div>
      <div className=" w-full px-3">
        <div className="w-full mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            LinkedIn
          </label>
          <input
            className={`${
              data.socialmedia.linkedin === ""
                ? ""
                : !isLink(data.socialmedia.linkedin)
                ? "border-l-4 border-fred"
                : "border-l-4 border-flime"
            } appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3`}
            type="text"
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                socialmedia: {
                  ...data.socialmedia,
                  linkedin: e.target.value,
                },
              }))
            }}
            value={data.socialmedia.linkedin}
          />
        </div>
      </div>
      <div className="md:flex w-full px-3">
        <div className="w-full md:w-1/2 mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            Sector
          </label>
          <div className="w-full">
            <SelectOption
              optionsTitle={
                data.investingIn.length === 1
                  ? data.investingIn[0]
                  : data.investingIn.length > 1
                  ? `${data.investingIn.length} selected`
                  : "Select the business areas"
              }
              options={investingIns}
              choices={data.investingIn}
              setChoices={setChoices}
              other={true}
              field={"investingIn"}
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 mb-2 px-2">
          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            Stages
          </label>
          <div className="w-full">
            <SelectOption
              optionsTitle={
                data.stagesIn.length === 1
                  ? data.stagesIn[0]
                  : data.stagesIn.length > 1
                  ? `${data.stagesIn.length} selected`
                  : "Select the stages"
              }
              options={stagesIns}
              choices={data.stagesIn}
              setChoices={setChoices}
              other={true}
              field={"stagesIn"}
            />
          </div>
        </div>
      </div>
      <div className="w-full px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around ">
        <button
          className="px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4"
          onClick={() => {
            edit ? setEdit(false) : history.goBack()
          }}
        >
          Cancel
        </button>
        <button
          className="px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4"
          onClick={save}
        >
          {saving ? (
            <div className="flex justify-center">
              <div
                style={{ borderTopColor: "transparent" }}
                className="w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin"
              ></div>
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  )
}

export default AddFirm
