import { Transition } from "@headlessui/react"
import { useFormikContext } from "formik"
import { useState, useContext, useEffect } from "react"
import axios from "axios"
import SignUpContext from "../../../contexts/SignUp"
import Banner from "./Components/Banner"
import Button from "./Components/Button"
import ListInput from "./Components/ListInput"
import TextArea from "./Components/TextArea"
import TextInput from "./Components/TextInput"

const formFields = [
  "firstName",
  "lastName",
  "country",
  "city",
  "pronouns",
  "companyName",
  "title",
  "businessArea",
  "industry",
  "companyLink",
  "bio",
  "companyBio",
  "socialmedia.linkedin",
  "socialmedia.instagram",
  "socialmedia.twitter",
]
const currentStep = 1
const config = {
  headers: {
    "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRIES,
    "Content-Type": "application/json",
  },
}
const getKeys = async (obj) => {
  let keys = []
  for (const key in obj) {
    keys.push(key)
    if (typeof obj[key] === "object") {
      let subkeys = await getKeys(obj[key])
      keys = keys.concat(subkeys.map((subkey) => key + "." + subkey))
    }
  }
  return keys
}

const ProfileData = () => {
  const { activeStep, pronouns, verticals, previousStep } =
    useContext(SignUpContext)
  const formikData = useFormikContext()
  const [isActive] = useState(activeStep === currentStep)
  const [countryList, setCountryList] = useState([])
  const [cityList, setCityList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)

  useEffect(() => {
    if (previousStep > activeStep) {
      for (let i = 0; i < formFields.length; i++) {
        formikData.setFieldTouched(formFields[i])
      }
    }
  }, [previousStep])

  // Get Countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data } = await axios.get(
          `https://api.countrystatecity.in/v1/countries`,
          config
        )
        setCountryList(data)
      } catch (e) {
        console.log(e)
      }
    }
    fetchCountries()
  }, [])
  // Get Cities
  useEffect(() => {
    const fetchCities = async () => {
      setSelectedCountry(
        countryList.find(
          (country) => country.name === formikData.values.country
        )
      )
      try {
        if (selectedCountry) {
          const { data } = await axios.get(
            `https://api.countrystatecity.in/v1/countries/${selectedCountry?.iso2}/cities`,
            config
          )
          setCityList(data)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchCities()
  }, [formikData.values.country, selectedCountry, countryList])
  // Get Geolocation
  useEffect(() => {
    const fetchGeolocation = async () => {
      setSelectedCity(
        cityList.find((city) => city.name === formikData.values.city)
      )
      try {
        if (selectedCity && formikData.values.city) {
          const {
            data: [location],
          } = await axios.get(
            `https://api.openweathermap.org/geo/1.0/direct?q=${formikData.values.city},${selectedCountry?.iso2}&limit=1&appid=${process.env.REACT_APP_OPEN_WEATHER}`
          )
          formikData.setFieldValue("geoLocation", {
            lat: location?.lat,
            lng: location?.lon,
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchGeolocation()
  }, [formikData.values.city, selectedCity, cityList])

  // Jump to error
  useEffect(() => {
    if (!formikData.isSubmitting) return
    const checkErrorsOnPage = async () => {
      const currentErrors = await getKeys(formikData.errors)
      if (currentErrors.length > 0) {
        const errorOnPageArray = formFields.filter((error) =>
          currentErrors.includes(error)
        )
        if (errorOnPageArray.length) {
          document
            .getElementsByName(errorOnPageArray[0])[0]
            .scrollIntoView({ behavior: "smooth" })
          setTimeout(() => {
            document.getElementsByName(errorOnPageArray[0])[0].focus()
          }, 500)
        }
      }
    }
    checkErrorsOnPage()
  }, [formikData])

  return (
    <div
      className={`max-h-screen w-full flex flex-col items-center overflow-y-scroll dur`}
    >
      <Banner />
      <Transition
        appear={true}
        show={isActive}
        enter="transform transition-all ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transform transition-all ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="form flex flex-col justify-start items-center px-6 lg:px-12">
          <h2 className="w-full text-center lg:text-left">
            Create your profile
          </h2>
          <section>
            <h3 className="w-full text-left">About you</h3>
            <div className="w-full lg:grid lg:grid-cols-2 lg:gap-x-16">
              <TextInput
                label={"First Name"}
                placeholder={""}
                name={"firstName"}
                required
              />
              <TextInput
                label={"Last Name"}
                placeholder={""}
                name={"lastName"}
                required
              />
              <TextInput
                label={"Country"}
                placeholder={""}
                name={"country"}
                required
                dataList={countryList}
                selectedData={selectedCountry}
              />
              <TextInput
                label={"City"}
                placeholder={""}
                name={"city"}
                required
                dataList={cityList}
                selectedData={selectedCity}
              />
              <ListInput
                label={"Pronouns"}
                placeholder={""}
                name={"pronouns"}
                options={pronouns}
              />
            </div>
            <h3 className="w-full text-left">Your org</h3>
            <div className="w-full lg:grid lg:grid-cols-2 lg:gap-x-16">
              <TextInput
                label={"Company Name"}
                placeholder={""}
                name={"companyName"}
                required={formikData.values.role === 'founder' ? true : false}
              />
              <TextInput
                label={formikData.values.role === 'founder' ? "Your Role" : 'Your Title'}
                placeholder={"ex. CEO, CMO, MD, etc.."}
                name={"title"}
                required={formikData.values.role === 'founder' ? true : false}
              />
              <ListInput
                label={"Vertical"}
                placeholder={""}
                name={"businessArea"}
                options={verticals}
                notes={formikData.values.role === 'founder' ? "To connect you with the right investors." : ''}
                required={formikData.values.role === 'founder' || formikData.values.role === 'investor' ? true : false}

              />
              <TextInput
                label={"Industry"}
                placeholder={""}
                name={"industry"}
                notes={
                  formikData.values.role === 'founder' ?  "Self-describe your industry for your community profile.":''
                }
                required={formikData.values.role === 'founder' || formikData.values.role === 'investor' ? true : false}

              />
            </div>
            <TextInput
              label={"Company Website"}
              placeholder={"https://..."}
              name={"companyLink"}
              required={formikData.values.role === 'founder' ? true : false}
            />
            <h3 className="w-full text-left">Tell your story</h3>
            <TextArea
              label={"Introduce Yourself"}
              placeholder={""}
              name={"bio"}
              notes={
                formikData.values.role === 'founder' ? "This is your chance to share your personal story. What are your roots and how did you get where you are now?" : 'This is your chance to share your personal story.'
              }
              footNotes={"Max. 300 characters"}
              rows={5}
              max={300}
            />
            { formikData.values.role  === 'founder' ? <TextArea
              label={"Tell Us About Your Company"}
              placeholder={""}
              name={"companyBio"}
              notes={
                "This is your elevator pitch to your community of peers, external investors and allies. Focus on the essentials of your business. Include what you do, how you do it, who you do it for, and WHY you do it! "
              }
              footNotes={"Max. 300 characters"}
              rows={5}
              max={300}
            /> :  formikData.values.role  === 'investor' ? <TextArea  label={"Tell Us About Your Company"}
              placeholder={""}
              name={"companyBio"}
              notes={
                "Focus on the essentials of your business. Include what you do, how you do it, who you do it for, and WHY you do it! "
              }
              footNotes={"Max. 300 characters"}
              rows={5}
              max={300}
            />  : <TextArea label={"Tell Us About Your Expertise"}
              placeholder={""}
              name={"companyBio"}
              notes={
                "Tell the community of Founders what you do, how you do it, who you do it for, and WHY you do it! "
              }
              footNotes={"Max. 300 characters"}
              rows={5}
              max={300}
            /> }
            <h3 className="w-full text-left">Your socials</h3>
            <p className="paragraph">
              We want to amplify you and your business.
            </p>
            <TextInput
              label={"LinkedIn"}
              placeholder={"https://linkedin.com/in/..."}
              name={"socialmedia.linkedin"}
              required
            />
            <TextInput
              label={"Instagram"}
              placeholder={"@"}
              name={"socialmedia.instagram"}
            />
            <TextInput
              label={"Twitter"}
              placeholder={"@"}
              name={"socialmedia.twitter"}
            />
          </section>
          <div className="w-full flex justify-end mt-12 lg:mt-16">
            <Button label={"Next"} styled={"next"} type={"submit"} />
          </div>
          <div className="h-24 w-full"></div>
        </div>
      </Transition>
    </div>
  )
}

export default ProfileData
