import axios from 'axios'
import { useState, useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import { useHistory } from 'react-router-dom'

import Banner from '../Widgets/Banner'

const deleteUrl = '/api/users/community/remove/'

const ConfirmRemove = ({ data, setRemoving, firm }) => {
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const [banner, setBanner] = useState({ show: false })
  const { setCCModal, config, reload, setReload } = useContext(AdminContext)
  const [removeAssociates, setRemoveAssociates] = useState(false)
  const save = async () => {
    setSaving(true)
    try {
      if (firm) {
        config.data = { removeAssociates }
        const deleted = await axios.delete(
          '/api/users/community/firms/remove/' + data._id,
          config
        )
        if (deleted.data.success) {
          setSaving(false)
          setBanner({
            success: 1,
            show: true,
            message: `${firm ? 'Firm' : 'Member'} deleted! Redirecting..`,
          })
          setTimeout(() => {
            setReload(reload + 1)
            setCCModal(false)
            setRemoving(false)
            history.push('/admin/members/')
          }, 2000)
        } else {
          throw new Error('Sorry, something went wrong while saving')
        }
      } else {
        const deleted = await axios.delete(deleteUrl + data._id, config)
        if (deleted.data.success) {
          setSaving(false)
          setBanner({
            success: 1,
            show: true,
            message: 'Member deleted! Redirecting..',
          })
          setTimeout(() => {
            setReload(reload + 1)
            setCCModal(false)
            setRemoving(false)
            history.push('/admin/members/')
          }, 2000)
        } else {
          throw new Error('Sorry, something went wrong while saving')
        }
      }
    } catch (e) {
      setSaving(false)
      setBanner({
        error: 1,
        show: true,
        message: e.message,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }
  return (
    <div className='bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg'>
      <div className='w-full flex justify-center items-center'>
        <Banner message={banner} />
      </div>
      <div className='md:flex w-full px-3'>
        <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
          <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
            {firm ? (
              <>
                <p>Are you sure you want to permanently remove this firm?</p>
                <p className='text-xs'>This action is not reversible</p>
              </>
            ) : (
              <>
                <p>
                  Are you sure you want to permanently remove this member,
                  including all their related data (events, etc.)?
                </p>
                <p className='text-xs'>this action is not reversible</p>
              </>
            )}
          </label>
        </div>
      </div>
      {/* <div className="flex justify-center items-center w-full px-3">
                {firm && (
                    <div className="w-full  mb-2 px-2">
                        <Switch.Group
                            as="div"
                            className="flex md:flex-col mt-2 justify-center items-center py-2"
                        >
                            <Switch.Label className="mt-2 uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
                                Remove All Associates Permanently
                            </Switch.Label>
                            <Switch
                                as="button"
                                checked={removeAssociates}
                                onChange={() =>
                                    setRemoveAssociates(!removeAssociates)
                                }
                                className={`${
                                    removeAssociates
                                        ? "bg-flime-600"
                                        : "bg-gray-200"
                                } relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline ml-4 md:ml-0`}
                            >
                                {({ checked }) => (
                                    <span
                                        className={`${
                                            checked
                                                ? "translate-x-5"
                                                : "translate-x-0"
                                        } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
                                    >
                                        <CheckIcon
                                            className={checked ? "" : "hidden"}
                                        />
                                    </span>
                                )}
                            </Switch>
                        </Switch.Group>
                    </div>
                )}
            </div> */}
      <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
        <button
          className='px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4'
          onClick={() => {
            setCCModal(false)
            setRemoving(false)
          }}
        >
          Cancel
        </button>
        <button
          className='px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4'
          onClick={save}
        >
          {saving ? (
            <div className='flex justify-center'>
              <div
                style={{ borderTopColor: 'transparent' }}
                className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
              ></div>
            </div>
          ) : (
            'Confirm'
          )}
        </button>
      </div>
    </div>
  )
}

export default ConfirmRemove
