import Button from "./Components/Button"
import Banner from "./Components/Banner"
import { useContext } from "react"
import SignUpContext from "../../../contexts/SignUp"
import { Transition } from "@headlessui/react"

const currentStep = 0

const WelcomeScreen = () => {
  const { handleNext, activeStep, previousStep } = useContext(SignUpContext)
  const isActive = activeStep === currentStep

  return (
    <div className="h-screen w-full flex flex-col items-center overflow-y-scroll">
      <Banner />

      <div className="form flex flex-grow flex-col justify-center items-center px-6 lg:px-12 scal">
        <Transition
          appear={true}
          show={isActive}
          enter="transform transition-all ease-in-out duration-500"
          enterFrom={
            previousStep <= currentStep
              ? "scale-50 opacity-50"
              : "-translate-x-full"
          }
          enterTo={
            previousStep <= currentStep
              ? "scale-100 opacity-100"
              : "-translate-x-0"
          }
          leave="transform transition-all ease-in-out duration-200"
          leaveFrom={"translate-x-0"}
          leaveTo={"translate-x-full"}
        >
          <h1 className="text-center">Welcome to Founderland</h1>
          <p className="lead text-center">
            We're levelling the playing field by bringing together
            forward-thinking investors and engaged allies to get more diverse
            founders funded.
          </p>
          <p className="lead text-center">
            We're so happy you're here, and can't wait to grow together.
          </p>
          <p className="lead text-center">
            XOXO
            <br />
            Alina, Deborah and Stephanie
            <br />
            Co-Founders of Founderland
          </p>
          <div className="w-full flex justify-center mt-12 lg:mt-16">
            <Button
              label={"Let's Go!"}
              styled={"generic"}
              type={"button"}
              action={handleNext}
            />
          </div>
        </Transition>
        <div className="h-24 w-full"></div>
      </div>
    </div>
  )
}

export default WelcomeScreen
