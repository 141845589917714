import SelectAnswer from "./SelectAnswer"
import FastTrackContext from "../../../contexts/FastTrack"
import { useContext, useState } from "react"

const Question = ({ i, question }) => {
  const [checkBoxValues, setCheckBoxValues] = useState([])
  const { data, setData, checkField } = useContext(FastTrackContext)

  const answerHandler = (inputValue) => {
    const currentAnswer = data.responses.filter(
      (response) => response.question_id === question._id
    )
    const currentAnswers = data.responses.filter(
      (response) => response.question_id !== question._id
    )
    currentAnswer[0].answer_value = inputValue
    setData({ ...data, responses: [...currentAnswers, ...currentAnswer] })
  }

  const handleSelect = (answer) => {
    answerHandler(answer)
  }

  const handleCheck = (item) => {
    //search for the selected checkbox value index
    const selectedIndex = checkBoxValues.findIndex((el) => el === item.answer)
    //selectedIndex > 0 means the option was already there (so it's being deselected)
    if (selectedIndex === -1) {
      checkBoxValues.push(item.answer)
    } else {
      checkBoxValues.splice(selectedIndex, 1)
    }
    setCheckBoxValues([...checkBoxValues])
    //updates the answer values
    answerHandler([...checkBoxValues])
  }

  return (
    <div
      className={`w-full flex flex-col justify-between ${
        question.question.length < 100 &&
        question.type !== "multiple" &&
        "md:w-1/2"
      } p-4 py-6`}
    >
      <label className="block uppercase text-gray-400 text-md font-bold mb-2">
        {question.question}
      </label>
      <div className="relative mt-4">
        {question.type === "open" ? (
          <input
            required={question.mandatory}
            type="text"
            className={`w-full ${checkField(
              data.responses.filter(
                (response) => response.question_id === question._id
              )[0]?.answer_value
            )} text-2xl appearance-none border p-3 outline-none`}
            name={question.question}
            placeholder="Your answer"
            value={
              data.responses.filter(
                (response) => response.question_id === question._id
              )[0]?.answer_value
                ? data.responses.filter(
                    (response) => response.question_id === question._id
                  )[0].answer_value
                : ""
            }
            onChange={(e) => {
              answerHandler(e.target.value)
            }}
          />
        ) : question.type === "multiple" ? (
          <div className=" flex flex-col">
            {question.answers.map((item, index) => (
              <div key={item.answer}>
                <input
                  type="checkbox"
                  id={item.answer + index}
                  name="scales"
                  required={
                    question.mandatory && !checkBoxValues.length ? true : false
                  }
                  onChange={() => handleCheck(item)}
                />
                <label className="text-sm md:text-base 2xl:text-xl ml-4">
                  {item.answer}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <SelectAnswer
            question_id={question._id}
            answers={question.answers}
            handleSelect={handleSelect}
          />
        )}
      </div>
    </div>
  )
}
export default Question
