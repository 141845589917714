const weekDaysLong = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
const times = ['9h-13h', '13h-17h', '17h-21h']

const AvailabilityCard = ({ availability }) => {
  return (
    <div className='flex flex-col justify-center items-center space-y-6'>
      <div className='max-w-sm flex flex-col justify-center items-start space-y-2'>
        <label className='p-2 uppercase text-xs font-bold text-gray-400 flex items-center justify-start'>
          Days Available:
        </label>
        <div className='flex flex-row ustify-start items-center space-x-2'>
          {availability.days.map(
            (day, index) =>
              day && (
                <span
                  key={'day' + index}
                  className='text-base p-2 border border-black'
                >
                  {weekDaysLong[index]}
                </span>
              )
          )}
        </div>
        <label className='p-2 uppercase text-xs font-bold text-gray-400 flex items-center justify-start'>
          Times Available:
        </label>
        <div className='flex flex-row justify-start items-center space-x-2'>
          {availability.times.map(
            (time, index) =>
              time && (
                <span
                  key={'time' + index}
                  className='text-base p-2 border border-black'
                >
                  {times[index]}
                </span>
              )
          )}
        </div>
        <label className='p-2 uppercase text-xs font-bold text-gray-400 flex items-center justify-start'>
          Funding Stages:
        </label>
        <div className='flex flex-row justify-start items-center space-x-2'>
          {availability.stages.map(
            (stage, index) =>
              stage && (
                <span
                  key={'stage' + index}
                  className='text-base p-2 border border-black'
                >
                  {stage}
                </span>
              )
          )}
        </div>
        <label className='p-2 uppercase text-xs font-bold text-gray-400 flex items-center justify-start'>
          Ticket Sizes:
        </label>
        <div className='flex flex-row justify-start items-center space-x-2'>
          {availability.tickets.map(
            (ticket, index) =>
              ticket && (
                <span
                  key={'ticket' + index}
                  className='text-base p-2 border border-black'
                >
                  {ticket}
                </span>
              )
          )}
        </div>
        <label className='p-2 uppercase text-xs font-bold text-gray-400 flex items-center justify-start'>
          Sessions per Month:
        </label>
        <div className='flex flex-row justify-start items-center space-x-2'>
          <span className='text-base p-2 border border-black'>
            {availability.sessionsPerMonth}
          </span>
        </div>
      </div>
    </div>
  )
}

export default AvailabilityCard
