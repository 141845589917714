import { useState, useEffect, useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import ListWidget from '../Widgets/ListWidget'
import Loading from '../Widgets/Loading'
import axios from 'axios'
import moment from 'moment'

const styles = {
  pending: 'bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs',
  reviewed: 'bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs',
  rejected: 'bg-fred bg-opacity-50 text-red-900 py-1 px-3 rounded-full text-xs',
  closed: 'bg-fblue bg-opacity-50 text-blue-900 py-1 px-3 rounded-full text-xs',
  ongoing: 'bg-fpink bg-opacity-50 py-1 px-3 rounded-full text-xs',
}

const SessionsList = ({ memberId }) => {
  const [data, setData] = useState({
    header: [
      {
        title: 'Founder',
        key: 'name',
        style: 'text-left text-sm',
        showFilter: true,
      },
      {
        title: 'Status',
        key: 'status',
        style: 'text-center text-sm',
        showFilter: false,
      },
      {
        title: 'Requested On',
        key: 'dateCreated',
        style: 'table-cell text-center text-sm',
        showFilter: false,
      },
      { title: '', key: '-', style: 'text-center text-sm', showFilter: false },
    ],
    data: [],
  })
  const [loading, setLoading] = useState(true)
  const { config, reload } = useContext(AdminContext)
  const investorSessionsUrl = '/api/allysession/memberId/'
  const [filter, setFilter] = useState([
    { key: 'name', search: '', show: false, type: 'text' },
  ])

  //FETCH DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(investorSessionsUrl + memberId, config)
        const dataSet = result.data
        let filteredDataSet
        filteredDataSet = [...dataSet]
        filteredDataSet.forEach((element) => {
          if (element.created) {
            element.createdOn = moment(element.created).format('DD/M/YYYY')
          }
          element.name =
            element.founderId.firstName + ' ' + element.founderId.lastName
        })
        let filteredData = [...filteredDataSet].sort(
          (a, b) => new Date(a.dateUpdated) - new Date(b.dateUpdated)
        )
        filter.forEach(
          (term) =>
            (filteredData = [
              ...filteredData.filter((item) =>
                item[term.key].toLowerCase().includes(term.search.toLowerCase())
              ),
            ])
        )
        setData({ ...data, data: [...filteredData] })
        setLoading(false)
      } catch (e) {}
    }
    fetchData()
  }, [reload, filter])

  return loading ? (
    <Loading />
  ) : (
    <ListWidget
      title=''
      data={data}
      styles={styles}
      showing={5}
      filter={filter}
      setFilter={setFilter}
    />
  )
}

export default SessionsList
