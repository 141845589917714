import axios from 'axios'
import { useState, useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import { useParams } from 'react-router'
import Banner from '../Widgets/Banner'
import ListOption from '../Widgets/ListOption'

const notifyMembers = '/api/newsfeed/notify/'

const groups = [
  { name: 'Send test email to Admin', value: 'ADMIN' },
  { name: 'Send test email to Founderland Team', value: 'FOUNDERLAND' },
  { name: 'Accepted FOUNDERS in the Last week', value: 'ACCEPTED_FOUNDERS' },
  { name: 'Accepted INVESTORS in the Last week', value: 'ACCEPTED_INVESTORS' },
  { name: 'Accepted ALLIES in the Last week', value: 'ACCEPTED_ALLIES' },
  { name: 'Confirmed FOUNDERS - All', value: 'CONFIRMED_FOUNDERS' },
  {
    name: 'Confirmed FOUNDERS without Flags',
    value: 'CONFIRMED_FOUNDERS_NO_FLAGS',
  },
  { name: 'Unconfirmed FOUNDERS', value: 'UNCONFIRMED_FOUNDERS' },
  {
    name: 'Unconfirmed FOUNDERS - Notified >1 month',
    value: 'UNCONFIRMED_FOUNDERS_NOTIFIED',
  },
  { name: '⚠️ All FOUNDERS', value: 'FOUNDERS' },
  { name: '⚠️ All INVESTORS', value: 'INVESTORS' },
  { name: '⚠️ All ALLIES', value: 'ALLIES' },
  { name: '⚠️ All MEMBERS', value: 'MEMBERS' },
]

const NotifyArticle = ({ data }) => {
  const [saving, setSaving] = useState(false)
  const { id } = useParams()
  const [notification, setNotification] = useState({
    to: 'ADMIN',
    name: 'Send test email to Admin',
    id: id,
    subject: data.articleTitle,
    content: data.articleContent,
  })
  const [banner, setBanner] = useState({ show: false })
  const { setCCModal, config, reload, setReload } = useContext(AdminContext)

  const save = async () => {
    setSaving(true)
    try {
      const notifying = await axios.post(notifyMembers, notification, config)
      if (notifying.data.success) {
        setSaving(false)
        setReload(reload + 1)
        setBanner({
          success: 1,
          show: true,
          message: notifying.data.Notified + ' Members Notified! ',
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
          setCCModal(false)
        }, 1500)
      } else {
        throw new Error('Sorry, something went wrong')
      }
    } catch (e) {
      setSaving(false)
      setBanner({
        error: 1,
        show: true,
        message: e?.response?.data?.message
          ? e.response.data.message
          : 'Sorry, something went wrong',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  const setType = (value) => {
    setNotification((prev) => ({
      ...prev,
      to: value,
      name: groups.filter((item) => item.value === value)[0].name,
    }))
  }

  return (
    <div className='bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg'>
      <div className='w-full flex justify-center items-center'>
        <Banner message={banner} />
      </div>
      <div className='md:flex w-full px-3'>
        <div className='flex justify-center items-center w-full px-3'>
          <div className='w-full mb-2 px-2'>
            <label className='block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2'>
              Send Notification To
            </label>
            <div className='w-full flex px-2'>
              <ListOption
                options={groups}
                choice={notification.to}
                setChoice={setType}
                format={'mt-1 w-full'}
              />
            </div>
            {/* Note that all flagged and locked accounts are excluded */}
            <label className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mt-4'>
              Note that all flagged and locked accounts are excluded
            </label>
          </div>
        </div>
      </div>
      <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
        <button
          className='px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4'
          onClick={() => {
            setCCModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className='px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4'
          onClick={save}
        >
          {saving ? (
            <div className='flex justify-center'>
              <div
                style={{ borderTopColor: 'transparent' }}
                className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
              ></div>
            </div>
          ) : (
            'Confirm'
          )}
        </button>
      </div>
    </div>
  )
}

export default NotifyArticle
