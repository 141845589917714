import { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import AdminContext from '../../../contexts/Admin'
import Tabs from '../Widgets/Tabs'
import SessionsList from './SessionsList'
import AvailableInvestorsList from './AvaliableInvestorsList'
import AvailableInvestor from './AvailableInvestor'
import SessionDetails from './SessionDetails'

const Beacon = () => {
  const history = useHistory()
  const { id, category } = useParams()
  const { reload, selectedTab, user, setCCModal } = useContext(AdminContext)
  const [availableInvestorsData, setAvailableInvestorsData] = useState([])
  const tabs = [
    {
      index: 0,
      name: 'Available Investors',
      role: 'available',
      restricted: '',
    },
    {
      index: 1,
      name: 'Sessions',
      role: 'sessions',
      restricted: '',
    },
  ]

  return (
    <>
      <Tabs tabs={tabs} id={id} />
      <section className='relative h-full w-full flex md:px-4  items-center flex-col bg-white outline-none overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue'>
        {!id ? (
          <>
            {selectedTab === 1 ? (
              <SessionsList />
            ) : (
              <AvailableInvestorsList
                setAvailableInvestorsData={setAvailableInvestorsData}
              />
            )}
          </>
        ) : selectedTab === 0 ? (
          <AvailableInvestor />
        ) : selectedTab === 1 ? (
          <SessionDetails />
        ) : (
          'Nothing to show'
        )}
      </section>
    </>
  )
}

export default Beacon
