import { useState } from 'react'

const Filter = ({ filter, setFilter, filters, data }) => {
  const [suggestions, setSuggestions] = useState([])

  const handleInputChange = (e) => {
    const userInput = e.target.value
    const filterKey = filter.key
    setFilter({ ...filters, [filterKey]: userInput })
    if (e.target.value === '') {
      setSuggestions([])
      return
    }
    const filteredSuggestions = [
      ...new Set(
        data
          .filter((item) =>
            item[filterKey].toLowerCase().includes(userInput.toLowerCase())
          )
          .map((item) => item[filterKey])
      ),
    ].sort((a, b) => a.localeCompare(b))
    setSuggestions(filteredSuggestions)
  }

  const handleSuggestionClick = (suggestion) => {
    setFilter({ ...filters, [filter.key]: suggestion })
    setSuggestions([])
  }

  return filter.type === 'text' ? (
    <div className='relative w-full sm:w-60'>
      <label className='text-grotesk block text-sm font-bold mb-2'>
        {filter.name}
      </label>
      <input
        type='text'
        className='border border-gray-300 py-2 px-4 block w-full appearance-none'
        value={filters[filter.key] ? filters[filter.key] : ''}
        onChange={(e) => {
          handleInputChange(e)
        }}
      />
      {suggestions.length > 0 ? (
        <ul className='w-full max-h-40 scrollbar scrollbar-thin scrollbar-thumb-black scrollbar-track-gray-100 overflow-y-scroll bg-white absolute top-18 border border-black'>
          {suggestions.map((suggestion, index) => (
            <li
              className='px-4'
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  ) : filter.type === 'list' ? (
    <div className='w-full sm:w-60'>
      <label className='text-grotesk block text-sm font-bold mb-2'>
        {filter.name}
      </label>
      <select
        className='border border-gray-300 py-2 px-4 block w-full appearance-none'
        value={filters[filter.key] ? filters[filter.key] : filter.values[0]}
        onChange={(e) => {
          setFilter({ ...filters, [filter.key]: e.target.value })
        }}
      >
        <option key={'all'} value='all'>
          Select {filter.name}
        </option>
        {filter.values.map((option, index) =>
          option === 'all' ? (
            ''
          ) : (
            <option key={index} value={option}>
              {option}
            </option>
          )
        )}
      </select>
    </div>
  ) : filter.type === 'boolean' ? (
    <div className='w-full sm:w-60'>
      <label className='text-grotesk block text-sm font-bold mb-2'>
        {filter.name}
      </label>
      <select
        className='border border-gray-300 py-2 px-4 block w-full appearance-none'
        value={filters[filter.key] ? filters[filter.key] : filter.values[0]}
        onChange={(e) => {
          setFilter({ ...filters, [filter.key]: e.target.value })
        }}
      >
        <option key={'include'} value='include'>
          Select {filter.name}
        </option>
        {filter.values.map((option, index) =>
          option === 'include' ? (
            ''
          ) : (
            <option key={index} value={option}>
              {option}
            </option>
          )
        )}
      </select>
    </div>
  ) : (
    ''
  )
}

export default Filter
