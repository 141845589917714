import { useState, useRef, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import SignUpContext from '../../../contexts/SignUp'
import WelcomeScreen from './WelcomeScreen'
import ProfileData from './ProfileData'
import Symbols from './Symbols'
import ProfilePicture from './ProfilePicture'
import FlagCreation from './FlagCreation'
import CreateProfile from './CreateProfile'
import Success from './Success'
import Banner from './Components/Banner'
import gifLogo from '../../../assets/images/logo-animation.gif'

const signUpURL = '/api/auth/signup'
const getProfileURL = '/api/users/community/profile'
const newSignUp = '/api/auth/newsignup/'

const pronouns = [
  { name: 'She/Her', value: 'She/Her' },
  { name: 'He/Him', value: 'He/Him' },
  { name: 'They/Them', value: 'They/Them' },
  { name: 'Other', value: 'Other' },
  { name: 'Rather not say', value: ' ' },
]
const verticals = [
  { name: 'SaaS/Enterprise Software', value: 'SaaS/Enterprise Software' },
  {
    name: 'Mobility',
    value: 'Mobility',
  },
  {
    name: 'Sustainability/Impact investment',
    value: 'Sustainability/Impact investment',
  },
  { name: 'HealthTech', value: 'HealthTech' },
  {
    name: 'DTC',
    value: 'DTC',
  },
  { name: 'E-comm/Marketplaces', value: 'E-comm/Marketplaces' },
  { name: 'IoT', value: 'IoT' },
  {
    name: 'FoodTech',
    value: 'FoodTech',
  },
  { name: 'Gaming/Entertainment', value: 'Gaming/Entertainment' },
  { name: 'Engineering/DeepTech/AI', value: 'Engineering/DeepTech/AI' },
  {
    name: 'FinTech',
    value: 'FinTech',
  },
  { name: 'EdTech', value: 'EdTech' },
]
const validationSchemaFounder = {
  1: yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    title: yup.string().required('Required'),
    city: yup.string().required('Required'),
    country: yup.string().required('Required'),
    bio: yup.string().max(300, 'Max. 300 characters').nullable(),
    businessArea: yup
      .string()
      .oneOf(
        verticals.map((vertical) => vertical.value),
        'Please select a vertical'
      )
      .required('Required'),
    industry: yup.string().required('Required'),
    companyName: yup.string().required('Required'),
    companyBio: yup.string().max(300, 'Max. 300 characters').nullable(),
    companyLink: yup
      .string()
      .url('Please provide a valid URL (requires https:// or http://)')
      .required('Required'),
    pronouns: yup.string().nullable(),
    socialmedia: yup.object().shape({
      linkedin: yup
        .string()
        .url('Please provide a valid URL (requires https:// or http://)')
        .required('Required'),
      instagram: yup.string().nullable(),
      twitter: yup.string().nullable(),
    }),
  }),
  5: yup.object().shape({
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, {
        message: 'Password does not meet the requirements',
      })
      .required('Required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  }),
}
const validationSchemaInvestor = {
  1: yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    title: yup.string().nullable(),
    city: yup.string().required('Required'),
    country: yup.string().required('Required'),
    bio: yup.string().max(300, 'Max. 300 characters').nullable(),
    businessArea: yup
      .string()
      .oneOf(
        verticals.map((vertical) => vertical.value),
        'Please select a vertical'
      )
      .required('Required'),
    industry: yup.string().nullable(),
    companyName: yup.string().nullable(),
    companyBio: yup.string().max(300, 'Max. 300 characters').nullable(),
    companyLink: yup
      .string()
      .url('Please provide a valid URL (requires https:// or http://)')
      .nullable(),
    pronouns: yup.string().nullable(),
    socialmedia: yup.object().shape({
      linkedin: yup
        .string()
        .url('Please provide a valid URL (requires https:// or http://)')
        .required('Required'),
      instagram: yup.string().nullable(),
      twitter: yup.string().nullable(),
    }),
  }),
  5: yup.object().shape({
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, {
        message: 'Password does not meet the requirements',
      })
      .required('Required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  }),
}
const validationSchemaAlly = {
  1: yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    title: yup.string().nullable(),
    city: yup.string().required('Required'),
    country: yup.string().required('Required'),
    bio: yup.string().max(300, 'Max. 300 characters').nullable(),
    businessArea: yup
      .string()
      .oneOf(
        verticals.map((vertical) => vertical.value),
        'Please select a vertical'
      )
      .nullable(),
    industry: yup.string().nullable(),
    companyName: yup.string().nullable(),
    companyBio: yup.string().max(300, 'Max. 300 characters').nullable(),
    companyLink: yup
      .string()
      .url('Please provide a valid URL (requires https:// or http://)')
      .nullable(),
    pronouns: yup.string().nullable(),
    socialmedia: yup.object().shape({
      linkedin: yup
        .string()
        .url('Please provide a valid URL (requires https:// or http://)')
        .required('Required'),
      instagram: yup.string().nullable(),
      twitter: yup.string().nullable(),
    }),
  }),
  5: yup.object().shape({
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, {
        message: 'Password does not meet the requirements',
      })
      .required('Required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  }),
}
const steps = [
  'Welcome Screen',
  'Profile Data',
  'Symbols',
  'Profile Picture',
  'Flag Picture',
  'Password',
]

const NSignUp = () => {
  const { token } = useParams()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [created, setCreated] = useState(false)
  const [initialyTouched, setInitialyTouched] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const [previousStep, setPreviousStep] = useState(0)
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    title: '',
    city: '',
    country: '',
    geoLocation: '',
    bio: '',
    businessArea: '',
    industry: '',
    password: '',
    confirmPassword: '',
    photo: null,
    flag: null,
    companyName: '',
    companyBio: '',
    companyLink: '',
    pronouns: '',
    symbols: [
      { icon: 'affirmative', color: 'black' },
      { icon: 'affirmative', color: 'black' },
      { icon: 'affirmative', color: 'black' },
    ],
    socialmedia: {
      linkedin: '',
      instagram: '',
      twitter: '',
    },
    consent: false,
    bannerColor: 'black',
    privacyConsent: { main: true, analytics: true },
  })
  const currentValidationSchema =
    initialValues.role === 'founder'
      ? validationSchemaFounder[activeStep]
      : initialValues.role === 'investor'
      ? validationSchemaInvestor[activeStep]
      : validationSchemaAlly[activeStep]
  const isLastStep =
    initialValues.role === 'founder'
      ? activeStep === steps.length - 1
      : activeStep === steps.length - 3

  const renderCurrentStepFounder = (step) => {
    switch (step) {
      case 1:
        return <ProfileData />
      case 2:
        return <Symbols />
      case 3:
        return <ProfilePicture />
      case 4:
        return <FlagCreation />
      case 5:
        return <CreateProfile />
      default:
        return <div>Not Found</div>
    }
  }
  const renderCurrentStepInvestor = (step) => {
    switch (step) {
      case 1:
        return <ProfileData />
      case 2:
        return <ProfilePicture />
      case 3:
        return <CreateProfile />
      default:
        return <div>Not Found</div>
    }
  }
  const renderCurrentStepAlly = (step) => {
    switch (step) {
      case 1:
        return <ProfileData />
      case 2:
        return <ProfilePicture />
      case 3:
        return <CreateProfile />
      default:
        return <div>Not Found</div>
    }
  }
  const submitForm = async (values, actions) => {
    try {
      //receive new login token and forward to community app
      const { data } = await axios.post(signUpURL, values, config)
      if (data.access_token) {
        localStorage.setItem('authToken', data.access_token)
        setCreated(true)
        setTimeout(() => {
          actions.setSubmitting(false)
          setActiveStep(
            values.role === 'founder' ? activeStep + 1 : activeStep + 3
          )
        }, 1500)
      } else {
        actions.setSubmitting(false)
        throw new Error({
          response: {
            status: 500,
            message: 'Sorry, something went wrong',
          },
        })
      }
    } catch (err) {
      actions.setSubmitting(false)
      setError(
        err?.response?.status === 401
          ? 'Invalid token'
          : 'Sorry, something went wrong'
      )
    }
  }
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions)
    } else {
      setActiveStep(activeStep + 1)
      setPreviousStep(activeStep)
      actions.setTouched({})
      actions.setSubmitting(false)
    }
  }
  const handleBack = () => {
    setPreviousStep(activeStep)
    setActiveStep(activeStep - 1)
  }
  const handleNext = () => {
    setPreviousStep(activeStep)
    setActiveStep(activeStep + 1)
  }

  const pageTop = useRef(null)
  const scrollUp = () => {
    pageTop.current.scrollIntoView({ behavior: 'smooth' })
  }

  const jwtDecoded = jwt.decode(token)
  const config = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  }, [token])

  useEffect(() => {
    const fetchProfile = async () => {
      if (jwtDecoded?.email) {
        const now = Date.now() / 1000
        try {
          if (now < jwtDecoded.exp) {
            const {
              data: { data },
            } = await axios.get(`${getProfileURL}/${jwtDecoded.id}`, config)
            if (data) {
              let firmInfo = {}
              if (data.firmId) {
                firmInfo = {
                  ...firmInfo,
                  companyLink: data.firmId.companyLink,
                  companyName: data.firmId.companyName,
                  companyBio: data.firmId.companyBio,
                }
              }
              setInitialValues({
                ...initialValues,
                ...data,
                symbols: [
                  { icon: 'affirmative', color: 'black' },
                  { icon: 'affirmative', color: 'black' },
                  { icon: 'affirmative', color: 'black' },
                ],
                bannerColor: 'black',
                privacyConsent: { main: true, analytics: true },
                ...firmInfo,
                businessArea:
                  data.businessArea === 'Select the business area'
                    ? ''
                    : data.businessArea,
              })
              const formFields = Object.keys(initialValues)
              let touchedFields = {}
              for (let i = 0; i < formFields.length; i++) {
                if (
                  typeof data[formFields[i]] === 'string' &&
                  data[formFields[i]] !== '' &&
                  data[formFields[i]] !== 'Select the business area' &&
                  !formFields[i].toLowerCase().includes('password') &&
                  !formFields[i].toLowerCase().includes('bannercolor')
                ) {
                  touchedFields[formFields[i]] = true
                }
              }
              setInitialyTouched(touchedFields)
              setTimeout(() => {
                setLoading(false)
              }, 1500)
            } else {
              setLoading(false)
              setError('Invalid Token - empty')
            }
          } else {
            const newLink = await axios.get(newSignUp + jwtDecoded.id, config)
            if (newLink.data.message.includes('unverified')) {
              setError('Invalid Token - expired')
            } else if (newLink.data.message.includes('confirmed')) {
              setError('Invalid Token - confirmed')
            }
            setLoading(false)
          }
        } catch (err) {
          console.log(err)
          setLoading(false)
          setError('Invalid Token - error fetching data')
        }
      } else {
        setLoading(false)
        setError('Invalid Token - no email')
      }
    }
    fetchProfile()
  }, [token])

  return (
    <SignUpContext.Provider
      value={{
        pageTop,
        scrollUp,
        pronouns,
        verticals,
        activeStep,
        previousStep,
        handleBack,
        handleNext,
        handleSubmit,
        loading,
        config,
      }}
    >
      <div className='v2 h-screen w-full flex flex-col items-center overflow-y-scroll'>
        {loading ? (
          <div className='h-screen w-screen flex flex-col justify-center items-center'>
            <img src={gifLogo} alt='founderland logo' className='max-h-80' />
          </div>
        ) : error !== '' ? (
          <>
            <Banner />
            <div className='w-full h-full flex flex-col justify-start items-center'>
              {error.includes('confirmed') ? (
                <>
                  <h2 className='text-center'>
                    It looks like you have already registered.
                  </h2>
                  <a
                    href='/community'
                    className='lead text-fblue-200 hover:text-fblue'
                  >
                    Go to Community login
                  </a>
                </>
              ) : error.includes('expired') ? (
                <>
                  <h2 className='text-center'>
                    This sign up link has expired.{' '}
                  </h2>
                  <p className='lead text-center'>
                    A new one has been issued, please check your mailbox and
                    sign up with the new link!
                  </p>
                </>
              ) : (
                <h2 className='text-center'>Invalid Sign Up link</h2>
              )}
              <p className='paragraph text-center'>
                If you are experiencing issues signing up, please contact us via
                email
                <br /> 📧{' '}
                <a
                  className='text-fblue-200 hover:text-fblue'
                  href='mailto:admin@founderland.org'
                >
                  admin@founderland.org
                </a>
              </p>
            </div>
          </>
        ) : activeStep === steps.length ? (
          <Success created={created} />
        ) : activeStep === 0 ? (
          <WelcomeScreen />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            enableReinitialize={true}
            initialTouched={initialyTouched}
            initialErrors={{}}
            validateOnMount={true}
          >
            {({ isSubmitting }) => (
              <Form id='signUp' className='w-full max-h-screen'>
                {initialValues.role === 'founder'
                  ? renderCurrentStepFounder(activeStep)
                  : initialValues.role === 'ally'
                  ? renderCurrentStepAlly(activeStep)
                  : renderCurrentStepInvestor(activeStep)}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </SignUpContext.Provider>
  )
}

export default NSignUp
