import { Fragment, useContext, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import AdminContext from "../../../contexts/Admin"
import { SaveIcon } from "@heroicons/react/outline"
import Banner from "../Widgets/Banner"
import axios from "axios"

const updateProfile = "/api/users/community/profile/update"

const SymbolsModal = ({ children, currentSymbols, currentBanner, id }) => {
  const { sModal, setSModal, config, setReload, reload } =
    useContext(AdminContext)
  const [saving, setSaving] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const saveSymbols = async () => {
    setSaving(true)
    const symbolsArray = currentSymbols.map((item) => item.icon)
    let isDuplicateS = symbolsArray.some(
      (item, i) => symbolsArray.indexOf(item) !== i
    )
    const colorArray = currentSymbols.map((item) => item.color)
    let isDuplicateC = colorArray.some(
      (item, i) => colorArray.indexOf(item) !== i
    )
    if (isDuplicateS || isDuplicateC) {
      setBanner({
        error: 1,
        show: true,
        message: "Symbols and colors must not be duplicated",
      })
      setSaving(false)
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    } else {
      try {
        const updateBio = await axios.put(
          updateProfile,
          { _id: id, bannerColor: currentBanner, symbols: [...currentSymbols] },
          config
        )
        if (updateBio) {
          setBanner({
            success: 1,
            show: true,
            message: "Changes saved!",
          })
          setTimeout(() => {
            setBanner((prev) => ({ ...prev, show: false }))
            setReload(reload + 1)
            setSModal(false)
          }, 4000)
        }
      } catch (e) {
        setBanner({
          error: 1,
          show: true,
          message: e.message,
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
      setSaving(false)
    }
  }
  return (
    <Transition appear show={sModal} as={Fragment}>
      <Dialog
        as="div"
        onClose={() => setSModal(false)}
        className="fixed z-50 inset-1 overflow-auto h-screen"
      >
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-80"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-80"
          >
            <div className="relative p-2 w-full md:w-5/6  mt-28 sm:mt-0">
              <div className="bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full shadow-lg">
                <div className="w-full flex justify-center items-center">
                  <Banner message={banner} />
                </div>
                {children}

                <div className="px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around ">
                  <button
                    className="px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4"
                    onClick={() => {
                      setSModal(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="flex justify-center space-x-4 px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4"
                    onClick={() => {
                      saveSymbols()
                    }}
                  >
                    {saving ? (
                      <div className="flex justify-center">
                        <div
                          style={{ borderTopColor: "transparent" }}
                          className="w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin"
                        ></div>
                      </div>
                    ) : (
                      <>
                        <SaveIcon className="w-6 h-6" />
                        <p>Save Changes</p>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default SymbolsModal
