import { useState, useContext, useEffect } from 'react'
import icons from '../../../assets/icons/Icons'
import { ReactComponent as SmallLogo } from '../../../assets/small.svg'
import SymbolPicker from '../../Community/Widgets/SymbolPicker'
import * as htmlToImage from 'html-to-image'
import AdminContext from '../../../contexts/Admin'
import { Popover } from '@headlessui/react'
import Banner from '../Widgets/Banner'
import { ColorSwatchIcon, DownloadIcon } from '@heroicons/react/outline'
import DropZoneProfilePicture from '../Widgets/DropZoneProfilePicture'
const symbolColors = ['fblue', 'fred', 'fpink', 'flime']

const FlagCreation = () => {
  const [flag, setFlag] = useState({
    flag: {},
    symbols: [
      { icon: 'affirmative', color: 'fblue' },
      { icon: 'affirmative', color: 'fblue' },
      { icon: 'affirmative', color: 'fblue' },
    ],
    photo: {},
  })
  const [currentSymbols, setCurrentSymbols] = useState([
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
    { icon: 'affirmative', color: 'fblue' },
  ])

  const { config, getUuid } = useContext(AdminContext)
  const [uploadStatus, setUploadStatus] = useState({
    success: false,
    message: '',
  })
  const [banner, setBanner] = useState({ show: false })
  const [touched, setTouched] = useState({
    symbols: [true, true, true],
    bannerColor: true,
  })

  useEffect(() => {
    if (uploadStatus.success) {
      setBanner({
        success: 1,
        show: true,
        message: `Photo uploaded`,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 2000)
    } else if (uploadStatus.message !== '') {
      setBanner({
        success: 0,
        show: true,
        message: uploadStatus.message,
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 2000)
    }
  }, [uploadStatus])
  //SET IDENTITY
  const setSymbols = (symbol, i) => {
    let current = [...currentSymbols]
    current[i] = symbol
    setCurrentSymbols([...current])
  }

  useEffect(() => {
    setFlag((prev) => ({ ...prev, symbols: [...currentSymbols] }))
  }, [currentSymbols])

  useEffect(() => {
    setFlag((prev) => ({ ...prev, flag: { ...flag.photo } }))
  }, [flag.photo])

  //DOWNLOAD MATERIAL
  const download = () => {
    exportAsPicture(getUuid() + '.jpeg')
  }
  const exportAsPicture = async (filename) => {
    const data = document.getElementsByClassName('htmlToCanvas')
    for (let i = 0; i < data.length; ++i) {
      const canvas = await htmlToImage.toJpeg(data[i], { quality: 0.95 })
      saveAs(canvas, filename)
    }
  }
  const saveAs = async (blob, fileName) => {
    const elem = window.document.createElement('a')
    elem.href = blob
    elem.download = fileName
    elem.style = 'display:none;'
    ;(document.body || document.documentElement).appendChild(elem)
    if (typeof elem.click === 'function') {
      elem.click()
    } else {
      elem.target = '_blank'
      elem.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      )
    }
    URL.revokeObjectURL(elem.href)
    elem.remove()
  }

  return (
    <div className='w-full'>
      <div className='w-full px-3 mt-8'>
        <div className='w-full flex justify-center items-center'>
          <Banner message={banner} />
        </div>
      </div>
      <div className=' w-full px-3 mt-8'>
        <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
          <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
            Add a picture
          </label>
        </div>
        <DropZoneProfilePicture
          data={flag}
          setData={setFlag}
          config={config}
          type={'tool'}
          setUploadStatus={setUploadStatus}
        />
      </div>

      <div className='w-full px-3 mt-8'>
        <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
          <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
            Select the Symbols
          </label>
          <div className='w-full flex flex-col sm:flex-row justify-center p-2'>
            {flag.symbols.map((symbol, i) => (
              <div className='w-full md:w-1/3' key={i}>
                <SymbolPicker
                  data={symbol}
                  setSymbols={setSymbols}
                  position={i}
                  touched={touched}
                  setTouched={setTouched}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {flag.photo.url ? (
        <>
          <div className='w-full px-3 mt-8'>
            <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
              <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
                Preview the flag
              </label>
              <div className='relative flex justify-center items-center max-w-2xl'>
                {flag.photo?.public_id && (
                  <img
                    src={flag.photo.url}
                    alt='profile'
                    className='w-full h-full object-cover'
                  />
                )}
                {flag.symbols.map((symbol, i) =>
                  icons[symbol.icon](
                    `m-0 absolute bottom-0 inset-2/3 inset-x-${
                      i === 0 ? 0 : i === 1 ? '1/3' : '2/3'
                    } w-1/3 h-1/3 text-${symbol.color} fill-current`,
                    `${
                      symbol.color === 'flime' ? 'text-black' : 'text-white'
                    } fill-current`,
                    i
                  )
                )}
              </div>
            </div>
          </div>
          <div className='w-full px-3 mt-8'>
            <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
              <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
                Download the flag
              </label>
              <div className='relative flex justify-center items-center max-w-2xl'>
                <button
                  className='flex items-center justify-center space-x-2 px-8 py-2 w-full bg-gray-700 transition duration-200 hover:bg-flime hover:text-black text-white mb-4 '
                  onClick={() => download()}
                >
                  <DownloadIcon className='w-6 h-6' />
                  <p className='text-sm xl:text-base'>Download Media</p>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='w-full px-3 mt-8'>
          <div className='w-full mb-2 px-2 flex flex-col justify-center items-center'>
            <label className='block uppercase tracking-wide text-grey-darker text-md font-bold mb-2'>
              A preview will be displayed here before you can download the flag
            </label>
          </div>
        </div>
      )}
      <div className='w-full h-0 overflow-hidden'>
        {/* Profile */}
        <div
          className='htmlToCanvas relative flex justify-center items-center '
          style={{ width: 1288, height: 1278 }}
        >
          {flag.photo?.public_id && (
            <img
              src={flag.photo.url}
              alt='profile'
              className='w-full h-full object-cover'
            />
          )}

          {flag.symbols.map((symbol, i) => (
            <div
              key={i}
              className={`absolute bottom-0 left-${
                i === 0 ? 0 : i === 1 ? '1/3' : '2/3'
              }`}
              style={{ width: 430, height: 430 }}
            >
              <div className='relative'>
                {icons[symbol.icon](
                  `absolute text-${symbol.color} fill-current`,
                  `${
                    symbol.color === 'flime' ? 'text-black' : 'text-white'
                  } fill-current`,
                  i
                )}
              </div>
            </div>
          ))}
        </div>

        {/* symbols */}
        {flag.symbols.map((symbol, i) => (
          <div
            key={i}
            className={`htmlToCanvas  relative flex justify-center items-center bg-${symbol.color}`}
            style={{ width: 1288, height: 1278 }}
          >
            <div
              className='w-full absolute flex justify-center items-center left-0 bottom-0'
              style={{
                height: 246,
              }}
            >
              <p
                className={`w-full text-7xl font-bold text-center text-hanson ${
                  symbol.color === 'flime' ? 'text-black' : 'text-white'
                }`}
              >
                {symbol.icon.charAt(0).toUpperCase() +
                  symbol.icon.replace('_', ' ').slice(1)}
              </p>
            </div>
            <div className='absolute ' style={{ width: 700, height: 700 }}>
              {icons[symbol.icon](
                `absolute m-auto inset-0 w-full h-full text-${symbol.color} fill-current`,
                `${
                  symbol.color === 'flime' ? 'text-black' : 'text-white'
                } fill-current`,
                i
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FlagCreation
