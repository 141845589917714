import { Listbox, Transition } from "@headlessui/react"
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/outline"
import { Fragment } from "react"

const ListOption = ({
  options,
  choice,
  setChoice,
  format,
  color,
  extra,
  defaultValue,
}) => {
  return (
    <Listbox value={choice} onChange={setChoice}>
      <Listbox.Button
        className={`border ${color} ${format} relative w-full text-left bg-white cursor-default text-2xl appearance-none p-2 outline-none`}
      >
        <span className="flex-none truncate text-lg">
          {options.filter((item) => item.value === choice)[0]?.name
            ? options.filter((item) => item.value === choice)[0]?.name
            : defaultValue}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center justify-end pr-2 pointer-events-none w-full text-2xl appearance-none p-3">
          <ChevronDownIcon
            className="w-5 h-5 text-gray-600"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute w-full z-40 py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-40 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map((choice, index) => (
            <Listbox.Option
              key={choice.value + index}
              className={({ active }) =>
                `${
                  active
                    ? "text-fblue bg-fblue bg-opacity-20 '"
                    : "text-gray-900 w-full"
                }
              cursor-default select-none relative py-2 pl-10 pr-4`
              }
              value={choice.value}
            >
              {({ selected, active }) => (
                <>
                  <div
                    className={`${
                      selected ? "text-md" : "font-normal"
                    } flex justify-between truncate`}
                  >
                    <p> {choice.name}</p>
                    <p className="text-right text-xs">
                      {extra ? "Total: " + extra[index] : ""}
                    </p>
                  </div>
                  {selected ? (
                    <span className="text-fblue absolute inset-y-0 left-0 flex items-center pl-3">
                      <CheckIcon className="w-5 h-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  )
}

export default ListOption
