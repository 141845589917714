import { useContext, useEffect } from "react"

import FastTrackContext from "../../../contexts/FastTrack"
import FormPage from "./FormPage"

const ApplicationSteps = ({ previousStep, nextStep, pagesItems, isActive }) => {
  const { pageTop, scrollUp, data } = useContext(FastTrackContext)

  useEffect(() => {
    if (isActive) scrollUp()
  }, [isActive])

  return (
    <div
      className="flex flex-col justify-around items-center h-full w-full bg-white p-3"
      ref={pageTop}
    >
      <div className="w-full flex flex-col justify-center items-center">
        <h1 className="font-bold text-grotesk text-xl md:text-2xl lg:text-3xl">
          {pagesItems[0]?.category}
        </h1>
      </div>
      <FormPage
        questions={pagesItems}
        previousStep={previousStep}
        nextStep={nextStep}
        data={data}
      />
    </div>
  )
}

export default ApplicationSteps
