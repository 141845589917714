
const affirmative = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 1080 1080"
    className={outer}
    key={i}
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g className={inner}>
      <path d="M468.43,140.56H611.6V666.25H468.43ZM433.75,832.9c0-62.64,45.86-106.24,106.26-106.24s106.25,43.6,106.25,106.24S600.41,939.17,540,939.17,433.75,895.54,433.75,832.9" />
    </g>
  </svg>
)

const audible = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 1080 1080"
    className={outer}
    key={i}
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1047.43" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g className={inner}>
      {" "}
      <path
        className={inner}
        d="M254.64,662.46h0ZM853.89,789.52c-49.53,0-87.08-34.3-111.6-101.93-13.85-38.18-24.32-86.32-34.45-132.88-3.09-14.19-6.82-31.38-10.91-48.8-4.1,17.42-7.84,34.61-10.92,48.8-10.14,46.56-20.6,94.7-34.44,132.88C627,755.22,589.49,789.52,540,789.52s-87.06-34.3-111.6-101.93c-13.82-38.18-24.3-86.32-34.44-132.88-3.08-14.18-6.8-31.36-10.9-48.78-4.09,17.42-7.83,34.6-10.91,48.78-10.14,46.56-20.6,94.7-34.44,132.88-24.52,67.63-62.06,101.93-111.6,101.93S139,755.22,114.48,687.57C100.64,649.39,90.18,601.25,80,554.69c-8.31-38.24-21.39-98.38-33.3-125.92H0V290.48H69.15c49.53,0,87.07,34.3,111.58,101.95,13.84,38.16,24.31,86.3,34.44,132.86,3.09,14.18,6.82,31.36,10.91,48.77,4.09-17.41,7.82-34.58,10.91-48.75,10.13-46.56,20.6-94.7,34.44-132.88C296,324.78,333.5,290.48,383,290.48s87.07,34.3,111.59,101.95c13.84,38.18,24.32,86.32,34.44,132.88,3.09,14.17,6.82,31.34,10.91,48.75,4.09-17.41,7.82-34.59,10.91-48.75,10.13-46.56,20.6-94.72,34.44-132.88,24.53-67.65,62.07-101.95,111.59-101.95s87.08,34.3,111.6,101.95c13.86,38.16,24.32,86.32,34.46,132.88,3.08,14.17,6.83,31.39,10.92,48.81,4.08-17.42,7.84-34.64,10.92-48.81,10.13-46.56,20.6-94.72,34.44-132.88,24.53-67.65,62.09-101.95,111.61-101.95H1080V428.77h-46.74c-11.91,27.54-25,87.7-33.32,125.94-10.13,46.56-20.6,94.7-34.45,132.88C941,755.22,903.41,789.52,853.89,789.52"
      />
    </g>
  </svg>
)

const balance = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <rect
      className={inner}
      x="184.51"
      y="156.4"
      width="143.51"
      height="767.24"
    />
    <rect
      className={inner}
      x="468.24"
      y="156.37"
      width="143.51"
      height="767.24"
    />
    <rect
      className={inner}
      x="751.96"
      y="156.42"
      width="143.51"
      height="767.24"
    />
  </svg>
)

const blooming = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080.28" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080.28" height="1080" />
    <g>
      <path
        className={inner}
        d="M624.46,556.26c674.52,0-84.31,758.86-84.31,84.31,0,674.55-758.86-84.31-84.33-84.31-674.53,0,84.33-758.83,84.33-84.31,0-674.52,758.83,84.31,84.31,84.31"
      />
    </g>
  </svg>
)

const calm = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          className="fill-current"
          y="0.74"
          width="1080.28"
          height="1079.26"
        />
      </clipPath>
    </defs>
    <rect y="0.74" width="1080.28" height="1079.26" />
    <g>
      <path
        className={inner}
        d="M540.14,516.2c196.73,0,356.25-159.52,356.25-356.25H183.91c0,196.73,159.52,356.25,356.23,356.25"
      />
      <path
        className={inner}
        d="M540.15,565.56c-196.72,0-356.25,159.52-356.25,356.25H896.39c0-196.81-159.52-356.25-356.24-356.25"
      />
    </g>
  </svg>
)

const money = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" x="-6.9" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect x="-6.9" width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M767.07,462.4V441.16c0-120.36-62.84-201.8-176.14-220.37V126.08H484.73v94.71C365.24,241.13,298,333.19,298,469.48V607.56c0,145.16,70.81,230.12,186.76,248.71V951h106.2V857.15c118.61-18.59,176.14-100.89,176.14-221.27V614.64H650.24v15.93c0,54.87-15.94,96.48-59.31,114.18V333.19c39.83,18.58,59.31,59.31,59.31,113.28V462.4Zm-352.26,154V460.63c0-57.51,23-107.09,69.92-128.33V745.64c-48.68-18.59-69.92-65.51-69.92-129.23"
      />
    </g>
  </svg>
)

const connected = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M666,556.69V542.56c0-103.67-49.48-167.28-126-167.28S416.31,438.89,416.31,542.56v14.13C416.31,660.35,463.43,724,540,724s126-63.61,126-167.27M18.15,554.34V526.06C18.15,203.29,218.42,3,543.54,3h25.91c295.67,0,492.39,197.9,492.39,492.4v87.17c0,164.91-67.13,261.51-202.61,261.51H790.92c-74.22,0-114.27-31.81-114.27-82.45V759.3H655.44c-20,50.66-60.07,91.89-146.07,91.89-148.43,0-239.14-129.58-239.14-292.14V540.21c0-164.93,88.36-292.15,227.36-292.15,87.17,0,122.51,36.51,147.25,77.76H666v-70.7H809.76v417c0,27.1,9.42,40.07,36.51,40.07H851c45.94,0,64.79-43.59,64.79-129.59V497.79c0-223.81-114.27-360.46-351-360.46H548.24c-238,0-384,136.65-384,393.45v18.84c0,256.81,148.43,395.8,402.88,395.8h245v134.3H557.67c-336.91,0-539.52-202.61-539.52-525.38"
      />
    </g>
  </svg>
)

const connective = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect x="0.14" y="0.14" width="1079.72" height="1079.72" />
    <polygon
      className={inner}
      points="357.65 943.42 221.81 943.42 376.93 136.29 512.76 136.29 357.65 943.42"
    />
    <polygon
      className={inner}
      points="696.74 943.42 560.9 943.42 716.02 136.29 851.85 136.29 696.74 943.42"
    />
    <rect
      className={inner}
      x="160.77"
      y="301.55"
      width="752.13"
      height="135.83"
    />
    <rect
      className={inner}
      x="160.77"
      y="642.32"
      width="752.13"
      height="135.83"
    />
  </svg>
)

const continuous_growth = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M417.46,207.3a148.15,148.15,0,0,0-105-43.56c-81.92,0-148.56,66.65-148.56,148.57A148.52,148.52,0,0,0,312.45,460.87H461V312.31a148.12,148.12,0,0,0-43.56-105M97.68,527.08A303.72,303.72,0,0,1,312.45,8.59c167.48,0,303.73,136.23,303.74,303.72V616H312.45a301.78,301.78,0,0,1-214.77-89"
      />
      <path
        className={inner}
        d="M619,618.83V767.4a148.57,148.57,0,0,0,297.13,0c0-81.92-66.65-148.57-148.57-148.57ZM552.77,982.17a301.76,301.76,0,0,1-89-214.77V463.68l303.73,0c167.48,0,303.72,136.26,303.74,303.74h0A303.72,303.72,0,0,1,552.77,982.17"
      />
    </g>
  </svg>
)

const direction = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1087.27" />
    <rect
      className={inner}
      x="191.5"
      y="347.99"
      width="519.56"
      height="384.02"
    />
    <polygon
      className={inner}
      points="711.05 732 711.05 347.99 903.06 540 711.05 732"
    />
  </svg>
)

const emerging = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M195.81,540A544,544,0,0,1,539.72,882.15,544,544,0,0,1,883.6,540,544,544,0,0,1,539.72,197.85,544.05,544.05,0,0,1,195.81,540"
      />
    </g>
  </svg>
)

const energized = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <path
      className={inner}
      d="M485,594.87l-4.54,88.74,59.56-66,59.52,66L595,594.87l88.75,4.53-66-59.54,66-59.54L595,484.85l4.53-88.74L540,462.06l-59.56-65.95L485,484.85l-88.73-4.53,65.94,59.54-66,59.54ZM728.91,996,540,786.69,351.08,996,365.46,714.4,83.92,728.78,293.16,539.86,83.9,350.94l281.56,14.38L351.08,83.77,540,293,728.91,83.77,714.53,365.32l281.55-14.38L786.82,539.86,996.08,728.78,714.53,714.4Z"
    />
  </svg>
)

const enhanced = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <rect
      className={inner}
      x="458.23"
      y="101.52"
      width="163.53"
      height="863.79"
    />
    <rect
      className={inner}
      x="108.11"
      y="451.65"
      width="863.79"
      height="163.53"
    />
  </svg>
)

const expanding = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <rect width="1078.34" height="1078.34" />
    <path
      style={{
        strokeMiterlimit: 10,
        strokeWidth: "68.68px",
        fill: "none",
        stroke: inner.includes("white") ? "#fff" : "#000",
      }}
      d="M590.24,854.1H851.11V593.22M221.25,485.1V224.23H482.12M243,254.09,833.28,844.32m-612-251.1V854.1H482.12M590.24,224.23H851.11V485.1m-29.85-231L246,829.37"
    />
    <rect
      x="345.73"
      y="345.72"
      width="386.89"
      height="386.89"
      transform="translate(-223.33 539.17) rotate(-45)"
    />
  </svg>
)

const for_people = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1079.72" />
      </clipPath>
    </defs>
    <rect width="1080" height="1079.72" />
    <g>
      <path
        className={inner}
        d="M727.08,464.28q0-19.54-13.52-33t-33-13.5a45.17,45.17,0,0,0-32.75,13.5,44.46,44.46,0,0,0-13.8,33q0,19,13.8,32.75t32.75,13.8a44.48,44.48,0,0,0,33-13.8,45.27,45.27,0,0,0,13.52-32.75M371.32,658Q400.06,712.58,442,741.3T540.29,770q55.73,0,98-28.72t71-83.34l-36.2-20.69Q628.23,720,540.29,720T407,637.27ZM446,464.28q0-19.54-13.5-33t-33-13.5a45.22,45.22,0,0,0-32.77,13.5,44.48,44.48,0,0,0-13.78,33q0,19,13.78,32.75t32.77,13.8a44.47,44.47,0,0,0,33-13.8A45.17,45.17,0,0,0,446,464.28m460.37,75.86q0,151.74-107.19,258.91T540.29,906.24q-151.74,0-259.21-107.19T173.61,540.14q0-152.29,107.47-259.49T540.29,173.46q151.73,0,258.91,107.19T906.39,540.14"
      />
    </g>
  </svg>
)
const forward = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1078.98" />
    <polygon
      className={inner}
      points="244.82 398.32 593.32 398.32 286.33 705.3 385.58 806.41 693.5 498.49 693.5 823.37 835.18 823.37 835.18 256.64 244.82 256.64 244.82 398.32"
    />
  </svg>
)

const forward_II = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect x="1.66" y="1.66" width="1076.69" height="1076.69" />
    <polygon
      className={inner}
      points="601.44 871.35 512.28 782.19 753.98 540.5 512.28 298.81 601.44 209.65 932.3 540.5 601.44 871.35"
    />
    <polygon
      className={inner}
      points="233.54 871.35 144.38 782.19 386.08 540.5 144.38 298.81 233.54 209.65 564.4 540.5 233.54 871.35"
    />
  </svg>
)

const goals = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1105" height="1104.71" />
      </clipPath>
    </defs>
    <rect width="1080" height="1078.34" />
    <g>
      <path
        className={inner}
        d="M552.5,236.18a314.16,314.16,0,0,0-223.57,92.6c-123.26,123.28-123.26,323.87,0,447.14a316.19,316.19,0,0,0,447.14,0c123.26-123.27,123.26-323.86,0-447.14a314.18,314.18,0,0,0-223.57-92.6m0,746.71c-115,0-223.13-44.79-304.44-126.11-167.87-167.87-167.87-441,0-608.85,81.31-81.32,189.43-126.12,304.44-126.12s223.12,44.8,304.44,126.12c167.86,167.86,167.86,441,0,608.85C775.62,938.1,667.5,982.89,552.5,982.89"
      />
      <path
        className={inner}
        d="M552.5,410.17A142.2,142.2,0,0,0,451.91,652.94c55.46,55.46,145.72,55.47,201.19,0A142.18,142.18,0,0,0,552.5,410.17m0,381.77A239.62,239.62,0,0,1,383,382.86c93.47-93.46,245.53-93.46,339,0A239.59,239.59,0,0,1,552.5,791.94"
      />
      <path
        className={inner}
        d="M552.52,529.76a22.59,22.59,0,1,0,16,6.61,22.54,22.54,0,0,0-16-6.61m0,106.65A84.06,84.06,0,1,1,612,611.81a83.79,83.79,0,0,1-59.45,24.6"
      />
    </g>
  </svg>
)
const haven = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <rect
      className={inner}
      x="204.71"
      y="416.31"
      width="695.6"
      height="495.38"
    />
    <polygon
      className={inner}
      points="900.3 416.3 204.7 416.3 552.5 151.94 900.3 416.3"
    />
  </svg>
)

const inclusive = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M759.42,953.5,649.54,843.62C817,676.11,817,403.56,649.54,236.05L759.42,126.16c228.1,228.1,228.1,599.25,0,827.34"
      />
      <path
        className={inner}
        d="M320.85,953.5C210.36,843,149.5,696.1,149.5,539.83s60.86-303.17,171.35-413.67L430.73,236.05c-81.14,81.15-125.83,189-125.83,303.78s44.69,222.65,125.83,303.79Z"
      />
    </g>
  </svg>
)

const intersectional = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M417.64,747a207.22,207.22,0,1,1,0-414.44A208.33,208.33,0,0,1,465,338.07a280.83,280.83,0,0,1,75-52.68C435,235,305,253.25,218.06,340.2A282.41,282.41,0,0,0,525.7,800.71q7.26-3,14.3-6.41a283.69,283.69,0,0,1-74.95-52.74A206.68,206.68,0,0,1,417.64,747"
      />
      <path
        className={inner}
        d="M624.82,539.78A207,207,0,0,0,465,338.07c-.7.71-1.48,1.43-2.19,2.13a282.33,282.33,0,0,0,0,399.15c.78.79,1.49,1.49,2.27,2.2A207.07,207.07,0,0,0,624.82,539.78M862,340.2c-87-86.95-217-105.23-322-54.82a282,282,0,0,1,77.21,54.82c110.07,110.06,110.07,289.08,0,399.15A283.2,283.2,0,0,1,540,794.3,282.36,282.36,0,0,0,862,340.2"
      />
    </g>
  </svg>
)

const invest = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M802,464.13V443c0-137.61-82-224.07-229.37-224.07-134.08,0-217.9,77.63-234.66,202.91H278v91.74h56.46v44.11H278v91.75h58.23c14.11,138.5,102.33,211.73,236.41,211.73C727,861.12,802,773.78,802,637V615.87H685.56v15.88c0,73.21-29.12,123.49-112.92,123.49-74.1,0-111.15-40.56-120-105.85h120V557.64H450.9V513.53H572.64V421.79H455.32c11.47-56.47,50.28-97,117.32-97,75.88,0,112.92,49.39,112.92,123.5v15.88Z"
      />
    </g>
  </svg>
)

const invest_II = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M582.58,218.9q50.56,0,91.37,10.65a449.94,449.94,0,0,1,75.39,24.83l-39.91,99.34a609.82,609.82,0,0,0-62.09-19.5,215,215,0,0,0-58.56-8q-31,0-49.67,16.85-17.73,16-17.73,57.66v48.78H676.6V525.8H521.38v49.67H676.6v77.18H520.49q0,23.05-14.19,50.56t-50.57,45.24H767.07V861.09H312.93V753.76q42.57-15.06,60.32-41.68A107.6,107.6,0,0,0,391,652.65H313.82V575.47H391V525.8H313.82V449.52H391V399.85q0-65.66,24.84-104.67a141.61,141.61,0,0,1,68.3-57.66q43.47-18.63,98.46-18.62"
      />
    </g>
  </svg>
)

const moving_together = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          className="fill-current"
          y="-25.28"
          width="1105.57"
          height="1105.28"
        />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M550.37,532c-145.79,0-264-118.18-264-264s118.19-264,264-264"
      />
      <path
        className={inner}
        d="M550.37,532c145.78,0,264,118.19,264,264s-118.19,264-264,264"
      />
      <path
        className={inner}
        d="M555.49,532c0,145.78-118.18,264-264,264s-264-118.2-264-264"
      />
      <path
        className={inner}
        d="M550.37,532c0-145.78,118.17-264,264-264s264,118.2,264,264"
      />
    </g>
  </svg>
)

const multiplication = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <rect
      className={inner}
      x="74.1"
      y="490.35"
      width="931.8"
      height="146.71"
      transform="translate(-240.44 546.94) rotate(-45)"
    />
    <polygon
      className={inner}
      points="817.57 945.01 158.69 286.14 262.42 182.4 921.31 841.27 817.57 945.01"
    />
  </svg>
)

const navigating = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <polygon
      className={inner}
      points="818.48 842.77 540 701.1 261.53 842.77 540 204.99 818.48 842.77"
    />
  </svg>
)

const no_boundaries = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M893.33,907.46H186.68V525.57c0-195.14,158.19-353.33,353.33-353.33S893.33,330.43,893.33,525.57Z"
      />
    </g>
  </svg>
)

const open = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect y="0.74" width="1079.26" height="1079.26" />
    <polygon
      className={inner}
      points="346.16 929.07 152.28 735.18 346.16 541.29 540.04 735.18 346.16 929.07"
    />
    <polygon
      className={inner}
      points="346.16 539.44 152.28 345.56 346.16 151.66 540.04 345.56 346.16 539.44"
    />
    <polygon
      className={inner}
      points="733.11 929.07 539.23 735.18 733.11 541.29 926.99 735.18 733.11 929.07"
    />
    <polygon
      className={inner}
      points="733.11 539.44 539.23 345.56 733.11 151.66 926.99 345.56 733.11 539.44"
    />
  </svg>
)

const powerful = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <polygon
      className={inner}
      points="878.65 680.07 669.68 669.39 680.36 878.36 540.14 723.05 399.93 878.36 410.6 669.39 201.64 680.07 356.95 539.85 201.64 399.64 410.6 410.31 399.93 201.35 540.14 356.66 680.36 201.35 669.68 410.31 878.65 399.64 723.34 539.85 878.65 680.07"
    />
  </svg>
)

const reference = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect className={inner} width="1080" height="1080" />
    <g className={outer} key={i}>
      <path d="M713.78,446.35A173.74,173.74,0,0,1,540,620.11c-95.91,0-173.7-77.77-173.7-173.76A173.73,173.73,0,0,1,540,272.65c96,0,173.76,77.79,173.76,173.7" />
      <path d="M783.67,712.32,540,956,296.35,712.32a344.83,344.83,0,0,1,0-487.45c134.58-134.55,352.8-134.55,487.32,0s134.58,352.82,0,487.45M0,1080H1080V0H0Z" />
    </g>
  </svg>
)

const security = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <polygon
      className={inner}
      points="540 221.96 815.42 380.98 815.42 699.01 540 858.03 264.58 699.01 264.58 380.98 540 221.96"
    />
  </svg>
)

const stability = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <rect
      className={inner}
      x="156.36"
      y="184.54"
      width="767.24"
      height="143.51"
    />
    <rect
      className={inner}
      x="156.37"
      y="468.27"
      width="767.24"
      height="143.51"
    />
    <rect
      className={inner}
      x="156.34"
      y="752.01"
      width="767.24"
      height="143.51"
    />
  </svg>
)

const team = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect className="fill-current" width="1080" height="1080" />
      </clipPath>
    </defs>
    <rect width="1080" height="1080" />
    <g>
      <path
        className={inner}
        d="M265.29,403.74a137.2,137.2,0,1,0,137.2,137.2,137.21,137.21,0,0,0-137.2-137.2"
      />
      <path
        className={inner}
        d="M540.93,128a137.2,137.2,0,1,0,137.2,137.2A137.2,137.2,0,0,0,540.93,128"
      />
      <path
        className={inner}
        d="M539.08,677.54a137.2,137.2,0,1,0,137.2,137.2,137.21,137.21,0,0,0-137.2-137.2"
      />
      <path
        className={inner}
        d="M814.73,401.85a137.2,137.2,0,1,0,137.2,137.2,137.2,137.2,0,0,0-137.2-137.2"
      />
    </g>
  </svg>
)

const transparent = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect y="0.74" width="1079.26" height="1079.26" />
    <polygon
      className={inner}
      points="540.91 523.13 422.73 107.48 659.09 107.48 540.91 523.13"
    />
    <polygon
      className={inner}
      points="528.35 527.28 150.86 316.93 318 149.79 528.35 527.28"
    />
    <polygon
      className={inner}
      points="522.4 539.09 106.75 657.28 106.75 420.91 522.4 539.09"
    />
    <polygon
      className={inner}
      points="526.55 551.65 316.2 929.14 149.06 762 526.55 551.65"
    />
    <polygon
      className={inner}
      points="538.36 557.6 656.55 973.25 420.18 973.25 538.36 557.6"
    />
    <polygon
      className={inner}
      points="550.92 553.45 928.41 763.8 761.27 930.95 550.92 553.45"
    />
    <polygon
      className={inner}
      points="556.87 541.64 972.52 423.46 972.52 659.82 556.87 541.64"
    />
    <polygon
      className={inner}
      points="552.72 529.08 763.07 151.59 930.2 318.73 552.72 529.08"
    />
  </svg>
)

const uplift = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1078.34" />
    <rect
      className={inner}
      x="132.99"
      y="693.88"
      width="270.23"
      height="270.23"
    />
    <rect
      className={inner}
      x="403.23"
      y="423.65"
      width="270.23"
      height="270.23"
    />
    <rect
      className={inner}
      x="673.47"
      y="153.4"
      width="270.23"
      height="270.23"
    />
  </svg>
)

const value = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs></defs>
    <rect width="1080" height="1080" />
    <rect
      className={inner}
      x="476.11"
      y="134.02"
      width="127.79"
      height="811.68"
    />
    <rect
      className={inner}
      x="134.16"
      y="475.95"
      width="811.68"
      height="127.8"
    />
    <rect
      className={inner}
      x="134.16"
      y="475.96"
      width="811.68"
      height="127.8"
      transform="translate(-223.57 539.96) rotate(-45)"
    />
    <rect
      className={inner}
      x="476.1"
      y="134.02"
      width="127.8"
      height="811.68"
      transform="translate(-223.57 539.96) rotate(-45)"
    />
  </svg>
)

const visibility = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          className="fill-current"
          x="-12.5"
          y="1.66"
          width="1105"
          height="1086.85"
        />
      </clipPath>
    </defs>
    <rect x="-3.57" y="1.66" width="1083.57" height="1081.77" />
    <g>
      <path
        className={inner}
        d="M540,802.35c-118.59,0-214.72-96.13-214.72-214.72S421.41,372.91,540,372.91,754.72,469,754.72,587.63,658.59,802.35,540,802.35M930.32,425.46c-215.57-215.57-565.07-215.57-780.64,0L-12.51,587.63,149.68,749.82c215.57,215.55,565.07,215.55,780.64,0l162.17-162.19Z"
      />
    </g>
  </svg>
)

const warmth = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          className="fill-current"
          y="0.74"
          width="1079.26"
          height="1079.26"
        />
      </clipPath>
    </defs>
    <rect y="0.74" width="1079.26" height="1079.26" />
    <g>
      <path
        className={inner}
        d="M843.35,286.76C770,213.4,651,213.4,577.63,286.76c-3.55,3.54-6.76,7.28-10,11l-27.34,27.33-28.71-28.71c-3.21-3.74-6.43-7.48-10-11C428.26,212,309.31,212,235.92,285.4s-73.38,192.33,0,265.72L534,847.65a9.28,9.28,0,0,0,13.12,0S839.8,556,843.35,552.48c73.37-73.37,73.37-192.33,0-265.72"
      />
    </g>
  </svg>
)
const haven_II = (outer, inner, i) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          className="fill-current"
          y="0.74"
          width="1079.26"
          height="1079.26"
        />
      </clipPath>
    </defs>

    <rect y="0.74" width="1079.26" height="1079.26" />
    <g className={inner}>
      <path
        className={inner}
        d="M539.64,159.82c-196.56,0-355.91,159.35-355.91,355.91H895.55c0-196.56-159.35-355.91-355.91-355.91"
      />
      <path
        className={inner}
        d="M539.64,565c-196.56,0-355.91,159.35-355.91,355.91H895.55C895.55,724.35,736.2,565,539.64,565"
      />
    </g>
  </svg>
)

const your_letter = (outer, inner, i, letter) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    className={outer}
    key={i}
    viewBox="0 0 1080 1080"
  >
    <rect width="1081.02" height="1080" />
    <path
      className={inner}
      d="M947.89,836.25H719.75l-44.62-99.34H381.9l-44.62,99.34H110.41L376.07,244.78H682.15Zm-343-270.8L528.45,410.59,452,565.45Z"
    />
  </svg>
)

const icons = {
  affirmative,
  audible,
  balance,
  blooming,
  calm,
  money,
  connected,
  connective,
  continuous_growth,
  direction,
  emerging,
  energized,
  enhanced,
  expanding,
  for_people,
  forward,
  forward_II,
  goals,
  haven,
  haven_II,
  inclusive,
  intersectional,
  invest,
  invest_II,
  moving_together,
  multiplication,
  navigating,
  no_boundaries,
  open,
  powerful,
  reference,
  security,
  stability,
  team,
  transparent,
  uplift,
  value,
  visibility,
  warmth,
}

export default icons
