import { useContext } from 'react'
import AdminContext from '../../../../contexts/Admin'

const NewFounderCard = ({ data }) => {
  const { countryList } = useContext(AdminContext)
  const getEmojiFlag = (countryName) => {
    const [currentCountry] = countryList.filter(
      (country) => country.name === countryName
    )
    if (currentCountry) {
      const codePoints = currentCountry?.iso2
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt())
      return String.fromCodePoint(...codePoints)
    }

    return null
  }

  return (
    <div
      className={`w-96 bg-white shadow-md flex flex-col rounded px-8 py-6 mb-4 border-t-2 ${
        data.role === 'founder'
          ? 'border-fred'
          : data.role === 'investor'
          ? 'border-fblue'
          : 'border-flime'
      }`}
    >
      <p className='text-md font-bold'>
        {data.firstName} {data.lastName}
      </p>
      <p className='text-sm mt-2'>
        {data.title ? data.title + ' - ' : ''}
        {data.companyName}
      </p>
      <p
        className={`flex-grow whitespace-prewrap ${
          data.role === 'founder' ? 'text-md' : 'text-xs'
        } overflow-y-scroll font-bold`}
      >
        {' '}
        {data.businessArea}
      </p>
      <p className='text-sm my-2'>
        {data.city ? data.city + ', ' : ''}
        {data.country} {getEmojiFlag(data.country)}
      </p>
      <p className='text-sm'>{data.email}</p>
      <p></p>
      <p className='text-sm font-bold mt-2'>
        {data.linkedin || data.socialmedia.linkedin}
      </p>
    </div>
  )
}

export default NewFounderCard
