import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { TrashIcon, MailOpenIcon } from '@heroicons/react/outline'
import Loading from '../Widgets/Loading'
import moment from 'moment'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import ConfirmModal from '../Widgets/ConfirmModal'
import Confirm from './Confirm'
import NotifyArticle from './NotifyArticle'
import AddArticle from './AddArticle'
import 'react-quill/dist/quill.snow.css'
import SunEditorBox from '../Widgets/SunEditorBox'
import 'suneditor/dist/css/suneditor.min.css'
import Notifications from './Notifications'

const articleUrl = '/api/newsfeed/id/'

const Article = () => {
  const { id } = useParams()
  const { config, reload, setCCModal, user } = useContext(AdminContext)
  const [data, setData] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [removing, setRemoving] = useState(true)
  const [error, setError] = useState(null)
  //GET DATA FROM DB WITH articleId FROM URL
  useEffect(() => {
    axios
      .get(articleUrl + id, config)
      .then((res) => {
        if (res.data) {
          setData(res.data)
          setLoading(false)
        } else {
          setError('No ressource found with this ID')
        }
      })
      .catch((err) => {})
  }, [id, reload])

  return (
    <section className='w-full flex justify-center'>
      <ConfirmModal>
        {removing ? (
          <Confirm data={data} setRemoving={setRemoving} />
        ) : (
          <NotifyArticle data={data} setRemoving={setRemoving} />
        )}
      </ConfirmModal>
      {loading ? (
        <Loading />
      ) : error ? (
        error
      ) : edit ? (
        <AddArticle article={data} edit={edit} setEdit={setEdit} />
      ) : (
        <div className='flex flex-col md:flex-row w-full pb-6 mt-5'>
          <div className='w-full md:w-2/3 border-0 px-4'>
            <header className='relative w-full mx-auto flex justify-center items-center'>
              <div className='w-full bg-fblue flex flex-col items-center justify-center'>
                <p className='border-b border-white w-full bg-black text-white text-xl font-semibold leading-tight p-2'>
                  {data.articleTitle}
                </p>
                <img
                  src='https://pbs.twimg.com/profile_banners/1396067735612891141/1625879788/1500x500'
                  alt='banner'
                  className='max-w-2xl object-cover w-full h-64'
                  style={{
                    outline: 'none',
                    textDecoration: 'none',
                    clear: 'both',
                    display: 'inline-block',
                    border: 'none',
                    height: 'auto',
                    float: 'none',
                  }}
                />
              </div>
            </header>
            <div className='relative w-full mx-auto flex justify-center items-center'>
              <SunEditorBox
                articleContent={data.articleContent}
                disable={true}
                hideToolbar={true}
                height={'100%'}
              />
            </div>
            <footer className='w-full bg-fblue mx-auto flex justify-center items-center'>
              <div className='w-full bg-fblue p-4 flex flex-col justify-center items-center'>
                <div className='w-full py-3 flex space-x-2 justify-center items-center'>
                  <a
                    href='https://www.linkedin.com/company/founderland'
                    title='LinkedIn'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      version='1.1'
                      id='Layer_1'
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      width='32'
                      style={{
                        outline: 'none',
                        textDecoration: 'none',
                        clear: 'both',
                        display: 'block',
                        border: 'none',
                        height: 'auto',
                        float: 'none',
                        maxwidth: '32px',
                        enableBackground: 'new 0 0 291.319 291.319',
                      }}
                      viewBox='0 0 291.319 291.319'
                    >
                      <g>
                        <path
                          style={{ fill: '#FFFFFF' }}
                          d='M145.659,0c80.45,0,145.66,65.219,145.66,145.66s-65.21,145.659-145.66,145.659S0,226.1,0,145.66
             S65.21,0,145.659,0z'
                        />
                        <path
                          style={{ fill: '#0063e2' }}
                          d='M82.079,200.136h27.275v-90.91H82.079V200.136z M188.338,106.077
             c-13.237,0-25.081,4.834-33.483,15.504v-12.654H127.48v91.21h27.375v-49.324c0-10.424,9.55-20.593,21.512-20.593
             s14.912,10.169,14.912,20.338v49.57h27.275v-51.6C218.553,112.686,201.584,106.077,188.338,106.077z M95.589,100.141
             c7.538,0,13.656-6.118,13.656-13.656S103.127,72.83,95.589,72.83s-13.656,6.118-13.656,13.656S88.051,100.141,95.589,100.141z'
                        />
                      </g>
                    </svg>
                  </a>
                  <a
                    href='https://www.instagram.com/founderland_/'
                    title='Instagram'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      version='1.1'
                      id='Layer_1'
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      width='32'
                      style={{
                        outline: 'none',
                        textDecoration: 'none',
                        clear: 'both',
                        display: 'block',
                        border: 'none',
                        height: 'auto',
                        float: 'none',
                        maxwidth: '32px',
                      }}
                      viewBox='0 0 260 260'
                      color='white'
                    >
                      <path
                        stroke='currentcolor'
                        fill='currentcolor'
                        d='M164.58,64H95.42C78.1,64,64,78.1,64,95.42v69.16C64,181.9,78.1,196,95.42,196h69.16c17.32,0,31.42-14.1,31.42-31.42V95.42
            C196,78.1,181.9,64,164.58,64z M130,171.1c-22.66,0-41.1-18.44-41.1-41.1s18.44-41.1,41.1-41.1s41.1,18.44,41.1,41.1
            S152.66,171.1,130,171.1z M172.22,97.3c-5.3,0-9.6-4.3-9.6-9.61c0-5.3,4.3-9.6,9.6-9.6c5.31,0,9.61,4.3,9.61,9.6
            C181.83,93,177.53,97.3,172.22,97.3z M130,102.9c-14.94,0-27.1,12.16-27.1,27.1s12.16,27.1,27.1,27.1s27.1-12.16,27.1-27.1
            S144.94,102.9,130,102.9z M130,2C59.31,2,2,59.31,2,130s57.31,128,128,128s128-57.31,128-128S200.69,2,130,2z M210,164.58
            c0,25.04-20.38,45.42-45.42,45.42H95.42C70.38,210,50,189.62,50,164.58V95.42C50,70.38,70.38,50,95.42,50h69.16
            C189.62,50,210,70.38,210,95.42V164.58z'
                      />
                    </svg>
                  </a>
                  <a
                    href='https://twitter.com/founderland_/'
                    title='Twitter'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      version='1.1'
                      id='Capa_1'
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      width='32'
                      style={{
                        outline: 'none',
                        textDecoration: 'none',
                        clear: 'both',
                        display: 'block',
                        border: 'none',
                        height: 'auto',
                        float: 'none',
                        maxwidth: '32px',
                        enableBackground: '0 0 112.197 112.197',
                      }}
                      viewBox='0 0 112.197 112.197'
                    >
                      <g>
                        <circle
                          style={{ fill: '#FFFFFF' }}
                          cx='56.099'
                          cy='56.098'
                          r='56.098'
                        />
                        <g>
                          <path
                            style={{ fill: '#0063e2' }}
                            d='M90.461,40.316c-2.404,1.066-4.99,1.787-7.702,2.109c2.769-1.659,4.894-4.284,5.897-7.417
               c-2.591,1.537-5.462,2.652-8.515,3.253c-2.446-2.605-5.931-4.233-9.79-4.233c-7.404,0-13.409,6.005-13.409,13.409
               c0,1.051,0.119,2.074,0.349,3.056c-11.144-0.559-21.025-5.897-27.639-14.012c-1.154,1.98-1.816,4.285-1.816,6.742
               c0,4.651,2.369,8.757,5.965,11.161c-2.197-0.069-4.266-0.672-6.073-1.679c-0.001,0.057-0.001,0.114-0.001,0.17
               c0,6.497,4.624,11.916,10.757,13.147c-1.124,0.308-2.311,0.471-3.532,0.471c-0.866,0-1.705-0.083-2.523-0.239
               c1.706,5.326,6.657,9.203,12.526,9.312c-4.59,3.597-10.371,5.74-16.655,5.74c-1.08,0-2.15-0.063-3.197-0.188
               c5.931,3.806,12.981,6.025,20.553,6.025c24.664,0,38.152-20.432,38.152-38.153c0-0.581-0.013-1.16-0.039-1.734
               C86.391,45.366,88.664,43.005,90.461,40.316L90.461,40.316z'
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className='w-full text-white text-center py-4 '>
                  <a
                    style={{ color: '#FFFFFF' }}
                    rel='noreferrer'
                    href='https://www.founderland.org'
                    target='_blank'
                  >
                    www.founderland.org
                  </a>{' '}
                  &copy; {moment(new Date()).format('YYYY')} Founderland
                </div>
                <div className='w-5/6 border-t-white border-t-2'></div>
              </div>
            </footer>
            <div className='max-w-3xl mx-auto border-t-2 border-gray-200'>
              <p className='text-center text-xs py-2'>
                Last updated -{' '}
                {moment(data.articleLastUpdated).format('DD MMMM YYYY')}
              </p>
            </div>
            {user.role.includes('admin') && (
              <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
                <button
                  className='flex justify-center items-center px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4'
                  onClick={() => {
                    setCCModal(true)
                  }}
                >
                  <TrashIcon className='h-5 w-5' />
                  Delete
                </button>

                <button
                  className='px-8 py-2 w-full shadow-lg sm:w-1/3 bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4'
                  onClick={() => {
                    setEdit(true)
                  }}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
          <div className='w-full md:w-1/3 border-0 px-4 flex flex-col'>
            <button
              className='flex justify-center items-center gap-2 px-10 py-2 w-full shadow-lg bg-flime transition duration-200 hover:bg-fblue hover:text-white mb-4'
              onClick={() => {
                setRemoving(false)
                setCCModal(true)
              }}
            >
              <MailOpenIcon className='h-5 w-5' />
              <span>Send to group</span>
            </button>
            <Notifications notifications={data.articleNotifications} />
          </div>
        </div>
      )}
    </section>
  )
}

export default Article
