import { PlusCircleIcon } from "@heroicons/react/outline"
import { useState, useRef, useContext } from "react"
import FastTrackContext from "../../../contexts/FastTrack"
import ListOption from "../../Admin/Widgets/ListOption"

const formatValue = (value) => {
  const newValue = value.trimStart()
  return newValue.replace(value[0], value[0]?.toUpperCase())
}

const SelectAnswer = ({ question_id, answers, handleSelect }) => {
  const [choice, setChoice] = useState({ name: "", value: "" })
  const inputRef = useRef(null)
  const { data } = useContext(FastTrackContext)
  // check if the value entered by the user is included in the object list
  const isSelectionIncluded = (object) => {
    return Object.values(object).some(
      (item) =>
        item.answer ===
        data.responses.filter(
          (response) => response.question_id === question_id
        )[0]?.answer_value
    )
  }

  const addNewField = (inputRef) => {
    setChoice([
      ...choice,
      {
        name: formatValue(inputRef.current.value),
        value: formatValue(inputRef.current.value),
      },
    ])
    handleSelect(formatValue(inputRef.current.value))
  }

  return (
    <>
      <ListOption
        options={answers.map((answer) => ({
          name: answer.answer,
          value: answer.answer,
        }))}
        required={true}
        color={"bg-sky-50 h-12 truncate"}
        format={"mt-1 w-full"}
        choice={
          isSelectionIncluded(answers) &&
          data.responses.filter(
            (response) => response.question_id === question_id
          )[0]?.answer_value
        }
        setChoice={(value) => {
          handleSelect(value)
        }}
      />
      <div
        className={
          (data.responses.filter(
            (response) => response.question_id === question_id
          )[0]?.answer_value === "Other" ||
            !isSelectionIncluded(answers)) &&
          data.responses.filter(
            (response) => response.question_id === question_id
          )[0]?.answer_value
            ? "w-full py-2 absolute "
            : "hidden"
        }
      >
        {(data.responses.filter(
          (response) => response.question_id === question_id
        )[0]?.answer_value === "Other" ||
          !isSelectionIncluded(answers)) && (
          <input
            ref={inputRef}
            type="text"
            // placeholder='Enter your business area'
            defaultValue={
              isSelectionIncluded(answers)
                ? null
                : data.responses.filter(
                    (response) => response.question_id === question_id
                  )[0]?.answer_value
            }
            className="w-full text-sm appearance-none bg-grey-50 text-grey-500 border p-2 outline-none absolute "
          />
        )}
        <button
          type="button"
          className=" absolute right-0 top-2 p-2 "
          onClick={() => addNewField(inputRef)}
        >
          <PlusCircleIcon className="w-6 h-6 hover:text-flime-900" />
        </button>
      </div>
    </>
  )
}

export default SelectAnswer
