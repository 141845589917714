import ActiveDot from './ActiveDot'

const CategoryItem = ({ text, isActive }) => {
  return (
    <div className='relative flex items-center py-2'>
      {isActive ? (
        <ActiveDot />
      ) : (
        <span className='flex h-4 w-4 relative mr-4'>
          <span className='relative inline-flex rounded-full h-4 w-4 border-white border-2 bg-black'></span>
        </span>
      )}
      <li
        className={
          'item text-lg lg:text-xl text-mono ' + (isActive && 'my-2 font-bold')
        }
      >
        {text}
      </li>
    </div>
  )
}

export default CategoryItem
