import { useState, useEffect, useContext } from 'react'
import AdminContext from '../../../contexts/Admin'
import ListWidget from '../Widgets/ListWidget'
import Loading from '../Widgets/Loading'
import axios from 'axios'
import moment from 'moment'

const styles = {
  new: 'bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs',
  pending: 'bg-yellow-200 text-yellow-600 py-1 px-3 rounded-full text-xs',
  reviewed: 'bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs',
  investor: 'bg-fred bg-opacity-50 text-red-900 py-1 px-3 rounded-full text-xs',
  founder:
    'bg-fblue bg-opacity-50 text-blue-900 py-1 px-3 rounded-full text-xs',
  ally: 'bg-flime bg-opacity-50 py-1 px-3 rounded-full text-xs',
  sadmin: 'bg-fred bg-opacity-50 py-1 px-3 rounded-full text-xs',
  admin: 'bg-fblue bg-opacity-50 py-1 px-3 rounded-full text-xs',
  user: 'bg-fpink bg-opacity-50 py-1 px-3 rounded-full text-xs',
}

const AvailableInvestorsList = ({ setAvailableInvestorsData }) => {
  const [data, setData] = useState({
    header: [
      {
        title: 'Name',
        key: 'name',
        style: 'text-left text-sm',
        showFilter: true,
      },
      {
        title: 'Location',
        key: 'location',
        style: 'text-center text-sm',
        showFilter: true,
      },
      {
        title: 'Signed up on',
        key: 'confirmedOn',
        style: 'hidden md:table-cell text-center text-sm',
        showFilter: false,
      },
      {
        title: 'Added Availability',
        key: 'createdOn',
        style: 'text-center text-sm',
        showFilter: false,
      },
      {
        title: 'Availability',
        key: 'available',
        style: 'hidden md:table-cell text-center text-sm',
        showFilter: false,
      },
      { title: '', key: '-', style: 'text-center text-sm', showFilter: false },
    ],
    data: [],
  })
  const [loading, setLoading] = useState(true)
  const { config, reload } = useContext(AdminContext)
  const availableInvestorsAPI = '/api/availability/investor'
  const [filter, setFilter] = useState([
    { key: 'name', search: '', show: false, type: 'text' },
    { key: 'location', search: '', show: false, type: 'text' },
  ])

  //FETCH DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const lastWeek = new Date()
        lastWeek.setDate(lastWeek.getDate() - 7)
        const lastMonth = new Date()
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        const result = await axios.get(availableInvestorsAPI, config)
        const dataSet = result.data
        let filteredDataSet
        filteredDataSet = [...dataSet]
        filteredDataSet.forEach((element) => {
          if (element.created) {
            element.createdOn = moment(element.created).format('DD/M/YYYY')
          }
          if (element.memberId) {
            if (element.memberId.confirmed) {
              element.confirmedOn = moment(element.memberId.confirmed).format(
                'DD/M/YYYY'
              )
            }
            element.name =
              element.memberId.firstName + ' ' + element.memberId.lastName
            element.location = element.memberId.city.length
              ? element.memberId.city
              : ''
            element.location += element.memberId.country.length
              ? ', ' + element.memberId.country
              : ''
          } else {
            return
          }
        })
        let filteredData = [...filteredDataSet].sort(
          (a, b) => new Date(a.created) - new Date(b.created)
        )
        filter.forEach(
          (term) =>
            (filteredData = [
              ...filteredData.filter((item) =>
                item[term.key]
                  ?.toLowerCase()
                  .includes(term.search?.toLowerCase())
              ),
            ])
        )
        setData({ ...data, data: [...filteredData] })
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [reload, filter])

  return loading ? (
    <Loading />
  ) : (
    <ListWidget
      title=''
      data={data}
      styles={styles}
      showing={10}
      filter={filter}
      setFilter={setFilter}
    />
  )
}

export default AvailableInvestorsList
