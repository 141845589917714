import { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import AdminContext from '../../contexts/Admin'
import CompactWidget from './Widgets/CompactWidget'
import ChartWidget from './Widgets/ChartWidget'
import ListWidget from './Widgets/ListWidget'
import Loading from './Widgets/Loading'
import { useRef } from 'react'
import { getElementsAtEvent } from 'react-chartjs-2'
const styles = {
  new: 'bg-green-200 text-green-600 mx-auto py-1 px-2 text-xs text-center w-max flex justify-center items-center rounded-xl capitalize',
  pending:
    'bg-yellow-200 text-yellow-600 mx-auto py-1 px-2 text-xs text-center w-max flex justify-center items-center rounded-xl capitalize',
  reviewed:
    'bg-purple-200 text-purple-600 mx-auto py-1 px-2 text-xs text-center w-max flex justify-center items-center rounded-xl capitalize',
  founder:
    'bg-fred bg-opacity-50 text-red-900  mx-auto py-1 px-2 text-xs text-center w-max flex justify-center items-center rounded-xl capitalize',
  investor:
    'bg-fblue bg-opacity-50 text-blue-900  mx-auto py-1 px-2 text-xs text-center w-max flex justify-center items-center rounded-xl capitalize',
  ally: 'bg-flime bg-opacity-50  mx-auto py-1 px-2 text-xs text-center w-max flex justify-center items-center rounded-xl capitalize',
  sadmin: 'border border-green-600',
  admin: 'border-fblue',
  user: 'border-fpink',
}

const applicantsURL = '/api/applicants/responses/'
const membersAPI = '/api/users/community/members/'
const membersStatsAPI = '/api/users/community/map/'
const sessionsAPI = '/api/allysession/'
const newsletterAPI = '/api/applicants/responses/newsletter/all'

const flatten = (arr) => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    )
  }, [])
}

const applicationsStatisticsDefault = {
  type: 'line',
  label: 'New Applicants',
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  },
}
const foundersStatisticsDefault = {
  type: 'pie',
  label: 'Registered Founders',
  data: {
    labels: ['Notified', 'Not Notified', 'Signed Up', 'Flag Setup'],
    datasets: [
      {
        label: 'Registered Founders',
        data: [],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        hoverOffset: 2,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  },
}
const investorsStatisticsDefault = {
  type: 'pie',
  label: 'Registered Investors',
  data: {
    labels: ['Notified', 'Not Notified', 'Signed Up'],
    datasets: [
      {
        label: 'Registered Investors',
        data: [],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        hoverOffset: 2,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  },
}
const alliesStatisticsDefault = {
  type: 'pie',
  label: 'Registered Allies',
  data: {
    labels: ['Notified', 'Not Notified', 'Signed Up'],
    datasets: [
      {
        label: 'Registered Allies',
        data: [],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        hoverOffset: 2,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  },
}
const colorsStatisticsDefault = {
  type: 'pie',
  label: 'Symbol Colors Picked',
  data: {
    labels: ['Blue', 'Red', 'Lime', 'Pink'],
    datasets: [
      {
        label: 'Colors Picked',
        data: [],
        backgroundColor: [
          'rgba(0, 99, 226, 0.2)',
          'rgba(246, 51, 28, 0.2)',
          'rgba(215, 251, 3, 0.2)',
          'rgba(238, 147, 181, 0.2)',
        ],
        borderColor: [
          'rgba(0, 99, 226, 1)',
          'rgba(246, 51, 28, 1)',
          'rgba(215, 251, 3, 1)',
          'rgba(238, 147, 181, 1)',
        ],
        hoverOffset: 2,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  },
}
const symbolsStatisticsDefault = {
  type: 'line',
  label: 'Symbols Picked',
  data: {
    labels: [],
    datasets: [{ backgroundColor: ['rgba(0, 99, 226, 0.6)'] }],
  },
  options: {
    scales: {
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
      },
    },
  },
}
const sessionsStatisticsDefault = {
  type: 'line',
  label: 'Sessions',
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value, index, values) {
            return parseInt(value)
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  },
}
const newsletterStatisticsDefault = {
  type: 'line',
  label: 'Newsletter Signups',
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value, index, values) {
            return parseInt(value)
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  },
}
const newsletterInterestsStatisticsDefault = {
  type: 'pie',
  label: 'Newsletter Interests',
  data: {
    labels: [],
    datasets: [
      {
        label: 'Newsletter Interests',
        data: [],
        backgroundColor: [],
        borderColor: [],
        hoverOffset: 2,
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  },
}

// Get Array of the last 6months
const getMonthsLabel = (n) => {
  const d = new Date()
  const currentMonth = d.getMonth()
  const currentYear = d.getFullYear()
  const locale = 'en-GB'

  let result = []
  for (let i = n; i > 0; i--) {
    d.setMonth(currentMonth - i)
    d.getMonth() > currentMonth
      ? d.setFullYear(currentYear - 1)
      : d.setFullYear(currentYear)
    result.push(
      d.toLocaleDateString(locale, { month: 'short' }) +
        '/' +
        d.toLocaleDateString(locale, { year: '2-digit' })
    )
  }
  result.push(
    new Date().toLocaleDateString(locale, { month: 'short' }) +
      '/' +
      d.toLocaleDateString(locale, { year: '2-digit' })
  )
  return result
}

const AdminDashboard = () => {
  const { config, setSatisticsFilter, satisticsFilter, history } =
    useContext(AdminContext)
  const [widgetData, setWidgetData] = useState({
    foundersWidget: {},
    investorsWidget: {},
    alliesWidget: {},
    pendingApplicants: {},
    foundersStatistics: {},
    investorsStatistics: {},
    alliesStatistics: {},
    applicationsStatistics: {},
    sessionsStatistics: {},
    colorsStatistics: {},
    symbolsStatistics: {},
    newsletterStatistics: {},
    newsletterInterestsStatistics: {},
  })
  const [loading, setLoading] = useState({
    foundersWidget: true,
    investorsWidget: true,
    alliesWidget: true,
    pendingApplicants: true,
    foundersStatistics: true,
    investorsStatistics: true,
    alliesStatistics: true,
    applicationsStatistics: true,
    sessionsStatistics: true,
    colorsStatistics: true,
    symbolsStatistics: true,
    newsletterStatistics: true,
    newsletterInterestsStatistics: true,
  })
  const [filter, setFilter] = useState([
    { key: 'name', search: '', show: false, type: 'text' },
    { key: 'email', search: '', show: false, type: 'text' },
    { key: 'role', search: '', show: false, type: 'list' },
  ])
  const lastWeek = new Date()
  lastWeek.setDate(lastWeek.getDate() - 7)
  //GET FOUNDERS COUNT
  useEffect(() => {
    axios
      .get(membersStatsAPI + 'founder', config)
      .then((res) => {
        //# from LAST 7 DAYS
        const newFounders = res.data.data.filter(
          (founder) => new Date(founder.created).valueOf() >= lastWeek.valueOf()
        )
        const data = {
          label: 'Founders',
          value: res.data.data.length,
          new: newFounders.length,
        }
        //Founders Statistics
        let statisticsData = { ...foundersStatisticsDefault }
        const flagCount = res.data.data.filter(
          (founder) => founder.flag?.public_id
        ).length
        const notifiedCount = res.data.data.filter(
          (founder) => founder.notified && !founder.confirmed
        ).length
        const signedUp = res.data.data.filter(
          (founder) => founder.confirmed && !founder.flag?.public_id
        ).length
        const notNotified = res.data.data.filter(
          (founder) => founder.notified === null
        ).length
        statisticsData.data.datasets[0].data = [
          notifiedCount,
          notNotified,
          signedUp,
          flagCount,
        ]
        //Colors & Symbols Statistics
        const colors = ['fblue', 'fred', 'flime', 'fpink']
        let colorsData = { ...colorsStatisticsDefault }
        const symbolsPicked = res.data.data
          .filter((founder) => founder.flag?.public_id)
          .map((element) => element.symbols)
        const colorsCount = colors.map((color) => {
          let colorCount = symbolsPicked
            .reduce(
              (accumulator, currentValue) => accumulator.concat(currentValue),
              []
            )
            .filter((item) => item.color === color).length
          return colorCount
        })
        colorsData.data.datasets[0].data = [...colorsCount]

        let symbolsData = { ...symbolsStatisticsDefault }
        const allSymbols = flatten(
          symbolsPicked.map((el) => el.map((el) => el.icon))
        )
        symbolsData.data.labels = allSymbols
          .filter((value, index, self) => {
            return self.indexOf(value) === index
          })
          .sort()
        const symbolsCount = symbolsData.data.labels.map((icon) => {
          let symbolCount = symbolsPicked
            .reduce(
              (accumulator, currentValue) => accumulator.concat(currentValue),
              []
            )
            .filter((item) => item.icon === icon).length
          return symbolCount
        })
        symbolsData.data.datasets[0].data = [...symbolsCount]

        setWidgetData((prev) => ({
          ...prev,
          foundersWidget: data,
          foundersStatistics: statisticsData,
          colorsStatistics: colorsData,
          symbolsStatistics: symbolsData,
        }))
        setLoading((prev) => ({
          ...prev,
          foundersWidget: false,
          foundersStatistics: false,
          colorsStatistics: false,
          symbolsStatistics: false,
        }))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [config])
  //GET INVESTORS COUNT
  useEffect(() => {
    axios
      .get(membersAPI + 'investor', config)
      .then((res) => {
        //# from LAST 7 DAYS
        const newInvestors = res.data.data.filter(
          (investor) =>
            new Date(investor.created).valueOf() >= lastWeek.valueOf()
        )
        const data = {
          label: 'Investors',
          value: res.data.data.length,
          new: newInvestors.length,
        }
        //Investors Statistics
        let statisticsData = { ...investorsStatisticsDefault }
        const notifiedCount = res.data.data.filter(
          (investor) => investor.notified && !investor.confirmed
        ).length
        const signedUp = res.data.data.filter(
          (investor) => investor.confirmed && !investor.flag?.public_id
        ).length
        const notNotified = res.data.data.filter(
          (investor) => investor.notified === null
        ).length
        statisticsData.data.datasets[0].data = [
          notifiedCount,
          notNotified,
          signedUp,
        ]
        setWidgetData((prev) => ({
          ...prev,
          investorsWidget: data,
          investorsStatistics: statisticsData,
        }))
        setLoading((prev) => ({
          ...prev,
          investorsWidget: false,
          investorsStatistics: false,
        }))
      })
      .catch((err) => {})
  }, [config])
  //GET ALLIES COUNT
  useEffect(() => {
    axios
      .get(membersAPI + 'ally', config)
      .then((res) => {
        //# from LAST 7 DAYS
        const newAllies = res.data.data.filter(
          (ally) => new Date(ally.created).valueOf() >= lastWeek.valueOf()
        )
        const data = {
          label: 'Allies',
          value: res.data.data.length,
          new: newAllies.length,
        }
        //Allies Statistics
        let statisticsData = { ...alliesStatisticsDefault }
        const notifiedCount = res.data.data.filter(
          (ally) => ally.notified && !ally.confirmed
        ).length
        const signedUp = res.data.data.filter(
          (ally) => ally.confirmed && !ally.flag?.public_id
        ).length
        const notNotified = res.data.data.filter(
          (ally) => ally.notified === null
        ).length
        statisticsData.data.datasets[0].data = [
          notifiedCount,
          notNotified,
          signedUp,
        ]
        setWidgetData((prev) => ({
          ...prev,
          alliesWidget: data,
          alliesStatistics: statisticsData,
        }))
        setLoading((prev) => ({
          ...prev,
          alliesWidget: false,
          alliesStatistics: false,
        }))
      })
      .catch((err) => {})
  }, [config])
  //GET PENDING REVIEWS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          applicantsURL + 'allpending/null',
          config
        )
        const userData = result.data.map((item) => {
          // Getting first and last name
          const firstName = item.firstName.length
            ? item.firstName
            : item.answerData.find((x) => x.question === 'First name')
                ?.answer_value

          const lastName = item.lastName.length
            ? item.lastName
            : item.answerData.find((x) => x.question === 'Last name')
                ?.answer_value

          let finalObject = {
            ...item,
            applicantName: `${firstName} ${lastName}`,
          }

          return finalObject
        })

        setWidgetData((prev) => ({
          ...prev,
          pendingApplicants: {
            header: [
              {
                title: 'Role',
                key: 'role',
                style: 'py-3 px-6 text-center',
                showFilter: false,
              },
              {
                title: 'Name',
                key: 'applicantName',
                style: 'table-cell text-left ',
                showFilter: false,
              },
              {
                title: 'Comments',
                key: 'comments',
                style: 'hidden lg:table-cell items-center text-left',
                showFilter: false,
              },
              {
                title: 'Status',
                key: 'status',
                style: 'text-center hidden md:table-cell ',
                showFilter: false,
              },
              { title: '', key: '-', style: 'text-center', showFilter: false },
            ],
            data: userData,
            colSize: [],
          },
        }))
        setLoading((prev) => ({ ...prev, pendingApplicants: false }))
      } catch (e) {}
    }
    fetchData()
  }, [config])
  //GET APPLICATION STATISTICS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(applicantsURL, config)

        let statisticsData = { ...applicationsStatisticsDefault }
        //SET LAST SIX MONTHS
        statisticsData.data.labels = [...getMonthsLabel(5)]
        //LOOP THROUGH THE LAST SIX MONTHS OF APPLICATIONS PER ROLE
        const founderApplications = result.data.filter(
          (application) => application.role === 'founder'
        )
        const investorApplications = result.data.filter(
          (application) => application.role === 'investor'
        )
        const allyApplications = result.data.filter(
          (application) => application.role === 'ally'
        )
        let applicationDataSet = [
          {
            label: 'Founders',
            backgroundColor: 'rgba(246,51,28, 0.4)',
            borderColor: 'rgba(246,51,28,1)',
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [],
          },
          {
            label: 'Investors',
            backgroundColor: 'rgba(0,99,226, 0.4)',
            borderColor: 'rgba(0,99,226,1)',
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [],
          },
          {
            label: 'Allies',
            backgroundColor: 'rgba(192,251,3, 0.4)',
            borderColor: 'rgba(192,251,3,1)',
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [],
          },
        ]
        for (let i = 0; i < 6; i++) {
          //Loop throught the months for each role
          let foundersCount = founderApplications.filter(
            (application) =>
              new Date(application.submissionDate).getMonth() ===
                new Date(statisticsData.data.labels[i]).getMonth() &&
              new Date(application.submissionDate).getFullYear() ===
                new Date().getFullYear()
          ).length
          let investorCount = investorApplications.filter(
            (application) =>
              new Date(application.submissionDate).getMonth() ===
                new Date(statisticsData.data.labels[i]).getMonth() &&
              new Date(application.submissionDate).getFullYear() ===
                new Date().getFullYear()
          ).length
          let allyCount = allyApplications.filter(
            (application) =>
              new Date(application.submissionDate).getMonth() ===
                new Date(statisticsData.data.labels[i]).getMonth() &&
              new Date(application.submissionDate).getFullYear() ===
                new Date().getFullYear()
          ).length
          applicationDataSet[0].data.push(foundersCount)
          applicationDataSet[1].data.push(investorCount)
          applicationDataSet[2].data.push(allyCount)
        }
        statisticsData.data.datasets = [...applicationDataSet]
        setWidgetData((prev) => ({
          ...prev,
          applicationsStatistics: statisticsData,
        }))
        setLoading((prev) => ({
          ...prev,
          applicationsStatistics: false,
        }))
      } catch (e) {}
    }
    fetchData()
  }, [config])
  //GET SESSIONS STATISTICS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(sessionsAPI, config)
        let statisticsData = { ...sessionsStatisticsDefault }
        //SET LAST SIX MONTHS
        statisticsData.data.labels = [...getMonthsLabel(5)]
        //LOOP THROUGH THE LAST SIX MONTHS OF APPLICATIONS PER ROLE
        let sessionsDataSet = [
          {
            label: 'Sessions',
            backgroundColor: 'rgba(0,99,226, 0.4)',
            borderColor: 'rgba(0,99,226,1)',
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [],
          },
        ]
        for (let i = 0; i < 6; i++) {
          //Loop throught the months for each role
          let sessionsCount = result.data.filter(
            (session) =>
              new Date(session.dateCreated).getMonth() ===
              new Date(statisticsData.data.labels[i]).getMonth()
          ).length
          sessionsDataSet[0].data.push(sessionsCount)
        }
        statisticsData.data.datasets = [...sessionsDataSet]
        setWidgetData((prev) => ({
          ...prev,
          sessionsStatistics: statisticsData,
        }))
        setLoading((prev) => ({
          ...prev,
          sessionsStatistics: false,
        }))
      } catch (e) {}
    }
    fetchData()
  }, [config])
  //GET NEWSLETTER STATISTICS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(newsletterAPI, config)
        let statisticsData = { ...newsletterStatisticsDefault }
        //SET LAST SIX MONTHS
        statisticsData.data.labels = [...getMonthsLabel(5)]
        //LOOP THROUGH THE LAST SIX MONTHS OF APPLICATIONS PER ROLE
        let newsletterDataSet = [
          {
            label: 'Newsletter',
            backgroundColor: 'rgba(0,99,226, 0.4)',
            borderColor: 'rgba(0,99,226,1)',
            borderWidth: 1,
            //stack: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [],
          },
        ]
        for (let i = 0; i < 6; i++) {
          //Loop throught the months for each role
          let newsletterCount = result.data.filter(
            (newsletter) =>
              new Date(newsletter.subscriptionDate).getMonth() ===
                new Date(statisticsData.data.labels[i]).getMonth() &&
              new Date(newsletter.subscriptionDate).getFullYear() ===
                new Date().getFullYear()
          ).length
          newsletterDataSet[0].data.push(newsletterCount)
        }
        statisticsData.data.datasets = [...newsletterDataSet]
        setWidgetData((prev) => ({
          ...prev,
          newsletterStatistics: statisticsData,
        }))
        setLoading((prev) => ({
          ...prev,
          newsletterStatistics: false,
        }))
        //GET UNIQUE LIST OF interests answered
        let interests = result.data.map((item) => item.interests)
        interests = interests.reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue),
          []
        )
        interests = interests.filter((value, index, self) => {
          return self.indexOf(value) === index
        })
        //GET COUNT OF EACH INTEREST
        let interestsCount = interests.map((interest) => {
          let interestCount = result.data.filter(
            (item) => item.interests === interest
          ).length
          return interestCount
        })
        //GET COLORS FOR EACH INTEREST
        let colors = interests.map((interest) => {
          const r = Math.floor(Math.random() * 256) // Red component (0-255)
          const g = Math.floor(Math.random() * 256) // Green component (0-255)
          const b = Math.floor(Math.random() * 256) // Blue component (0-255)
          const alpha = Math.random().toFixed(1) // Random alpha value between 0.0 and 1.0

          return `rgba(${r}, ${g}, ${b}, ${alpha})`
        })
        let interestsStatisticsData = {
          ...newsletterInterestsStatisticsDefault,
        }
        interestsStatisticsData.data.labels = [...interests]
        interestsStatisticsData.data.datasets[0].data = [...interestsCount]
        interestsStatisticsData.data.datasets[0].backgroundColor = [...colors]
        interestsStatisticsData.data.datasets[0].borderColor = [...colors]
        setWidgetData((prev) => ({
          ...prev,
          newsletterInterestsStatistics: interestsStatisticsData,
        }))
        setLoading((prev) => ({
          ...prev,
          newsletterInterestsStatistics: false,
        }))
      } catch (e) {
        console.log(e)
      }
    }
    fetchData()
  }, [config])

  //Chart filters
  const membersChart = useRef()
  const membersFilterHandler = (event) => {
    const elementClicked = getElementsAtEvent(membersChart.current, event)
    if (elementClicked.length === 0) return
    const filterLabel =
      widgetData.foundersStatistics.data.labels[elementClicked[0].index]
    setSatisticsFilter({ ...satisticsFilter, search: filterLabel })
    history.push('members')
  }
  const investorsChart = useRef()
  const investorsFilterHandler = (event) => {
    return
  }
  const alliesChart = useRef()
  const alliesFilterHandler = (event) => {
    return
  }
  const applicantsChart = useRef()
  const applicantsFilterHandler = (event) => {
    return
  }
  const sessionsChart = useRef()
  const sessionsFilterHandler = (event) => {
    return
  }
  const colorsChart = useRef()
  const symbolsChart = useRef()
  const newsletterChart = useRef()
  const interestsChart = useRef()

  return (
    <div className='h-full flex flex-col w-full px-3 overflow-auto'>
      <div className='flex-none md:flex w-full'>
        <CompactWidget
          loading={loading.foundersWidget}
          data={widgetData.foundersWidget}
        />
        <CompactWidget
          loading={loading.investorsWidget}
          data={widgetData.investorsWidget}
        />
        <CompactWidget
          loading={loading.alliesWidget}
          data={widgetData.alliesWidget}
        />
      </div>
      <div className='w-full flex flex-col md:flex-row bg-white'>
        <div className=' flex-none md:flex w-full  md:w-1/3 '>
          <div className='w-full min-h-full px-2'>
            <ChartWidget
              loading={loading.foundersStatistics}
              data={widgetData.foundersStatistics}
              chartRef={membersChart}
              onSetClick={membersFilterHandler}
            />
          </div>
        </div>
        <div className=' flex-none md:flex w-full  md:w-1/3 '>
          <div className='w-full min-h-full px-2'>
            <ChartWidget
              loading={loading.investorsStatistics}
              data={widgetData.investorsStatistics}
              chartRef={investorsChart}
              onSetClick={investorsFilterHandler}
            />
          </div>
        </div>
        <div className=' flex-none md:flex w-full  md:w-1/3 '>
          <div className='w-full min-h-full px-2'>
            <ChartWidget
              loading={loading.alliesStatistics}
              data={widgetData.alliesStatistics}
              chartRef={alliesChart}
              onSetClick={alliesFilterHandler}
            />
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col md:flex-row bg-white'>
        <div className='w-full min-h-full px-2'>
          <ChartWidget
            loading={loading.applicationsStatistics}
            data={widgetData.applicationsStatistics}
            chartRef={applicantsChart}
            onSetClick={applicantsFilterHandler}
          />
        </div>
      </div>
      <div className='w-full bg-white'>
        {loading.pendingApplicants ? (
          <Loading />
        ) : (
          <ListWidget
            title='Latest Pending Applicants'
            data={widgetData.pendingApplicants}
            showing={5}
            styles={styles}
            link='applicants/id/'
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div>
      <div className='w-full flex flex-col md:flex-row bg-white'>
        <div className=' flex-none md:flex w-full  md:w-1/3 '>
          <div className='w-full min-h-full px-2'>
            <ChartWidget
              loading={loading.newsletterInterestsStatistics}
              data={widgetData.newsletterInterestsStatistics}
              chartRef={interestsChart}
              onSetClick={() => {
                return
              }}
            />
          </div>
        </div>
        <div className=' flex-none md:flex w-full  md:w-2/3 '>
          <div className='w-full min-h-full px-2'>
            <ChartWidget
              loading={loading.newsletterStatistics}
              data={widgetData.newsletterStatistics}
              chartRef={newsletterChart}
              onSetClick={() => {
                return
              }}
            />
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col md:flex-row bg-white'>
        <div className='w-full min-h-full px-2'>
          <ChartWidget
            loading={loading.sessionsStatistics}
            data={widgetData.sessionsStatistics}
            chartRef={sessionsChart}
            onSetClick={sessionsFilterHandler}
          />
        </div>
      </div>
      <div className='w-full flex flex-col md:flex-row bg-white'>
        <div className=' flex-none md:flex w-full  md:w-1/3 '>
          <div className='w-full min-h-full px-2'>
            <ChartWidget
              loading={loading.colorsStatistics}
              data={widgetData.colorsStatistics}
              chartRef={colorsChart}
              onSetClick={() => {
                return
              }}
            />
          </div>
        </div>
        <div className='flex-none md:flex w-full  md:w-2/3'>
          <div className='w-full min-h-full px-2'>
            <ChartWidget
              loading={loading.symbolsStatistics}
              data={widgetData.symbolsStatistics}
              chartRef={symbolsChart}
              tooltip={true}
              onSetClick={() => {
                return
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard
