import { memo } from "react"
import icons from "../../../assets/icons/Icons"

const Tooltip = ({ data, position, visibility }) => {
  return (
    <div
      className={`absolute p-1 rounded-lg shadow-lg bg-white overflow-hidden transition-all duration-300
        ${visibility ? "visible" : "invisible"}
          `}
      style={{
        top: position?.top,
        left: position?.left,
      }}
    >
      {data && (
        <>
          {icons[data.title[0]](
            `w-10 h-10 text-fblue fill-current`,
            `text-white fill-current`,
            0
          )}
          {data.dataPoints[0].raw}
        </>
      )}
    </div>
  )
}

export default memo(Tooltip)
