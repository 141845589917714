import { Doughnut, Bar, Pie } from "react-chartjs-2"
import "chart.js/auto"
import Loading from "./Loading"
import Tooltip from "./Tooltip"
import { useState } from "react"
import { useCallback } from "react"

const ChartWidget = ({ data, loading, chartRef, onSetClick, tooltip }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [tooltipData, setTooltipData] = useState(null)
  const [tooltipPos, setTooltipPos] = useState(null)

  const customTooltip = useCallback((context) => {
    if (context.tooltip.opacity === 0) {
      // hide tooltip visibilty
      setTooltipVisible(false)
      return
    }

    const chart = chartRef.current
    const canvas = chart.canvas
    if (canvas) {
      // enable tooltip visibilty
      setTooltipVisible(true)

      // set position of tooltip
      const left = context.tooltip.x
      const top = context.tooltip.y

      // handle tooltip multiple rerender
      if (tooltipPos?.top !== top) {
        setTooltipPos({ top: top, left: left })
        setTooltipData(context.tooltip)
      }
    }
  })
  if (tooltip && data?.options?.plugins) {
    data.options.plugins.tooltip = {
      ...data.options.plugins.tooltip,
      external: customTooltip,
    }
  }

  return (
    <div className="w-full mb-4">
      <div className="bg-white md:h-96 shadow-md md:shadow-lg">
        <div className="w-full h-full flex flex-col px-3 py-4 text-center">
          <h4 className="text-sm uppercase text-gray-500 leading-tight my-3">
            {data?.label}
          </h4>
          <div className="relative flex-grow px-8">
            {loading ? (
              <Loading />
            ) : data.type === "doughnut" ? (
              <Doughnut
                data={data.data}
                options={data.options}
                ref={chartRef}
                onClick={onSetClick}
              />
            ) : data.type === "pie" ? (
              <Pie
                data={data.data}
                options={data.options}
                ref={chartRef}
                onClick={onSetClick}
              />
            ) : (
              <Bar
                data={data.data}
                options={data.options}
                ref={chartRef}
                onClick={onSetClick}
              />
            )}
            {tooltipPos && (
              <Tooltip
                data={tooltipData}
                position={tooltipPos}
                visibility={tooltipVisible}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartWidget
