import { useContext } from "react"
import Button from "./Components/Button"
import TextInput from "./Components/TextInput"
import Toggle from "./Components/Toggle"
import SignUpContext from "../../../contexts/SignUp"
import { useFormikContext } from "formik"
import gifLogo from "../../../assets/images/logo-animation.gif"
import Banner from "./Components/Banner"
import { Transition } from "@headlessui/react"
import { useState } from "react"


const CreateProfile = () => {
  const { handleBack, activeStep } = useContext(SignUpContext)
  const { isSubmitting, values } = useFormikContext()
  const currentStep = values.role === 'founder' ? 5 : 3
  const [isActive, setIsActive] = useState(activeStep === currentStep)

  const back = () => {
    setIsActive(false)
    setTimeout(() => {
      handleBack()
    }, 200)
  }

  return (
    <div className="max-h-screen w-full flex flex-col items-center overflow-y-scroll">
      <Banner />
      <Transition
        appear={true}
        show={isActive}
        enter="transform transition-all ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transform transition-all ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {isSubmitting ? (
          <div className="form h-full flex flex-col justify-start items-center  px-6 lg:px-12">
            <img src={gifLogo} alt="founderland logo" className="max-h-80" />{" "}
            <p
              className={`w-full lg:w-2/3 lead text-center transition-all duration-400 `}
            >
              One moment while we set up your profile and log you into your new
              community platform.
            </p>
          </div>
        ) : (
          <div className="form h-full flex flex-col justify-start items-center  px-6 lg:px-12">
            <h2 className="w-full text-center">Setup your account</h2>
            <section>
              <h3 className="w-full text-left">choose a password</h3>
              <input
                type="hidden"
                name={"email"}
                value={values.email}
                placeholder="username"
              />
              <TextInput
                type={"password"}
                label={"Password"}
                placeholder={""}
                name={"password"}
                required
                footNotes={
                  "Minimum eight characters, at least one upper case letter, one lower case letter, and one number"
                }
              />
              <TextInput
                type={"password"}
                label={"Confirm Password"}
                placeholder={""}
                name={"confirmPassword"}
                required
              />
              <h2 className="text-left">Privacy terms</h2>
              <p className="paragraph text-justify">
                To provide and continue to improve a high quality experience for
                all Founders, we use analytical and preferences cookies. These
                are necessary for the site to work properly and to give us
                information about its usage.
              </p>
              <div className="my-1">
                <Toggle
                  label={"Technical and preference cookies (Required)"}
                  name={"privacyConsent.main"}
                  required
                  status={"locked"}
                />
                <Toggle
                  label={"Analytics cookies (Optional)"}
                  name={"privacyConsent.analytics"}
                  required
                />
              </div>
              <p className="paragraph text-justify ">
                By using this site, you accept our terms and conditions. You may
                read our data protection and privacy terms{" "}
                <a
                  href="https://www.founderland.org/privacy-policy"
                  alt="privacy terms details"
                  rel="noreferrer"
                  target="_blank"
                  className="text-black hover:text-fblue font-bold underline"
                >
                  here
                </a>
              </p>
              <div className="w-full flex justify-between mt-12 lg:mt-16">
                <Button
                  label={"< Back"}
                  styled={"back"}
                  type={"button"}
                  action={back}
                />
                <Button
                  label={"Accept and Create Account"}
                  styled={"next"}
                  type={"submit"}
                />
              </div>
              <div className="h-24 w-full"></div>
            </section>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default CreateProfile
