import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { useEffect, useState, useContext } from "react"
import axios from "axios"
import AdminContext from "../../../contexts/Admin"
import Banner from "../Widgets/Banner"
import Loading from "../Widgets/Loading"
import AddAssociates from "./AddAssociates"
import ConfirmModal from "../Widgets/ConfirmModal"
import ConfirmRemove from "./ConfirmRemove"

import moment from "moment"
import {
  TrashIcon,
  HashtagIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  UserGroupIcon,
  PencilAltIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline"
import AddFirm from "./AddFirm"
import AssociateCard from "./AssociateCard"
import EditPicture from "./EditPicture"

const firmUrl = "/api/users/community/firm/"

const styles = {
  founder: "fred",
  investor: "fblue",
  ally: "flime",
}
const defaultProfile = {
  photo: null,
  locked: false,
  _id: null,
  companyName: null,
  companyBio: null,
  city: null,
  country: null,
  geoLocation: [],
  created: null,
  members: [],
  mainContact: null,
  investingIn: [],
  stagesIn: [],
  lastUpdate: null,
}
const FirmProfile = () => {
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const [editingLogo, setEditingLogo] = useState(false)
  const [addingAssociates, setAddingAssociates] = useState(false)
  const [saving, setSaving] = useState(false)
  const [profile, setProfile] = useState(defaultProfile)
  const [emojiFlags, setEmojiFlags] = useState(null)
  const [banner, setBanner] = useState({ show: false })
  const [removing, setRemoving] = useState(false)

  const { id } = useParams()
  const { config, user, setCCModal, countryList, reload } =
    useContext(AdminContext)

  const getEmojiFlag = (countryName) => {
    const [currentCountry] = countryList.filter(
      (country) => country.name === countryName
    )
    if (currentCountry) {
      const codePoints = currentCountry?.iso2
        .toUpperCase()
        .split("")
        .map((char) => 127397 + char.charCodeAt())
      return String.fromCodePoint(...codePoints)
    }
    return null
  }

  //GET PROFILE
  useEffect(() => {
    if (!profile._id) setLoading(true)
    const getProfile = async () => {
      try {
        const { data } = await axios.get(firmUrl + id, config)
        if (data) {
          const mainContact = data[0].members.length
            ? data[0].members.filter((member) => {
                return member.main
              })
            : null
          setProfile({
            ...data[0],
            mainContact: mainContact?.length ? mainContact[0].member : null,
          })
          setEmojiFlags(getEmojiFlag(data[0].country))
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
        setBanner({
          error: 1,
          show: true,
          message: "Error getting profile data!",
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    }
    getProfile()
    return () => {
      setProfile(defaultProfile)
    }
  }, [id, reload])

  //REMOVE ACCOUNT
  const remove = async () => {
    setRemoving(true)
    setCCModal(true)
  }

  const addAssociates = async () => {
    setAddingAssociates(true)
    setCCModal(true)
  }

  const editLogo = async () => {
    setEditingLogo(true)
    setCCModal(true)
  }

  const updateMembers = async (members) => {
    const mainContact = members.length
      ? members.filter((member) => {
          return member.main
        })
      : null
    setProfile((prev) => ({
      ...prev,
      members: members,
      mainContact: mainContact?.length ? mainContact[0].member : null,
    }))
  }

  return (
    <section className="h-full w-full xl:w-5/6 mx-auto">
      {loading ? (
        <Loading />
      ) : editing ? (
        <AddFirm firm={profile} edit={editing} setEdit={setEditing} />
      ) : (
        <>
          <ConfirmModal>
            {removing ? (
              <ConfirmRemove data={profile} setRemoving={setRemoving} firm />
            ) : editingLogo ? (
              <EditPicture
                data={profile}
                setProfile={setProfile}
                setEditingLogo={setEditingLogo}
              />
            ) : addingAssociates ? (
              <AddAssociates
                members={profile.members}
                firm={profile}
                updateMembers={updateMembers}
              />
            ) : (
              ""
            )}
          </ConfirmModal>
          <div className="pt-8 w-full flex items-center justify-center z-20">
            <Banner message={banner} />
          </div>
          <div className="w-full flex-none md:flex no-wrap md:-mx-2">
            <div
              className={`w-full md:w-1/3 md:mx-2 shadow bg-white p-3 border-t-8 border-fblue`}
            >
              <div className="image overflow-hidden p-0">
                <div className="mx-auto relative p-0 w-64 h-64 md:h-52 md:w-52 xl:w-64 xl:h-64">
                  <img
                    src={profile.logo ? profile.logo.url : "null"}
                    alt="Logo"
                    className="relative w-full h-full object-cover"
                  />
                  <button
                    className="absolute bottom-0 right-0 px-4 py-2 bg-flime hover:bg-fblue hover:text-white"
                    onClick={() => {
                      editLogo()
                    }}
                  >
                    <PencilAltIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
              <p className="text-gray-900 uppercase font-bold text-xl my-2">
                {profile.companyName}
              </p>
              <p className="mb-1">
                {profile.companyLink ? (
                  <a
                    href={profile.companyLink}
                    className="text-xs text-gray-600 hover:text-sky-600"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {profile.companyLink}
                  </a>
                ) : (
                  ""
                )}
              </p>
              <div className="py-2 text-sm">
                {profile.city}
                {profile.country?.length
                  ? `, ${profile.country} ${
                      emojiFlags !== null ? emojiFlags : ""
                    }`
                  : ""}
              </div>
              <div className="bg-gray-100 bg-opacity-60 text-gray-700 py-1 px-3 mt-3 divide-y ">
                <div className="flex items-center py-3">
                  <p className="uppercase text-sm text-grotesk">Status</p>
                  {profile.locked ? (
                    <div className="ml-auto bg-red-500 py-1 px-2 text-white text-sm shadow">
                      Locked
                    </div>
                  ) : profile.members?.length ? (
                    <div className="ml-auto bg-green-500 py-1 px-2 text-white text-sm shadow">
                      Active
                    </div>
                  ) : (
                    <div className="ml-auto bg-yellow-500 py-1 px-2 text-white text-sm shadow">
                      Unrepresented
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-start justify-center py-3">
                  <div className="w-full grid grid-cols-2 gap-x-2">
                    <div className="ml-auto w-full text-center">
                      <p className="text-xs text-grotesk">Added on</p>
                      <p className="font-bold text-xs md:text-sm">
                        {moment(profile.created).format("DD/M/YYYY")}
                      </p>
                    </div>
                    <div className="ml-auto w-full text-center">
                      <p className="text-xs text-grotesk">Last Update</p>
                      <p className="font-bold text-xs md:text-sm">
                        {moment(profile.lastUpdate).format("DD/M/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center py-3">
                  <p className="uppercase text-sm text-grotesk ">
                    Main contact
                  </p>
                  <div className="w-full text-right">
                    {profile.mainContact ? (
                      <Link
                        to={`/admin/members/id/${profile.mainContact._id}`}
                        className="flex space-x-4 items-center justify-end font-bold hover:text-sky-600 text-sm"
                      >
                        <p>
                          {profile.mainContact.firstName +
                            " " +
                            profile.mainContact.lastName}
                        </p>
                        <ChevronRightIcon className="h-4" />
                      </Link>
                    ) : (
                      <>
                        <p className="text-sm text-right">
                          Missing main contact
                        </p>
                        <p className="text-xxs">
                          You may select a main contact from the list of
                          associates
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4"></div>
            <div
              className={`w-full md:w-2/3 md:mx-1 shadow bg-white p-3 border-t-8 border-fblue`}
            >
              <div className="bg-white p-3 text-base text-gray-700">
                <div className="flex items-center space-x-2 font-semibold uppercase text-sm my-2">
                  <OfficeBuildingIcon
                    className={`h-5 w-5 text-${styles[profile.role]}`}
                  />
                  <span className="tracking-wider text-grotesk">Details</span>
                </div>
                <div className="grid md:grid-cols-1 gap-2">
                  <div className="grid grid-cols-1">
                    <div className="p-2 uppercase text-xs font-bold text-gray-400">
                      Company Bio
                    </div>
                    <div className="p-2 text-base">{profile.companyBio}</div>
                  </div>
                  <div className="grid grid-cols-1">
                    <div className="p-2 uppercase text-xs font-bold text-gray-400">
                      Investment Thesis
                    </div>
                    <div className="p-2 text-base">{profile.companyThesis}</div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-3 text-base text-gray-700">
                <div className="flex items-center space-x-2 font-semibold uppercase text-sm my-2">
                  <HashtagIcon
                    className={`h-5 w-5 text-${styles[profile.role]}`}
                  />
                  <span className="tracking-wider text-grotesk">About</span>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col">
                    <div className="p-2 uppercase text-xs font-bold text-gray-400">
                      Investing In
                    </div>
                    <div className="p-2 text-sm flex flex-col">
                      {profile.investingIn.map((sector) => (
                        <div
                          key={sector}
                          className="flex space-x-2 justify-start items-center"
                        >
                          <ChevronRightIcon className="w-3 h-3" />
                          <p className="text-sm text-mono">{sector}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="p-2 uppercase text-xs font-bold text-gray-400">
                      Stages Interest
                    </div>
                    <div className="p-2 text-sm flex flex-col">
                      {profile.stagesIn.map((stage) => (
                        <div
                          key={stage}
                          className="flex space-x-2 justify-start items-center"
                        >
                          <ChevronRightIcon className="w-3 h-3" />
                          <p className="text-sm text-mono">{stage}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-3 text-base text-gray-700">
                <div className="flex items-center space-x-2 font-semibold uppercase text-sm my-2">
                  <UserGroupIcon
                    className={`h-5 w-5 text-${styles[profile.role]}`}
                  />
                  <span className="tracking-wider text-grotesk">
                    Associates
                  </span>
                  <PlusCircleIcon
                    className="w-4 h-4 text-gray-700 hover:text-green-700 cursor-pointer"
                    onClick={() => {
                      addAssociates()
                    }}
                  />
                </div>
                <div className="flex flex-col max-h-40 overflow-hidden">
                  <div className="flex flex-col  overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-fblue">
                    {profile.members.map((member) => (
                      <AssociateCard
                        key={member._id}
                        associate={member.member}
                        firm={profile}
                        setBanner={setBanner}
                        updateMembers={updateMembers}
                        main={member.main}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 flex flex-col sm:flex-row items-center justify-around ">
            <button
              className="flex items-center justify-center space-x-2 px-8 py-2 w-5/6 shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fblue text-white mb-4 "
              onClick={() => {
                setEditing(!editing)
              }}
            >
              <PencilAltIcon className="w-6 h-6" />
              <p className="text-sm md:text-base">Edit</p>
            </button>
            {user.role.includes("sadmin") && id !== "61814cbf5f7dd7305e7615f5" && (
              <button
                className={`flex items-center justify-center space-x-2 px-8 py-2 w-5/6 shadow-lg sm:w-1/3 transition duration-200 ${
                  editing
                    ? "bg-fblue hover:bg-flime hover:text-black"
                    : "bg-fred-700 hover:bg-fred-100"
                } mb-4  text-white`}
                onClick={() => {
                  remove()
                }}
              >
                {saving ? (
                  <div className="flex justify-center">
                    <div
                      style={{
                        borderTopColor: "transparent",
                      }}
                      className="w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin"
                    ></div>
                  </div>
                ) : (
                  <>
                    <TrashIcon className="w-6 h-6" />
                    <p className="text-sm md:text-base">Remove Firm</p>
                  </>
                )}
              </button>
            )}
          </div>
        </>
      )}
    </section>
  )
}

export default FirmProfile
