import { useState, useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'
import icons from '../../../assets/icons/Icons'
import { ReactComponent as SmallLogo } from '../../../assets/small.svg'

const DropZoneProfilePicture = ({
  data,
  setData,
  type,
  setUploadStatus,
  noPreview,
  config,
}) => {
  const getUuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  }
  const [previewSource, setPreviewSource] = useState(null)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [cropData, setCropData] = useState('#')
  const [cropper, setCropper] = useState()
  const finalPreview = useRef(null)
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL())
      uploadImage(cropper.getCroppedCanvas().toDataURL())
      setPreviewSource(cropper.getCroppedCanvas().toDataURL())
      scrollToRef(finalPreview)
    }
  }

  // CLOUDINARY
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onerror = () => console.log('file reading has failed')
    reader.onloadend = () => {
      const binaryStr = reader.result

      if (!noPreview) {
        setPreviewSource(binaryStr)
        setImage(binaryStr)
      } else {
        uploadImage(binaryStr)
      }
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const uploadImage = async (base64EncodedImage) => {
    setLoading(true)
    try {
      const result = await axios.post(
        '/api/profile-picture/upload',
        {
          data: base64EncodedImage,
          public_id: `${type}`, //-${Date.now()}
          folder: type.includes('tool')
            ? `flags/${data.firstName || getUuid()}`
            : `profiles/${data.firstName}-${data.lastName}-${
                data._id || data.id
              }`,
        },
        config
      )

      if (result.data?.url) {
        setUploadStatus({
          success: true,
          message: result.data.message,
        })
        if (type.includes('profile') || type.includes('tool')) {
          setData((prev) => ({
            ...prev,
            photo: {
              public_id: result.data.public_id,
              url: result.data.url,
            },
          }))
        } else {
          setData((prev) => ({
            ...prev,
            flag: {
              public_id: result.data.public_id,
              url: result.data.url,
            },
          }))
        }
        setLoading(false)
        setTimeout(() => {
          setUploadStatus({
            success: false,
            message: '',
          })
          // setPreviewSource(null)
        }, 4000)
      }
    } catch (e) {
      console.log(e)
      setUploadStatus({
        success: false,
        message: e.response?.data.message || 'Sorry something went wrong',
      })
      setLoading(false)
      setTimeout(() => {
        setUploadStatus({
          success: false,
          message: '',
        })
      }, 4000)
    }
  }

  return (
    <>
      {image &&
        previewSource &&
        (type.includes('profile') || type.includes('tool')) && (
          <div className='relative flex flex-col w-5/6 lg:w-4/6 mx-auto justify-center items-center'>
            <h1 className='w-full my-1 text-grotesk text-center text-xs'>
              ℹ️&nbsp;&nbsp;Scroll to zoom in or out of the picture. Adjust the
              area to crop and click the Use button to upload your picture!
            </h1>
            <Cropper
              style={{ height: '200px', width: '100%' }}
              zoomTo={0.5}
              initialAspectRatio={1}
              aspectRatio={
                type.includes('profile') || type.includes('tool') ? 1 : 8 / 4
              }
              preview='.img-preview'
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance)
              }}
              guides={true}
            />
            <button
              className='absolute bottom-0 right-0 text-xl bg-fblue hover:bg-flime text-white hover:text-black py-2 px-6 m-4'
              onClick={getCropData}
            >
              Use
            </button>
          </div>
        )}
      <div
        {...getRootProps()}
        className='w-full h-full flex flex-col justify-center items-center border-dashed border-4 border-black-600 p-4 m-3 rounded-xl'
      >
        <input {...getInputProps()} />
        {loading && (
          <div className='flex justify-center'>
            <span
              style={{ borderTopColor: 'transparent' }}
              className='w-16 h-16 border-8 border-black  border-dotted rounded-full animate-spin'
            ></span>
          </div>
        )}
        {!noPreview && !type.includes('tool') && (
          <div className='flex flex-col md:flex-row'>
            {!loading &&
              ((previewSource || data.photo?.public_id) &&
              type.includes('profile') ? (
                <>
                  <div className='relative m-1 w-full md:w-1/2 flex-none'>
                    <p className='text-xs'>Profile Flag Preview</p>
                    <div className=' relative p-0 h-48 w-48'>
                      <img
                        src={previewSource ? previewSource : data.photo.url}
                        alt='chosen'
                        className='w-full h-full object-cover'
                      />
                      {data.role === 'founder' &&
                        data.symbols.map((symbol, i) =>
                          icons[symbol.icon](
                            `m-0 absolute bottom-0 inset-2/3 inset-x-${
                              i === 0 ? 0 : i === 1 ? '1/3' : '2/3'
                            } w-1/3 h-1/3 text-${symbol.color} fill-current`,
                            `${
                              symbol.color === 'flime'
                                ? 'text-black'
                                : 'text-white'
                            } fill-current`,
                            i
                          )
                        )}
                    </div>
                  </div>
                  <div className={`m-1 w-full md:w-1/2 flex-none`}>
                    <p className='text-xs'>Badge Preview</p>
                    <img
                      src={previewSource ? previewSource : data.photo.url}
                      style={{
                        width: '200px',
                        height: '200px',
                      }}
                      alt='chosen'
                      className='flex-none p-4 object-cover rounded-full'
                    />
                  </div>
                </>
              ) : previewSource || data.flag?.public_id ? (
                <div className='w-full flex flex-col justify-center items-center'>
                  <p>Flag Preview</p>
                  <div className='relative p-0 w-full md:w-4/6'>
                    <img
                      src={previewSource ? previewSource : data.flag.url}
                      alt='chosen'
                      className='w-full h-full object-cover'
                    />

                    {data.symbols.map((symbol, i) =>
                      icons[symbol.icon](
                        `absolute right-0 inset-y-${
                          i === 0 ? 0 : i === 1 ? '1/4' : '2/4'
                        } h-1/4 text-${symbol.color} fill-current`,
                        `${
                          symbol.color === 'flime' ? 'text-black' : 'text-white'
                        } fill-current`,
                        i
                      )
                    )}
                    <SmallLogo className='absolute right-0 h-1/4 z-20 text-white bg-black fill-current inset-y-3/4' />
                    <div
                      className={`bg-${data.bannerColor} absolute bottom-0 h-1/4 w-full flex items-center justify-start`}
                    >
                      <p
                        className={`w-5/6 pt-1 text-center text-${
                          data.bannerColor === 'flime' ? 'black' : 'white'
                        } uppercase text-hanson font-bold text-xl tracking-widest`}
                      >
                        {data.firstName}'s Land
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                'A preview will be displayed here'
              ))}
          </div>
        )}
        <p
          className='block uppercase text-gray-600 text-sm font-bold my-2 text-center'
          ref={finalPreview}
        >
          Drag and drop your photo here, or click here to select it from your
          folders
        </p>
      </div>
    </>
  )
}

export default DropZoneProfilePicture
