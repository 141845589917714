import { useField } from "formik"
import { at } from "lodash"

const TextArea = ({
  label,
  placeholder,
  name,
  notes,
  footNotes,
  type,
  required,
  rows,
  max,
}) => {
  const [field, meta] = useField({ name, type, placeholder, label })
  const [touched, error] = at(meta, "touched", "error")

  const getRemainingLength = (value, max) => {
    if (value.length) {
      return max - value.length
    }
    return " "
  }

  return (
    <div className="w-full mt-4 lg:mt-8">
      <label className="block text-black text-grotesk text-base font-bold lg:text-xl">
        {label} {required ? "*" : "(optional)"}
      </label>
      {notes && (
        <p className="mt-2 text-base text-grotesk lg:text-xl">{notes}</p>
      )}
      <textarea
        {...field}
        className={`w-full mt-2 ${
          !touched
            ? "border-l-5 border-black"
            : error
            ? "border-l-5 border-fred"
            : meta.value !== "" && meta.value !== null
            ? "border-l-5 border-valid"
            : "border-l-5 border-black"
        } text-base text-grotesk border-2 px-6 py-4 outline-none appearance-none lg:text-xl`}
        rows={rows}
      />
      {(footNotes || error) && (
        <div
          className={`relative mt-2 text-base text-grotesk lg:text-xl ${
            touched && error ? "text-fred " : "text-fgray"
          }`}
        >
          {footNotes || meta.error}
          <span className="absolute right-0">
            {getRemainingLength(meta.value, max)}
          </span>
        </div>
      )}
    </div>
  )
}

export default TextArea
