import StepWizard from 'react-step-wizard'
import axios from 'axios'
import { useEffect, useState } from 'react'
import FormPage from './Forms/FormPage'
import symbolsVertical from '../assets/images/symbol_vertical_big.png'
import whiteLogo from '../assets/images/twoLinesWhite.svg'
import CategoryItem from './Forms/CategoryItem'
import symbolsHorizontal from '../assets/images/SymbolsHorizontal.png'
import AnswersContext from '../contexts/Answers'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const analyticsId = process.env.REACT_APP_ANALYTICS

const Form = ({ match, memberType, questionPreview }) => {
  if (match?.params.memberType) {
    memberType = match.params.memberType
  }

  const [questions, setQuestions] = useState([])
  const [activeStep, setactiveStep] = useState(0)
  const [categoryNames, setCategoryNames] = useState([])
  const [pagesInCategory, setPagesInCategory] = useState([])
  const [formatedQuestions, setFormatedQuestions] = useState([])
  const [user, setUser] = useState({
    privacyConsent: { main: false, analytics: false },
  })
  const [answers, setAnswers] = useState([])
  const [submit, setSubmit] = useState(false)
  const [next, setNext] = useState(false)
  const [prev, setPrev] = useState(false)
  const [viewButton, setViewButton] = useState(false)
  const [viewId, setViewId] = useState({})
  const location = useLocation()
  const role = location.pathname.split('/form/')[1]
  let totalscore = 0

  useEffect(() => {
    const fetchQuestions = async () => {
      const result = await axios.get(`/api/form/${memberType}/questions`)
      try {
        const { data } = result
        if (questionPreview) {
          // when editing existing questions we replace the original one with the preview
          const foundIndex = data.findIndex(
            (item) => item._id === questionPreview._id
          )
          if (foundIndex >= 0) {
            data.splice(foundIndex, 1, questionPreview)
          } else {
            data.push(questionPreview)
          }
        }
        setQuestions(data)
      } catch (e) {
        console.log(e)
      }
    }
    fetchQuestions()
  }, [memberType, questionPreview])

  useEffect(() => {
    if (questions.length > 0) {
      const QuestionsArray = []
      const pagesInEachCategory = []

      //  Finding the unique category value in the question array of objects
      const uniqueCategories = [
        ...new Set(questions?.map((item) => item.category)),
      ]

      // Looping through each unique category
      for (const cat of uniqueCategories) {
        const pageArray = []

        // Filtering unique Category items and saving it to CatArray
        const catArray = questions.filter((item) => item.category === cat)

        // Finding the highest page number for each category in CatArray
        const maxPageNumber = catArray.reduce(function (prev, current) {
          return parseInt(prev.categoryPage) > parseInt(current.categoryPage)
            ? prev
            : current
        }).categoryPage

        // splitting catArray into different arrays with unique pageNumbers
        for (let i = 1; i <= maxPageNumber; i++) {
          pageArray.push(catArray.filter((item) => item.categoryPage === i))
        }

        QuestionsArray.push(pageArray)

        pagesInEachCategory.push(maxPageNumber)
      }
      setCategoryNames(uniqueCategories)
      setPagesInCategory(pagesInEachCategory)
      setFormatedQuestions(QuestionsArray)
    }
  }, [questions])

  const getActiveStep = (activeStep) => {
    let sum = 0
    for (let i = 0; i < pagesInCategory.length; i++) {
      sum = sum + pagesInCategory[i]
      if (activeStep <= sum) {
        setactiveStep(i)
        break
      }
    }
  }

  const WizardNav = ({ activeStep }) => (
    <div className=' hidden md:flex flex-col  h-screen  items-center bg-black z-10 text-white md:w-3/12'>
      <div
        className={
          !submit
            ? 'h-3/4 w-full flex items-center justify-center pl-8'
            : 'hidden'
        }
      >
        <ul>
          {categoryNames.map((item, index) => (
            <CategoryItem
              key={item}
              text={item}
              isActive={activeStep === index}
            />
          ))}
        </ul>
      </div>
      <div className='h-1/4 w-2/3 flex items-center'>
        <img className='text-fpink' src={whiteLogo} alt='logo' />
      </div>
    </div>
  )

  const Symbols = () => (
    <>
      <div className='hidden md:w-1/12 md:flex md:justify-end'>
        <img src={symbolsVertical} alt='symbols' />
      </div>
      <div className='md:hidden h-12 items-end fixed bottom-0 z-10'>
        <img
          src={symbolsHorizontal}
          alt='logo'
          className='h-full object-cover object-left'
        />
      </div>
    </>
  )

  const answerHandler = (inputValue) => {
    answers[inputValue.id] = inputValue.value
    const id = inputValue.id

    const answer = inputValue.value
    const answer_id = inputValue.answer_id
    const score = inputValue.score
    totalscore = totalscore + inputValue.score
    let object = {
      question_id: `${id}`,
      question: inputValue.question,
      rank: inputValue.rank,
      category: inputValue.category,
      answer_value: `${answer}`,
      score: `${score}`,
    }
    // adding answer_id only if it exists
    if (answer_id?.length > 0) {
      object = {
        ...object,
        answer_id: answer_id,
      }
    }
    answers.push(object)
    setAnswers([...answers])
  }

  const submitHandler = (value) => {
    setSubmit(value)
  }
  const nextHandler = (value) => {
    setNext(value)
  }
  const prevHandler = (value) => {
    setPrev(value)
  }
  // For view button in Cells file
  const buttonClicked = (value) => {
    setViewButton(value)
  }

  // For getting the particular item that is clicked
  const viewIdHandler = (value) => {
    setViewId(value)
  }

  useEffect(() => {
    if (answers.length > 0) {
      let total = 0
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].score !== '') {
          total += parseInt(answers[i].score)
        }
      }
      const responseData = {
        firstName: answers.filter(
          (answer) => answer.question.toLowerCase().trim() === 'first name'
        )[0].answer_value,
        lastName: answers.filter(
          (answer) => answer.question.toLowerCase().trim() === 'last name'
        )[0].answer_value,
        email: answers.filter(
          (answer) => answer.question.toLowerCase().trim().search('email') >= 0
        )[0].answer_value,
        totalScore: total,
        role: role,
        answerData: answers,
      }
      axios
        .post('/api/applicants/response', responseData)
        .then((result) => {
          setAnswers([])
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [answers])

  useEffect(() => {
    if (user.privacyConsent.analytics) {
      ReactGA.initialize(analyticsId)
    }
  }, [user])

  if (user.privacyConsent.analytics) {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }

  return (
    //  Providing the context
    <AnswersContext.Provider
      value={{
        answers: answers,
        answerHandler: answerHandler,
        submit: submit,
        submitHandler: submitHandler,
        nextHandler: nextHandler,
        next: next,
        prev: prev,
        prevHandler: prevHandler,
        buttonClicked: buttonClicked,
        viewButton: viewButton,
        viewIdHandler: viewIdHandler,
        viewId: viewId,
        setViewButton: setViewButton,
      }}
    >
      <div className='relative v2 m-0 flex flex-row-reverse w-screen items-end h-screen overflow-hidden'>
        <Symbols />
        {questions && (
          <>
            <StepWizard
              initialStep={1}
              onStepChange={(res) => getActiveStep(res.activeStep)}
              className='h-screen md:w-8/12 mb-8'
            >
              {formatedQuestions.map((catItems, catIndex, catArray) =>
                catItems.map((pagesItems, pageIndex, pageArray) => (
                  <FormPage
                    role={memberType}
                    questionPreview={questionPreview}
                    questions={pagesItems}
                    isFirst={pageIndex === 0 && catIndex === 0}
                    isLast={
                      pageIndex === pageArray.length - 1 &&
                      catIndex === catArray.length - 1
                    }
                    uniquePageNumber={
                      pageIndex.toString() + catIndex.toString()
                    }
                  />
                ))
              )}
            </StepWizard>
          </>
        )}
        {!submit && <WizardNav activeStep={activeStep} />}
      </div>
    </AnswersContext.Provider>
  )
}

export default Form
