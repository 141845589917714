import { useState } from "react"
import ListOption from "../Widgets/ListOption"

let defaultBusinessAreas = [
  { name: "Select the business area", value: "Select the business area" },
  { name: "SaaS/Enterprise Software", value: "SaaS/Enterprise Software" },
  {
    name: "Mobility",
    value: "Mobility",
  },
  {
    name: "Sustainability/Impact investment",
    value: "Sustainability/Impact investment",
  },
  { name: "HealthTech", value: "HealthTech" },
  {
    name: "DTC",
    value: "DTC",
  },
  { name: "E-comm/Marketplaces", value: "E-comm/Marketplaces" },
  { name: "IoT", value: "IoT" },
  {
    name: "FoodTech",
    value: "FoodTech",
  },
  { name: "Gaming/Entertainment", value: "Gaming/Entertainment" },
  { name: "Engineering/DeepTech/AI", value: "Engineering/DeepTech/AI" },
  {
    name: "FinTech",
    value: "FinTech",
  },
  { name: "EdTech", value: "EdTech" },
  { name: "Other", value: "Other" },
]

const formatValue = (value) => {
  const newValue = value.trimStart()
  return newValue.replace(value[0], value[0]?.toUpperCase())
}

const BusinessAreaSelect = ({
  profile,
  setProfile,
  required,
  bgColor,
  checkField,
}) => {
  const [businessAreas, setbusinessAreas] = useState([...defaultBusinessAreas])

  // check if the value entered by the user is included in the object list
  const isSelectionIncluded = (object) => {
    return Object.values(object).some(
      (item) => item.name === profile.businessArea
    )
  }

  return (
    <div className="relative h-auto">
      <ListOption
        options={businessAreas}
        required={true}
        color={
          required && profile.businessArea === "Select the business area"
            ? "bg-red-200 animate-pulse "
            : bgColor
            ? bgColor
            : "bg-sky-50"
        }
        format={
          profile.businessArea !== "Select the business area" &&
          profile.businessArea !== "Other"
            ? checkField(profile.businessArea)
            : "border-l-4 border-gray-500 text-gray-500 text-grotesk"
        }
        choice={
          isSelectionIncluded(businessAreas)
            ? profile.businessArea || "Select the business area"
            : "Other"
        }
        setChoice={(value) => {
          setProfile({
            ...profile,
            businessArea: value,
          })
        }}
      />
      <div
        className={
          profile.businessArea === "Other" ||
          !isSelectionIncluded(businessAreas)
            ? "w-72 py-1 absolute"
            : "hidden"
        }
      >
        {(profile.businessArea === "Other" ||
          !isSelectionIncluded(businessAreas)) && (
          <input
            onChange={(e) => {
              setProfile({
                ...profile,
                businessArea: formatValue(e.target.value),
              })
            }}
            required={true}
            type="text"
            placeholder="Enter your business area"
            defaultValue={
              isSelectionIncluded(businessAreas) ? null : profile.businessArea
            }
            className="w-60 text-sm appearance-none border p-2 outline-none absolute "
          />
        )}
      </div>
    </div>
  )
}

export default BusinessAreaSelect
