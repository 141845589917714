import { ChevronDownIcon } from '@heroicons/react/outline'
import React, { useEffect, useRef, useState, useCallback } from 'react'

export default function SelectAnswer({
  answers,
  selectedAnswer,
  selectValidation,
}) {
  const [showList, setShowList] = useState(false)
  const [selectedItem, setSelectedItem] = useState('')
  const panelResultElement = useRef()
  const selectButton = useRef()

  // const { next } = useContext(AnswersContext)

  const handleClickOutside = useCallback((event) => {
    const myHTMLWrapper = panelResultElement.current
    const searchElement = selectButton.current
    if (
      myHTMLWrapper &&
      searchElement &&
      !myHTMLWrapper.contains(event.target) &&
      !searchElement.contains(event.target)
    ) {
      setShowList(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const itemClicked = (item, index) => {
    setSelectedItem(item.answer)
    setShowList(!showList)
    selectedAnswer(item.answer, item._id, item.points)
  }

  const listItem = (item, index) => (
    <li
      key={item + index}
      id='listbox-item-0'
      role='option'
      aria-selected
      onClick={() => itemClicked(item, index)}
      className=' text-sm md:text-base 2xl:text-xl text-gray-900 cursor-default hover:bg-fblue hover:text-white select-none relative py-2 pl-3 pr-9 '
    >
      <div className='flex items-center '>
        <span className='ml-3 block  text-sm md:text-base 2xl:text-xl  break-word'>
          {item.answer}
        </span>
      </div>
    </li>
  )
  return (
    <div className='flex flex-col w-full'>
      <div className='mt-2 relative w-full'>
        <button
          type='button'
          ref={selectButton}
          onClick={() => setShowList(!showList)}
          className={`border-l-5 border-black relative w-full pl-6 pr-8 py-4 text-base lg:text-xl text-grotesk text-left border-2 outline-none appearance-none bg-white cursor-default h-16 truncate ${
            selectValidation && selectedItem.length
              ? 'border-valid'
              : !selectValidation
              ? 'border-fred'
              : 'border-black'
          }`}
        >
          <span className='ml-3 block truncate text-base md:text-base 2xl:text-xl'>
            {selectedItem && selectedItem}
          </span>
          <span className='absolute inset-y-0 right-0 flex items-center justify-end pr-2 pointer-events-none w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3'>
            <ChevronDownIcon
              className='w-5 h-5 text-gray-600 '
              aria-hidden='true'
            />
          </span>
        </button>
        {showList && (
          <div
            ref={panelResultElement}
            className='absolute w-full z-40 mt-1 overflow-auto text-base lg:text-xl text-grotesk text-left bg-white shadow-lg max-h-44 ring-1 ring-black ring-opacity-5 focus:outline-none'
          >
            <ul
              tabIndex={-1}
              role='listbox'
              aria-labelledby='listbox-label'
              aria-activedescendant='listbox-item-3'
              className='ring-1 ring-black ring-opacity-5 overflow-auto text-base lg:text-xl'
            >
              {answers.map((item, index) => listItem(item, index))}
            </ul>
          </div>
        )}
      </div>
      <div className='w-full'>
        {selectedItem === 'Other' && (
          <input
            type='text'
            className='mt-4 flex-1 text-sm md:text-base 2xl:text-xl appearance-none border border-gray-300 w-11/12 md:w-4/5 px-2 py-2 md:py-4 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-fblue-light focus:border-transparent'
            name='firstname'
            placeholder='Type your answer... '
            id={answers[0]._id}
          />
        )}
      </div>
    </div>
  )
}
