import { useContext, useState, useEffect } from "react"
import FastTrackContext from "../../../contexts/FastTrack"
import Banner from "../../Admin/Widgets/Banner"
import { useHistory } from "react-router-dom"
import axios from "axios"
const fastTrackURL = "/api/auth/fasttrack"

const SixthStep = ({ nextStep, previousStep, isActive }) => {
  const {
    data,
    setData,
    scrollUp,
    pageTop,
    setAccountCreated,
    config,
    existing,
  } = useContext(FastTrackContext)
  const [banner, setBanner] = useState({ show: false })
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  const submit = async () => {
    setSaving(true)
    try {
      const responseData = {
        firstName: data.firstName,
        lastName: data.lastName,
        answerData: [...data.responses],
        role: "founder",
        email: data.email,
      }
      const memberData = { ...data, application: { ...responseData } }
      const { data: res } = await axios.post(fastTrackURL, memberData, config)
      setSaving(false)
      if (res.access_token) {
        localStorage.setItem("authToken", res.access_token)
        setAccountCreated(true)
        nextStep()
        //redirect to community
        setTimeout(() => {
          history.push("/community")
        }, 5000)
      } else {
        setSaving(false)
        setBanner({
          success: 0,
          show: true,
          message: "Sorry something went wrong",
        })
        setTimeout(() => {
          setBanner((prev) => ({ ...prev, show: false }))
        }, 4000)
      }
    } catch (err) {
      setSaving(false)
      setBanner({
        success: 0,
        show: true,
        message:
          err?.response?.status === 401
            ? "Invalid token"
            : "Sorry, something went wrong",
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  useEffect(() => {
    if (isActive) scrollUp()
  }, [isActive])

  return (
    <div
      className="flex flex-col justify-around items-center h-full w-full bg-white p-3"
      ref={pageTop}
    >
      <div className="w-full flex flex-col justify-center items-center">
        <h1 className="font-bold text-xl md:text-2xl lg:text-3xl text-center text-grotesk">
          Photography Release Consent
        </h1>
      </div>

      <div className="w-full flex flex-col">
        <div className="flex flex-col justify-center items-center w-full text-grotesk font-bold">
          <p className="w-full py-4 px-2 text-grotesk text-justify text-base">
            In order to proceed, please accept to the following declaration of
            consent for contributors in the context of photo, video and sound
            recordings:
          </p>

          <div className="flex pt-2">
            <input
              type="checkbox"
              id="consent"
              name="consent"
              required={true}
              checked={data.consent}
              onChange={() => {
                setData({ ...data, consent: !data.consent })
              }}
              className="h-6 w-6 ml-2 mt-2"
            />
            <p className="pl-4 pr-6 text-base text-justify">
              I hereby give my express consent to participate as a contributor
              in the above production (hereinafter "Production"). I grant
              Founderland and all companies affiliated with Founderland
              (including Founderland's partners) the right to make sound, film
              and photo recordings (hereinafter "recordings") of me as part of
              the entire production and agree that these may be used and
              published for the aforementioned purpose without restriction as to
              time or place. I do not derive any rights (e.g. remuneration) from
              my consent to this use and publication. I authorize Founderland
              and all companies affiliated with Founderland to edit, redesign,
              shorten, synchronize or transfer the recordings in whole or in
              part into other work forms.
            </p>
          </div>
          <div>
            <p className="text-xs mt-4 p-2">
              <u>Production:</u> Announcement, promotion and general
              communications content via Founderland.org owned platforms and
              media distribution partners.
              <br />
              <u>Intended use:</u> To be used as general promotional material in
              digital formats and printed materials, on websites and in social
              media channels.
            </p>
          </div>
        </div>
        <div className="w-full flex justify-between p-4">
          <Banner message={banner} />
          <button
            type="button"
            className="px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 md:w-1/6 "
            onClick={() => previousStep()}
          >
            Back
          </button>
          <button
            type="button"
            className={`px-5 py-2 ${
              existing ? "bg-black" : "bg-fblue"
            }  font-bold text-lg text-white transition duration-200 ${
              existing ? "hover:bg-flime hover:text-black" : "hover:bg-blue-700"
            }  md:w-1/6`}
            onClick={() => {
              if (data.consent) {
                existing ? submit() : nextStep()
              } else {
                setBanner({
                  error: 1,
                  show: true,
                  message: "Please accept the terms to proceed",
                })
                setTimeout(() => {
                  setBanner((prev) => ({
                    ...prev,
                    show: false,
                  }))
                }, 3000)
              }
            }}
          >
            {saving ? (
              <div className="flex justify-center">
                <div
                  style={{
                    borderTopColor: "transparent",
                  }}
                  className="w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin"
                ></div>
              </div>
            ) : existing ? (
              "Submit"
            ) : (
              "Next"
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SixthStep
