import { EmojiHappyIcon } from '@heroicons/react/outline'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import Banner from '../../Admin/Widgets/Banner'
import FastTrackContext from '../../../contexts/FastTrack'

const SeventhStep = ({ previousStep, isActive }) => {
  const [error, setError] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const {
    handleSubmit,
    data,
    setData,
    scrollUp,
    pageTop,
    accountCreated,
    error: submissionError,
    existing,
  } = useContext(FastTrackContext)
  const [banner, setBanner] = useState({ show: false })
  const [saving, setSaving] = useState(false)
  const isPassSecure = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(
    data.password
  )
  const isEmailValid = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(
    data.email
  )
  const checkPasswordsAndSumbit = () => {
    if (!isPassSecure) {
      setError(true)
      setBanner({
        success: 0,
        show: true,
        message: "Password doesn't meet the minimum requirements",
      })
      setTimeout(() => {
        setError(false)
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    } else if (!(data.password === data.confirmPassword)) {
      setError(true)
      setBanner({
        success: 0,
        show: true,
        message: 'Passwords do not match',
      })
      setTimeout(() => {
        setError(false)
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    } else if (!validEmail) {
      setError(true)
      setBanner({
        success: 0,
        show: true,
        message: 'Email already in use',
      })
      setTimeout(() => {
        setError(false)
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    } else {
      setSaving(true)
      handleSubmit()
    }
  }

  const checkPassword = () => {
    if (data.password) {
      return isPassSecure ? 'border-l-4 border-valid' : 'border-l-4 border-fred'
    } else {
      return 'border-l-4 border-black'
    }
  }
  const checkConfirmPassword = () => {
    if (data.password) {
      return data.password === data.confirmPassword
        ? 'border-l-4 border-valid'
        : 'border-l-4 border-fred'
    } else {
      return 'border-l-4 border-black'
    }
  }
  const checkEmail = () => {
    if (data.email) {
      return isEmailValid && validEmail
        ? 'border-l-4 border-valid'
        : 'border-l-4 border-fred'
    } else {
      return 'border-l-4 border-black'
    }
  }

  useEffect(() => {
    if (isActive) scrollUp()
  }, [isActive])

  useEffect(() => {
    //check if email exists
    const checkExistingEamil = async () => {
      if (isEmailValid) {
        const response = await axios.get(
          '/api/users/community/checkprofile/' + data.email
        )
        response.data.data.isEmailValid
          ? setValidEmail(true)
          : setValidEmail(false)
      }
    }
    checkExistingEamil()
  }, [data.email])

  return accountCreated ? (
    <div
      className='h-full flex flex-col justify-around items-center w-full bg-white p-3'
      ref={pageTop}
    >
      <div className='flex flex-col items-center justify-center mt-10 text-grotesk text-xl md:text-2xl xl:text-4xl text-center h-1/4 md:w-4/6 lg:w-2/6 p-2 '>
        <span></span>
        <span className='font-bold pb-2'>
          Congratulations {data.firstName} your account has been succesfully
          {existing ? ' updated' : ' created'}.
          <EmojiHappyIcon className='w-10 h-10 m-auto' />
        </span>
        You will now be redirected to our Community in a few seconds...
      </div>
    </div>
  ) : data.confirmed ? (
    'Thank you!'
  ) : (
    <div className='h-full flex flex-col justify-around items-center w-full bg-white p-3'>
      <div className='w-full flex flex-col text-grotesk text-xl lg:text-2xl text-center  p-2'>
        <h1 className='font-bold text-xl md:text-2xl lg:text-3xl text-grotesk mb-3 text-center'>
          Complete your login information
        </h1>
        <p className='text-base'>Please create your login credentials</p>
        <p className='text-xs'>
          *Minimum eight characters, at least one upper case letter, one lower
          case letter and one number
        </p>
      </div>
      {(error || submissionError) && (
        <div className='w-full md:w-3/4  lg:w-4/6  flex items-center justify-center font-bold mt-5 '>
          <Banner
            message={
              submissionError
                ? {
                    success: 0,
                    show: true,
                    message: submissionError,
                  }
                : banner
            }
          />
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault(e)
          checkPasswordsAndSumbit()
        }}
        className='w-full h-3/4 flex flex-col justify-around items-center'
      >
        <div className='w-full xl:w-4/5 px-2 py-6'>
          <label className='block uppercase text-gray-400 text-md font-bold mb-2'>
            Email
          </label>
          <input
            type='email'
            className={
              checkEmail() +
              ' w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3 outline-none'
            }
            required={true}
            placeholder='Enter Email'
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <p className='text-sm text-fred text-mono py-2'>
            {isEmailValid && !validEmail && 'This email is already in use'}
          </p>
        </div>
        <div className='w-full xl:w-4/5 px-2 py-6'>
          <label className='block uppercase text-gray-400 text-md font-bold mb-2'>
            Password
          </label>
          <input
            type='password'
            name='password'
            autoComplete='new-password'
            className={
              checkPassword() +
              ' w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3 outline-none'
            }
            required={true}
            placeholder='Enter password'
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />
        </div>
        <div className='w-full xl:w-4/5  px-2  py-6 '>
          <label className='block uppercase text-gray-400 text-md font-bold mb-2'>
            Confirm Password
          </label>
          <input
            type='password'
            name='confirmPassword'
            autoComplete='new-password'
            className={
              checkConfirmPassword() +
              ' w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3 outline-none'
            }
            required={true}
            placeholder='Enter password again'
            onChange={(e) =>
              setData({
                ...data,
                confirmPassword: e.target.value,
              })
            }
          />
        </div>

        <div className='w-full flex justify-between p-4'>
          <button
            type='button'
            className='px-5 py-2 bg-fblue font-bold text-lg text-white transition duration-200 hover:bg-blue-700 w-1/3'
            onClick={() => previousStep()}
          >
            Back
          </button>
          <button
            type='submit'
            className='px-5 py-2 bg-black font-bold text-lg text-white transition duration-200 hover:bg-flime hover:text-black w-1/3'
          >
            {saving ? (
              <div className='flex justify-center'>
                <div
                  style={{
                    borderTopColor: 'transparent',
                  }}
                  className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                ></div>
              </div>
            ) : (
              'Create account'
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default SeventhStep
