import { CheckIcon } from "@heroicons/react/solid"
import { useField, useFormikContext } from "formik"
import { at } from "lodash"

const Toggle = ({ name, label, notes, footNotes, status }) => {
  const [field, meta] = useField({ name, label })
  const { handleChange, handleBlur, setFieldValue } = useFormikContext()
  const [touched, error] = at(meta, "touched", "error")

  return (
    <div className="w-full flex flex-col m-2 lg:mt-4">
      <div className="w-full mt-2 flex justify-start items-center space-x-4">
        <div className="w-full mt-2 flex justify-start items-center space-x-4">
          <label
            className={`flex items-center ${
              status === "locked" ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <div className="relative">
              <input
                type="checkbox"
                {...field}
                value={meta.value}
                className="sr-only"
                disabled={status === "locked"}
                onChange={handleChange}
                onBlur={handleBlur}
                onClick={() => {
                  setFieldValue(name, !meta.value)
                }}
              />
              <div
                className={`flex items-center flex-shrink-0 ${
                  status === "locked"
                    ? "bg-gray-500"
                    : meta.value
                    ? "bg-valid"
                    : "bg-gray-400"
                } w-15 h-7 rounded-full transition-colors duration-200 ease-in-out border-2 border-transparent`}
              >
                <div
                  className={`transform ${
                    meta.value ? "translate-x-7" : "translate-x-0"
                  } absolute left-1 top-1 bg-white w-5.5 h-5.5 rounded-full transition`}
                >
                  <CheckIcon
                    className={
                      status === "locked"
                        ? "text-gray-500"
                        : meta.value
                        ? "text-valid"
                        : "hidden"
                    }
                  />
                </div>
              </div>
            </div>
          </label>
          <label className="text-black text-grotesk text-base font-bold lg:text-xl">
            <p>{label}</p>
            {notes && (
              <p className="text-xs text-grotesk lg:text-base font-normal">
                {notes}
              </p>
            )}
          </label>
        </div>
      </div>
      {(footNotes || error) && (
        <p
          className={`mt-2 text-xs text-grotesk lg:text-base ${
            status === "invalid" ? "text-fred " : "text-gray-500"
          }`}
        >
          {footNotes || error}
        </p>
      )}
    </div>
  )
}

export default Toggle
