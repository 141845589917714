import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Banner from '../Widgets/Banner'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'

const EmailUpdate = ({ member, view, setView }) => {
  const [newEmail, setNewEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [saving, setSaving] = useState(false)
  const [banner, setBanner] = useState({ show: false })
  const { config, reload, setReload } = useContext(AdminContext)
  const isEmailValid = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(newEmail)
  const checkEmail = () => {
    if (newEmail.length > 0) {
      return isEmailValid && validEmail
        ? 'border-l-4 border-flime'
        : 'border-l-4 border-fred'
    } else {
      return 'border-l-4 border-gray'
    }
  }

  const save = async () => {
    setSaving(true)
    try {
      if (isEmailValid && validEmail) {
        const response = await axios.put(
          '/api/users/community/profile/update',
          { _id: member._id, email: newEmail },
          config
        )
        setSaving(false)
        setBanner({
          success: 1,
          show: true,
          message: 'Email updated! ',
        })
        setTimeout(() => {
          setView(false)
          setReload(reload + 1)
        }, 2000)
      } else {
        throw new Error('Email is not valid')
      }
    } catch (e) {
      console.log(e)
      setSaving(false)
      setBanner({
        error: 1,
        show: true,
        message: 'Something went wrong while saving',
      })
      setTimeout(() => {
        setBanner((prev) => ({ ...prev, show: false }))
      }, 4000)
    }
  }

  useEffect(() => {
    //check if email exists
    const checkExistingEamil = async () => {
      if (isEmailValid) {
        const response = await axios.get(
          '/api/users/community/checkprofile/' + newEmail
        )
        response.data.data.isEmailValid
          ? setValidEmail(true)
          : setValidEmail(false)
      }
    }
    checkExistingEamil()
  }, [newEmail])

  return (
    <Transition appear show={view} as={Fragment}>
      <Dialog
        as='div'
        onClose={() => setView(false)}
        className='fixed z-50 inset-1 overflow-auto h-screen'
      >
        <div className='flex justify-center items-center h-screen'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0 scale-80'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-80'
          >
            <div className='bg-white px-8 pt-8 pb-4 flex rounded flex-col w-full lg:w-1/2 shadow-lg z-50'>
              <div className='w-full flex justify-center items-center'>
                <Banner message={banner} />
              </div>
              <div className='md:flex w-full px-3'>
                <div className='flex justify-center items-center w-full px-3'>
                  <div className='w-full mb-2 px-2'>
                    <label className='block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2'>
                      Update nember email
                    </label>
                    <div className='w-full flex flex-col gap-4 px-2'>
                      <div className=' w-full flex flex-col items-start justify-between mb-2'>
                        <label className=' font-bold mb-2'>Current Email</label>
                        <input
                          type='text'
                          className={`w-full p-2`}
                          value={member.email}
                          disabled
                        />
                      </div>
                      <div className=' w-full flex flex-col items-start justify-between mb-2'>
                        <label className='lg:mx-3 font-bold mb-2'>
                          New Email
                        </label>
                        <input
                          type='text'
                          className={` ${checkEmail()} w-full p-2`}
                          value={newEmail}
                          onChange={(e) => {
                            setNewEmail(e.target.value)
                          }}
                        />
                        <div className='flex justify-start items-center'>
                          <p className='text-xs text-fred'>
                            {isEmailValid && !validEmail
                              ? 'Email is already in use by another member'
                              : !isEmailValid && newEmail.length > 0
                              ? 'Email is not valid'
                              : ''}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='px-4 pt-6 flex flex-col-reverse sm:flex-row items-center justify-around '>
                <button
                  className='px-10 py-2 w-full shadow-lg sm:w-1/3 bg-gray-700 transition duration-200 hover:bg-fred-200 text-white mb-4'
                  onClick={() => {
                    setView(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`px-8 py-2 w-full shadow-lg sm:w-1/3 transition duration-200 mb-4 ${
                    isEmailValid && validEmail
                      ? 'bg-flime hover:bg-fblue hover:text-white '
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                  onClick={() => save()}
                >
                  {saving ? (
                    <div className='flex justify-center'>
                      <div
                        style={{ borderTopColor: 'transparent' }}
                        className='w-6 h-6 border-4 border-white border-dotted rounded-full animate-spin'
                      ></div>
                    </div>
                  ) : (
                    'Confirm'
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default EmailUpdate
