import { useState, useEffect, useRef, useMemo } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"
import jwt from "jsonwebtoken"
import axios from "axios"
import StepWizard from "react-step-wizard"
import FirstStep from "./FirstStep"
import SecondStep from "./SecondStep"
import ThirdStep from "./ThirdStep"
import FourthStep from "./FourthStep"
import FifthStep from "./FifthStep"
import SixthStep from "./SixthStep"
import SeventhStep from "./SeventhStep"
import FastTrackContext from "../../../contexts/FastTrack"
import { EmojiSadIcon } from "@heroicons/react/outline"
import { ReactComponent as LogoLines } from "../../../assets/line.svg"
import gifLogo from "../../../assets/images/Logo-Transform.gif"
import whiteLogo from "../../../assets/images/logo_md_white.png"
import founder from "../../../assets/images/founders-community.jpg"
import ApplicationSteps from "./ApplicationSteps"
import ReactGA from "react-ga4"
import PrivacyConsentPopup from "../Widgets/PrivacyConsentPopup"

const fastTrackURL = "/api/auth/fasttrack"
const confirmTokenURL = "/api/users/community/token/confirm"
const getProfileURL = "/api/users/community/profile"
const analyticsId = process.env.REACT_APP_ANALYTICS

const FastTrack = () => {
  const location = useLocation()
  const { token } = useParams()

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    title: "",
    city: "",
    country: "",
    email: "",
    geoLocation: "",
    bio: "",
    businessArea: "Select the business area",
    password: "",
    confirmPassword: "",
    confirmed: null,
    photo: null,
    flag: null,
    companyName: "",
    companyBio: "",
    companyLink: "",
    symbols: [
      { icon: "affirmative", color: "fblue" },
      { icon: "affirmative", color: "fblue" },
      { icon: "affirmative", color: "fblue" },
    ],
    consent: false,
    bannerColor: "fblue",
    responses: [],
    privacyConsent: { main: false, analytics: false },
  })
  const [accountCreated, setAccountCreated] = useState(false)
  const [questions, setQuestions] = useState([])
  const [emptyQuestions, setEmptyQuestions] = useState([])
  const [formatedQuestions, setFormatedQuestions] = useState([])
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [existing, setExisting] = useState(false)
  const [cModal, setCModal] = useState(false)

  const pageTop = useRef(null)
  const jwtDecoded = jwt.decode(token)
  let history = useHistory()
  const config = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  }, [token])
  const scrollUp = () => {
    pageTop.current.scrollIntoView({ behavior: "smooth" })
  }

  //CHECK TOKEN
  useEffect(() => {
    const fetchProfile = async () => {
      if (jwtDecoded?.id) {
        //check if token is expired or load the data
        const now = Date.now() / 1000
        try {
          if (now < jwtDecoded.exp) {
            //VALID TOKEN
            //GET THE REGISTERED DATA BASED ON THE ID OR EMAIL
            let result
            if (jwtDecoded.email) {
              result = await axios.get(
                `${getProfileURL}/${jwtDecoded.id}`,
                config
              )
            } else {
              result = await axios.get(
                `${confirmTokenURL}/${jwtDecoded.id}`,
                config
              )
            }
            if (result.data._id) {
              setShowForm(true)
              if (data.responses.length !== 0) setLoading(false)
            } else if (result.data.data) {
              setExisting(true)
              let profileData = result.data.data
              if (profileData.symbols?.length === 0)
                profileData.symbols = [...data.symbols]
              setData({
                ...data,
                ...profileData,
                responses: [...emptyQuestions],
              })
              setShowForm(true)
              if (data.responses.length !== 0) setLoading(false)
            } else {
              setLoading(false)
              setError("Invalid Token - empty response")
            }
          } else {
            setLoading(false)
            setError("Invalid Token - expired")
          }
        } catch (err) {
          setLoading(false)
          setError("Invalid Token - error on validation")
        }
      } else {
        setLoading(false)
        setError("Invalid Token")
      }
    }
    fetchProfile()
  }, [token])

  //GET QUESTIONS
  useEffect(() => {
    const fetchQuestions = async () => {
      const result = await axios.get(`/api/form/fasttrack/questions`)
      try {
        const response = result
        setQuestions(response.data)
      } catch (e) {
        setError(
          e?.response?.status === 401
            ? "Invalid token"
            : "Sorry, something went wrong"
        )
      }
    }
    fetchQuestions()
  }, [])

  //FORMAT QUESTIONS
  useEffect(() => {
    if (questions.length > 0) {
      const QuestionsArray = []
      const pagesInEachCategory = []
      //  Finding the unique category value in the question array of objects
      const uniqueCategories = [
        ...new Set(questions?.map((item) => item.category)),
      ]
      // Looping through each unique category
      for (const cat of uniqueCategories) {
        const pageArray = []
        // Filtering unique Category items and saving it to CatArray
        const catArray = questions.filter((item) => item.category === cat)
        // Finding the highest page number for each category in CatArray
        const maxPageNumber = catArray.reduce(function (prev, current) {
          return parseInt(prev.categoryPage) > parseInt(current.categoryPage)
            ? prev
            : current
        }).categoryPage
        // splitting catArray into different arrays with unique pageNumbers
        for (let i = 1; i <= maxPageNumber; i++) {
          pageArray.push(catArray.filter((item) => item.categoryPage === i))
        }
        QuestionsArray.push(pageArray)
        pagesInEachCategory.push(maxPageNumber)
      }
      const emptyQuestions = questions.map((question) => ({
        question_id: question._id,
        category: question.category,
        question: question.question,
      }))
      setEmptyQuestions([...emptyQuestions])
      setFormatedQuestions(QuestionsArray)
    }
  }, [questions])

  //COMPLETE PROFILE
  useEffect(() => {
    if (emptyQuestions.length !== 0) {
      setData({
        ...data,
        responses: [...emptyQuestions],
      })
      if (loading && showForm) {
        setLoading(false)
      }
    }
  }, [emptyQuestions])

  useEffect(() => {
    if (showForm && loading && data.responses.length !== 0) {
      setTimeout(() => {
        setLoading(false)
      }, 3500)
    }
  }, [showForm])
  //submit form
  const handleSubmit = async () => {
    try {
      //receive new login token and forward to community app
      //CreateResponse data
      const responseData = {
        firstName: data.firstName,
        lastName: data.lastName,
        answerData: [...data.responses],
        role: "founder",
        email: data.email,
      }
      const memberData = { ...data, application: { ...responseData } }
      const { data: res } = await axios.post(fastTrackURL, memberData, config)
      setLoading(false)
      if (res.access_token) {
        localStorage.setItem("authToken", res.access_token)
        setAccountCreated(true)
        //redirect to community
        setTimeout(() => {
          history.push("/community")
        }, 5000)
      } else {
        setLoading(false)
        setError("Sorry, something went wrong")
        throw new Error({
          response: {
            status: 500,
            message: "Sorry, something went wrong",
          },
        })
      }
    } catch (err) {
      setLoading(false)
      setError(
        err?.response?.status === 401
          ? "Invalid token"
          : "Sorry, something went wrong"
      )
      setTimeout(() => {
        setError("")
      }, 5000)
    }
  }

  const checkField = (field) => {
    return field ? "border-l-4 border-valid" : "border-l-4 border-black"
  }

  useEffect(() => {
    if (data?.privacyConsent.analytics) {
      ReactGA.initialize(analyticsId)
    }
  }, [data])

  if (data?.privacyConsent.analytics) {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    })
  }

  //Render form with data from database
  //RENDER WELCOME MESSAGE -> 3s
  return (
    <FastTrackContext.Provider
      value={{
        scrollUp,
        history,
        pageTop,
        cModal,
        setCModal,
        jwtDecoded,
        config,
        data,
        setData,
        accountCreated,
        setAccountCreated,
        error,
        handleSubmit,
        loading,
        existing,
        checkField,
      }}
    >
      {loading ? (
        <div className="h-screen w-screen mt-10 flex flex-col items-center justify-center">
          <h1 className="p-4 text-hanson text-xl lg:text-3xl xl:text-4xl text-center w-full">
            WELCOME TO OUR COMMUNITY
          </h1>
          <img src={gifLogo} alt="founderland logo" className="max-h-80" />
        </div>
      ) : showForm && !data.applicationId ? (
        <div className="h-screen w-screen flex flex-col lg:flex-row justify-start overflow-hidden">
          <LogoLines className="w-full h-1/5 lg:hidden" />
          <div className="relative h-full hidden lg:flex lg:w-1/3 items-end justify-center z-10 ">
            <img
              src={founder}
              className="h-full w-full object-cover object-center"
              alt="founders"
            />
            <img
              src={whiteLogo}
              className="w-full xl:w-5/6 max-h-36 p-6 pb-3 object-contain absolute object-bottom"
              alt="founderland logo"
            />
          </div>
          <StepWizard
            initialStep={1}
            className="h-screen w-full lg:w-2/3 overflow-x-hidden overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-fblue scrollbar-track-blue-100"
          >
            {/* Identity */}
            <FirstStep />
            {/* Business */}
            <SecondStep />
            {/* Application */}
            {formatedQuestions.map((catItems, catIndex, catArray) =>
              catItems.map((pagesItems, pageIndex, pageArray) => (
                <ApplicationSteps
                  key={catIndex + "-" + pageIndex}
                  pagesItems={pagesItems}
                  pageIndex={pageIndex}
                />
              ))
            )}
            {/* Symbols */}
            <ThirdStep />
            {/* Profile */}
            <FourthStep />
            {/* Flag */}
            <FifthStep />
            {/* Release */}
            <SixthStep />
            {/* Password */}
            <SeventhStep />
          </StepWizard>
          <PrivacyConsentPopup
            config={config}
            user={data}
            isOpen={loading}
            setUser={setData}
            local={"signup"}
          />
        </div>
      ) : (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="w-full h-full sm:w-1/2 pt-5">
            <div className="flex justify-center">
              <LogoLines className="w-5/6" />
            </div>
            <div className="my-3 flex items-center justify-between">
              <span className="border-b w-1/5 lg:w-1/4"></span>
              <p className="text-grotesk text-center text-blue-500 uppercase">
                Community
              </p>
              <span className="border-b w-1/5 lg:w-1/4"></span>
            </div>
            <div className=" w-full flex flex-col justify-center items-center text-lg text-center">
              <div className="flex flex-col space-x-4">
                {data.applicationId ? (
                  <>
                    <p>
                      It looks like you have already completed your profile.
                      Check in the Community app how to set up your flag.
                    </p>
                    <a
                      href="/community"
                      className="text-fblue-200 hover:text-fblue"
                    >
                      Go to Community login
                    </a>
                  </>
                ) : error.includes("expired") ? (
                  <>
                    <p>
                      Please note this Sign Up link is only valid for 7 days.
                      You will need to request a new link.
                    </p>
                  </>
                ) : error.includes("Invalid") ? (
                  <>
                    <p>Invalid Sign Up link</p>
                  </>
                ) : (
                  <div className="flex self-center space-x-2">
                    <EmojiSadIcon className="w-7 h-7 " />
                    <p className="">Sorry something went wrong</p>
                  </div>
                )}
                <p className="p-4 text-xs">
                  If you are still experiencing issues, please contact us via
                  email 📧{" "}
                  <a
                    className="text-fblue-200 hover:text-fblue"
                    href="mailto:admin@founderland.org"
                  >
                    admin@founderland.org
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </FastTrackContext.Provider>
  )
}

export default FastTrack
