import { useState, useEffect, useContext } from "react"
import AdminContext from "../../../contexts/Admin"
import ListWidget from "../Widgets/ListWidget"
import Loading from "../Widgets/Loading"
import axios from "axios"
import moment from "moment"
import ConfirmModal from "../Widgets/ConfirmModal"
import AddToken from "./AddToken"

const styles = {
  valid: "bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs",
  expired: "bg-fred bg-opacity-50 text-red-900 py-1 px-3 rounded-full text-xs",
}

const TokensList = () => {
  const [data, setData] = useState({
    header: [
      {
        title: "Token",
        key: "token",
        style: "text-right text-sm truncate",
        showFilter: false,
      },
      {
        title: "Issued On",
        key: "issuedOn",
        style: "text-center text-sm",
        showFilter: false,
      },
      {
        title: "Expires On",
        key: "expiredOn",
        style: "text-center text-sm",
        showFilter: false,
      },
      {
        title: "Used",
        key: "used",
        style: "text-center text-sm",
        showFilter: false,
      },
      { title: "", key: "-", style: "text-center text-sm", showFilter: false },
    ],
    data: [],
  })
  const [loading, setLoading] = useState(true)
  const { config, reload } = useContext(AdminContext)
  const [filter, setFilter] = useState([])
  //FETCH DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/users/community/tokens/", config)
        let filteredData = [...response.data]
        filteredData.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (key.includes("On") && item[key] !== null) {
              item[key] = moment(item[key]).format("DD/M/YYYY")
            }
          })
          item.usedOn ? (item.used = "✅") : (item.used = "◻️")
        })
        setData({ ...data, data: filteredData })
        setLoading(false)
      } catch (e) {}
    }
    fetchData()
  }, [reload])

  return loading ? (
    <Loading />
  ) : (
    <>
      <ConfirmModal>
        <AddToken />
      </ConfirmModal>
      <ListWidget
        title=""
        data={data}
        styles={styles}
        showing={10}
        filter={filter}
        setFilter={setFilter}
        link={"/admin/members/token/"}
      />
    </>
  )
}

export default TokensList
