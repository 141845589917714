import { useState, useEffect, useMemo, useRef } from 'react'
import Login from '../Admin/Login'
import Main from './Main'
import UserContext from '../../contexts/User'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import Maintenance from './Widgets/Maintenance'

const version = '0.6.3'
const analyticsId = process.env.REACT_APP_ANALYTICS

const views = {
  community: {
    icon: 'userGroup',
    name: 'Community',
    hide: false,
    restricted: null,
  },
  allies: {
    icon: 'academy',
    name: 'Allies',
    hide: false,
    restricted: ['founder', 'ally'],
  },
  investors: {
    icon: 'library',
    name: 'Investors',
    hide: false,
    restricted: ['founder', 'investor'],
  },
  resources: {
    icon: 'collection',
    name: 'Resources',
    hide: false,
    restricted: ['founder'],
  },
  events: {
    icon: 'calendar',
    name: 'Events',
    hide: false,
    restricted: ['founder'],
  },
  sessions: {
    icon: 'chat',
    name: 'Sessions',
    hide: false,
    restricted: null,
  },
  profile: { icon: 'user', name: 'Profile', hide: true, restricted: null },
}
const Community = () => {
  const location = useLocation()
  const [token, setToken] = useState(localStorage.authToken)
  const [user, setUser] = useState(null)
  const [reload, setReload] = useState(false)
  const [cModal, setCModal] = useState(false)
  const [cCModal, setCCModal] = useState(false)
  const [category, setCategory] = useState(false)
  const [isSidebarSelected, setIssideSelected] = useState(false)
  const [selectedName, setSelectedName] = useState('')
  const [isNameSelected, setIsNameselected] = useState(false)
  const [countryList, setCountryList] = useState([])
  const pageTop = useRef(null)
  const [refreshData, setRefreshData] = useState(0)
  const history = useHistory()
  const config = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  }, [token])

  useEffect(() => {
    const checkLoggedUser = async () => {
      if (localStorage.authToken) {
        const decode = jwt.decode(localStorage.authToken)
        if (decode.id && !decode.avatar) {
          //Check user
          const checkUser = await axios.get('/api/auth/check', config)
          if (checkUser) {
            if (checkUser.data.access_token === token) {
              const decodeCheck = jwt.decode(checkUser.data.access_token)
              setUser({
                id: decodeCheck.id,
                firstName: decodeCheck.firstName,
                lastName: decodeCheck.lastName,
                email: decodeCheck.email,
                role: decodeCheck.role,
                photo: decodeCheck.photo,
                firmId: decodeCheck.firmId,
                privacyConsent: decodeCheck.privacyConsent,
                version: version,
              })
            } else {
              setToken(checkUser.data.access_token)
            }
          }
        } else {
          setUser(null)
          setToken(null)
        }
      }
    }
    checkLoggedUser()
  }, [token])

  const logout = () => {
    localStorage.authToken = ''
    setUser(null)
    setToken(null)
    history.push('/community')
  }
  const countryConfig = {
    headers: {
      'X-CSCAPI-KEY': process.env.REACT_APP_COUNTRIES,
      'Content-Type': 'application/json',
    },
  }
  useEffect(() => {
    axios
      .get(`https://api.countrystatecity.in/v1/countries`, countryConfig)
      .then((res) => {
        setCountryList(res.data)
      })
      .catch((e) => {})
  }, [])
  const getUuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  }
  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = width <= 768
  const isRunningStandalone = () => {
    return window.matchMedia('(display-mode: standalone)').matches
  }
  const [isOpen, setIsOpen] = useState(isMobile && !isRunningStandalone())

  const scrollUp = () => {
    pageTop.current.scrollIntoView({ behavior: 'smooth' })
  }

  const isNameSelectedEvent = (val) => {
    setIsNameselected(val)
  }
  const selectedNameEvent = (val) => {
    setSelectedName(val)
  }

  const categoryHandler = (value) => {
    setCategory(value)
  }
  const sidebarHandler = (value) => {
    setIssideSelected(value)
  }

  useEffect(() => {
    if (user?.privacyConsent.analytics) {
      ReactGA.initialize(analyticsId)
    }
  }, [user])

  if (user?.privacyConsent.analytics) {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }

  return (
    <UserContext.Provider
      value={{
        isMobile,
        isRunningStandalone,
        user,
        setUser,
        views,
        logout,
        token,
        setToken,
        config,
        reload,
        setReload,
        getUuid,
        cModal,
        setCModal,
        cCModal,
        setCCModal,
        categoryHandler,
        category,
        sidebarHandler,
        isSidebarSelected,
        isNameSelectedEvent,
        isNameSelected,
        selectedNameEvent,
        selectedName,
        scrollUp,
        refreshData,
        setRefreshData,
        pageTop,
        isOpen,
        setIsOpen,
        countryList,
      }}
    >
      <span ref={pageTop}></span>
      {/* {user ? <Main /> : <Login />} */}
      <Maintenance />
    </UserContext.Provider>
  )
}

export default Community
