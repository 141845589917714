import { Popover, Transition } from "@headlessui/react"
import {
  ChevronDownIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline"
import { Fragment, useRef } from "react"

const SelectOption = ({
  optionsTitle,
  options,
  choices,
  setChoices,
  format,
  color,
  other,
  field,
}) => {
  const addChoice = useRef(null)

  return (
    <Popover as="div" className={"relative" + format}>
      <Popover.Button
        className={`${
          color && color
        }  relative w-full py-3 px-4 pr-10 text-left bg-white cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500`}
      >
        <span className="flex-none truncate text-lg">{optionsTitle}</span>
        <span className="absolute inset-y-0 right-0 flex items-center justify-end pr-2 pointer-events-none w-full text-2xl appearance-none bg-grey-50 text-grey-500 border p-3">
          <ChevronDownIcon
            className="w-5 h-5 text-gray-600 "
            aria-hidden="true"
          />
        </span>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute z-50 max-w-2xl mt-1 overflow-auto divide-y divide-gray-100 text-base bg-white shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map((option, index) => {
            const isSelected = choices.some((choice) => choice === option)

            return (
              <div
                key={option + index}
                className="relative group flex z-50 items-center w-full h-10 px-4 py-2 text-sm text-gray-800 hover:bg-fblue"
                onClick={() => {
                  setChoices(field, option)
                }}
              >
                <div
                  className={`${
                    isSelected ? "text-fblue text-md" : "font-normal"
                  } flex justify-between truncate w-full`}
                >
                  <p className="ml-6 group-hover:text-white">{option}</p>
                </div>
                {isSelected ? (
                  <span className="text-fblue group-hover:text-white absolute inset-y-0 left-0 flex items-center pl-3">
                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                  </span>
                ) : null}
              </div>
            )
          })}
          {other && (
            <div className="relative group flex z-50 items-center w-full h-10 p-1 text-sm text-gray-800 hover:bg-fblue">
              <input
                className={`font-normal flex justify-between truncate w-full border p-2`}
                ref={addChoice}
              />

              <span className="absolute inset-y-0 right-4 flex items-center pl-3">
                <PlusCircleIcon
                  className="h-4 hover:text-green-500 cursor-pointer"
                  onClick={() => {
                    setChoices(field, addChoice.current.value)
                    addChoice.current.value = ""
                  }}
                />
              </span>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export default SelectOption
